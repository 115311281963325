import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import TagManager from "react-gtm-module";
import "moment/locale/ro";
import I18n from "redux-i18n";
import * as yup from "yup";
import store from "./store/reduxStore";
import history from "./history";
import "./index.css";
import App from "./App";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import AuthRoute from "./Components/AuthRoute";
import Evalueaza from "./Components/ViewerEvaluari/Evalueaza";
import MainPage from "./Components/ViewerEvaluari/MainPage";
import RegisterWizardLayout from "./Components/Layout/RegisterWizardLayout";
import ContNou from "./Components/Inscriere/ContNou";
import Snack from "./Components/General/Snackbar";
import VerifyEmail from "./Components/Inscriere/VerifyEmail";
import Login from "./Components/Layout/Login";
import PlataSucces from "./Components/Inscriere/PlataSucces";
import "./tailwind.generated.css";
import EligibiliList from "./Components/EligibiliList/EligibiliList";
import AdmisiList from "./Components/AdmisiList/AdmisiList";
import "./utils/latinise";

const tagManagerArgs = {
  gtmId: "GTM-MJ634GK",
};

TagManager.initialize(tagManagerArgs);

moment.locale("ro");
yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    background: {
      default: "#f1f2f4",
    },
    primary: { main: "#81C534", dark: "#78B82D" },
    text: { primary: "#3F526C" },
  },
  shadows: Array(25).fill(
    "0px 2px 2px -1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03)"
  ),
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth: "1200px",
      },
      paperWidthSm: {
        maxWidth: "1200px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#8997A6",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #D6DBE0",
        },
      },
    },
    MuiTouchRipple: { ripple: { color: "#3F526C" } },
    MuiPaper: {
      rounded: { borderRadius: "2px" },
      elevation8: {
        boxShadow:
          "0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
      },
    },
    MuiAppBar: {
      colorDefault: {
        zIndex: 999,
        backgroundColor: "#FFF",
        marginBottom: "1px",
      },
    },
    MuiButton: {
      containedPrimary: {
        color: "#fff",
        // textTransform: 'capitalize',
      },
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <I18n translations={{}} useReducer defaultLang="ro" initialLang="ro">
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <Switch>
              <AuthRoute
                exact
                path="/admisi/:registrationType"
                component={AdmisiList}
              />
              <AuthRoute
                exact
                path="/eligibili/:registrationType"
                component={EligibiliList}
              />
              <Route path="/cont-nou" component={ContNou} />
              <Route
                path="/verificare-email/:verificationToken"
                component={VerifyEmail}
              />
              <Route exact path="/inscriere" component={RegisterWizardLayout} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/uitat-parola" component={ForgotPassword} />
              <Route
                exact
                path="/resetare-parola/:resetPasswordToken"
                component={ResetPassword}
              />
              <Route
                path="/evalueaza/:idCampanie/:codDeEvaluare"
                component={Evalueaza}
              />
              <Route path="/evalueaza" component={MainPage} />
              <Route path="/payment/response" component={PlataSucces} />
              <AuthRoute path="/" component={App} />
            </Switch>
          </Router>
          <Snack />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </I18n>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
unregister();
