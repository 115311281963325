import { Satellite } from "@material-ui/icons";
// eslint-disable-next-line import/no-cycle
import { getPosition } from "../actions/inscrisi-ro";
import * as TYPES from "../types";

const inscrisiReducer = (
  state = {
    items: [],
    byId: {},
    isFetching: false,
    candidatType: undefined,
    query: undefined,
    error: undefined,
    dosar: {},
    paymentInfo: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
    position: -1,
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case "getPosition":
      return { ...state, position: payload };
    case TYPES.PAYMENT_INFO_SUCCESS:
      return { ...state, paymentInfo: payload };
    case TYPES.LOGIN_REFRESH_SUCCESS:
      return {
        ...state,
        candidatType: { ...payload.user.candidatType },
      };
    case TYPES.INSCRIS_FINALIZE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          status:
            state.byId.status === "finalizat" ? "plataValidata" : "finalizat",
        },
      };
    case TYPES.INSCRIS_UPLOAD_ANEXA4_UPDATE:
      return { ...state, dosar: { ...state.dosar, uploadatAnexa4: true } };
    case TYPES.INSCRISI_SET_CANDIDAT_TYPE:
      return { ...state, candidatType: { ...payload } };
    case TYPES.INSCRISI_MY_UPDATE_SUCCESS:
      return { ...state, dosar: payload };
    case TYPES.INSCRIS_FETCH_SUCCESS:
      return { ...state, dosar: payload };
    case TYPES.INSCRISI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.INSCRISI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.INSCRISI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.INSCRISI_FETCH_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.INSCRISI_FETCH_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.INSCRISI_FETCH_BYID_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.INSCRISI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.INSCRISI_SEARCH_SUCCESS:
      return {
        ...state,
        items: payload.dosare,
        pageInfo: payload.pageInfo,
        query: action.query,
      };
    case TYPES.INSCRISI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.INSCRISI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.INSCRISI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.INSCRISI_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((dosar) => dosar._id !== payload),
      };
    default:
      return state;
  }
};

export const selectPaymentInfo = (state) => state.inscrisi.paymentInfo;
export const getDosarInscris = (state) => state.inscrisi.dosar;
export const getCandidatType = (state) => state.inscrisi.candidatType;
export const getInscrisi = (state) => state.inscrisi.items;
export const getInscrisiQuery = (state) => state.inscrisi.query;
export const getInscrisiPageInfo = (state) => state.inscrisi.pageInfo;
export const getInscrisById = (state) => state.inscrisi.byId;
export const getIsFetchingInscrisi = (state) => state.inscrisi.isFetching;

export default inscrisiReducer;
