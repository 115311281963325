import * as TYPES from "../types";

const templateuriReducer = (
  state = {
    items: [],
    forDropdown: [],
    byId: {},
    isFetching: false,
    query: undefined,
    error: undefined,
    pageInfo: {
      pageCount: undefined,
      itemCount: undefined,
      page: undefined,
      pageSize: undefined,
    },
  },
  action
) => {
  const { payload, error } = action;
  switch (action.type) {
    case TYPES.TEMPLATEURI_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.TEMPLATEURI_FETCH_SUCCESS:
      return { ...state, isFetching: false, items: payload };
    case TYPES.TEMPLATEURI_FETCH_FAILURE:
      return { ...state, isFetching: false, error };
    case TYPES.TEMPLATEURI_BYID_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.TEMPLATEURI_BYID_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.TEMPLATEURI_BYID_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.TEMPLATEURI_ADD_SUCCESS:
      return { ...state, byId: payload };
    case TYPES.TEMPLATEURI_UPDATE_REQUEST:
      return { ...state, isFetching: true };
    case TYPES.TEMPLATEURI_UPDATE_SUCCESS:
      return { ...state, isFetching: false, byId: payload };
    case TYPES.TEMPLATEURI_UPDATE_FAIL:
      return { ...state, isFetching: false, error };
    case TYPES.TEMPLATEURI_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((dosar) => dosar._id !== payload),
      };
    case TYPES.TEMPLATEURI_SEARCH_SUCCESS:
      return {
        ...state,
        items: payload.templateuri,
        pageInfo: payload.pageInfo,
        query: action.query,
      };
    case TYPES.TEMPLATEURI_DROPDOWN_FETCH_SUCCESS:
      return { ...state, forDropdown: payload };
    default:
      return state;
  }
};

export const getTemplateuri = (state) => state.templateuri.items;
export const getTemplateuriQuery = (state) => state.templateuri.query;
export const getTemplateuriPageInfo = (state) => state.templateuri.pageInfo;
export const getTemplateById = (state) => state.templateuri.byId;
export const getTemplateuriForDropdown = (state) =>
  state.templateuri.forDropdown;
export const getIsFetchingTemplateuri = (state) => state.templateuri.isFetching;

export default templateuriReducer;
