import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import {
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PasswordTextField from "../General/PasswordTextField";
import { register, resendVerificationToken } from "../../actions";
import {
  getUser,
  getCandidatTypeForLoggedInUser,
} from "../../reducers/authReducer";
import { getCandidatType } from "../../reducers/inscrisiReducer";

yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
class ContNou extends Component {
  state = { isPendingEmailVerification: false };

  componentDidMount() {
    if (!this.props.candidatType.candidatType) {
      this.props.history.push("/");
    }
    if (this.props.user.email && !this.props.user.emailVerified) {
      this.setState({ isPendingEmailVerification: true });
    }
  }

  handleSubmit = async (values) => {
    const res = await this.props.register(values);
    if (res) {
      this.setState({ isPendingEmailVerification: true });
    }
  };

  handleSendVerificationToken = () => {
    this.props.resendVerificationToken();
  };

  render() {
    const { t, user, isPendingEmailVerification, isLoading } = this.props;
    const isPendingVerification =
      isPendingEmailVerification || this.state.isPendingEmailVerification;
    if (isPendingVerification) {
      return (
        <div>
          <Typography gutterBottom>
            {t.verifyEmailPart1} {user.email} {t.verifyEmailPart2}
          </Typography>
          <div style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSendVerificationToken}
            >
              {t.requestResendVerificationToken}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        {!isLoading && (
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              acceptTerms: false,
              acceptGDPR: false,
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .required(t.requiredField)
                .email(t.invalidEmail),
              password: yup
                .string()
                .required(t.requiredField)
                .min(6, t.shortPassword)
                .sameAs(yup.ref("confirmpassword"), t.passwordsNoMatch),
              confirmPassword: yup
                .string()
                .required(t.requiredField)
                .sameAs(yup.ref("password"), t.passwordsNoMatch),
              acceptTerms: yup.bool().required(t.required),
              acceptGDPR: yup.bool().required(t.required),
            })}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <TextField
                    margin="dense"
                    fullWidth
                    required
                    label={t.email}
                    name="email"
                    error={!!errors.email && !!touched.email}
                    helperText={
                      !!errors.email && !!touched.email && errors.email
                    }
                    FormHelperTextProps={{ error: true }}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <PasswordTextField
                    required
                    margin="dense"
                    label={t.password}
                    name="password"
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                  />
                  <PasswordTextField
                    margin="dense"
                    required
                    label={t.confirmPassword}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                  />
                  <FormControl margin="dense" fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={values.acceptTerms}
                          onChange={(e, val) =>
                            setFieldValue("acceptTerms", val)
                          }
                          value={values.acceptTerms}
                          name="acceptTerms"
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          Sunt de acord cu prevederile din{" "}
                          <a
                            style={{ color: "#81C534" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://admitere.utcb.ro/wp-content/uploads/2020/07/Acord-online-prelucrare-datelor-caracter-personal-UTCB.pdf"
                          >
                            Acordul online de prelucrare a datelor cu caracter
                            personal
                          </a>
                        </span>
                      }
                    />
                  </FormControl>
                  <FormControl margin="dense" fullWidth required>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={values.acceptGDPR}
                          onChange={(e, val) =>
                            setFieldValue("acceptGDPR", val)
                          }
                          value={values.acceptGDPR}
                          name="acceptGDPR"
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          Sunt de acord cu{" "}
                          <a
                            style={{ color: "#81C534" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://utcb.ro/politica-de-confidentialitate"
                          >
                            Politica de confidentialitate
                          </a>
                        </span>
                      }
                    />
                  </FormControl>
                  <div className="lvd-spacer10" />

                  <Button
                    disabled={!values.acceptGDPR || !values.acceptTerms}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {t.save}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  user: getUser(state),
  candidatType: getCandidatType(state) || getCandidatTypeForLoggedInUser(state),
});

const mapDispatchToProps = {
  register,
  resendVerificationToken,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContNou)
);
