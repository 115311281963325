import countries from "../../../utils/countries.json";

/* eslint-disable no-underscore-dangle */
export default function (t, props, lang) {
  return [
    {
      label: t.identificationData,
      name: "a01",
      type: "textDelimiter",
      captionText: t.identificationDataExplanation,
    },
    {
      type: "children",
      name: "a02",
      children: [
        {
          name: "nume",
          label: t.familyName,
          type: "text",
          required: true,
          helperText: t.familyNameExplanation,
        },
        {
          name: "prenume",
          label: t.firstName,
          type: "text",
          required: true,
        },
        {
          name: "numePurtateAnterior",
          label: t.previousSurnames,
          type: "text",
        },
        {
          name: "stareCivila",
          label: t.maritalStatus,
          type: "select",
          options: [
            {
              label: t.married,
              value: "casatorit",
            },
            {
              label: t.notMarried,
              value: "necasatorit",
            },
          ],
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a03",
      children: [
        {
          name: "prenumeTata",
          label: t.fathersName,
          type: "text",
          required: true,
        },
        {
          name: "prenumeMama",
          label: t.mothersName,
          type: "text",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a04",
      children: [
        {
          name: "sex",
          label: "Sex",
          type: "select",
          options: [
            {
              label: "M",
              value: "masculin",
            },
            {
              label: "F",
              value: "feminin",
            },
          ],
          required: true,
        },
        {
          name: "taraNasterii",
          label: t.countryOfBirth,
          required: true,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
        },
        {
          name: "localitateaNasterii",
          label: t.placeOfBirth,
          required: true,
          type: "text",
        },
        {
          name: "dataNasterii",
          label: t.dateOfBirth,
          type: "datepicker",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a05",
      children: [
        {
          name: "nationalitate",
          label: t.nationality,
          type: "text",
          required: true,
        },
        {
          name: "cetateniePrezent",
          label: t.citizenshipPresent,
          type: "text",
          required: true,
          // options: props.nomenclatoare.cetatenii.map((n) => ({
          //   value: n._id,
          //   label: n.nume,
          // })),
        },
        {
          name: "cetatenieTrecut",
          label: t.citizenshipPast,
          required: true,
          type: "text",
          // options: props.nomenclatoare.cetatenii.map((n) => ({
          //   value: n._id,
          //   label: n.nume,
          // })),
        },
      ],
    },
    {
      type: "children",
      name: "a06",
      children: [
        {
          name: "domiciliuStabilTara",
          label: t.residenceCountry,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
          required: true,
        },
        {
          name: "domiciliuStabilLocalitate",
          label: t.residencePlace,
          type: "text",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a07",
      children: [
        {
          name: "seriePasaport",
          label: t.passportSerial,
          type: "text",
          required: true,
        },
        {
          name: "numarPasaport",
          label: t.passportNumber,
          type: "text",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a08",
      children: [
        {
          name: "pasaportEliberatDe",
          label: t.passportIssuedBy,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
          required: true,
        },
        {
          name: "dataEliberarePasaport",
          label: t.passportIssueDate,
          type: "datepicker",
          required: true,
        },
        {
          name: "valabilitatePasaport",
          label: t.passportValabilityYears,
          type: "datepicker",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a09",
      children: [
        {
          name: "profesia",
          label: t.profession,
          type: "text",
          required: true,
        },
        {
          name: "loculDeMunca",
          label: t.workPlace,
          type: "text",
          required: true,
        },
      ],
    },
    {
      name: "adresaCorespondenta",
      label: t.correspondenceAddress,
      type: "text",
      required: true,
    },
    {
      name: "adresaCorespondentaInfo",
      label: t.correspondenceAddress,
      type: "warning",
    },
    {
      label: t.previousEducation,
      name: "a15",
      type: "textDelimiter",
      captionText: t.previousEducationExplanation,
    },
    {
      type: "children",
      name: "a10",
      children: [
        {
          name: "educatieAnterioara.0.certificat",
          label: t.certificatePreviousEducation,
          type: "text",
          required: true,
        },
        {
          name: "educatieAnterioara.0.tara",
          label: t.countryPreviousEducation,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
          required: true,
        },
        {
          name: "educatieAnterioara.0.universitate",
          label: t.nameOfSchoolPreviousEducation,
          type: "text",
          required: true,
        },
        {
          name: "educatieAnterioara.0.anAdmitere",
          label: t.yearOfAdmissionPreviousEducation,
          type: "text",
          required: true,
        },
        {
          name: "educatieAnterioara.0.anAbsolvire",
          label: t.yearOfGraduationPreviousEducation,
          type: "text",
          required: true,
        },
      ],
    },
    {
      type: "children",
      name: "a11",
      children: [
        {
          name: "educatieAnterioara.1.certificat",
          label: t.certificatePreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.1.tara",
          label: t.countryPreviousEducation,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
          required: false,
        },
        {
          name: "educatieAnterioara.1.universitate",
          label: t.nameOfSchoolPreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.1.anAdmitere",
          label: t.yearOfAdmissionPreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.1.anAbsolvire",
          label: t.yearOfGraduationPreviousEducation,
          type: "text",
          required: false,
        },
      ],
    },
    {
      type: "children",
      name: "a12",
      children: [
        {
          name: "educatieAnterioara.2.certificat",
          label: t.certificatePreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.2.tara",
          label: t.countryPreviousEducation,
          type: "select",
          options: countries.map((n) => ({
            value: n.name,
            label: n.name,
          })),
          required: false,
        },
        {
          name: "educatieAnterioara.2.universitate",
          label: t.nameOfSchoolPreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.2.anAdmitere",
          label: t.yearOfAdmissionPreviousEducation,
          type: "text",
          required: false,
        },
        {
          name: "educatieAnterioara.2.anAbsolvire",
          label: t.yearOfGraduationPreviousEducation,
          type: "text",
          required: false,
        },
      ],
    },
    {
      label: t.studiesAppliedRomania,
      name: "a13",
      type: "textDelimiter",
      captionText: t.studiesAppliedRomaniaCaptionText,
    },
    // {
    //   label: t.openStudyOptionsModal,
    //   name: "studyOptionsModal",
    //   type: "studyOptionsModal",
    // },
    {
      type: "children",
      name: "studyOptChildren",
      children: [
        ...(props?.dosar?.candidatType?.aplr
          ? [
              {
                label: t.study,
                name: "optiuneStudii",
                // helperText: t.studyHelperText,
                type: "select",
                required: true,
                options: [
                  {
                    label: "Secondary grade",
                    value: "secondary_grade",
                  },
                  {
                    label: "Undergraduate",
                    value: "undergraduate",
                  },
                  {
                    label: "Master",
                    value: "master",
                  },
                  {
                    label: "Postgraduate medical education",
                    value: "postgraduate_medical_education",
                  },
                  {
                    label: "Ph.D.",
                    value: "phd",
                  },
                ],
              },
              {
                label: t.studyOptionSpecialty,
                name: "optiuneStudiiDomeniu",
                required: true,
                type: "text",
              },
              {
                label: t.studyOptionHighschoolUniversity,
                name: "optiuneStudiiLiceuUniversitate",
                required: true,
                type: "text",
              },
              {
                label: t.studyOptionLanguage,
                name: "optiuneStudiiLimba",
                required: true,
                type: "select",
                options: [
                  {
                    label: "RO",
                    value: "ro",
                  },
                  {
                    label: "EN",
                    value: "en",
                  },
                  {
                    label: "FR",
                    value: "fr",
                  },
                ],
              },
            ]
          : [
              {
                type: "select",
                name: "optiuneStudiiV2",
                label: t.study,
                options: props.nomenclatoare.optiuniStudii.map((n) => ({
                  value: n._id,
                  label: n.trans[lang],
                })),
              },
              // {
              //   label: t.study,
              //   name: "optiuneStudii",
              //   // helperText: t.studyHelperText,
              //   type: "select",
              //   required: true,
              //   options: [
              //     {
              //       label: "Secondary grade",
              //       value: "secondary_grade",
              //     },
              //     {
              //       label: "Undergraduate",
              //       value: "undergraduate",
              //     },
              //     {
              //       label: "Master",
              //       value: "master",
              //     },
              //     {
              //       label: "Postgraduate medical education",
              //       value: "postgraduate_medical_education",
              //     },
              //     {
              //       label: "Ph.D.",
              //       value: "phd",
              //     },
              //   ],
              // },
            ]),
        // {
        //   label: t.studyAPLR,
        //   name: "optiuneStudiiAPLR",
        //   // helperText: t.studyHelperText,
        //   type: "realCheckbox",
        // },
      ],
    },
    {
      label: t.proficiencyInOtherLanguages,
      name: "a14",
      type: "textDelimiter",
    },
    {
      type: "children",
      name: "a15",
      children: [
        {
          label: t.language,
          name: "alteLimbi.0.limba",
          type: "text",
        },
        {
          label: t.writing,
          name: "alteLimbi.0.scris",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.speaking,
          name: "alteLimbi.0.vorbit",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.institution,
          name: "alteLimbi.0.institutie",
          type: "text",
        },
      ],
    },
    {
      type: "children",
      name: "a16",
      children: [
        {
          label: t.language,
          name: "alteLimbi.1.limba",
          type: "text",
        },
        {
          label: t.writing,
          name: "alteLimbi.1.scris",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.speaking,
          name: "alteLimbi.1.vorbit",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.institution,
          name: "alteLimbi.1.institutie",
          type: "text",
        },
      ],
    },
    {
      type: "children",
      name: "a17",
      children: [
        {
          label: t.language,
          name: "alteLimbi.2.limba",
          type: "text",
        },
        {
          label: t.writing,
          name: "alteLimbi.2.scris",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.speaking,
          name: "alteLimbi.2.vorbit",
          type: "select",
          options: [
            {
              label: t.excellent,
              value: "excellent",
            },
            {
              label: t.good,
              value: "good",
            },
            {
              label: t.poor,
              value: "poor",
            },
          ],
        },
        {
          label: t.institution,
          name: "alteLimbi.2.institutie",
          type: "text",
        },
      ],
    },
    // {
    //   name: "acceptDeclaratie",
    //   label: t.declaration,
    //   type: "realCheckbox",
    //   required: true,
    // },
  ];
}
