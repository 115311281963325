import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import request from "superagent";
import { Button, Typography } from "@material-ui/core";
import FileSaver from "file-saver";
import { showSnackbar } from "../../../actions";
import { serverUrl } from "../../../utils";
import { getToken } from "../../../reducers/authReducer";
/* eslint-disable no-underscore-dangle */
class Cerere extends Component {
  requestPdf = async () => {
    const { jwt, dosar } = this.props;
    try {
      const res = await request
        .get(`${serverUrl}/inscrisi/${dosar._id}/generate-pdf`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar("Internal Server Error: generate-pdf", "error");
    }
  };

  render() {
    const { dosar } = this.props;
    return (
      <div>
        {/* {dosar.status === "trimisCatreValidare" ? ( */}
        <Button variant="contained" color="primary" onClick={this.requestPdf}>
          Downloadeaza pdf
        </Button>
        {/* : (  <Typography>Dosarul nu este in stadiul corect</Typography>
         )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jwt: getToken(state),
});

const mapDispatchToProps = { showSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(Cerere);
