import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import { Switch, Route, Link } from "react-router-dom";
import startCase from "lodash/startCase";
import "./App.css";
import { LinearProgress } from "@material-ui/core";
import footer from "./svg/logo-footer.svg";
import NavList from "./Components/Layout/NavList";
import Main from "./Components/Main";
import DoctoranziList from "./Components/Dosare/DoctoranziList";
import CandidatiList from "./Components/Dosare/CandidatiList";
import ConducatoriList from "./Components/Dosare/ConducatoriList";
import ELearning from "./Components/ELearning/ELearning";
import Evaluare from "./Components/Evaluare/EvaluareList";
import EvaluareAdd from "./Components/Evaluare/EvaluareAdd";
import EvaluareEdit from "./Components/Evaluare/EvaluareEdit";
import EvaluareView from "./Components/Evaluare/EvaluareView";
import Publicatii from "./Components/Publicatii/Publicatii";
import Videoconferinte from "./Components/Videoconferinte/Videoconferinte";
import ConducatoriAdd from "./Components/Dosare/ConducatoriAdd";
import CandidatiAdd from "./Components/Dosare/CandidatiAdd";
import { breadCrumbsMap } from "./utils";
import NomenclatoareList from "./Components/Nomenclatoare/NomenclatoareList";
import DoctoranziAdd from "./Components/Dosare/DoctoranziAdd";
import TipuriDeDocumente from "./Components/Nomenclatoare/TipuriDeDocumente";
import AddCurs from "./Components/ELearning/AddCurs";
import EditCurs from "./Components/ELearning/EditCurs";
import AddVideoconferinta from "./Components/Videoconferinte/AddVideoconferinta";
import UtilizatoriList from "./Components/Utilizatori/UtilizatoriList";
import AddUtilizator from "./Components/Utilizatori/AddUtilizator";
import { refreshLogin, logout } from "./actions";
import { getUser, getAppBarTitle } from "./reducers/authReducer";
import EditUtilizator from "./Components/Utilizatori/EditUtilizator";
import CandidatiEdit from "./Components/Dosare/CandidatiEdit";
import CandidatiView from "./Components/Dosare/CandidatiView";
import ConducatoriEdit from "./Components/Dosare/ConducatoriEdit";
import ConducatoriView from "./Components/Dosare/ConducatoriView";
import DoctoranziView from "./Components/Dosare/DoctoranziView";
import DoctoranziEdit from "./Components/Dosare/DoctoranziEdit";
import DosarulMeu from "./Components/DosarulMeu/DosarulMeu";
import AuthRoute from "./Components/AuthRoute";
import EditVideoconferinta from "./Components/Videoconferinte/EditVideoconferinta";
import ViewVideoconferinta from "./Components/Videoconferinte/ViewVideoconferinta";
import EditPublicatie from "./Components/Publicatii/EditPublicatie";
import ViewPublicatie from "./Components/Publicatii/ViewPublicatie";
import AddPublicatie from "./Components/Publicatii/AddPublicatie";
import TemplateList from "./Components/Templateuri/TemplateList";
import EditTemplate from "./Components/Templateuri/EditTemplate";
import ViewTemplate from "./Components/Templateuri/ViewTemplate";
import AddTemplate from "./Components/Templateuri/AddTemplate";
import InscrisiList from "./Components/Dosare/Inscrisi/InscrisiList";
import InscrisiEdit from "./Components/Dosare/Inscrisi/InscrisiEdit";
import InscrisiNonEuList from "./Components/Dosare/Inscrisi-NON-EU/InscrisiList";
import InscrisiNonEuEdit from "./Components/Dosare/Inscrisi-NON-EU/InscrisiEdit";
import GeneralList from "./Components/Dosare/GeneralList/GeneralList";
import Distribuire from "./Components/Distribuire/Distribuire";
import Settings from "./Components/Settings/Settings";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  flex: {
    flexGrow: 1,
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 2px -1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03)",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class App extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    refreshLogin: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    appBarTitle: PropTypes.string,
  };

  static defaultProps = {
    appBarTitle: undefined,
  };

  state = {
    mobileOpen: false,
    open: false,
    anchorEl: null,
    authenticating: false,
  };

  async componentDidMount() {
    this.setState({ authenticating: true });
    await this.props.refreshLogin();
    this.setState({ authenticating: false });
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  render() {
    const { classes, theme, user, appBarTitle, t } = this.props;
    const { anchorEl, authenticating } = this.state;
    const openUser = Boolean(anchorEl);
    let pathSnippets = this.props.location.pathname
      .split("/")
      .filter((x) => x !== "");
    const isEditOrView =
      pathSnippets.indexOf("editeaza") < 0
        ? pathSnippets.indexOf("vizualizeaza")
        : pathSnippets.indexOf("editeaza");
    if (isEditOrView >= 0) {
      pathSnippets = pathSnippets.slice(0, isEditOrView + 1);
    }
    const breadCrumbs = [
      <Link as="a" to="/" href="/" key="home">
        UTCB
      </Link>,
      ...pathSnippets.map((crumb, index) => (
        <React.Fragment key={`${crumb}`}>
          <span key={`${crumb}/`}>\</span>
          {index === pathSnippets.length - 1 ? (
            <span key={crumb}>{breadCrumbsMap[crumb] || startCase(crumb)}</span>
          ) : (
            <span key={crumb}>{breadCrumbsMap[crumb] || startCase(crumb)}</span>
            // <Link as="a" to={`/${crumb}`} href={`/${crumb}`} key={crumb}>
            //   {breadCrumbsMap[crumb] || startCase(crumb)}
            // </Link>
          )}
        </React.Fragment>
      )),
    ];
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
              classes={{
                root: "lvd-drawer-icon",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              noWrap
              className={classes.flex}
            >
              {appBarTitle ||
                breadCrumbsMap[pathSnippets[pathSnippets.length - 1]] ||
                startCase(pathSnippets[pathSnippets.length - 1])}
            </Typography>
            <div>
              <IconButton
                aria-owns={openUser ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="primary"
                className="lvd-account-icon"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openUser}
                onClose={this.handleClose}
              >
                <MenuItem
                  component={Link}
                  to="/profil"
                  href="/profil"
                  onClick={this.handleClose}
                >
                  {user.email}
                </MenuItem>
                <MenuItem onClick={this.handleLogout}>Iesi din cont</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <NavList />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
            style={{
              height: "100%",
            }}
          >
            <NavList />
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {authenticating && (
            <LinearProgress style={{ top: "-13px", left: "-8px" }} />
          )}
          <div className="lvd-breadcrumbs">{breadCrumbs}</div>
          <Switch>
            <Route exact path="/" component={Main} />
            <AuthRoute
              path="/doctoranzi/editeaza/:dosarId"
              component={DoctoranziEdit}
            />
            <AuthRoute
              path="/doctoranzi/vizualizeaza/:dosarId"
              component={DoctoranziView}
            />
            <AuthRoute path="/doctoranzi/adauga" component={DoctoranziAdd} />
            <AuthRoute path="/doctoranzi" component={DoctoranziList} />
            <AuthRoute
              path="/inscrisi-ro/editeaza/:dosarId"
              adminOnly
              component={InscrisiEdit}
            />
            <AuthRoute
              path="/inscrisi-ro/:candidatType"
              component={GeneralList}
            />
            <AuthRoute path="/inscrisi-ro" component={GeneralList} />
            <AuthRoute
              path="/inscrisi-noneu/editeaza/:dosarId"
              adminOnly
              component={InscrisiNonEuEdit}
            />
            <AuthRoute path="/inscrisi-noneu" component={InscrisiNonEuList} />
            <AuthRoute
              path="/conducatori/editeaza/:dosarId"
              component={ConducatoriEdit}
            />
            <AuthRoute
              path="/conducatori/vizualizeaza/:dosarId"
              component={ConducatoriView}
            />
            <AuthRoute path="/conducatori/adauga" component={ConducatoriAdd} />
            <AuthRoute path="/conducatori" component={ConducatoriList} />
            <AuthRoute
              path="/candidati/editeaza/:dosarId"
              component={CandidatiEdit}
            />
            <AuthRoute
              path="/candidati/vizualizeaza/:dosarId"
              component={CandidatiView}
            />
            <AuthRoute path="/candidati/adauga" component={CandidatiAdd} />
            <AuthRoute path="/candidati" component={CandidatiList} />
            <Route path="/elearning/editeaza/:cursId" component={EditCurs} />
            <Route path="/elearning/adauga" component={AddCurs} />
            <Route path="/elearning" component={ELearning} />
            <Route
              path="/evaluari/editeaza/:evaluareId"
              component={EvaluareEdit}
            />
            <Route
              path="/evaluari/vizualizeaza/:evaluareId"
              component={EvaluareView}
            />
            <Route path="/evaluari/adauga" component={EvaluareAdd} />
            <Route path="/evaluari" component={Evaluare} />
            <Route
              path="/publicatii/editeaza/:publicatieId"
              component={EditPublicatie}
            />
            <Route
              path="/publicatii/vizualizeaza/:publicatieId"
              component={ViewPublicatie}
            />
            <Route path="/publicatii/adauga/" component={AddPublicatie} />
            <Route path="/publicatii" component={Publicatii} />
            <Route
              path="/templateuri/editeaza/:templateId"
              component={EditTemplate}
            />
            <Route
              path="/templateuri/vizualizeaza/:templateId"
              component={ViewTemplate}
            />
            <Route path="/templateuri/adauga/" component={AddTemplate} />
            <Route path="/templateuri" component={TemplateList} />
            <AuthRoute
              path="/videoconferinte/vizualizeaza/:conferintaId"
              component={ViewVideoconferinta}
            />
            <AuthRoute
              path="/videoconferinte/editeaza/:conferintaId"
              component={EditVideoconferinta}
            />
            <AuthRoute
              path="/videoconferinte/adauga"
              component={AddVideoconferinta}
            />
            <AuthRoute path="/settings" component={Settings} />
            <AuthRoute path="/videoconferinte" component={Videoconferinte} />
            <AuthRoute path="/nomenclatoare" component={NomenclatoareList} />
            <AuthRoute path="/documente" component={TipuriDeDocumente} />
            <AuthRoute
              path="/utilizatori/editeaza/:userId"
              component={EditUtilizator}
            />
            <AuthRoute path="/utilizatori/adauga" component={AddUtilizator} />
            <AuthRoute path="/utilizatori" component={UtilizatoriList} />
            <AuthRoute path="/distribuire" component={Distribuire} />
            <Route path="/dosar" component={DosarulMeu} />
            <Route path="/conferinte" component={Videoconferinte} />
          </Switch>
          <div className="lvd-footer">
            <img src={footer} alt="footer" />
            <span style={{ color: theme.palette.text.primary }}>UTCB</span>
            <br />
            <span style={{ color: theme.palette.text.primary }}>
              Developed by{" "}
              <a
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "none",
                }}
                href="http://livedesign.ro"
              >
                Live Design
              </a>
            </span>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  appBarTitle: getAppBarTitle(state),
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
});

export default connect(mapStateToProps, { refreshLogin, logout })(
  withStyles(styles, { withTheme: true })(App)
);
