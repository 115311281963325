import runtimeEnv from "@mars/heroku-js-runtime-env";

// const env = runtimeEnv();
export const serverUrl = process.env.REACT_APP_API_URL;
export const mobilpayUrl = process.env.REACT_APP_MOBILPAY_URL;
export const getPrimaOptiuneString = (dosar) => {
  let optiune = "";
  if (dosar?.optiuniDistribuire?.length > 0) {
    const opt = dosar?.optiuniDistribuire[0]?.label;
    optiune = `${opt.split(" | ")[0]} ${opt.split(" | ")[2]}`;
  }
  return optiune;
};
// env.REACT_APP_API_URL;
export const breadCrumbsMap = {
  candidati: "Candidati la abilitare",
  conducatori: "Conducatori de doctorat",
  documente: "Tipuri de documente",
  elearning: "E-learning",
  sustinereamea: "Sustinerea mea",
  teme: "Teme de doctorat",
  doctoranziimei: "Doctoranzii mei",
  dosarulmeu: "Dosarul meu",
};
