import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button,
  withStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Checkbox,
  FormLabel,
  Grid,
  InputLabel,
  FormHelperText,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanel,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { showSnackbar, login, setCandidatType } from "../actions";

const styles = () => {};

class RegisterForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    theme: PropTypes.shape({}).isRequired,
  };

  state = {
    candidatType: "ro",
    registerFor: "licenta", // TODO: FOR MASTER
    translationAndInterpretation: false,
    expansionOpen: true,
  };

  componentDidMount() {
    const sp = new URLSearchParams(this.props.location.search);
    if (sp.get("registerFor")) {
      this.setState({
        registerFor: sp.get("registerFor"),
        candidatType: sp.get("candidatType"),
        translationAndInterpretation:
          sp.get("translationAndInterpretation") === "true",
        dppd: sp.get("dppd") === "true",
        colegiu: sp.get("colegiu") === "true",
        expansionOpen: false,
      });
    }
  }

  handleSubmit = async (values) => {
    this.props.setCandidatType(values);
    this.props.history.push("/inscriere");
  };

  handleExpansionOpen = () => {
    this.setState(({ expansionOpen }) => ({
      expansionOpen: !expansionOpen,
    }));
  };

  render() {
    const { t } = this.props;
    const { expansionOpen } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            // candidatType: "non-eu",
            // registerFor: this.state.registerFor,
            // translationAndInterpretation: this.state
            //   .translationAndInterpretation,
            candidatType: this.state.candidatType || "ro",
            registerFor: this.state.registerFor,
            translationAndInterpretation: this.state
              .translationAndInterpretation,
            dppd: this.state.dppd,
            colegiu: this.state.colegiu,
            rdp: this.state.rdp,
          }}
          validationSchema={yup.object().shape({
            candidatType: yup.string().required(t.requiredField),
            registerFor: yup.string().required(t.requiredField),
          })}
          onSubmit={this.handleSubmit}
          render={({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <ExpansionPanel
                elevation={0}
                style={{
                  borderColor: "#d2d6dc",
                  borderWidth: "1px",
                  boxShadow: "none",
                  borderRadius: "5px",
                }}
                expanded={expansionOpen}
              >
                <ExpansionPanelSummary onClick={this.handleExpansionOpen}>
                  {t.changeRegistrationOptions}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={2} style={{ marginTop: "12px" }}>
                    <Grid item xs={12}>
                      {/* TODO: FOR MASTER */}
                      <FormControl fullWidth>
                        <InputLabel>{t.registeringFor}</InputLabel>
                        <Select
                          onChange={(e) => {
                            setFieldValue("registerFor", e.target.value);
                            if (e.target.value === "master") {
                              setFieldValue(
                                "translationAndInterpretation",
                                false
                              );
                            }
                          }}
                          value={values.registerFor}
                          name="registerFor"
                        >
                          <MenuItem value="licenta">{t.bachelor}</MenuItem>
                          <MenuItem value="master">{t.master}</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {!!errors.registerFor &&
                            !!touched.registerFor &&
                            errors.registerFor}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>{t.tipInscriere}</InputLabel>
                        <Select
                          name="translationAndInterpretation"
                          value={
                            values.translationAndInterpretation
                              ? "translationAndInterpretation"
                              : values.dppd
                              ? "dppd"
                              : "engineering"
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "translationAndInterpretation",
                              e.target.value === "translationAndInterpretation"
                            );
                            setFieldValue("dppd", e.target.value === "dppd");
                          }}
                        >
                          <MenuItem value="engineering">
                            {t.engineering}
                          </MenuItem>
                          {values.registerFor !== "master" && (
                            <MenuItem value="translationAndInterpretation">
                              {t.translationAndInterpretation}
                            </MenuItem>
                          )}
                          {values.registerFor === "master" && (
                            <MenuItem value="dppd">DPPD</MenuItem>
                          )}
                        </Select>
                        <FormHelperText />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.translationAndInterpretation}
                              onChange={(e, val) =>
                                setFieldValue(
                                  "translationAndInterpretation",
                                  val
                                )
                              }
                              value="translationAndInterpretation"
                              name="translationAndInterpretation"
                              color="primary"
                            />
                          }
                          label={t.translationAndInterpretation}
                        />
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          {t.candidateType}
                        </FormLabel>

                        <RadioGroup
                          onChange={handleChange}
                          name="candidatType"
                          value={values.candidatType}
                        >
                          <FormControlLabel
                            value="ro"
                            control={<Radio color="primary" />}
                            label={t.roCandidate}
                            // disabled
                            // label={t.euCandidate}
                          />
                          <FormControlLabel
                            value="non-eu"
                            control={<Radio color="primary" />}
                            label={t.nonEuCandidate}
                          />
                        </RadioGroup>
                        <FormHelperText error>
                          {!!errors.candidatType &&
                            !!touched.candidatType &&
                            errors.candidatType}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div className="lvd-spacer20" />
              {/* <Typography variant="h5">
                {values.registerFor !== "" &&
                  `${t.registerFormOne} ${t.registerFor(values.registerFor)}${
                    values.translationAndInterpretation
                      ? ` (${t.translationAndInterpretation}). `
                      : ". "
                  }`}
                {values.candidatType !== "" &&
                  `${
                    values.candidatType === "ro"
                      ? t.registerFormRoCandidateType
                      : t.registerFormNonEuCandidateType
                  }`}
              </Typography> */}
              <div className="lvd-spacer20" />
              <Button variant="contained" color="primary" type="submit">
                {t.startRegistration}
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
});

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, { showSnackbar, login, setCandidatType })(
      RegisterForm
    )
  )
);
