/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { AppBar, Tabs, Tab, Paper, Button } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import * as yup from "yup";
// import _ from 'lodash';
import {
  fetchNomenclatoare,
  fetchTipuriDeDocumente,
  fetchConducatorById,
  updateConducator,
  setAppBarTitle,
} from "../../actions";
import DocumentTable from "../General/DocumentTable";
import AddEditForm from "../General/AddEditForm";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import { getConducatorById } from "../../reducers/conducatoriReducer";

class ConducatoriEdit extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    fetchConducatorById: PropTypes.func.isRequired,
    setAppBarTitle: PropTypes.func.isRequired,
    updateConducator: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    dosar: PropTypes.shape({
      nume: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    dosar: undefined,
  };

  state = {
    inputs: [],
    currentTab: 0,
  };

  static getDerivedStateFromProps = (props, state) => ({
    inputs: [
      {
        name: "email",
        label: "Email",
        type: "text",
        disabled: true,
      },
      {
        name: "nume",
        label: "Nume",
        type: "text",
        required: true,
      },
      {
        name: "prenume",
        label: "Prenume",
        type: "text",
        required: true,
      },
      {
        name: "cnp",
        label: "CNP",
        type: "text",
      },
      {
        name: "titulatura",
        label: "Titulatura",
        type: "text",
      },
      {
        name: "anObtinereConducere",
        label: "An Obtinere Conducere",
        type: "number",
      },
      {
        name: "facultate",
        label: "Facultate",
        type: "select",
        options: props.nomenclatoare.facultati.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "departament",
        label: "Departament",
        type: "select",
        options: props.nomenclatoare.departamente.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "domeniuDoctorat",
        label: "Domeniu Doctorat",
        type: "select",
        options: props.nomenclatoare.domenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "specializari",
        label: "Specializari",
        type: "chips",
        options: props.nomenclatoare.specializari.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "comisiaDeAbilitare",
        label: "Comisia de abilitare",
        type: "text",
      },
      {
        name: "telefon",
        label: "Telefon",
        type: "text",
      },
      {
        name: "linkScopus",
        label: "Link Scopus",
        type: "text",
      },
      {
        name: "linkProfilUtcb",
        label: "Link Profil UTCB",
        type: "text",
      },
      {
        name: "linkGoogleScholar",
        label: "Link Google Scholar",
        type: "text",
      },
      {
        name: "linkISIWebOfScience",
        label: "Link ISI Web Of Science",
        type: "text",
      },
      {
        name: "linkResearchGate",
        label: "Link Research Gate",
        type: "text",
      },
      {
        name: "hGoogleScholar",
        label: "H Google Scholar",
        type: "number",
      },
      {
        name: "hWebOfScience",
        label: "H Web Of Science",
        type: "number",
      },
      {
        name: "hScopus",
        label: "H Scopus",
        type: "number",
      },
    ],
    tipuriDocumente: props.tipuriDeDocumente.candidati,
  });

  async componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchTipuriDeDocumente();
    if (this.props.isUserView)
      await this.props.fetchConducatorById(this.props.dosarId);
    else await this.props.fetchConducatorById(this.props.match.params.dosarId);
    this.props.setAppBarTitle(
      `${this.props.dosar.nume} ${this.props.dosar.prenume}`
    );
  }

  componentWillUnmount() {
    this.props.setAppBarTitle(undefined);
  }

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  handleBackClick = () => {
    this.props.history.push("/conducatori");
  };

  handleSubmit = async (values) => {
    await this.props.updateConducator(values);
  };

  renderTabContent = () => {
    const { inputs, tipuriDocumente, currentTab } = this.state;
    const { dosar } = this.props;
    switch (currentTab) {
      case 0:
        return (
          <AddEditForm
            name="candidati"
            inputs={inputs}
            isViewing={false}
            handleSubmit={this.handleSubmit}
            validationSchema={yup.object().shape({
              nume: yup.string().required("Camp obligatoriu"),
              prenume: yup.string().required("Camp obligatoriu"),
              // cnp: yup.string().required('Camp obligatoriu'),
              // email: yup.string().required('Camp obligatoriu'),
              // nume: yup.string().required('Camp obligatoriu'),
              // prenume: yup.string().required('Camp obligatoriu'),
              // titulatura: yup.string().required('Camp obligatoriu'),
              // anObtinereConducere: yup.number().required('Camp obligatoriu'),
              // facultate: yup
              //   .object()
              //   .transform((value, originalValue) => {
              //     console.log(value);
              //     console.log(originalValue);
              //     return originalValue === null || !originalValue.value ? undefined : value;
              //   })
              //   .required('Camp obligatoriu'),
              // departament: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // domeniuDoctorat: yup
              //   .object()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // specializari: yup
              //   .array(yup.object())
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) =>
              //       (originalValue === null || !originalValue.value ? undefined : value)),
              // telefon: yup.string().required('Camp obligatoriu'),
              // linkProfilUtcb: yup.string().required('Camp obligatoriu'),
              // linkScopus: yup.string().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string().required('Camp obligatoriu'),
              // linkISIWebOfScience: yup.string().required('Camp obligatoriu'),
              // linkResearchGate: yup.string().required('Camp obligatoriu'),
              // hGoogleScholar: yup.string().required('Camp obligatoriu'),
              // hWebOfScience: yup.string().required('Camp obligatoriu'),
              // hScopus: yup.string().required('Camp obligatoriu'),
            })}
            initialValues={{
              nume: "",
              prenume: "",
              cnp: "",
              email: "",
              titulatura: "",
              anObtinereConducere: "",
              facultate: {},
              departament: {},
              domeniuDoctorat: {},
              specializari: [],
              telefon: "",
              linkProfilUtcb: "",
              linkScopus: "",
              linkGoogleScholar: "",
              linkISIWebOfScience: "",
              linkResearchGate: "",
              hGoogleScholar: "",
              hWebOfScience: "",
              hScopus: "",
              ...dosar,
            }}
          />
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            tipuriDocumente={tipuriDocumente}
            documente={dosar.documente}
            type="edit"
            name="conducatori"
          />
        );
      default:
        return "Unknown tab";
    }
  };

  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        {!this.props.stayAfterEdit && (
          <div className="lvd-control-buttons">
            <Button
              onClick={this.handleBackClick}
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
            >
              <ArrowBackIos />
              Inapoi
            </Button>
          </div>
        )}
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label="Dosar" />
            <Tab label="Documente" />
          </Tabs>
        </AppBar>
        <Paper className="lvd-container-form">{this.renderTabContent()}</Paper>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  nomenclatoare: state.nomenclatoare,
  tipuriDeDocumente: getTipuriDeDocumente(state),
  dosar: getConducatorById(state),
});
export default withRouter(
  connect(mapStateToProps, {
    fetchConducatorById,
    fetchNomenclatoare,
    fetchTipuriDeDocumente,
    updateConducator,
    setAppBarTitle,
  })(ConducatoriEdit)
);
