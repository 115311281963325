import React from "react";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import FileSaver from "file-saver";
import { Button } from "@material-ui/core";
import { getToken } from "../../../reducers/authReducer";
import { getInscrisById } from "../../../reducers/inscrisiReducer";
import { serverUrl } from "../../../utils";
import { showSnackbar } from "../../../actions";

export default function Contract() {
  const jwt = useSelector(getToken);
  const dosar = useSelector(getInscrisById);
  const dispatch = useDispatch();
  const handleSendReminder = async () => {
    try {
      const res = await request
        .get(`${serverUrl}/inscrisi-ro/${dosar._id}/trimite-reminder`)
        .set("Authorization", `Bearer ${jwt}`);
      dispatch(showSnackbar("Reminderul a fost trimis!", "success"));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
    }
  };
  const handleDownloadContract = async () => {
    try {
      const res = await request
        .get(
          `${serverUrl}/inscrisi-ro/${dosar._id}/generate-contract-scolarizare`
        )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      dispatch(showSnackbar("Error: generate-contract", "error"));
    }
  };
  const handleDownloadTaxa = async () => {
    try {
      const res = await request
        .get(
          `${serverUrl}/inscrisi-ro/${dosar._id}/generate-act-aditional-taxa`
        )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      dispatch(showSnackbar("Error: generate-act-aditional-taxa", "error"));
    }
  };
  const handleDownloadAPLR = async () => {
    try {
      const res = await request
        .get(`${serverUrl}/inscrisi-ro/${dosar._id}/generate-aplr-document`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      dispatch(showSnackbar("Error: generate-aplr-document", "error"));
    }
  };
  return (
    <div className="space-y-4">
      <div>
        {dosar.hasSignedContract ? (
          <div>Contractul a fost semnat</div>
        ) : (
          <Button
            disabled={dosar.hasSignedContract}
            onClick={handleSendReminder}
            variant="contained"
          >
            Trimite reminder
          </Button>
        )}
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadContract}
        >
          Descarca contract
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadTaxa}
        >
          Descarca act aditional taxa
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadAPLR}
        >
          Descarca document APLR
        </Button>
      </div>
    </div>
  );
}
