import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import pickBy from "lodash/pickBy";
import AddIcon from "@material-ui/icons/Add";
import ReactTable from "react-table";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  withMobileDialog,
  withStyles,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import "react-table/react-table.css";
import RefreshIcon from "@material-ui/icons/Refresh";
import { connect } from "react-redux";
import DeleteModal from "./DeleteModal";
import FilterModal from "./FilterModal";
import * as utils from "../../utils/helpers";
import { showSnackbar } from "../../actions";

const styles = (theme) => ({
  tablePaper: {
    // height: 'calc(100vh - 400px)',
  },
});

class TableWithFilter extends Component {
  static propTypes = {
    addButtonText: PropTypes.string,
    fetchItems: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    applyFilter: PropTypes.func.isRequired,
    onExport: PropTypes.func,
    onExportDosar: PropTypes.func,
    handleDownloadCoduri: PropTypes.func,
    handleDownloadCampaign: PropTypes.func,
    handleDownloadResults: PropTypes.func,
    hideAddButton: PropTypes.bool,
    showDeleteAllButton: PropTypes.bool,
  };

  static defaultProps = {
    addButtonText: "Adauga",
    hideAddButton: false,
    onExport: null,
    onExportDosar: null,
    handleDownloadCoduri: () => {},
    handleDownloadCampaign: () => {},
    handleDownloadResults: () => {},
    showDeleteAllButton: false,
  };

  state = {
    openModal: false,
    columns: [],
    anchorEl: null,
    id: undefined,
    filterNo: 0,
    query: undefined,
    deleteName: undefined,
    openDeleteModal: false,
    selectedItem: {},
    openDeleteAllModal: false,
    openTransformModal: false,
  };

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    return {
      ...state,
      columns: props.baseColumns,
      filterConfig: props.filterConfig,
      query:
        props.query === undefined || props.query ? props.query : state.query,
    };
  };

  componentDidMount() {
    this.divRef.current.scrollIntoView({
      behavior: "smooth",
    });

    if (this.props.query) {
      this.props.applyFilter(this.props.query, {}, this.props.candidatType);
      this.handleSetFilterNo(utils.countFilters(this.props.query));
    } else {
      this.props.fetchItems({}, {}, this.props.candidatType);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.candidatType !== prevProps.candidatType) {
      if (this.props.query) {
        this.props.applyFilter(this.props.query, {}, this.props.candidatType);
        this.handleSetFilterNo(utils.countFilters(this.props.query));
      } else {
        this.props.fetchItems({}, {}, this.props.candidatType);
      }
    }
  }

  handleViewClick = () => {
    this.props.history.push(
      `/${this.props.name}/vizualizeaza/${this.state.id}`
    );
    this.handleClose();
  };

  handleEditClick = () => {
    this.props.history.push(`/${this.props.name}/editeaza/${this.state.id}`);
    this.handleClose();
  };

  handleDelete = () => {
    this.props.onDelete(this.state.id);
    this.setState({
      openDeleteModal: false,
      deleteName: this.props.deleteName,
    });
    this.handleClose();
  };

  handleDeleteClick = () => {
    this.setState({
      openDeleteModal: true,
      deleteName: this.props.deleteName,
    });
  };

  handleOpenMenu = (event, id, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      id,
      selectedItem: item,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      id: null,
    });
  };

  openFilterModal = () => {
    this.setState({
      openModal: true,
    });
  };

  handleCloseFilterModal = (action, func) => {
    if (action === "reset") {
      this.setState(({ columns, filter }) => ({
        openModal: false,
        filter: {},
        query: {},
        // columns: columns.map((column) => ({ ...column, show: false })),
      }));
    } else {
      this.setState({
        openModal: false,
      });
    }
  };

  handleSubmit = (values) => {
    const filter = pickBy(values);
    this.setState(({ columns }) => ({
      filter,
      columns: columns.map((column) =>
        filter[column.name]
          ? { ...column, show: true }
          : { ...column, show: false }
      ),
    }));
  };

  handleAddClick = () => {
    this.props.history.push(`/${this.props.name}/adauga`);
  };

  handlePageChange = (page) => {
    const { query } = this.state;
    const { pageInfo } = this.props;
    this.props.applyFilter(
      query,
      { pageSize: pageInfo.pageSize, page },
      this.props.candidatType
    );
  };

  handlePageSizeChange = (pageSize) => {
    const { query } = this.state;
    const { pageInfo } = this.props;
    this.props.applyFilter(
      query,
      { ...pageInfo, pageSize, page: 0 },
      this.props.candidatType
    );
  };

  handleRefresh = async () => {
    const { query } = this.state;
    const { pageInfo } = this.props;
    await this.props.applyFilter(query, pageInfo, this.props.candidatType);
    this.props.showSnackbar("Au fost aduse date noi", "success");
  };

  handleSetQuery = (query) => {
    const { pageInfo } = this.props;
    this.setState({
      query,
    });
    this.props.applyFilter(
      query,
      {
        ...pageInfo,
        pageSize: pageInfo.pageSize,
        page: 0,
      },
      this.props.candidatType
    );
  };

  handleSetFilterNo = (filterNo) => {
    this.setState({
      filterNo,
    });
  };

  handleExport = () => {
    const { query } = this.state;
    this.props.onExport(query);
    this.handleClose();
  };

  handleExportDosar = async () => {
    const { id, selectedItem } = this.state;
    await this.props.onExportDosar(id, selectedItem);
    this.handleClose();
  };

  handleDownloadCoduri = () => {
    const { id } = this.state;
    this.props.handleDownloadCoduri(id);
    this.handleClose();
  };

  handleDownloadCampaign = () => {
    const { id } = this.state;
    this.props.handleDownloadCampaign(id);
    this.handleClose();
  };

  handleDownloadResults = () => {
    const { id } = this.state;
    this.props.handleDownloadResults(id);
    this.handleClose();
  };

  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  handleDeleteRejected = () => this.setState({ openDeleteAllModal: true });

  handleOpenTransformModal = () => this.setState({ openTransformModal: true });

  handleCloseTransformModal = () =>
    this.setState({ openTransformModal: false });

  handleCloseDeleteAllModal = () =>
    this.setState({ openDeleteAllModal: false });

  handleConfirmTransform = async () => {
    await this.props.transformInscrisi();
    this.handleCloseTransformModal();
  };

  handleConfirmDeleteAll = async () => {
    await this.props.deleteAll();
    this.handleCloseDeleteAllModal();
  };

  render() {
    const {
      query,
      openModal,
      columns,
      anchorEl,
      filterConfig,
      filterNo,
      openDeleteModal,
      openDeleteAllModal,
      openTransformModal,
    } = this.state;
    const {
      fullScreen,
      items,
      addButtonText,
      classes,
      deleteName,
      isFetching,
      isFetchingFilters,
      pageInfo,
      name,
      onExport,
      hideAddButton,
      showDeleteAllButton,
      menuShowView,
      menuShowEdit,
      menuShowExport,
      menuShowDelete,
      menuShowDownloadCoduri,
      menuShowDownloadCampaign,
      menuShowDownloadResults,
      menuShowStatistics,
      menuComponent,
    } = this.props;
    // const activeColumns = columns.filter(column => column.default || column.show);
    // const itemsWithActivePropertiesOnly = items.map(item =>
    //   pickBy(item, (value, key) =>
    //     activeColumns.some(column =>
    //       column.name === '_id' || (column.name === key && (column.default || column.show)))));
    return (
      <div>
        <div
          style={{
            top: "-100px",
            position: "relative",
          }}
          ref={this.divRef}
        />
        <div className="lvd-spacer20" />
        <div className="flex lvd-control-buttons">
          {!hideAddButton && (
            <div>
              <Button
                variant="contained"
                color="primary"
                className="lvd-button-padded-icon"
                onClick={this.handleAddClick}
              >
                <AddIcon />
                {addButtonText}
              </Button>
            </div>
          )}
          <div>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.openFilterModal}
              disabled={isFetchingFilters}
            >
              <SearchIcon />
              {filterNo !== 0
                ? filterNo === 1
                  ? `${filterNo} filtru activ`
                  : `${filterNo} filtre active`
                : "Aplica filtre"}
            </Button>
          </div>
          {name !== "utilizatori" && !!this.props.onExport && (
            <div>
              <Button
                variant="contained"
                color="primary"
                className="lvd-button-green-text"
                onClick={this.handleExport}
              >
                <CloudDownloadIcon />
                Exporta
              </Button>
            </div>
          )}
          <div className="ml-auto">
            <IconButton onClick={this.handleRefresh}>
              <RefreshIcon color="primary" />
            </IconButton>
          </div>
        </div>
        <Paper className={classes.tablePaper}>
          <ReactTable
            sortable={false}
            loadingText="Se aduc date..."
            loading={isFetching}
            className="lvd-datatable -striped -highlight"
            data={items}
            manual
            pages={pageInfo.pageCount || -1}
            pageSize={pageInfo.pageSize}
            page={pageInfo.page}
            columns={columns(
              anchorEl,
              this.handleOpenMenu,
              this.handleClose,
              this.handleViewClick,
              this.handleEditClick,
              this.handleDeleteClick,
              this.handleExportDosar,
              this.handleDownloadCoduri,
              this.handleDownloadCampaign,
              this.handleDownloadResults
            )}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            PaginationComponent={(props) => (
              <TablePagination
                page={props.page}
                count={pageInfo.itemCount || -1}
                rowsPerPage={props.pageSize}
                onChangePage={(e, page) => props.onPageChange(page)}
                onChangeRowsPerPage={(e) =>
                  props.onPageSizeChange(e.target.value)
                }
                labelRowsPerPage={<span>Randuri</span>}
                labelDisplayedRows={({ count, from, page, to }) => (
                  <span>
                    Pagina {page + 1}{" "}
                    <span className="inline-block ml-6">{count} itemi</span>
                  </span>
                )}
                component="div"
                rowsPerPageOptions={[25, 50, 100]}
              />
            )}
          />
        </Paper>
        {menuComponent ? (
          menuComponent({
            handleClose: this.handleClose,
            handleViewClick: this.handleViewClick,
            handleEditClick: this.handleEditClick,
            handleExportDosar: this.handleExportDosar,
            handleDeleteClick: this.handleDeleteClick,
            row: this.state.selectedItem,
            anchorEl: this.state.anchorEl,
            onClose: this.handleClose,
          })
        ) : (
          <Menu
            className="lvd-options-container"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            // open={false}
            onClose={this.handleClose}
          >
            {menuShowView && (
              <MenuItem onClick={this.handleViewClick}>
                {menuShowStatistics ? "Statistici" : "Vizualizeaza"}
              </MenuItem>
            )}
            {menuShowEdit && (
              <MenuItem onClick={this.handleEditClick}>Editeaza</MenuItem>
            )}
            {menuShowExport && (
              <MenuItem onClick={this.handleExportDosar}>Exporta</MenuItem>
            )}
            {menuShowDelete && (
              <MenuItem onClick={this.handleDeleteClick}>Sterge</MenuItem>
            )}
            {menuShowDownloadCoduri && (
              <MenuItem onClick={this.handleDownloadCoduri}>
                Descarca coduri
              </MenuItem>
            )}
            {menuShowDownloadCampaign && (
              <MenuItem onClick={this.handleDownloadCampaign}>
                Descarca metadate campanie
              </MenuItem>
            )}
            {menuShowDownloadResults && (
              <MenuItem onClick={this.handleDownloadResults}>
                Descarca rezultate
              </MenuItem>
            )}
          </Menu>
        )}
        <DeleteModal
          open={openDeleteModal}
          handleClose={this.handleCloseDeleteModal}
          name={deleteName}
          handleDelete={this.handleDelete}
        />
        <Dialog
          open={openDeleteAllModal}
          onClose={this.handleCloseDeleteAllModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Sterge toate dosarele respinse
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sunteti sigur ca vreti sa stergeti toate dosarele respinse?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteAllModal} color="secondary">
              Renunta
            </Button>
            <Button onClick={this.handleConfirmDeleteAll} color="primary">
              Da
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTransformModal}
          onClose={this.handleCloseTransformModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Transforma dosarele finalizate in doctoranzi
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sunteti sigur ca vreti sa transformati dosarele finalizate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseTransformModal} color="secondary">
              Renunta
            </Button>
            <Button onClick={this.handleConfirmTransform} color="primary">
              Da
            </Button>
          </DialogActions>
        </Dialog>
        <FilterModal
          returnOnlyDirty
          handleClose={this.handleCloseFilterModal}
          query={query}
          openModal={openModal}
          fullScreen={fullScreen}
          filterConfig={filterConfig}
          handleSetFilterNo={this.handleSetFilterNo}
          handleSetQuery={this.handleSetQuery}
          filterNo={filterNo}
        />
        {showDeleteAllButton && (
          <div style={{ marginTop: "30px" }}>
            {/* <Button
              style={{ marginRight: "16px" }}
              variant="contained"
              color="primary"
              onClick={this.handleOpenTransformModal}
            >
              Transforma finalizati in doctoranzi
            </Button> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDeleteRejected}
            >
              Sterge respinsi
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  showSnackbar,
};

export default connect(
  null,
  mapDispatchToProps
)(withMobileDialog()(withRouter(withStyles(styles)(TableWithFilter))));
