import React, { Component } from "react";
import {
  withStyles,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { verifyToken } from "../../actions";
import OldLoginLayout from "../Layout/OldLoginLayout";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  headline: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
});
class VerifyEmail extends Component {
  state = {
    verifyTokenResponse: false,
    isLoading: true,
  };

  async componentDidMount() {
    const { verificationToken } = this.props.match.params;
    const verifyTokenResponse = await this.props.verifyToken(verificationToken);
    this.setState({
      verifyTokenResponse,
      isLoading: false,
    });
  }

  render() {
    const { classes, t } = this.props;
    const { isLoading, verifyTokenResponse } = this.state;
    if (isLoading) {
      return (
        <OldLoginLayout>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography
                gutterBottom
                className={classes.headline}
                variant="h1"
              >
                Email confirmat
              </Typography>
              <Typography>
                Veti fi redirectionat catre pagina principala
              </Typography>
            </>
          )}
        </OldLoginLayout>
      );
    }
    if (verifyTokenResponse) {
      return (
        <OldLoginLayout>
          <Typography gutterBottom className={classes.headline} variant="h1">
            Email confirmat
          </Typography>
          <Typography>Veti fi redirectionat catre pagina principala</Typography>
        </OldLoginLayout>
      );
    }
    return (
      <OldLoginLayout>
        <Typography gutterBottom className={classes.headline} variant="h1">
          {t.expiredLinkTitle}
        </Typography>
        <Typography>{t.expiredLinkDescription}</Typography>
        <Button
          onClick={() => this.props.history.push("/login")}
          style={{ marginTop: "12px" }}
          variant="contained"
          color="primary"
        >
          {t.backToLogin}
        </Button>
      </OldLoginLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
});

const mapDispatchToProps = {
  verifyToken,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
);
