import request from "superagent";
import { getToken } from "../reducers/authReducer";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";

const fetchSettingsRequest = () => ({
  type: TYPES.SETTINGS_FETCH_REQUEST,
});
const fetchSettingsSuccess = (payload) => ({
  type: TYPES.SETTINGS_FETCH_SUCCESS,
  payload,
});
const fetchSettingsFailure = (error) => ({
  type: TYPES.SETTINGS_FETCH_FAILURE,
  error,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchSettings = () => async (dispatch, getState) => {
  dispatch(fetchSettingsRequest());
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/settings`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchSettingsSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchSettingsFailure(e));
  }
};

export const updateSettings = (settings) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .put(`${serverUrl}/settings`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(settings);
    dispatch(fetchSettingsSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchSettingsFailure(e));
  }
};
