/* eslint-disable no-underscore-dangle */
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import request from "superagent";
import { fetchNomenclatoarePublice } from "../../actions";
import { serverUrl } from "../../utils";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const mapToActual = {
  traduceresiinterpretare: "traducereSiInterpretare",
  licenta: "licenta",
  master: "master",
};
const mapToHumanReadable = {
  traduceresiinterpretare: "Traducere si interpretare",
  licenta: "Licenta",
  master: "Master",
};

export default function EligibiliList() {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const isLoggedIn = !!localStorage.getItem("utcbJWT");
  const { registrationType } = match.params;
  const [facultate, setFacultate] = useState("");
  const [eligibili, setEligibili] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchableEligibili, setSearchableEligibili] = useState([]);
  const dispatch = useDispatch();
  const optiuniDistribuire =
    useSelector((state) => state.nomenclatoare.optiuniStudiiRo) || {};
  useEffect(() => {
    dispatch(fetchNomenclatoarePublice());
  }, []);
  useEffect(() => {
    async function getEligibiliByFacultate() {
      if (facultate && registrationType) {
        setIsLoading(true);
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/eligibili-by-facultate`)
          .send({
            facultate,
            registrationType: mapToActual[registrationType],
          });
        setEligibili(res.body);
        setSearchableEligibili(res.body);
        setIsLoading(false);
      }
    }
    getEligibiliByFacultate();
  }, [facultate, registrationType]);
  const [search, setSearch] = useState("");
  const [found, setFound] = useState([]);
  const debouncedSearch = debounce(
    (val) => {
      const latiniseSearchByVal = val.toLowerCase().latinize();
      setFound(
        searchableEligibili.filter((eligibil) =>
          eligibil._id.includes(latiniseSearchByVal)
        )
      );
    },
    1000,
    {
      trailing: true,
      leading: false,
    }
  );
  const handleSearch = (e) => {
    const searchByVal = e.target.value;
    setSearch(searchByVal);
    debouncedSearch(searchByVal);
  };
  const valuesForSelect = useMemo(() => {
    let keysForSelect = {};
    const x = optiuniDistribuire[mapToActual[registrationType]] || [];
    x.forEach((opt) => {
      const split = opt.nume.split(" | ");
      keysForSelect = {
        ...keysForSelect,
        [`${split[split.length - 1].trim()} | ${split[0].trim()}`]: "",
      };
    });
    return Object.keys(keysForSelect);
  }, [optiuniDistribuire[mapToActual[registrationType]]]);

  // optiuniForRegistrationType.map((opt) => {
  //   const split = opt.nume.split(" | ");
  //   return `${split[split.length - 1].trim()} | ${split[0].trim()}`;
  // });
  // const valuesForSelect = Object.keys(keysForSelect);
  if (!facultate && valuesForSelect.length > 0) {
    setFacultate(valuesForSelect[0]);
  }

  return (
    <div style={{ backgroundColor: "#f1f2f4" }} className="min-h-full pt-20">
      <div className="container mx-auto">
        <div className="text-secondaryText">
          Inscriere romani, {mapToHumanReadable[registrationType]}
        </div>
        <div style={{ color: "#84C441" }} className="text-3xl">
          Lista eligibili
        </div>
        <div className="mb-4">
          {/* <Alert severity="warning" variant="outlined" className="mb-4">
            Lista finala de candidati eligibili (stabilita in urma
            contestatiilor) va fi afisata la 21 iulie 2021, ora 18:00
          </Alert> */}
          <Alert severity="info" variant="outlined">
            Contestatiile se primesc pe adresa de e-mail{" "}
            <a href="mailto:secretariat@utcb.ro" className="underline">
              secretariat@utcb.ro
            </a>
          </Alert>
        </div>
        <div className="flex items-center justify-center my-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/")}
          >
            {isLoggedIn ? "Inapoi in contul tau" : "Inapoi la login"}
          </Button>
        </div>
        <div
          className="grid grid-cols-1 row-gap-4 p-4 mb-4 md:grid-cols-2 md:col-gap-4"
          style={{ backgroundColor: "#41576D" }}
        >
          <div>
            <FormControl fullWidth>
              <InputLabel>Vezi lista pentru:</InputLabel>
              <Select
                className="text-white"
                value={facultate}
                onChange={(e) => setFacultate(e.target.value)}
              >
                {valuesForSelect.map((opt) => (
                  <MenuItem value={opt} key={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              InputProps={{
                className: "text-white",
              }}
              fullWidth
              label="Cauta dupa id"
              name="numePrenume"
              onChange={handleSearch}
              value={search}
            />
          </div>
        </div>
        <div className="my-4 text-3xl text-center text-secondaryText">
          Lista candidatilor eligibili la Facultatea de {facultate},{" "}
          {mapToHumanReadable[registrationType]}
        </div>
        <div className="pb-32">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nr. crt.</TableCell>
                  <TableCell>Id utilizator</TableCell>
                  <TableCell>Media de admitere</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search !== ""
                  ? found.map((u) => (
                      <StyledTableRow key={u._id}>
                        <TableCell>
                          {eligibili.findIndex(
                            (eligibil) => u._id === eligibil._id
                          ) + 1}
                        </TableCell>
                        <TableCell>{u._id}</TableCell>
                        <TableCell>{u.medieAdmitere.toFixed(2)}</TableCell>
                      </StyledTableRow>
                    ))
                  : eligibili.map((u, index) => (
                      <StyledTableRow key={u._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{u._id}</TableCell>
                        <TableCell>{u.medieAdmitere.toFixed(2)}</TableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoading && (
            <div className="flex items-center justify-center mt-8">
              <CircularProgress />
            </div>
          )}
          {eligibili.length === 0 && !isLoading && (
            <div className="flex items-center justify-center mt-8 text-xl text-secondaryText">
              Niciun rezultat
            </div>
          )}
          {found.length === 0 && !isLoading && search !== "" && (
            <div className="flex items-center justify-center mt-8 text-xl text-secondaryText">
              Niciun rezultat
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
