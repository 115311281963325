/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import moment from "moment";
import AddEditForm from "../General/AddEditForm";
import { fetchNomenclatoarePublice } from "../../actions";
import {
  addInscris,
  fetchDosarInscris,
  updateMyInscris,
} from "../../actions/inscrisi-ro";
import { getUser } from "../../reducers/authReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";
import licee from "../../licee";
import judeteLocalitati from "../../judeteLocalitati";
// TODO Check modify nomenclator nume
const generateInputs = (t, props, candidatType) => [
  {
    name: "cwp01",
    type: "childrenWithPaper",
    children: [
      {
        label: t.identificationData,
        name: "a01",
        type: "textDelimiter",
      },
      {
        type: "children",
        name: "a02",
        children: [
          {
            name: "nume",
            label: t.familyName,
            type: "text",
            required: true,
            helperText: t.familyNameExplanation,
          },
          {
            name: "prenume",
            label: t.firstName,
            type: "text",
            required: true,
          },
          {
            name: "initialaTata",
            label: "Initiala tatalui",
            type: "text",
            required: true,
          },
        ],
      },
      {
        type: "children",
        name: "cnpAndSex",
        children: [
          {
            type: "text",
            label: "CNP",
            name: "cnp",
            required: true,
          },
          {
            name: "sex",
            label: "Sex",
            type: "select",
            options: [
              {
                label: "M",
                value: "masculin",
              },
              {
                label: "F",
                value: "feminin",
              },
            ],
            required: true,
          },
          {
            name: "nationalitate",
            label: t.nationality,
            type: "text",
            required: true,
          },
        ],
      },
      {
        type: "children",
        name: "c01",
        children: [
          {
            name: "dataNasterii",
            label: t.dateOfBirth,
            type: "datepicker",
            required: true,
          },
          {
            name: "localitateaNasterii",
            label: t.placeOfBirth,
            required: true,
            type: "text",
          },
          {
            type: "text",
            label: "Numar telefon",
            name: "nrTelefon",
            required: true,
          },
        ],
      },
      {
        type: "children",
        name: "a03",
        children: [
          candidatType.rdp
            ? {
                type: "text",
                name: "carteIdentitateSerie",
                label: "Serie pasaport",
                required: true,
              }
            : {
                type: "text",
                name: "carteIdentitateSerie",
                label: "Serie carte de identitate",
                required: true,
              },
          candidatType.rdp
            ? {
                type: "text",
                name: "carteIdentitateNumar",
                label: "Nr pasaport",
                required: true,
              }
            : {
                type: "text",
                name: "carteIdentitateNumar",
                label: "Nr carte de identitate",
                required: true,
              },
          {
            type: "text",
            name: "carteIdentitateEliberatDe",
            label: "Eliberat(a) de",
            required: true,
          },
        ],
      },
      {
        type: "children",
        name: "a04",
        children: [
          {
            type: "datepicker",
            name: "carteIdentitateEliberatData",
            label: "Eliberata la data",
            required: true,
          },
          {
            type: "datepicker",
            name: "carteIdentitateExpiraLaData",
            label: "Expira la data",
            required: true,
          },
        ],
      },
    ],
  },
  {
    type: "childrenWithPaper",
    name: "cwp02",
    children: [
      {
        type: "textDelimiter",
        name: "domiciliuLabel",
        label: "Domiciliu",
        captionText:
          "Trebuie să completezi datele din buletin și te rugăm să alegi județul și localitatea din meniul derulant",
      },
      {
        type: "children",
        name: "a05",
        children: [
          candidatType.rdp
            ? {
                name: "domiciliuStabilJudetSector",
                label: "Tara",
                type: "text",
                required: true,
              }
            : {
                name: "domiciliuStabilJudetSector",
                label: "Judet/Sector",
                type: "createableSelectV2",
                options: judeteLocalitati,
                optionName: "judet",
                clearOnSelect: "domiciliuStabilLocalitate",
                required: true,
              },
          candidatType.rdp
            ? {
                name: "domiciliuStabilLocalitate",
                label: "Localitate",
                type: "text",
                required: true,
              }
            : {
                name: "domiciliuStabilLocalitate",
                label: "Localitate",
                type: "createableSelectV2",
                options: judeteLocalitati,
                optionName: "localitate",
                filterByName: "judet",
                filterBy: "domiciliuStabilJudetSector",
                required: true,
              },
          {
            name: "domiciliuStabilStrada",
            label: "Strada",
            type: "text",
            required: true,
          },
        ],
      },
      {
        type: "children",
        name: "a06",
        children: [
          {
            name: "domiciliuStabilNr",
            label: "Numar",
            type: "text",
            required: true,
          },
          {
            name: "domiciliuStabilBloc",
            label: "Bloc",
            type: "text",
          },
          {
            name: "domiciliuStabilEtaj",
            label: "Etaj",
            type: "text",
          },
        ],
      },
      {
        type: "children",
        name: "c02",
        children: [
          {
            name: "domiciliuStabilScara",
            label: "Scara",
            type: "text",
          },
          {
            name: "domiciliuStabilApartament",
            label: "Apartament",
            type: "text",
          },
          {
            name: "domiciliuStabilCodPostal",
            label: "Cod postal",
            type: "text",
          },
        ],
      },
    ],
  },
  candidatType.dppd
    ? { type: "none" }
    : {
        type: "childrenWithPaper",
        name: "cwp03",
        children: [
          {
            label: "Apartinator legal",
            name: "td02",
            type: "textDelimiter",
            captionText:
              "Trebuie să completezi datele părinților sau ale aparținătorilor legali și la adresă, dacă aceasta coincide cu a ta te rugăm să completezi AA.",
          },
          {
            type: "children",
            name: "a10",
            children: [
              {
                name: "prenumeTata",
                label: "Nume Prenume Tata/sustinator legal",
                type: "text",
                required: true,
              },
              {
                name: "prenumeMama",
                label: "Nume Prenume Mama/sustinator legal",
                type: "text",
                required: true,
              },
              {
                type: "text",
                label: "Adresa parintilor sau a sustinatorilor legali",
                name: "adresaParinti",
                required: true,
              },
            ],
          },
        ],
      },
  {
    type: "childrenWithPaper",
    name: "cwp04",
    children: [
      {
        label: "Studii",
        name: "td02",
        type: "textDelimiter",
        captionText:
          candidatType.registerFor === "master"
            ? "Completează datele conform informațiilor de pe diplomă sau de pe adeverință."
            : "Te rugăm să alegi localitate liceului absolvit și numele acestuia din meniul derulant, iar la secție trebuie să completezi specializare pe care ai avut-o în liceu, ex: matematică - informatică sau științe ale naturii, etc.",
      },
      candidatType.registerFor === "licenta"
        ? {
            type: "children",
            name: "a193",
            children: [
              candidatType.rdp
                ? {
                    name: "liceuAbsolvitLocalitate",
                    label: "Localitate liceu",
                    type: "text",
                    required: true,
                  }
                : {
                    name: "liceuAbsolvitLocalitate",
                    label: "Localitate liceu",
                    type: "createableSelectV2",
                    required: true,
                    options: licee,
                    clearOnSelect: "liceuAbsolvitNume",
                    optionName: "localitate",
                  },
              candidatType.rdp
                ? {
                    name: "liceuAbsolvitNume",
                    label: "Liceu absolvit",
                    type: "text",
                    required: true,
                  }
                : {
                    name: "liceuAbsolvitNume",
                    label: "Liceu absolvit",
                    type: "createableSelectV2",
                    required: true,
                    options: licee,
                    optionName: "nume",
                    filterByName: "localitate",
                    filterBy: "liceuAbsolvitLocalitate",
                  },
              {
                name: "liceuAbsolvitSectie",
                label: "Sectie",
                type: "text",
                required: true,
              },
              {
                name: "liceuAbsolvitAn",
                label: "An",
                type: "text",
                required: true,
              },
            ],
          }
        : { type: "none" },
      {
        type: "children",
        name: "a07",
        children: [
          {
            name: "facultateAbsolvitaLocalitate",
            label: "Localitate",
            type: "text",
            required: candidatType.registerFor === "master",
          },
          {
            name: "facultateAbsolvitaNume",
            label: "Facultate absolvita",
            type: "text",
            required: candidatType.registerFor === "master",
          },
          {
            name: "facultateAbsolvitaAn",
            label: "An",
            type: "text",
            required: candidatType.registerFor === "master",
          },
          {
            name: "facultateAbsolvitaTipFinantare",
            label: "Tip finantare",
            type: "select",
            options: [
              {
                label: "Buget",
                value: "buget",
              },
              {
                label: "Taxa",
                value: "taxa",
              },
            ],
            required: candidatType.registerFor === "master",
          },
        ],
      },
      candidatType.registerFor === "licenta"
        ? {
            type: "children",
            name: "a08",
            children: [
              {
                name: "facultateStudentLocalitate",
                label: "Localitate",
                type: "text",
              },
              {
                name: "facultateStudentNume",
                label: "Am fost student la facultatea",
                type: "text",
              },
              {
                name: "facultateStudentAnStudii",
                label: "An",
                type: "text",
              },
              {
                name: "facultateStudentTipFinantare",
                label: "Tip finantare",
                type: "select",
                options: [
                  {
                    label: "Buget",
                    value: "buget",
                  },
                  {
                    label: "Taxa",
                    value: "taxa",
                  },
                ],
              },
            ],
          }
        : {
            type: "children",
            name: "a08",
            children: [
              {
                name: "masterAbsolvitLocalitate",
                label: "Localitate",
                type: "text",
              },
              {
                name: "masterAbsolvitNume",
                label: "Am mai absolvit Masterat/Studii aprofundate (după caz)",
                type: "text",
              },
              {
                name: "masterAbsolvitAn",
                label: "An",
                type: "text",
              },
              {
                name: "masterAbsolvitTipFinantare",
                label: "Tip finantare",
                type: "select",
                options: [
                  {
                    label: "Buget",
                    value: "buget",
                  },
                  {
                    label: "Taxa",
                    value: "taxa",
                  },
                ],
              },
            ],
          },
      candidatType.registerFor === "master"
        ? {
            name: "a100",
            type: "children",
            children: [
              {
                name: "masterStudentLocalitate",
                label: "Localitate",
                type: "text",
              },
              {
                name: "masterStudentNume",
                label:
                  "Am fost student la Masterat/Studii aprofundate (după caz)",
                type: "text",
              },
              {
                name: "masterStudentPerioada",
                label: "An",
                type: "text",
              },
              {
                name: "masterStudentTipFinantare",
                label: "Tip finantare",
                type: "select",
                options: [
                  {
                    label: "Buget",
                    value: "buget",
                  },
                  {
                    label: "Taxa",
                    value: "taxa",
                  },
                ],
              },
            ],
          }
        : { type: "none" },
      candidatType.registerFor === "licenta"
        ? {
            type: "children",
            name: "a09",
            children: [
              {
                name: "facultateCurentLocalitate",
                label: "Localitate",
                type: "text",
              },
              {
                name: "facultateCurentNume",
                label: "Sunt student si la facultatea",
                type: "text",
              },

              {
                name: "facultateCurentAnStudii",
                label: "An",
                type: "text",
              },
              {
                name: "facultateCurentTipFinantare",
                label: "Tip finantare",
                type: "select",
                options: [
                  {
                    label: "Buget",
                    value: "buget",
                  },
                  {
                    label: "Taxa",
                    value: "taxa",
                  },
                ],
              },
            ],
          }
        : {
            type: "children",
            name: "a09",
            children: [
              {
                name: "masterCurentLocalitate",
                label: "Localitate",
                type: "text",
              },
              {
                name: "masterCurentNume",
                label:
                  "Sunt student și la Masterat/Studii aprofundate (după caz)",
                type: "text",
              },
              {
                name: "masterCurentAn",
                label: "An",
                type: "text",
              },
              {
                name: "masterCurentTipFinantare",
                label: "Tip finantare",
                type: "select",
                options: [
                  {
                    label: "Buget",
                    value: "buget",
                  },
                  {
                    label: "Taxa",
                    value: "taxa",
                  },
                ],
              },
            ],
          },
    ],
  },
  {
    type: "childrenWithPaper",
    name: "cwp05",
    children: [
      candidatType.translationAndInterpretation
        ? {
            label: "Alege cea de-a doua limbă de studiu",
            captionText:
              "Scrie în ordinea pe care o dorești cele trei opțiuni (Franceză, Germană, Spaniolă)",
            name: "td02",
            type: "textDelimiter",
          }
        : {
            label: "Competente lingvistice",
            captionText:
              "Aceste campuri trebuie completate doar in cazul in care optati pentru studii intr-o limba straina",
            name: "td02",
            type: "textDelimiter",
          },
      candidatType.translationAndInterpretation
        ? {
            name: "ordineaLimbilorDeStudiu",
            label: "Ordinea limbilor de studiu",
            type: "text",
            required: true,
          }
        : {
            type: "children",
            name: "a11",
            children: [
              {
                type: "text",
                label: "Tip certificat de competenta lingvistica",
                name: "certificatCompetentaLingvisticaTip",
              },
              {
                type: "text",
                label: "Limba certificat de competenta lingvistica",
                name: "certificatCompetentaLingvisticaLimba",
              },
            ],
          },
      candidatType.translationAndInterpretation
        ? { type: "none" }
        : {
            name: "a12",
            type: "children",
            children: [
              {
                type: "select",
                label: "Engleza",
                name: "engleza",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
              {
                type: "select",
                label: "Franceza",
                name: "franceza",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
              {
                type: "select",
                label: "Germana",
                name: "germana",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
            ],
          },
      candidatType.translationAndInterpretation
        ? { type: "none" }
        : {
            name: "a13",
            type: "children",
            children: [
              {
                type: "select",
                label: "Spaniola",
                name: "spaniola",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
              {
                type: "select",
                label: "Rusa",
                name: "rusa",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
              {
                type: "select",
                label: "Italiana",
                name: "italiana",
                options: [
                  {
                    label: "Incepator",
                    value: 1,
                  },
                  {
                    label: "Mediu",
                    value: 2,
                  },
                  {
                    label: "Avansat",
                    value: 3,
                  },
                ],
              },
            ],
          },
      candidatType.translationAndInterpretation
        ? {
            label: "Alege nivelul de cunoaștere",
            name: "td03",
            type: "textDelimiter",
          }
        : { type: "none" },
      candidatType.translationAndInterpretation
        ? {
            type: "children",
            name: "tsi1",
            children: [
              {
                type: "select",
                label: "Franceză",
                name: "franceza",
                required: true,
                options: [
                  {
                    label: "A1",
                    value: 1,
                  },
                  {
                    label: "A2",
                    value: 2,
                  },
                  {
                    label: "B1",
                    value: 3,
                  },
                  {
                    label: "B2",
                    value: 4,
                  },
                  {
                    label: "C1",
                    value: 5,
                  },
                  {
                    label: "C2",
                    value: 6,
                  },
                ],
              },
              {
                type: "select",
                label: "Germană",
                name: "germana",
                required: true,
                options: [
                  {
                    label: "A1",
                    value: 1,
                  },
                  {
                    label: "A2",
                    value: 2,
                  },
                  {
                    label: "B1",
                    value: 3,
                  },
                  {
                    label: "B2",
                    value: 4,
                  },
                  {
                    label: "C1",
                    value: 5,
                  },
                  {
                    label: "C2",
                    value: 6,
                  },
                ],
              },
              {
                type: "select",
                label: "Spaniolă",
                name: "spaniola",
                required: true,
                options: [
                  {
                    label: "A1",
                    value: 1,
                  },
                  {
                    label: "A2",
                    value: 2,
                  },
                  {
                    label: "B1",
                    value: 3,
                  },
                  {
                    label: "B2",
                    value: 4,
                  },
                  {
                    label: "C1",
                    value: 5,
                  },
                  {
                    label: "C2",
                    value: 6,
                  },
                ],
              },
            ],
          }
        : { type: "none" },
    ],
  },
  {
    type: "childrenWithPaper",
    name: "cwp06",
    children: [
      candidatType.translationAndInterpretation
        ? {
            label: "Note pentru admitere",
            name: "td03",
            type: "textDelimiter",
          }
        : {
            label: "Note pentru admitere",
            captionText:
              candidatType.registerFor === "licenta" && !candidatType.colegiu
                ? `Alege media care știi că te avantajează în fața altor candidați!
      Daca vei completa proba la alegere, media de admitere va fi 50% nota probei + 50% media generala de la Bac.
      Daca vei completa doar media generala Bac, aceasta va fi 100% media de admitere.`
                : `Media de admitere se calculează folosind și nota suplimentară de la admitere conform informațiilor din Metodologia de admitere la studii universitare de master disponibilă pe site-ul UTCB sub tabul Sudiază - Master`,
            name: "td03",
            type: "textDelimiter",
          },
      candidatType.registerFor === "master"
        ? {
            type: "children",
            name: "mediiMaster",
            children: [
              {
                type: "text",
                name: "mediaAnilorDeStudiuLicenta",
                label: "Media anilor de studii universitare de licenta",
                required: true,
              },
              {
                type: "text",
                name: "mediaExamenLicenta",
                label: "Media examenului de licenta",
                required: true,
              },
            ],
          }
        : candidatType.colegiu
        ? {
            type: "text",
            name: "medieGeneralaBacalaureat",
            label: "Media generala de absolvire a studiilor liceale",
            required: true,
          }
        : {
            type: "text",
            name: "medieGeneralaBacalaureat",
            label: "Media generala a examenului de bacalaureat",
            required: true,
          },
      // eslint-disable-next-line no-nested-ternary
      (candidatType.registerFor === "licenta" && !candidatType.colegiu) ||
      (candidatType.translationAndInterpretation &&
        candidatType.registerFor === "licenta")
        ? {
            type: "children",
            name: "a14",
            children: [
              {
                type: "select",
                name: "probaAlegere",
                label: "Proba la alegere",
                options: [
                  {
                    label: "Nu include nota probei la alegere",
                    value: "doarMediaGenerala",
                  },
                  ...(candidatType.translationAndInterpretation &&
                  candidatType.registerFor === "licenta"
                    ? [
                        {
                          label: "Romana",
                          value: "romana",
                        },
                      ]
                    : [
                        {
                          label: "Matematica",
                          value: "matematica",
                        },
                        {
                          label: "Fizica",
                          value: "fizica",
                        },
                        {
                          label: "Informatica",
                          value: "informatica",
                        },
                        {
                          label: "Chimie",
                          value: "chimie",
                        },
                        {
                          label: "Economie",
                          value: "economie",
                        },
                      ]),
                ],
              },
              {
                type: "text",
                name: "notaProbaAlegere",
                label: "Nota la proba la alegere",
              },
            ],
          }
        : { type: "none", name: "n1" },
    ],
  },
  {
    type: "childrenWithPaper",
    name: "cwp07",
    children: [
      {
        label: "Alege optiunile de studiu",
        captionText: candidatType.rdp
          ? undefined
          : "Poti schimba ordinea optiunilor cu drag and drop",
        name: "a99",
        type: "textDelimiter",
      },
      {
        name: "alert1",
        type: "alert",
        label: (
          <>
            ATENȚIE! Dacă treci la pasul următor nu mai poți modifica opțiunea,
            așa că trebuie să le alegi cu atenție. Distribuirea candidaților
            pentru listele de admitere se face la nivel de universitate în
            funcție de medii. Numărul de locuri de școlarizare se găsește pe
            pagina web{" "}
            <a href="admitere.utcb.ro" className="text-link underline">
              admitere.utcb.ro
            </a>{" "}
            la secțiunea dedicată.
          </>
        ),
      },
      {
        type: "optiuniDistribuireV2",
        name: "optiuniDistribuireV2",
        isRdp: candidatType.rdp,
        isColegiu: candidatType.colegiu,
        options: candidatType.translationAndInterpretation
          ? candidatType.registerFor === "master"
            ? props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretareM
            : props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretare
          : candidatType.registerFor === "licenta"
          ? candidatType.rdp
            ? props.nomenclatoare.optiuniStudiiRo.rdp
            : candidatType.colegiu
            ? props.nomenclatoare.optiuniStudiiRo.colegiu
            : props.nomenclatoare.optiuniStudiiRo.licenta
          : candidatType.dppd
          ? props.nomenclatoare.optiuniStudiiRo.dppd
          : candidatType.registerFor === "master" && candidatType.rdp
          ? props.nomenclatoare.optiuniStudiiRo.rdpM
          : props.nomenclatoare.optiuniStudiiRo.master,
      },
    ],
  },

  // {
  //   type: "optiuniDistribuire",
  //   name: "optiuniDistribuire",
  //   options: candidatType.translationAndInterpretation
  //     ? props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretare
  //     : candidatType.registerFor === "licenta"
  //     ? props.nomenclatoare.optiuniStudiiRo.licenta
  //     : props.nomenclatoare.optiuniStudiiRo.master,
  // },

  {
    type: "alert",
    name: "alert1",
    label:
      "Verifică atent datele înainte de a apăsa salvează și trimite. După trimitere datele nu mai pot fi modificate",
  },
];

const getValidationSchema = (candidatType, t) => {
  if (
    !candidatType.translationAndInterpretation &&
    candidatType.registerFor === "licenta"
  ) {
    return yup.object().shape({
      optiuniDistribuire: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
        )
        .min(1),
      nume: yup.string().required(t.requiredField),
      prenume: yup.string().required(t.requiredField),
      initialaTata: yup.string().required(t.requiredField),
      cnp: yup.string().required(t.requiredField),
      sex: yup.string().required(t.requiredField),
      nationalitate: yup.string().required(t.requiredField),
      dataNasterii: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      localitateaNasterii: yup.string().required(t.requiredField),
      carteIdentitateSerie: yup.string().required(t.requiredField),
      carteIdentitateNumar: yup.string().required(t.requiredField),
      carteIdentitateEliberatDe: yup.string().required(t.requiredField),
      carteIdentitateEliberatData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      carteIdentitateExpiraLaData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      domiciliuStabilLocalitate: yup.string().required(t.requiredField),
      domiciliuStabilJudetSector: yup.string().required(t.requiredField),
      domiciliuStabilStrada: yup.string().required(t.requiredField),
      domiciliuStabilNr: yup.string().required(t.requiredField),
      nrTelefon: yup.string().required(t.requiredField),
      liceuAbsolvitNume: yup.string().required(t.requiredField),
      liceuAbsolvitLocalitate: yup.string().required(t.requiredField),
      liceuAbsolvitSectie: yup.string().required(t.requiredField),
      liceuAbsolvitAn: yup.string().required(t.requiredField),
      prenumeTata: yup.string().required(t.requiredField),
      prenumeMama: yup.string().required(t.requiredField),
      adresaParinti: yup.string().required(t.requiredField),
      medieGeneralaBacalaureat: yup
        .number()
        .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
        .required(t.requiredField),
      probaAlegere: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(t.requiredField),
      // notaProbaAlegere: yup
      //   .number()
      //   .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,"),
      // .required(t.requiredField),

      notaProbaAlegere: yup.mixed().when("probaAlegere", {
        is: (val) => {
          return val.value !== "doarMediaGenerala";
        },
        then: yup
          .number()
          .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
          .required(t.requiredField),
        otherwise: yup.string().nullable(),
      }),

      // notaProbaAlegere: yup.string().when("probaAlegere", {
      //   is: (val) => {
      //     return val.value !== "doarMediaGenerala";
      //   },
      //   then: yup
      //     .string()
      //     .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
      //     .required(t.requiredField),
      //   otherwise: yup.string().nullable(),
      // }),
    });
  }
  if (candidatType.registerFor === "master") {
    return yup.object().shape({
      optiuniDistribuire: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
        )
        .min(1),
      nume: yup.string().required(t.requiredField),
      prenume: yup.string().required(t.requiredField),
      initialaTata: yup.string().required(t.requiredField),
      cnp: yup.string().required(t.requiredField),
      sex: yup.string().required(t.requiredField),
      nationalitate: yup.string().required(t.requiredField),
      dataNasterii: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      localitateaNasterii: yup.string().required(t.requiredField),
      carteIdentitateSerie: yup.string().required(t.requiredField),
      carteIdentitateNumar: yup.string().required(t.requiredField),
      carteIdentitateEliberatDe: yup.string().required(t.requiredField),
      carteIdentitateEliberatData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      carteIdentitateExpiraLaData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      domiciliuStabilLocalitate: yup.string().required(t.requiredField),
      domiciliuStabilJudetSector: yup.string().required(t.requiredField),
      domiciliuStabilStrada: yup.string().required(t.requiredField),
      domiciliuStabilNr: yup.string().required(t.requiredField),
      nrTelefon: yup.string().required(t.requiredField),
      facultateAbsolvitaNume: yup.string().required(t.requiredField),
      facultateAbsolvitaLocalitate: yup.string().required(t.requiredField),
      facultateAbsolvitaAn: yup.string().required(t.requiredField),
      facultateAbsolvitaTipFinantare: yup.string().required(t.requiredField),
      prenumeTata: candidatType.dppd
        ? yup.string()
        : yup.string().required(t.requiredField),
      prenumeMama: candidatType.dppd
        ? yup.string()
        : yup.string().required(t.requiredField),
      adresaParinti: candidatType.dppd
        ? yup.string()
        : yup.string().required(t.requiredField),
      mediaAnilorDeStudiuLicenta: yup
        .number()
        .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
        .required(t.requiredField),
      mediaExamenLicenta: yup
        .number()
        .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
        .required(t.requiredField),
    });
  }
  if (candidatType.translationAndInterpretation) {
    return yup.object().shape({
      optiuniDistribuire: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
        )
        .min(1),
      nume: yup.string().required(t.requiredField),
      prenume: yup.string().required(t.requiredField),
      initialaTata: yup.string().required(t.requiredField),
      cnp: yup.string().required(t.requiredField),
      sex: yup.string().required(t.requiredField),
      nationalitate: yup.string().required(t.requiredField),
      dataNasterii: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      localitateaNasterii: yup.string().required(t.requiredField),
      carteIdentitateSerie: yup.string().required(t.requiredField),
      carteIdentitateNumar: yup.string().required(t.requiredField),
      carteIdentitateEliberatDe: yup.string().required(t.requiredField),
      carteIdentitateEliberatData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      carteIdentitateExpiraLaData: yup
        .date()
        .transform((value, originalValue) =>
          originalValue === null ? undefined : value
        )
        .required(t.requiredField),
      domiciliuStabilLocalitate: yup.string().required(t.requiredField),
      domiciliuStabilJudetSector: yup.string().required(t.requiredField),
      domiciliuStabilStrada: yup.string().required(t.requiredField),
      domiciliuStabilNr: yup.string().required(t.requiredField),
      nrTelefon: yup.string().required(t.requiredField),
      liceuAbsolvitNume: yup.string().required(t.requiredField),
      liceuAbsolvitLocalitate: yup.string().required(t.requiredField),
      liceuAbsolvitSectie: yup.string().required(t.requiredField),
      liceuAbsolvitAn: yup.string().required(t.requiredField),
      prenumeTata: yup.string().required(t.requiredField),
      prenumeMama: yup.string().required(t.requiredField),
      adresaParinti: yup.string().required(t.requiredField),
      ordineaLimbilorDeStudiu: yup.string().required(t.requiredField),
      franceza: yup.string().required(t.requiredField),
      germana: yup.string().required(t.requiredField),
      spaniola: yup.string().required(t.requiredField),
      medieGeneralaBacalaureat: yup
        .number()
        .typeError("Media trebuie sa fie un numar. Folositi . in loc de ,")
        .required(t.requiredField),
    });
  }
};

class CompletareFisa extends Component {
  state = {
    inputs: [],
    isEditing: false,
    validationSchema: null,
    isSubmitting: false,
  };

  async componentDidMount() {
    this.props.setIsLoading(1);
    await this.props.fetchNomenclatoarePublice();
    const { t, user } = this.props;
    if (user.dosarInscrisRo) {
      this.setState({ isEditing: true });
      await this.props.fetchDosarInscris();
    }
    this.setState({
      inputs: generateInputs(t, this.props, user.candidatType),
      validationSchema: getValidationSchema(user.candidatType, t),
    });
    this.props.setIsLoading(-1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { lang, t } = this.props;
    if (lang !== prevProps.lang) {
      this.setState({
        inputs: generateInputs(t, this.props, this.props.user.candidatType),
      });
    }
  }

  handleAddDosar = async (values) => {
    const { isEditing } = this.state;
    this.setState({ isSubmitting: true });
    try {
      if (isEditing) {
        await this.props.updateMyInscris({
          ...values,
          dataNasterii: moment(values.dataNasterii).toISOString(),
          dataEliberarePasaport: moment(
            values.dataEliberarePasaport
          ).toISOString(),
        });
      } else {
        await this.props.addInscris({
          ...values,
          dataNasterii: moment(values.dataNasterii).toISOString(),
          dataEliberarePasaport: moment(
            values.dataEliberarePasaport
          ).toISOString(),
        });
      }
      this.setState({ isSubmitting: false });
      this.props.handleNext();
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { t, user, isLoading, dosar } = this.props;
    const { inputs, isEditing, validationSchema, isSubmitting } = this.state;
    if (!user.candidatType) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        {!isLoading && (
          <AddEditForm
            name="inscrisi"
            inputs={inputs}
            isViewing={user.completatDosarInscris}
            handleNext={this.handleNext}
            handleSubmit={this.handleAddDosar}
            saveButtonLabel="Salveaza si trimite"
            validationSchema={validationSchema}
            isLoading={isSubmitting}
            initialValues={
              isEditing
                ? {
                    nume: "",
                    prenume: "",
                    initialaTata: "",
                    cnp: "",
                    sex: "",
                    nationalitate: "",
                    dataNasterii: null,
                    localitateaNasterii: "",
                    carteIdentitateSerie: "",
                    carteIdentitateNumar: "",
                    carteIdentitateEliberatDe: "",
                    carteIdentitateEliberatData: null,
                    carteIdentitateExpiraLaData: null,
                    domiciliuStabilLocalitate: "",
                    domiciliuStabilJudetSector: "",
                    domiciliuStabilCodPostal: "",
                    domiciliuStabilStrada: "",
                    domiciliuStabilNr: "",
                    domiciliuStabilBloc: "",
                    domiciliuStabilScara: "",
                    domiciliuStabilEtaj: "",
                    domiciliuStabilApartament: "",
                    nrTelefon: "",
                    liceuAbsolvitNume: "",
                    liceuAbsolvitLocalitate: "",
                    liceuAbsolvitSectie: "",
                    liceuAbsolvitAn: "",
                    facultateAbsolvitaNume: "",
                    facultateAbsolvitaLocalitate: "",
                    facultateAbsolvitaAn: "",
                    facultateAbsolvitaTipFinantare: "",
                    facultateStudentNume: "",
                    facultateStudentLocalitate: "",
                    facultateStudentAnStudii: "",
                    facultateStudentTipFinantare: "",
                    masterAbsolvitNume: "",
                    masterAbsolvitLocalitate: "",
                    masterAbsolvitAn: "",
                    masterAbsolvitTipFinantare: "",
                    masterStudentNume: "",
                    masterStudentLocalitate: "",
                    masterStudentPerioada: "",
                    masterStudentTipFinantare: "",
                    facultateCurentNume: "",
                    facultateCurentLocalitate: "",
                    facultateCurentAnStudii: "",
                    facultateCurentTipFinantare: "",
                    masterCurentNume: "",
                    masterCurentLocalitate: "",
                    masterCurentAn: "",
                    masterCurentTipFinantare: "",
                    prenumeTata: "",
                    prenumeMama: "",
                    adresaParinti: "",
                    ordineaLimbilorDeStudiu: "",
                    certificatCompetentaLingvisticaTip: "",
                    certificatCompetentaLingvisticaLimba: "",
                    engleza: "",
                    franceza: "",
                    germana: "",
                    spaniola: "",
                    rusa: "",
                    italiana: "",
                    medieGeneralaBacalaureat: "",
                    probaAlegere: {
                      label: "Nu include nota probei la alegere",
                      value: "doarMediaGenerala",
                    },
                    mediaAnilorDeStudiuLicenta: "",
                    mediaExamenLicenta: "",
                    notaProbaAlegere: "",
                    optiuniDistribuire: [],
                    ...this.props.dosar,
                  }
                : {
                    nume: "",
                    prenume: "",
                    initialaTata: "",
                    cnp: "",
                    sex: "",
                    nationalitate: "",
                    dataNasterii: null,
                    localitateaNasterii: "",
                    carteIdentitateSerie: "",
                    carteIdentitateNumar: "",
                    carteIdentitateEliberatDe: "",
                    carteIdentitateEliberatData: null,
                    carteIdentitateExpiraLaData: null,
                    domiciliuStabilLocalitate: "",
                    domiciliuStabilJudetSector: "",
                    domiciliuStabilCodPostal: "",
                    domiciliuStabilStrada: "",
                    domiciliuStabilNr: "",
                    domiciliuStabilBloc: "",
                    domiciliuStabilScara: "",
                    domiciliuStabilEtaj: "",
                    domiciliuStabilApartament: "",
                    nrTelefon: "",
                    liceuAbsolvitNume: "",
                    liceuAbsolvitLocalitate: "",
                    liceuAbsolvitSectie: "",
                    liceuAbsolvitAn: "",
                    facultateAbsolvitaNume: "",
                    facultateAbsolvitaLocalitate: "",
                    facultateAbsolvitaAn: "",
                    facultateAbsolvitaTipFinantare: "",
                    facultateStudentNume: "",
                    facultateStudentLocalitate: "",
                    facultateStudentAnStudii: "",
                    facultateStudentTipFinantare: "",
                    masterAbsolvitNume: "",
                    masterAbsolvitLocalitate: "",
                    masterAbsolvitAn: "",
                    masterAbsolvitTipFinantare: "",
                    masterStudentNume: "",
                    masterStudentLocalitate: "",
                    masterStudentPerioada: "",
                    masterStudentTipFinantare: "",
                    facultateCurentNume: "",
                    facultateCurentLocalitate: "",
                    facultateCurentAnStudii: "",
                    facultateCurentTipFinantare: "",
                    masterCurentNume: "",
                    masterCurentLocalitate: "",
                    masterCurentAn: "",
                    masterCurentTipFinantare: "",
                    prenumeTata: "",
                    prenumeMama: "",
                    adresaParinti: "",
                    ordineaLimbilorDeStudiu: "",
                    certificatCompetentaLingvisticaTip: "",
                    certificatCompetentaLingvisticaLimba: "",
                    engleza: "",
                    franceza: "",
                    germana: "",
                    spaniola: "",
                    rusa: "",
                    italiana: "",
                    medieGeneralaBacalaureat: "",
                    probaAlegere: {
                      label: "Nu include nota probei la alegere",
                      value: "doarMediaGenerala",
                    },
                    mediaAnilorDeStudiuLicenta: "",
                    mediaExamenLicenta: "",
                    notaProbaAlegere: "",
                    optiuniDistribuire: [],
                  }
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  nomenclatoare: state.nomenclatoare,
  user: getUser(state),
  dosar: getDosarInscris(state),
});

const mapDispatchToProps = {
  fetchNomenclatoarePublice,
  addInscris,
  fetchDosarInscris,
  updateMyInscris,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletareFisa);
