import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import request from "superagent";
import Dropzone from "react-dropzone";
import * as helpers from "../../utils";
import {
  fetchDosarInscris,
  getPosition,
  updateUploadAnexa4,
} from "../../actions/inscrisi-ro";
import { getDosarInscris } from "../../reducers/inscrisiReducer";
import { getPrimaOptiuneString } from "../../utils";
import { showSnackbar } from "../../actions";
import { getToken } from "../../reducers/authReducer";

const uploadAnexa4 = async (jwt, file) => {
  try {
    const res = await request
      .post(`${helpers.serverUrl}/payment/anexa-4`)
      .set("Authorization", `Bearer ${jwt}`)
      .attach("anexa4", file);
    return null;
  } catch (e) {
    return e;
  }
};

export default function Eligibil() {
  const dispatch = useDispatch();
  const dosar = useSelector(getDosarInscris);
  const jwt = useSelector(getToken);
  let uploadRef = createRef(null);
  useEffect(() => {
    dispatch(fetchDosarInscris());
    // if (dosar?._id) {
    //   dispatch(getPosition({ dosarId: dosar._id }));
    // }
  }, [dosar._id]);
  const t = useSelector(
    (state) => state.i18nState.translations[state.i18nState.lang]
  );
  const position = useSelector((state) => state.inscrisi.position);
  const primaOptiune = getPrimaOptiuneString(dosar);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (file) => {
    setIsUploading(true);
    const x = await uploadAnexa4(jwt, file);
    if (x) {
      dispatch(showSnackbar(x, "error"));
    } else {
      dispatch(updateUploadAnexa4());
    }
    setIsUploading(false);
  };
  if (!dosar.candidatType) {
    return <div>Loading...</div>;
  }
  if (dosar.candidatType.dppd && dosar.candidatType.registerFor === "master") {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">
          Esti eligibil{dosar.notaEseu > 5 && ". Confirmă locul!"}
        </p>
        <p className="mb-8 text-left">
          Media generală a anilor de studii universitare de licență este:{" "}
          {dosar.mediaAnilorDeStudiuLicenta}
          <br />
          Media examenului de licență: {dosar.mediaExamenLicenta}
          <br />
          {dosar.notaEseu && (
            <>
              Nota la proba de admitere este: {dosar.notaEseu}
              <br />
              Media de admitere este: {dosar.medieAdmitere}
              <br />
            </>
          )}
          <br />
          Dosarul tau are id: {dosar._id}
        </p>
        {typeof dosar.notaEseu === "undefined" && (
          <Alert className="mb-4 text-left" severity="info" variant="outlined">
            <p>
              Lista finală a candidaților eligibili pentru proba de admitere va
              fi stabilită în urma contestațiilor și va fi afișată conform
              calendarului de pe{" "}
              <a
                className="underline text-link"
                href="https://admitere.utcb.ro"
              >
                https://admitere.utcb.ro
              </a>
              . Tot aici găsești și informații suplimentare. Proba de admitere
              se susține conform calendarului. <br />
              <br />
              Contestatiile se primesc pe adresa de mail{" "}
              <a
                className="underline text-link"
                href="mailto:secretariat@utcb.ro"
              >
                secretariat@utcb.ro
              </a>
            </p>
          </Alert>
        )}
        {dosar.notaEseu && (
          <Alert severity="warning" variant="outlined" className="text-left">
            <span className="font-bold">
              PENTRU A CONFIRMA LOCUL TREBUIE SĂ ADUCI/TRIMIȚI DIPLOMELE DE
              BACALAUREAT ȘI LICENȚĂ ÎN ORIGINAL
            </span>
            <br />
            NU se accepă copii legalizate!
            <br />
            <a
              href="https://admitere.utcb.ro/#faq"
              className="underline text-link"
              target="blank"
            >
              (Vezi cum poți trimite diploma).
            </a>
            <br />
            Dacă ești absolvent în anul universitar curent sau anterior din
            cadrul Universității Tehnice de Construcții București și nu ai
            ridicat adeverința de absolvire, trebuie doar să anunți Biroul
            Studenți Internaționali din cadrul Secretariatului Rectorat.
          </Alert>
        )}
      </div>
    );
  }
  if (
    dosar.candidatType.translationAndInterpretation &&
    dosar.candidatType.registerFor === "master"
  ) {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">
          Esti eligibil {dosar.notaExamenMaster > 5 && "Confirmă locul!"}
        </p>
        <p className="mb-8 text-left">
          Media generală a anilor de studii universitare de licență este:{" "}
          {dosar.mediaAnilorDeStudiuLicenta}
          <br />
          Media examenului de licență: {dosar.mediaExamenLicenta}
          <br />
          {dosar.notaEseu && (
            <>
              Nota la proba de admitere este: {dosar.notaEseu}
              <br />
              Media de admitere este: {dosar.medieAdmitere}
              <br />
            </>
          )}
          <br />
          Dosarul tau are id: {dosar._id}
        </p>
        {typeof dosar.notaEseu === "undefined" && (
          <Alert className="mb-4 text-left" severity="info" variant="outlined">
            <p>
              Lista finală a candidaților eligibili pentru proba de admitere va
              fi stabilită în urma contestațiilor și va fi afișată conform
              calendarului de pe{" "}
              <a
                className="underline text-link"
                href="https://admitere.utcb.ro"
              >
                https://admitere.utcb.ro
              </a>
              . Tot aici găsești și informații suplimentare. Proba de admitere
              se susține conform calendarului. Contestatiile se primesc pe
              adresa de mail{" "}
              <a
                className="underline text-link"
                href="mailto:secretariat@utcb.ro"
              >
                secretariat@utcb.ro
              </a>
            </p>
          </Alert>
        )}
        {dosar.notaEseu && (
          <Alert severity="warning" variant="outlined" className="text-left">
            <span className="font-bold">
              PENTRU A CONFIRMA LOCUL TREBUIE SĂ ADUCI/TRIMIȚI DIPLOMELE DE
              BACALAUREAT ȘI LICENȚĂ ÎN ORIGINAL
            </span>
            <br />
            NU se accepă copii legalizate!
            <br />
            <a
              href="https://admitere.utcb.ro/#faq"
              className="underline text-link"
              target="blank"
            >
              (Vezi cum poți trimite diploma).
            </a>
            <br />
            Dacă ești absolvent în anul universitar curent sau anterior din
            cadrul Universității Tehnice de Construcții București și nu ai
            ridicat adeverința de absolvire, trebuie doar să anunți Biroul
            Studenți Internaționali din cadrul Secretariatului Rectorat.
          </Alert>
        )}
      </div>
    );
  }
  if (
    dosar.candidatType.translationAndInterpretation &&
    dosar.candidatType.registerFor === "licenta"
  ) {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">Esti eligibil</p>
        <p className="mb-8">
          {/* Te afli pe pozitia {position}, la {primaOptiune}.  */}
          Media ta de admitere este {dosar.medieAdmitere}, iar dosarul tau are
          id: {dosar._id}
        </p>
        <Alert className="mb-4 text-left" severity="info" variant="outlined">
          <p>
            Lista finală a candidaților eligibili pentru proba de admitere va fi
            stabilită în urma contestațiilor și va fi afișată conform
            calendarului de pe{" "}
            <a className="underline text-link" href="https://admitere.utcb.ro">
              https://admitere.utcb.ro
            </a>
            . Tot aici găsești și informații suplimentare. Proba de admitere se
            susține conform calendarului. <br />
            <br />
            Contestatiile se primesc pe adresa de mail{" "}
            <a
              className="underline text-link"
              href="mailto:secretariat@utcb.ro"
            >
              secretariat@utcb.ro
            </a>
          </p>
        </Alert>
        <Alert className="text-left" severity="warning" variant="outlined">
          PENTRU A INTRA ÎN CONCURSUL DE ADMITERE (TRECEREA ÎN PASUL URMĂTOR),
          TREBUIE SĂ ADUCI/TRIMIȚI DIPLOMA DE BACALAUREAT ÎN ORIGINAL. NU se
          accepă copii legalizate!
          <br />
          <a
            href="https://admitere.utcb.ro/#faq"
            className="underline text-link"
            target="blank"
          >
            (Vezi cum poți trimite diploma).
          </a>
        </Alert>
      </div>
    );
  }

  if (dosar.candidatType.rdp && dosar.candidatType.registerFor === "licenta") {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">Esti eligibil</p>
        <p className="mb-8">
          {/* Te afli pe pozitia {position}, la {primaOptiune}.  */}
          Media ta de admitere este {dosar.medieAdmitere}, iar dosarul tau are
          id: {dosar._id}
        </p>
        <Alert className="mb-4 text-left" severity="info" variant="outlined">
          <p>
            Lista finală a candidaților eligibili pentru proba de admitere va fi
            stabilită în urma contestațiilor și va fi afișată conform
            calendarului de pe{" "}
            <a className="underline text-link" href="https://admitere.utcb.ro">
              https://admitere.utcb.ro
            </a>
            . Tot aici găsești și informații suplimentare. Proba de admitere se
            susține conform calendarului. Contestatiile se primesc pe adresa de
            mail{" "}
            <a
              className="underline text-link"
              href="mailto:secretariat@utcb.ro"
            >
              secretariat@utcb.ro
            </a>
          </p>
        </Alert>
        <Alert className="mb-4 text-left" severity="info" variant="outlined">
          <div>
            {dosar.uploadatAnexa4 ? (
              <span className="font-bold">Ai încărcat Anexa 4</span>
            ) : (
              <>
                <span className="font-bold">Trebuie să încarci Anexa 4</span>
                <ol>
                  <li>
                    Descarci anexa{" "}
                    <a
                      href="https://utcb.ro/wp-content/uploads/2024/07/anexa4-rdp-template.doc"
                      target="blank"
                      className="underline text-link"
                    >
                      de aici
                    </a>
                  </li>
                  <li>Completezi corespunzător anexa, o semnezi</li>
                  <li>
                    Reîncarci anexa completată și semnată aici
                    <Button
                      onClick={() => {
                        uploadRef.open();
                      }}
                      disabled={isUploading}
                      variant="contained"
                      color="primary"
                      className="mx-4"
                    >
                      Incarca anexa
                    </Button>
                    <Dropzone
                      multiple={false}
                      ref={(node) => {
                        // eslint-disable-next-line no-param-reassign
                        uploadRef = node;
                      }}
                      style={{ display: "none" }}
                      onDrop={(accepted, rejected) => {
                        handleUpload(accepted[0]);
                      }}
                    >
                      <p>Drop files here.</p>
                    </Dropzone>
                  </li>
                </ol>
                <div className="py-4">
                  <span className="font-bold">
                    Încărcarea anexei este OBLIGATORIE pentru a putea intra în
                    concursul de admitere.
                  </span>
                </div>
              </>
            )}
          </div>
        </Alert>
      </div>
    );
  }
  if (dosar.candidatType.rdp && dosar.candidatType.registerFor === "master") {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">
          Esti eligibil {dosar.notaEseu > 5 && "Confirmă locul!"}
        </p>
        <p className="mb-8 text-left">
          Media generală a anilor de studii universitare de licență este:{" "}
          {dosar.mediaAnilorDeStudiuLicenta}
          <br />
          Media examenului de licență: {dosar.mediaExamenLicenta}
          <br />
          {dosar.notaEseu && (
            <>
              Nota la proba de admitere este: {dosar.notaEseu}
              <br />
              Media de admitere este: {dosar.medieAdmitere}
              <br />
            </>
          )}
          <br />
          Dosarul tau are id: {dosar._id}
        </p>
        {typeof dosar.notaEseu === "undefined" && (
          <Alert
            className="mb-4 text-left"
            severity="warning"
            variant="outlined"
          >
            <p>
              Lista finală a candidaților eligibili pentru proba de admitere va
              fi stabilită în urma contestațiilor și va fi afișată conform
              calendarului de pe{" "}
              <a
                style={{ color: "#81C534" }}
                className="underline"
                href="https://admitere.utcb.ro"
              >
                https://admitere.utcb.ro
              </a>
              . Tot aici găsești și informații suplimentare. Proba de admitere
              se susține conform calendarului. Contestatiile se primesc pe
              adresa de mail{" "}
              <a
                style={{ color: "#81C534" }}
                className="underline"
                href="mailto:secretariat@utcb.ro"
              >
                secretariat@utcb.ro
              </a>
            </p>
          </Alert>
        )}
        {dosar.medieAdmitere > 5 && dosar.notaEseu && (
          <>
            <Alert
              className="mb-4 text-left"
              severity="info"
              variant="outlined"
            >
              {dosar.uploadatAnexa4 ? (
                <span className="font-bold">Ai încărcat Anexa 4</span>
              ) : (
                <div>
                  <span className="font-bold">Trebuie să încarci Anexa 4</span>
                  <ol>
                    <li>
                      Descarci anexa{" "}
                      <a
                        href="https://admitere.utcb.ro/#faq"
                        target="blank"
                        className="underline text-link"
                      >
                        de aici
                      </a>
                    </li>
                    <li>Completezi corespunzător anexa, o semnezi</li>
                    <li>
                      Reîncarci anexa completată și semnată aici
                      <Button
                        onClick={() => {
                          uploadRef.open();
                        }}
                        disabled={isUploading}
                        variant="contained"
                        color="primary"
                        className="mx-4"
                      >
                        Incarca anexa
                      </Button>
                      <Dropzone
                        multiple={false}
                        ref={(node) => {
                          // eslint-disable-next-line no-param-reassign
                          uploadRef = node;
                        }}
                        style={{ display: "none" }}
                        onDrop={(accepted, rejected) => {
                          handleUpload(accepted[0]);
                        }}
                      >
                        <span>Drop files here.</span>
                      </Dropzone>
                    </li>
                  </ol>
                  <div className="py-4">
                    <span className="font-bold">
                      Încărcarea anexei este OBLIGATORIE pentru a putea intra în
                      concursul de admitere.
                    </span>
                  </div>
                </div>
              )}
            </Alert>
            <Alert className="text-left" severity="warning" variant="outlined">
              <span className="font-bold">
                PENTRU A CONFIRMA LOCUL TREBUIE SĂ ADUCI/TRIMIȚI DIPLOMELE DE
                BACALAUREAT ȘI LICENȚĂ ÎN ORIGINAL
              </span>
              <br />
              <span>NU se accepă copii legalizate!</span>
              <br />
              <a
                href="https://admitere.utcb.ro/#faq"
                className="underline text-link"
                target="blank"
              >
                (Vezi cum poți trimite diploma).
              </a>
              <br />
              Dacă ești absolvent în anul universitar curent sau anterior din
              cadrul Universității Tehnice de Construcții București și nu ai
              ridicat adeverința de absolvire, trebuie doar să anunți Biroul
              Studenți Internaționali din cadrul Secretariatului Rectorat.
            </Alert>
          </>
        )}
      </div>
    );
  }
  if (dosar.candidatType.registerFor === "licenta") {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">Esti eligibil</p>
        <p className="mb-8">
          Media ta de admitere este {dosar.medieAdmitere}, iar dosarul tau are
          id: {dosar._id}
        </p>
        <Alert className="mb-4 text-left" severity="warning" variant="outlined">
          <p>
            Lista finală a candidaților eligibili pentru proba de admitere va fi
            stabilită în urma contestațiilor și va fi afișată conform
            calendarului de pe{" "}
            <a
              style={{ color: "#81C534" }}
              className="underline"
              href="https://admitere.utcb.ro"
            >
              https://admitere.utcb.ro
            </a>
            . Tot aici găsești și informații suplimentare. Proba de admitere se
            susține conform calendarului.
            <br />
            <br />
            Contestatiile se primesc pe adresa de mail{" "}
            <a
              style={{ color: "#81C534" }}
              className="underline"
              href="mailto:secretariat@utcb.ro"
            >
              secretariat@utcb.ro
            </a>
            <br />
            <br />
            {/* TODO */}
            PENTRU A INTRA ÎN CONCURSUL DE ADMITERE (TRECEREA ÎN PASUL URMĂTOR),
            TREBUIE SĂ ADUCI/TRIMIȚI DIPLOMA DE BACALAUREAT ÎN ORIGINAL. NU se
            accepă copii legalizate!{" "}
            <a
              href="https://admitere.utcb.ro/#faq"
              className="underline text-link"
              target="blank"
            >
              (Vezi cum poți trimite diploma).
            </a>
          </p>
        </Alert>
      </div>
    );
  }
  if (dosar.candidatType.registerFor === "master") {
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="mb-4 text-3xl">Esti eligibil</p>

        <Alert className="mb-4 text-left" severity="info" variant="outlined">
          <p>
            Lista finală a candidaților eligibili pentru proba de admitere va fi
            stabilită în urma contestațiilor și va fi afișată conform
            calendarului de pe{" "}
            <a
              style={{ color: "#81C534" }}
              className="underline"
              href="https://admitere.utcb.ro"
            >
              https://admitere.utcb.ro
            </a>
            . Tot aici găsești și informații suplimentare. Proba de admitere se
            susține conform calendarului.
            <br />
            <br />
            Contestatiile se primesc pe adresa de mail{" "}
            <a
              style={{ color: "#81C534" }}
              className="underline"
              href="mailto:secretariat@utcb.ro"
            >
              secretariat@utcb.ro
            </a>
            <br />
            <br />
            Media generală a anilor de studii universitare de licență este:{" "}
            {dosar.mediaAnilorDeStudiuLicenta}
            <br />
            Media examenului de licență: {dosar.mediaExamenLicenta}
            <br />
            Dosarul tau are id:
            {dosar._id}
          </p>
        </Alert>
        {dosar.notaEseu && (
          <Alert severity="info" className="text-left" variant="outlined">
            Nota la proba de admitere este: {dosar.notaEseu}
            <br />
            Media de admitere este: {dosar.medieAdmitere}
            <br />
            <br />
            După ce susții proba de admitere, PENTRU A CONFIRMA LOCUL TREBUIE SĂ
            ADUCI/TRIMIȚI DIPLOMELE DE BACALAUREAT ȘI LICENȚĂ ÎN ORIGINAL. NU se
            accepă copii legalizate!
            <br />
            <a
              href="https://admitere.utcb.ro/#faq"
              className="underline text-link"
              target="blank"
            >
              (Vezi cum poți trimite diploma).
            </a>
            <br />
            Dacă ești absolvent în anul universitar curent sau anterior din
            cadrul Universității Tehnice de Construcții București și nu ai
            ridicat adeverința de absolvire, trebuie doar să anunți
            secretariatul facultății la care te-ai înscris de acest lucru.
          </Alert>
        )}
      </div>
    );
  }
  return null;
}
