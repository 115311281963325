import React, { Component } from "react";
import PropTypes from "prop-types";
import { setLanguage } from "redux-i18n";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

class LanguageBox extends Component {
  static propTypes = {
    variant: PropTypes.string,
    setLanguage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    variant: "dark",
  };

  componentDidMount() {
    const { setLanguage } = this.props;
    const language = localStorage.getItem("iosudLanguage");
    setLanguage(language || "ro");
  }

  handleChangeLanguage = (language) => () => {
    const { setLanguage } = this.props;
    localStorage.setItem("iosudLanguage", language);
    setLanguage(language);
  };

  render() {
    const { lang, variant } = this.props;
    return (
      <div className="lvd-language-box">
        <Button
          disabled={lang === "ro"}
          variant="text"
          color={variant === "light" ? "inherit" : "default"}
          onClick={this.handleChangeLanguage("ro")}
        >
          RO
        </Button>
        <Button
          disabled={lang === "en"}
          color={variant === "light" ? "inherit" : "default"}
          variant="text"
          onClick={this.handleChangeLanguage("en")}
        >
          EN
        </Button>
        <Button
          disabled={lang === "fr"}
          color={variant === "light" ? "inherit" : "default"}
          variant="text"
          onClick={this.handleChangeLanguage("fr")}
        >
          FR
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
});

const mapDispatchToProps = {
  setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageBox);
