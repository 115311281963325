import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();

ReactGA.initialize("UA-171256136-1");

history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

export default history;
