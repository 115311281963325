import React, { Component } from "react";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import moment from "moment";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  Tab,
  Tabs,
  AppBar,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as yup from "yup";
import { connect } from "react-redux";
import { setLanguage } from "redux-i18n";
import { Formik, Form } from "formik";
import FileSaver from "file-saver";
import request from "superagent";
import { Alert } from "@material-ui/lab";
import {
  fetchNomenclatoarePublice,
  fetchTipuriDeDocumente,
  showSnackbar,
  setAppBarTitle,
} from "../../../actions";
import {
  fetchInscrisById,
  updateInscris,
  handleReject,
  finalizeDosar,
  handleValidate,
} from "../../../actions/inscrisi-ro";
import AddEditForm from "../../General/AddEditForm";
import {
  getInscrisById,
  getCandidatType,
} from "../../../reducers/inscrisiReducer";
import { getTipuriDeDocumente } from "../../../reducers/tipuriDeDocumenteReducer";
import DocumentTable from "../../General/DocumentTable";
import { serverUrl } from "../../../utils";
import {
  getToken,
  getCandidatTypeForLoggedInUser,
  getRole,
} from "../../../reducers/authReducer";
import Payments from "./Payments";
import { getReadableStatusRo } from "../../../utils/helpers";
import Cerere from "./Cerere";
import Scrisoare from "./Scrisoare";
import generateInputs from "./secretaraInputs";
import Contract from "./Contract";
import InscrisiInfo from "./InscrisiInfo";
import AnexaPatru from "./AnexaPatru";

class InscrisiEdit extends Component {
  state = {
    inputs: [],
    isLoading: false,
    isEditing: false,
    currentTab: 0,
    isValidateDialogOpen: false,
    isRejectDialogOpen: false,
    isDeleteDialogOpen: false,
  };

  async componentDidMount() {
    const { t, nomenclatoare, match } = this.props;
    this.props.setLanguage("ro");
    await this.props.fetchInscrisById(match.params.dosarId);
    await this.props.fetchNomenclatoarePublice();
    await this.props.fetchTipuriDeDocumente();
    this.props.setAppBarTitle(
      `${this.props.dosar.nume} ${this.props.dosar.prenume}`
    );
    this.setState({
      inputs: generateInputs(t, this.props, this.props.dosar.candidatType),
    });
  }

  componentWillUnmount() {
    this.props.setAppBarTitle(undefined);
  }

  handleSubmit = async (values) => {
    await this.props.updateInscris(values);
  };

  handleChangeHasDeliveredDiploma = async () => {
    try {
      const { dosar, jwt, match } = this.props;
      await request
        .get(`${serverUrl}/inscrisi-ro/delivered-original-diploma/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      await this.props.fetchInscrisById(match.params.dosarId);
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleSendDiplomaReminder = async () => {
    try {
      const { dosar, jwt, match } = this.props;
      await request
        .get(`${serverUrl}/inscrisi-ro/${dosar._id}/trimite-reminder-diploma`)
        .set("Authorization", `Bearer ${jwt}`);
      await this.props.fetchInscrisById(match.params.dosarId);
      this.props.showSnackbar("Reminderul a fost trimis!", "success");
    } catch (e) {
      this.props.showSnackbar(e.message, "error");
    }
  };

  handleExport = async () => {
    try {
      const { dosar, jwt } = this.props;
      const res = await request
        .get(`${serverUrl}/inscrisi-ro/export/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleDeleteDosar = async () => {
    try {
      const { dosar, jwt } = this.props;
      const res = await request
        .delete(`${serverUrl}/inscrisi-ro/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      this.props.showSnackbar("Dosarul a fost sters", "success");
      this.props.history.push("/inscrisi-ro");
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleMarkAsInEditing = async () => {
    try {
      const { dosar, jwt, match } = this.props;
      await request
        .get(`${serverUrl}/inscrisi-ro/mark-as-editing/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      await this.props.fetchInscrisById(match.params.dosarId);
      this.props.showSnackbar("Dosarul este marcat in editare", "success");
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleFinalizeDosar = (event) => {
    this.props.finalizeDosar(this.props.dosar, event.target.checked);
  };

  renderTabContent = () => {
    const { inputs, currentTab, isEditing } = this.state;
    const { dosar, t, tipuriDoc, role } = this.props;
    const isViewing =
      role === "administrator"
        ? false
        : dosar.status === "eligibil" || dosar.status === "concursDeAdmitere";
    switch (currentTab) {
      case 0:
        return (
          <div>
            <div>
              {dosar.fisaCorecta && (
                <span className="my-4">
                  Fisa a fost marcata ca fiind corecta
                </span>
              )}
            </div>
            <AddEditForm
              name="inscrisi"
              inputs={inputs}
              isViewing={isViewing}
              enableForceEdit
              handleNext={this.handleNext}
              handleSubmit={this.handleSubmit}
              saveButtonLabel={t.save}
              validationSchema={yup.object().shape({})}
              initialValues={
                isEditing
                  ? {
                      ...dosar,
                    }
                  : {
                      ...dosar,
                    }
              }
            />
            {/* {this.props.role !== "administrator" && ( */}
            <div className="pt-4">
              <TabelOptiuni optiuni={dosar.optiuniDistribuire} />
            </div>
            {/* )} */}
          </div>
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente || []}
            tipuriDocumente={tipuriDoc}
            name="inscrisi-ro"
            type="edit"
          />
        );
      case 2:
        return <Payments dosar={dosar} />;
      case 3:
        return <Cerere dosar={dosar} />;
      case 4:
        return <Contract dosar={dosar} />;
      case 5:
        return <AnexaPatru dosar={dosar} />;
      default:
        return "Unknown Tab";
    }
  };

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  handleBackClick = () => {
    this.props.history.push("/inscrisi-ro");
  };

  handleOpenDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: true,
      });
    } else if (dialogType === "delete") {
      this.setState({
        isDeleteDialogOpen: true,
      });
    } else {
      this.setState({
        isValidateDialogOpen: true,
      });
    }
  };

  closeDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: false,
      });
    } else if (dialogType === "delete") {
      this.setState({
        isDeleteDialogOpen: false,
      });
    } else {
      this.setState({
        isValidateDialogOpen: false,
      });
    }
  };

  handleReject = async (values) => {
    const { dosar, match } = this.props;
    await this.props.handleReject(dosar, values);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("reject")();
  };

  handleValidate = async () => {
    const { dosar, match } = this.props;
    await this.props.handleValidate(dosar);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("validate")();
  };

  render() {
    const {
      currentTab,
      isRejectDialogOpen,
      isValidateDialogOpen,
      isDeleteDialogOpen,
    } = this.state;
    const { dosar, role } = this.props;
    // if (!dosar.email) {
    //   return <CircularProgress />;
    // }
    return (
      <div>
        <div className="lvd-spacer20" />
        {!this.props.stayAfterEdit && (
          <div>
            <Button
              onClick={this.handleBackClick}
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
            >
              <ArrowBackIos />
              Inapoi
            </Button>
          </div>
        )}
        <div className="lvd-spacer10" />
        <InscrisiInfo type="ro" dosar={dosar} role={role} />
        {/* <div className="flex flex-wrap items-center mb-4">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleExport}
          >
            <CloudDownloadIcon />
            Exporta
          </Button>
          <div className="w-full mt-4 ml-0 sm:ml-4 sm:mt-0 sm:w-auto">
            {!dosar.inEditareLa && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleMarkAsInEditing}
              >
                <SpellcheckIcon className="mr-2" />
                Marcheaza in editare
              </Button>
            )}
          </div>
          <div className="flex flex-wrap items-center mt-4 ml-0 sm:ml-auto sm:mt-0">
            {role === "administrator" && (
              <div className="w-full mb-4 mr-0 sm:mr-4 sm:w-auto sm:mb-0">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleOpenDialog("delete")}
                >
                  <DeleteForeverIcon className="mr-2" />
                  Sterge dosar
                </Button>
              </div>
            )}
            {dosar.status === "trimisCatreValidare" && (
              <>
                {dosar.areToateDocumenteleObligatoriiValidate && (
                  <div className="w-full mb-4 mr-0 sm:mr-4 sm:w-auto sm:mb-0">
                    <Button
                      onClick={this.handleOpenDialog("validate")}
                      variant="contained"
                      color="primary"
                    >
                      <ThumbUpIcon className="mr-2" />
                      Valideaza
                    </Button>
                  </div>
                )}
                <div className="w-full mb-4 sm:w-auto sm:mb-0">
                  <Button
                    onClick={this.handleOpenDialog("reject")}
                    variant="contained"
                    color="secondary"
                  >
                    <ThumbDownIcon className="mr-2" />
                    Respinge
                  </Button>
                </div>
              </>
            )}
          </div>
        </div> */}

        {/* <div>
          <Alert severity="info" variant="standard">
            <strong>Status:</strong>
            {getReadableStatusRo(dosar.status)}{" "}
            <span className="ml-6">
              <strong>User Id:</strong> {dosar.userId}
            </span>
          </Alert>
        </div> */}
        <div className="mt-2">
          {dosar.inEditareLa && (
            <Alert severity="error" variant="standard">
              In editare la {dosar.inEditareLa}
            </Alert>
          )}
        </div>
        {dosar.status === "eligibil" && false && (
          <div className="mt-4">
            <Paper className="p-4">
              <div className="flex flex-wrap items-center justify-between">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.handleChangeHasDeliveredDiploma}
                      checked={dosar.hasDeliveredDiploma}
                      // value={dosar.status === "finalizat"}
                      // style={{ marginRight: "16px" }}
                      variant="contained"
                      color="primary"
                    />
                  }
                  label="A adus diploma in original"
                >
                  {/* A adus diploma in original */}
                </FormControlLabel>
                {dosar.lastDiplomaReminderSentAt && (
                  <div>
                    <b>Ultimul reminder:</b>{" "}
                    {moment(dosar.lastDiplomaReminderSentAt).format(
                      "DD/MM HH:mm"
                    )}
                  </div>
                )}
                <div>
                  <b>Trimiteri: </b>
                  {dosar.noDiplomaReminders || 0}
                </div>
                <div>
                  <Button
                    color="primary"
                    onClick={this.handleSendDiplomaReminder}
                  >
                    Trimite reminder
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        )}
        <div className="my-4">
          {(dosar.status === "plataValidata" ||
            dosar.status === "finalizat") && (
            <FormControlLabel
              control={
                <Switch
                  onChange={this.handleFinalizeDosar}
                  checked={dosar.status === "finalizat"}
                  // value={dosar.status === "finalizat"}
                  style={{ marginRight: "16px" }}
                  variant="contained"
                  color="primary"
                />
              }
              label="Finalizeaza"
            >
              Finalizat
            </FormControlLabel>
          )}
        </div>
        <div className="lvd-spacer20" />
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            className="text-white bg-secondaryText"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label="Fisa" />
            <Tab label="Documente" />
            <Tab label="Plata" />
            <Tab
              label="Cerere"
              disabled={dosar.status !== "concursDeAdmitere"}
            />
            <Tab
              label="Contract"
              disabled={dosar.status !== "concursDeAdmitere"}
            />
            <Tab label="Anexa 4" disabled={!dosar.uploadatAnexa4} />
          </Tabs>
        </AppBar>
        {this.state.currentTab === 0 && (
          <div className="mt-4">{this.renderTabContent(dosar)}</div>
        )}
        {this.state.currentTab !== 0 && (
          <Paper className="mt-4 lvd-container-form">
            {this.renderTabContent(dosar)}
          </Paper>
        )}
        <Dialog
          open={isValidateDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog("validate")}
        >
          <DialogContent>
            Esti sigur ca vrei sa validezi acest dosar?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.closeDialog("validate")}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleValidate}
            >
              Valideaza
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDeleteDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog("delete")}
        >
          <DialogContent>
            Esti sigur ca vrei sa stergi acest dosar?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.closeDialog("delete")}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleDeleteDosar}
            >
              Sterge
            </Button>
          </DialogActions>
        </Dialog>
        <Formik
          onSubmit={this.handleReject}
          initialValues={{ motiv: "", fisaCorecta: false }}
          validationSchema={yup.object().shape({
            motiv: yup.string().required("Camp obligatoriu"),
            fisaCorecta: yup.bool(),
          })}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Dialog
              open={isRejectDialogOpen}
              onClose={this.closeDialog("reject")}
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Form>
                  <TextField
                    label="Motiv respingere"
                    fullWidth
                    name="motiv"
                    error={!!errors.motiv && !!touched.motiv}
                    FormHelperTextProps={{ error: true }}
                    helperText={
                      !!errors.motiv && !!touched.motiv && errors.motiv
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.motivRespingere}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.fisaCorecta}
                        onChange={(e) =>
                          setFieldValue("fisaCorecta", e.target.checked)
                        }
                        name="fisaCorecta"
                        color="primary"
                      />
                    }
                    label="Fisa corecta"
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.closeDialog("reject")}
                >
                  Renunta
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Respinge
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  }
}

const TabelOptiuni = ({ optiuni }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ordine</TableCell>
            <TableCell>Optiune</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {optiuni &&
            optiuni.map((opt, index) => (
              <TableRow key={opt.value}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{opt.label}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations.ro,
  jwt: getToken(state),
  nomenclatoare: state.nomenclatoare,
  role: getRole(state),
  dosar: getInscrisById(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
  candidatType: getCandidatType(state) || getCandidatTypeForLoggedInUser(state),
});

const mapDispatchToProps = {
  fetchNomenclatoarePublice,
  fetchInscrisById,
  fetchTipuriDeDocumente,
  updateInscris,
  setLanguage,
  handleReject,
  handleValidate,
  showSnackbar,
  finalizeDosar,
  setAppBarTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(InscrisiEdit);
