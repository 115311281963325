import request from "superagent";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";

const fetchNomenclatoareRequest = () => ({
  type: TYPES.NOMENCLATOARE_FETCH_REQUEST,
});
const fetchNomenclatoareSuccess = (payload) => ({
  type: TYPES.NOMENCLATOARE_FETCH_SUCCESS,
  payload,
});
const fetchNomenclatoareFailure = (error) => ({
  type: TYPES.NOMENCLATOARE_FETCH_FAILURE,
  error,
});

export const fetchNomenclatoare = () => async (dispatch) => {
  dispatch(fetchNomenclatoareRequest());
  try {
    const res = await request.get(`${serverUrl}/nomenclatoare`);
    dispatch(fetchNomenclatoareSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchNomenclatoareFailure(e));
  }
};

export const fetchNomenclatoarePublice = () => async (dispatch) => {
  dispatch(fetchNomenclatoareRequest());
  try {
    const res = await request.get(`${serverUrl}/nomenclatoare/inscriere`);
    dispatch(
      fetchNomenclatoareSuccess({
        ...res.body,
        tari: res.body.tari.sort((a, b) => a.nume.localeCompare(b.nume)),
      })
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchNomenclatoareFailure(e));
  }
};

const deleteNomenclatorRequest = (payload) => ({
  type: TYPES.NOMENCLATOARE_DELETE_REQUEST,
  payload,
});
const deleteNomenclatorSuccess = (payload) => ({
  type: TYPES.NOMENCLATOARE_DELETE_SUCCESS,
  payload,
});
const deleteNomenclatorFailure = (payload, error) => ({
  type: TYPES.NOMENCLATOARE_DELETE_FAILURE,
  payload,
  error,
});

export const deleteNomenclator = ({ id, type, nomenclatorName }) => async (
  dispatch
) => {
  dispatch(deleteNomenclatorRequest({ id, type, nomenclatorName }));
  try {
    await request.delete(`${serverUrl}/nomenclatoare`).send({ id, type });
    dispatch(deleteNomenclatorSuccess({ id, type, nomenclatorName }));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(deleteNomenclatorFailure({ id, type, nomenclatorName }, e));
  }
};

const addNomenclatorRequest = (payload) => ({
  type: TYPES.NOMENCLATOARE_ADD_REQUEST,
  payload,
});
const addNomenclatorSuccess = (payload) => ({
  type: TYPES.NOMENCLATOARE_ADD_SUCCESS,
  payload,
});
const addNomenclatorFailure = (payload, error) => ({
  type: TYPES.NOMENCLATOARE_ADD_FAILURE,
  payload,
  error,
});

export const addNomenclator = (payload) => async (dispatch) => {
  dispatch(addNomenclatorRequest(payload));
  try {
    const res = await request.post(`${serverUrl}/nomenclatoare`).send(payload);
    dispatch(addNomenclatorSuccess({ ...payload, ...res.body }));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(addNomenclatorFailure(payload, e));
  }
};

const editNomenclatorRequest = (payload) => ({
  type: TYPES.NOMENCLATOARE_EDIT_REQUEST,
  payload,
});
const editNomenclatorSuccess = (payload) => ({
  type: TYPES.NOMENCLATOARE_EDIT_SUCCESS,
  payload,
});
const editNomenclatorFailure = (payload, error) => ({
  type: TYPES.NOMENCLATOARE_EDIT_FAILURE,
  payload,
  error,
});

export const editNomenclator = (payload) => async (dispatch) => {
  dispatch(editNomenclatorRequest(payload));
  try {
    const res = await request.put(`${serverUrl}/nomenclatoare`).send(payload);
    dispatch(editNomenclatorSuccess({ ...payload, ...res.body }));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(editNomenclatorFailure(payload, e));
  }
};
