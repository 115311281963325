/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import * as yup from "yup";
import {
  Menu,
  Tab,
  Tabs,
  AppBar,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from "superagent";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { MoreVert } from "@material-ui/icons";
import { Form, Formik } from "formik";
import moment from "moment";
import { useHistory } from "react-router";
import { getToken, getUser } from "../../../reducers/authReducer";
import {
  getReadableStatusNonEu,
  getReadableStatusRo,
} from "../../../utils/helpers";
import { serverUrl } from "../../../utils";
import {
  fetchInscrisById,
  handleReject,
  handleValidate,
} from "../../../actions/inscrisi-ro";
import { showSnackbar } from "../../../actions";

export default function InscrisiInfo({ dosar, role, type }) {
  const jwt = useSelector(getToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const [menuAnchorEl, setMenuAnchorEl] = useState(false);
  const [dialogState, setDialogState] = useState({
    isRejectDialogOpen: false,
    isDeleteDialogOpen: false,
    isValidateDialogOpen: false,
  });
  const handleMarkAsInEditing = async () => {
    try {
      await request
        .get(`${serverUrl}/inscrisi-ro/mark-as-editing/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      await dispatch(fetchInscrisById(dosar._id));
      dispatch(showSnackbar("Dosarul este marcat in editare", "success"));
    } catch (e) {
      dispatch(showSnackbar(e, "error"));
    } finally {
      setMenuAnchorEl(null);
    }
  };
  const handleOpenDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      setDialogState((ds) => ({
        ...ds,
        isRejectDialogOpen: true,
      }));
    } else if (dialogType === "delete") {
      setDialogState((ds) => ({
        ...ds,
        isDeleteDialogOpen: true,
      }));
    } else {
      setDialogState((ds) => ({
        ...ds,
        isValidateDialogOpen: true,
      }));
    }
    setMenuAnchorEl(null);
  };
  const closeDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      setDialogState((ds) => ({
        ...ds,
        isRejectDialogOpen: false,
      }));
    } else if (dialogType === "delete") {
      setDialogState((ds) => ({
        ...ds,
        isDeleteDialogOpen: false,
      }));
    } else {
      setDialogState((ds) => ({
        ...ds,
        isValidateDialogOpen: false,
      }));
    }
  };
  const handleValidateLocal = async () => {
    await dispatch(handleValidate(dosar));
    await dispatch(fetchInscrisById(dosar._id));
    closeDialog("validate")();
  };
  const handleRejectLocal = async (values) => {
    await dispatch(handleReject(dosar, values));
    await dispatch(fetchInscrisById(dosar._id));
    closeDialog("reject")();
  };
  const handleDeleteDosar = async () => {
    try {
      const res = await request
        .delete(`${serverUrl}/inscrisi-ro/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      dispatch(showSnackbar("Dosarul a fost sters", "success"));
      history.push("/inscrisi-ro");
    } catch (e) {
      dispatch(showSnackbar(e, "error"));
    }
  };
  const handleChangeHasDeliveredDiploma = async () => {
    try {
      await request
        .get(`${serverUrl}/inscrisi-ro/delivered-original-diploma/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`);
      await dispatch(fetchInscrisById(dosar._id));
    } catch (e) {
      dispatch(showSnackbar(e, "error"));
    }
  };
  const handleSendDiplomaReminder = async () => {
    try {
      await request
        .get(`${serverUrl}/inscrisi-ro/${dosar._id}/trimite-reminder-diploma`)
        .set("Authorization", `Bearer ${jwt}`);
      await dispatch(fetchInscrisById(dosar._id));
      dispatch(showSnackbar("Reminderul a fost trimis!", "success"));
    } catch (e) {
      dispatch(showSnackbar(e.message, "error"));
    } finally {
      setMenuAnchorEl(null);
    }
  };
  return (
    <Paper>
      <div className="grid grid-cols-1 sm:grid-cols-3">
        <div className="px-2 py-4 border-r border-gray-300">
          <div className="flex">
            <div className="flex items-center justify-center">
              <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
              >
                {(dosar.inEditareLa === "" ||
                  user.role === "administrator") && (
                  <MenuItem onClick={handleMarkAsInEditing}>
                    {dosar.inEditareLa === ""
                      ? "Marcheaza: in editare"
                      : user.role === "administrator"
                      ? "Reseteaza 'in editare'"
                      : "-"}
                  </MenuItem>
                )}
                {dosar?.candidatType?.candidatType !== "non-eu" && (
                  <MenuItem onClick={handleSendDiplomaReminder}>
                    Trimite notificare diploma
                  </MenuItem>
                )}
                {role === "administrator" && (
                  <MenuItem onClick={handleOpenDialog("delete")}>
                    Sterge Dosar
                  </MenuItem>
                )}
              </Menu>
            </div>
            <div>
              <div className="pb-2 text-2xl">
                Status:{" "}
                <span className="font-bold">
                  {type === "ro"
                    ? getReadableStatusRo(dosar.status)
                    : getReadableStatusNonEu(dosar.status)}
                </span>
              </div>
              {dosar.inEditareLa && (
                <div className="pb-2 text-red-500">
                  In editare la {dosar.inEditareLa}
                </div>
              )}
              {dosar.validatDe && (
                <div className="pb-2 text-red-500">
                  Verificat de {dosar.validatDe}
                </div>
              )}
              {dosar.status === "trimisCatreValidare" && (
                <div className="flex">
                  <div className="w-full mb-4 mr-0 sm:mr-4 sm:w-auto sm:mb-0">
                    <Button
                      onClick={handleOpenDialog("reject")}
                      variant="contained"
                      color="secondary"
                    >
                      <ThumbDownIcon className="mr-2" />
                      Respinge
                    </Button>
                  </div>
                  {dosar.areToateDocumenteleObligatoriiValidate && (
                    <div className="w-full mb-4 sm:w-auto sm:mb-0">
                      <Button
                        onClick={handleOpenDialog("validate")}
                        variant="contained"
                        color="primary"
                      >
                        <ThumbUpIcon className="mr-2" />
                        Valideaza
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-4 border-r border-gray-300">
          <div>
            Email: <span className="font-bold">{dosar.email}</span>
          </div>
          <div>
            Telefon: <span className="font-bold">{dosar.nrTelefon}</span>
          </div>
          <div>
            User Id: <span className="font-bold">{dosar.userId}</span>
          </div>
        </div>
        {dosar?.candidatType?.candidatType !== "non-eu" && (
          <div className="px-4 py-4">
            <div>
              Status diploma:{" "}
              <span className="font-bold">
                {dosar.hasDeliveredDiploma
                  ? "A adus diploma"
                  : "Nu a adus diploma"}
                {(dosar.status === "eligibil" ||
                  dosar.status === "concursDeAdmitere") && (
                  <Switch
                    onChange={handleChangeHasDeliveredDiploma}
                    checked={dosar.hasDeliveredDiploma}
                    // value={dosar.status === "finalizat"}
                    // style={{ marginRight: "16px" }}
                    variant="contained"
                    color="primary"
                  />
                )}
              </span>
            </div>{" "}
            <div>
              Notificari diploma:{" "}
              <span className="font-bold">{dosar.noDiplomaReminders}</span>
            </div>
            <div>
              Ultimul reminder trimis la:{" "}
              <span className="font-bold">
                {moment(dosar.lastDiplomaReminderSentAt).format("DD/MM HH:mm")}
              </span>
            </div>
            <div>
              Autentic:{" "}
              <span className="font-bold">
                {dosar.areGradesVerified ? "Da" : "Nu"}
              </span>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={dialogState.isValidateDialogOpen}
        fullWidth
        maxWidth="xs"
        onClose={closeDialog("validate")}
      >
        <DialogContent>
          Esti sigur ca vrei sa validezi acest dosar?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeDialog("validate")}
          >
            Renunta
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleValidateLocal}
          >
            Valideaza
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        onSubmit={handleRejectLocal}
        initialValues={{ motiv: "", fisaCorecta: false }}
        validationSchema={yup.object().shape({
          motiv: yup.string().required("Camp obligatoriu"),
          fisaCorecta: yup.bool(),
        })}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
        }) => (
          <Dialog
            open={dialogState.isRejectDialogOpen}
            onClose={closeDialog("reject")}
            fullWidth
            maxWidth="xs"
          >
            <DialogContent>
              <Form>
                <TextField
                  label="Motiv respingere"
                  fullWidth
                  name="motiv"
                  error={!!errors.motiv && !!touched.motiv}
                  FormHelperTextProps={{ error: true }}
                  helperText={!!errors.motiv && !!touched.motiv && errors.motiv}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.motivRespingere}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.fisaCorecta}
                      onChange={(e) =>
                        setFieldValue("fisaCorecta", e.target.checked)
                      }
                      name="fisaCorecta"
                      color="primary"
                    />
                  }
                  label="Fisa corecta"
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={closeDialog("reject")}
              >
                Renunta
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={handleSubmit}
              >
                Respinge
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
      <Dialog
        open={dialogState.isDeleteDialogOpen}
        fullWidth
        maxWidth="xs"
        onClose={closeDialog("delete")}
      >
        <DialogContent>Esti sigur ca vrei sa stergi acest dosar?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeDialog("delete")}
          >
            Renunta
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteDosar}
          >
            Sterge
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
