import React, { useCallback, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Paper, IconButton, Grid } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteIcon from "@material-ui/icons/Delete";
// import DropdownTreeSelect from "react-dropdown-tree-select";
import "./DragAndDropSelect.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import Select from "./Select";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  paddingBottom: `0 0 ${grid * 2}px 0`,
  // padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function DragAndDropSelect({
  items,
  onDragEnd,
  setFieldValue,
  computedOptions,
  arrayHelpers,
}) {
  //   const [draggables, setDraggables] = useState(getItems(10));
  // using useCallback is optional
  const onBeforeCapture = useCallback(() => {
    /* ... */
  }, []);
  const onBeforeDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragStart = useCallback(() => {
    /* ... */
  }, []);
  const onDragUpdate = useCallback(() => {
    /* ... */
  }, []);
  //   const onDragEnd = useCallback((result) => {
  //     // the only one that is required
  //     // dropped outside the list
  //     if (!result.destination) {
  //       return;
  //     }

  //     const items = reorder(
  //       draggables,
  //       result.source.index,
  //       result.destination.index
  //     );

  //     setDraggables(items);
  //   }, []);
  const renderTree = (nodes, index) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      className={nodes.selectable ? "must-bold" : ""}
      label={nodes.name}
      // label={nodes.selectable ? nodes.name : `${nodes.name} ${index + 1}`}
      onClick={nodes.onClick ? nodes.onClick(index) : () => {}}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, index))
        : null}
    </TreeItem>
  );

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCapture}
      onBeforeDragStart={onBeforeDragStart}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="1">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={item._id || item.id || item.value.toString()}
                draggableId={item._id || item.id || item.value.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <Paper
                      elevation={5}
                      style={{
                        backgroundColor: "#f1f2f4",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        // marginTop: "1rem",
                        // marginBottom: "1rem",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <DragIndicatorIcon />
                        </Grid>
                        <Grid item xs={10}>
                          <div className="font-bold">Optiunea {index + 1}</div>
                          <div className="mt-2">
                            {items[index] &&
                              items[index].label &&
                              items[index].label}
                          </div>
                          {/* {JSON.stringify(items[index])} */}
                          <Paper elevation={8} className="py-4 px-2 mt-2">
                            <TreeView
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              // defaultExpanded={[]}
                              defaultExpandIcon={<ChevronRightIcon />}
                            >
                              {renderTree(computedOptions, index)}
                            </TreeView>
                          </Paper>
                          {/* <Select
                            fullWidth
                            onChange={setFieldValue}
                            name={`optiuniDistribuire.${index}`}
                            value={items[index]}
                            key={index}
                            margin="dense"
                            label={`Optiune proritate ${index + 1}`}
                            options={[
                              {
                                ...items[index],
                              },
                              ...computedOptions,
                            ]}
                          /> */}
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
