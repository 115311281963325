import React, { useState } from "react";
import { Paper, AppBar, Tabs, Tab } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";

const feesOptions = [
  {
    cycle: "Bachelor's degree",
    field: "Civil Engineering",
    specialization: "Civil Engineering (French/English)",
    fee: "2430 EURO",
  },
  {
    cycle: "Bachelor's degree",
    field: "Installations engineering",
    specialization: "Installations for buildings (French)",
    fee: "2430 EURO",
  },
  {
    cycle: "Master’s degree",
    field: "Civil engineering and installations",
    specialization: "Structural engineering (French/English)",
    fee: "2430 EURO",
  },
  {
    cycle: "Master’s degree",
    field: "Civil engineering and installations",
    specialization: "Aquatic Environment Engineering and Science (English)",
    fee: "2430 EURO",
  },
  {
    cycle: "Master’s degree",
    field: "Civil engineering and installations",
    specialization:
      "Efficacité énergétique des installations techniques du bâtiment (French)",
    fee: "2430 EURO",
  },
  {
    cycle: "PhD studies",
    field: "Civil engineering and installations",
    specialization: `Fundamental sciences in civil engineering
Structure mechanics
Seismic engineering and construction safety
Urban engineering and regional development
Civil engineering
Roads, bridges and tunnels
Railways
Roads and airfields
Reinforced concrete structures
Steel structures
Geotechnics and foundations
Water supply and sewage systems
Water treatments
Waste water systems
Hydraulic structures
Land reclamations
Hydraulics and fluid mechanics
Hydrology, Hydrogeology and water management
Environment protection in civil engineering
Building services
Management in Civil Engineering
Management of investments projects
Economy and efficiency of investments
Indoor air quality
Acoustic of buildings
Energetic efficiency of building services
Geodesy, Photogrammetry, Cartography and remote sensing`,
    fee: "2610 EURO",
  },
  {
    cycle: "PhD studies",
    field: "Mechanical Engineering",
    specialization: `Thermotechnics
Construction machines and equipment
Technical mechanics and vibrations`,
    fee: "2610 EURO",
  },
  {
    cycle: "PhD studies",
    field: "Electrical Engineering",
    specialization: `Electrotechnics`,
    fee: "2610 EURO",
  },
  {
    cycle: "PhD studies",
    field: "Industrial Engineering",
    specialization: `Machine Building Technology
Quality engineering`,
    fee: "2610 EURO",
  },
  {
    cycle: "Romanian Language Preparatory year",
    field: "Mathematics and Nature Sciences",
    specialization: `-`,
    fee: "2430 EURO",
  },
  {
    cycle: "Romanian Language Preparatory year",
    field: "Engineering Sciences",
    specialization: `-`,
    fee: "2430 EURO",
  },
  {
    cycle: "Romanian Language Preparatory year",
    field: "Humanities and Arts",
    specialization: `-`,
    fee: "2880 EURO",
  },
  {
    cycle: "Romanian Language Preparatory year",
    field: "Biological and Biomedical Sciences",
    specialization: `-`,
    fee: "2880 EURO",
  },
  {
    cycle: "Romanian Language Preparatory year",
    field: "Social Sciences",
    specialization: `-`,
    fee: "2880 EURO",
  },
];

const licenseOptions = [
  {
    ds: "Inginerie civilă și instalații/ Inginerie structurală	RO",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Ingineria clădirilor",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Proiectarea construcțiilor civile și industriale în zone seismice",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/  Tehnologia și managementul lucrărilor de construcții",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Dezvoltare urbană și regională",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Eficiența energetică a instalațiilor din clădiri",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Energie, confort și dezvoltare durabilă",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Tehnologii performante pentru protecția mediului urban",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Ingineria sistemelor de securitate la incendiu și de securitate fizică în clădiri",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Dezvoltare durabilă",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Inginerie geotehnică",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Inginerie hidraulică",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Inginerie hidraulică și protecția mediului",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Infrastructuri durabile de transport",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Poduri și tuneluri",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie civilă și instalații/Ingineria infrasatructurii transporturilor",
    ls: "RO",
    aplr: true,
  },
  { ds: "Inginerie geodezică/ Geomatică", ls: "RO", aplr: true },
  {
    ds:
      "Inginerie geodezică/ Planificare spațială și GIS pentru dezvoltare durabilă",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie geodezică/ Prelucrarea și analiza datelor spațiale",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie geodezică/ Sisteme informaționale în cadastru și publicitate imobiliară",
    ls: "RO",
    aplr: true,
  },
  { ds: "Inginerie mecanică/ Sisteme mecanice avansate", ls: "RO", aplr: true },
  {
    ds:
      "Inginerie mecanică/ Echipamente pentru dezafectarea/demolarea construcțiilor și reciclarea materialelor",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie mecanică/ Managementul și gestionarea situațiilor de urgență",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie și management/ Managementul proiectelor în construcții",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Inginerie și management/ Reprezentarea digitală și managementul informațiilor în construcții (BIM)",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă și instalații/ Inginerie structurală",
    ls: "EN",
    aplr: false,
  },
  {
    ds: "Inginerie civilă și instalații/ Inginerie structurală",
    ls: "FR",
    aplr: false,
  },
  {
    ds: "Inginerie civilă și instalații/ Ingineria și știința mediului acvatic",
    ls: "EN",
    aplr: false,
  },
  {
    ds:
      "Inginerie civilă și instalații/ Eficiența energetică a instalațiilor din clădiri",
    ls: "FR",
    aplr: false,
  },
];
const masterOptions = [
  {
    ds: "Inginerie civilă/ Construcții civile industriale și agricole",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă/ Inginerie urbană și dezvoltare regională",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă/ Amenajări și contrucții hidrotehnice",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă/Inginerie sanitară și protecția mediului",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie civilă/Căi ferate, drumuri și poduri",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Ingineri instalațiilor/Instalații pentru construcții",
    ls: "RO",
    aplr: true,
  },
  {
    ds:
      "Ingineri instalațiilor/Instalații și echipamente pentru protecția atmosferei",
    ls: "RO",
    aplr: true,
  },
  { ds: "Ingineria mediului/ Ingineria mediului", ls: "RO", aplr: true },
  {
    ds: "Ingineria sistemelor/ Automatică și informatică aplicată",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie geodezică/ Măsurători terestre și cadastru",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie mecanică/Utilaje tehnologice pentru construcții",
    ls: "RO",
    aplr: true,
  },
  {
    ds: "Inginerie și management/ Inginerie și management în construcții",
    ls: "RO",
    aplr: true,
  },
  { ds: "Inginerie civilă/ Inginerie civilă", ls: "EN", aplr: false },
  { ds: "Inginerie civilă/ Inginerie civilă", ls: "FR", aplr: false },
  {
    ds: "Inginerie instalațiilor/Instalații pentru construcții",
    ls: "FR",
    aplr: false,
  },
];
export default function StudyOptionsTable() {
  const [currentTab, setCurrentTab] = useState(0);
  const lang = useSelector((state) => state.i18nState.lang);
  const t = useSelector((state) => state.i18nState.translations[lang]);
  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return (
          <div>
            <TableContainer className="max-h-96" component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t.domainSpecialization}</TableCell>
                    <TableCell>{t.studyLanguage}</TableCell>
                    <TableCell>{t.aplr}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenseOptions.map(({ ls, ds, aplr }) => (
                    <TableRow key={`${ds}-${ls}-${aplr.toString()}-LICENTA`}>
                      <TableCell component="th" scope="row">
                        {ds}
                      </TableCell>
                      <TableCell>{ls}</TableCell>
                      <TableCell>{aplr ? t.yes : t.no}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 1:
        return (
          <div>
            <TableContainer className="max-h-96" component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t.university}</TableCell>
                    <TableCell>{t.domainSpecialization}</TableCell>
                    <TableCell>{t.studyLanguage}</TableCell>
                    <TableCell>{t.aplr}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {masterOptions.map(({ ls, ds, aplr }) => (
                    <TableRow key={`${ds}-${ls}-${aplr.toString()}-MASTER`}>
                      <TableCell component="th" scope="row">
                        UTCB
                      </TableCell>
                      <TableCell>{ds}</TableCell>
                      <TableCell>{ls}</TableCell>
                      <TableCell>{aplr ? t.yes : t.no}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case 2:
        return (
          <div>
            <TableContainer className="max-h-96" component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t.cycle}</TableCell>
                    <TableCell>{t.field}</TableCell>
                    <TableCell>{t.specialization}</TableCell>
                    <TableCell>{t.feePerYear}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feesOptions.map(({ cycle, field, specialization, fee }) => (
                    <TableRow key={`${cycle}-${field}-${specialization}-FEES`}>
                      <TableCell>{cycle}</TableCell>
                      <TableCell>{field}</TableCell>
                      <TableCell style={{ whiteSpace: "pre-wrap" }}>
                        {specialization}
                      </TableCell>
                      <TableCell>{fee}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          indicatorColor="primary"
          value={currentTab}
          onChange={(e, tab) => setCurrentTab(tab)}
        >
          <Tab label={t.bachelor} />
          <Tab label={t.master} />
          <Tab label={t.fees} />
        </Tabs>
      </AppBar>
      <div>{renderTabContent()}</div>
    </>
  );
}
