import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MenuItem, Menu, Button, CircularProgress } from "@material-ui/core";
import moment from "moment";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { Info } from "@material-ui/icons";
import {
  uploadDocument,
  downloadDocument,
  deleteDocument,
  validateDocument,
  invalidateDocument,
  showSnackbar,
} from "../../actions";
import { getRole, getIsAdmin } from "../../reducers/authReducer";
/* eslint max-classes-per-file: ["error", 2] */
// const headerRows = t => [
//   {
//     id: "optiuni",
//     numeric: false,
//     disablePadding: true,
//     label: t.options,
//     add: true,
//     onlyIfNoRegistration: true
//   },
//   {
//     id: "denumire",
//     numeric: false,
//     disablePadding: false,
//     label: t.name2,
//     add: true
//   },
//   {
//     id: "template",
//     numeric: false,
//     disablePadding: false,
//     label: t.template,
//     onlyOnRegistration: true
//   },
//   {
//     id: "fisier",
//     numeric: false,
//     disablePadding: false,
//     label: "Fisier",
//     add: true,
//     onlyOnAdd: true
//   },
//   {
//     id: "tip",
//     numeric: false,
//     disablePadding: false,
//     label: "Tip",
//     add: false
//   },
//   {
//     id: "dimensiune",
//     numeric: false,
//     disablePadding: false,
//     label: "Dimensiune",
//     add: false
//   },
//   {
//     id: "dataIncarcare",
//     numeric: false,
//     disablePadding: false,
//     label: "Data incarcare",
//     add: false
//   },
//   {
//     id: "dataValidare",
//     numeric: false,
//     disablePadding: false,
//     label: "Data validare",
//     add: false
//   }
// ];
class EnhancedTableHead extends React.Component {
  // createSortHandler = property => (event) => {
  //   this.props.onRequestSort(event, property);
  // };
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    type: PropTypes.string.isRequired,
    checkableRowsCount: PropTypes.number.isRequired,
  };

  state = {};

  render() {
    const {
      onSelectAllClick,
      numSelected,
      rows,
      type,
      checkableRowsCount,
    } = this.props;
    if (type === "registration") {
      return (
        <TableHead>
          <TableRow>
            <>
              {rows
                .filter((row) => !row.onlyOnAdd)
                .filter((row) => !row.onlyIfNoRegistration)
                .map((row) => (
                  <TableCell
                    key={row.id}
                    padding={row.disablePadding ? "checkbox" : "default"}
                  >
                    {row.label}
                  </TableCell>
                ))}
            </>
          </TableRow>
        </TableHead>
      );
    }
    if (type !== "add") {
      return (
        <TableHead>
          <TableRow>
            <>
              {/* {type !== "view" &&
                type !== "registration" &&
                this.props.name !== "inscrisi" && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        numSelected > 0 && numSelected < checkableRowsCount
                      }
                      checked={
                        numSelected === checkableRowsCount && numSelected !== 0
                      }
                      disabled={checkableRowsCount === 0}
                      onChange={onSelectAllClick}
                    />
                  </TableCell>
                )} */}
              {rows
                .filter((row) => !row.onlyOnAdd)
                .filter((row) => !row.onlyOnRegistration)
                .map((row) => (
                  <TableCell
                    key={row.id}
                    padding={row.disablePadding ? "none" : "default"}
                  >
                    {row.label}
                  </TableCell>
                ))}
            </>
          </TableRow>
        </TableHead>
      );
    }
    if (type === "add") {
      return (
        <TableHead>
          <TableRow>
            {rows
              .filter((row) => row.add === true)
              .map((row) => (
                <TableCell
                  key={row.id}
                  // padding={row.disablePadding ? 'none' : 'default'}
                >
                  {row.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
      );
    }
  }
}

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: "#fff",
    // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, type } = props;

  if (type === "registration") {
    return null;
  }
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected === 1
              ? `${numSelected} document selectat `
              : `${numSelected} documente selectate`}
          </Typography>
        ) : (
          <Typography variant="h5" id="tableTitle">
            Documente
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class DocumentTable extends React.Component {
  static propTypes = {
    uploadDocument: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    validateDocument: PropTypes.func.isRequired,
    invalidateDocument: PropTypes.func.isRequired,
  };

  state = {
    selected: [],
    anchorEl: null,
    id: null,
    page: 0,
    rowsPerPage: 5,
    uploaded: [],
    doc: {},
    isUploading: {},
    isDownloading: {},
    headerRows: [],
  };

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      headerRows: [
        {
          id: "optiuni",
          numeric: false,
          disablePadding: true,
          label: t.options,
          add: true,
          onlyIfNoRegistration: false,
        },
        {
          id: "denumire",
          numeric: false,
          disablePadding: false,
          label: t.name2,
          add: true,
        },
        {
          id: "template",
          numeric: false,
          disablePadding: false,
          label: t.template,
          onlyOnRegistration: true,
        },
        {
          id: "fisier",
          numeric: false,
          disablePadding: false,
          label: t.file,
          add: true,
          onlyOnAdd: true,
        },
        {
          id: "tip",
          numeric: false,
          disablePadding: false,
          label: t.type,
          add: false,
        },
        {
          id: "dimensiune",
          numeric: false,
          disablePadding: false,
          label: t.size,
          add: false,
        },
        {
          id: "dataIncarcare",
          numeric: false,
          disablePadding: false,
          label: t.uploadDate,
          add: false,
        },
        {
          id: "dataValidare",
          numeric: false,
          disablePadding: false,
          label: t.validationDate,
          add: false,
        },
        {
          id: "download",
          numeric: false,
          disablePadding: false,
          label: t.downloadUpload,
          add: false,
        },
      ],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      const { t } = this.props;
      this.setState({
        headerRows: [
          {
            id: "optiuni",
            numeric: false,
            disablePadding: true,
            label: t.options,
            add: true,
            onlyIfNoRegistration: false,
          },
          {
            id: "denumire",
            numeric: false,
            disablePadding: false,
            label: t.name2,
            add: true,
          },
          {
            id: "template",
            numeric: false,
            disablePadding: false,
            label: t.template,
            onlyOnRegistration: true,
          },
          {
            id: "fisier",
            numeric: false,
            disablePadding: false,
            label: t.file,
            add: true,
            onlyOnAdd: true,
          },
          {
            id: "tip",
            numeric: false,
            disablePadding: false,
            label: t.type,
            add: false,
          },
          {
            id: "dimensiune",
            numeric: false,
            disablePadding: false,
            label: t.size,
            add: false,
          },
          {
            id: "dataIncarcare",
            numeric: false,
            disablePadding: false,
            label: t.uploadDate,
            add: false,
          },
          {
            id: "dataValidare",
            numeric: false,
            disablePadding: false,
            label: t.validationDate,
            add: false,
          },
          {
            id: "download",
            numeric: false,
            disablePadding: false,
            label: t.downloadUpload,
            add: false,
          },
        ],
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    const { documente, tipuriDocumente } = this.props;
    if (event.target.checked) {
      this.setState((state) => ({
        selected: documente
          ? documente
              .filter((doc) => !!doc.dataIncarcare)
              .map((doc) => doc.documentId)
          : tipuriDocumente.map((n) => n._id),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleOpenMenu = (event, doc) => {
    this.setState({
      anchorEl: event.currentTarget,
      id: doc.documentId,
      doc,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      id: null,
    });
  };

  handleSelectDocument = async (item, doc) => {
    if (!doc) {
      this.props.showSnackbar(
        "Acest tip de document nu este acceptat",
        "error"
      );
      return;
    }
    this.setState(({ isUploading, uploaded }) => ({
      isUploading: { ...isUploading, [item.nume]: true },
      uploaded: { ...uploaded, [item.nume]: doc },
    }));
    const { name, dosarId } = this.props;
    await this.props.uploadDocument({
      entityName: name,
      dosarId,
      doc,
      name: item.nume,
    });
    this.handleClose();
    this.setState(({ isUploading }) => ({
      isUploading: { ...isUploading, [item.nume]: false },
    }));
  };

  handleDownloadClick = async (id) => {
    if (!id) {
      id = this.state.id;
    }
    const { name, dosarId } = this.props;
    this.setState(({ isDownloading }) => ({
      isDownloading: { ...isDownloading, [id]: true },
    }));
    await this.props.downloadDocument({
      entityName: name,
      dosarId,
      documentId: id,
    });
    this.handleClose();
    this.setState(({ isDownloading }) => ({
      isDownloading: { ...isDownloading, [id]: false },
    }));
  };

  handleDeleteClick = async () => {
    const { id } = this.state;
    const { name, dosarId } = this.props;
    this.props.deleteDocument({
      entityName: name,
      dosarId,
      documentId: id,
    });
    this.handleClose();
  };

  handleValidateClick = async () => {
    const { id } = this.state;
    const { name, dosarId } = this.props;
    this.props.validateDocument({
      entityName: name,
      dosarId,
      documentId: id,
    });
    this.handleClose();
  };

  handleInvalidateClick = async () => {
    const { id } = this.state;
    const { name, dosarId } = this.props;
    this.props.invalidateDocument({
      entityName: name,
      dosarId,
      documentId: id,
    });
    this.handleClose();
  };

  renderColumns = (row, inputRefs) => {
    if (this.props.type === "registration" && !row.dataIncarcare) {
      return (
        <>
          <TableCell>
            {row.nume ? (
              <>
                <span>{row.nume} </span>
                {row.obligatoriu && (
                  <span className="lvd-required">obligatoriu</span>
                )}
              </>
            ) : (
              "-"
            )}
            {row.descriere && (
              <span className="ml-2">
                <Tooltip title={row.descriere}>
                  <Info style={{ color: "#81C534" }} />
                </Tooltip>
              </span>
            )}
          </TableCell>
          <TableCell>
            {row.template ? (
              <>
                <span>
                  <a
                    href={row.template}
                    target="blank"
                    style={{ color: "#81C534" }}
                  >
                    Download {row.nume}
                  </a>
                </span>
              </>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell colSpan={4} />
          <TableCell>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ position: "relative" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  disabled={this.state.isUploading[row.nume]}
                  onClick={() => inputRefs[row.nume].open()}
                >
                  {this.props.t.upload}
                </Button>
                {this.state.isUploading[row.nume] && (
                  <CircularProgress
                    style={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                    size={24}
                  />
                )}
              </div>
            </div>
            <Dropzone
              multiple={false}
              ref={(node) => {
                inputRefs[row.nume] = node;
              }}
              accept="image/jpeg,image/png,image/jpg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onDrop={(accepted, rejected) => {
                this.handleSelectDocument(row, accepted[0]);
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
          </TableCell>
        </>
      );
    }
    if (!row.dataIncarcare && this.props.type !== "view") {
      return (
        <>
          <TableCell>
            {row.nume ? (
              <>
                <span>{row.nume} </span>
                {row.obligatoriu && (
                  <span className="lvd-required">obligatoriu</span>
                )}
              </>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell colSpan={4} />
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "center" }}
              disabled={this.state.isUploading[row.nume]}
              onClick={() => inputRefs[row.nume].open()}
            >
              Adauga
              {this.state.isUploading[row.nume] && (
                <CircularProgress
                  style={{
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                  size={24}
                />
              )}
            </Button>
            <Dropzone
              multiple={false}
              ref={(node) => {
                inputRefs[row.nume] = node;
              }}
              accept="image/jpeg,image/png,image/jpg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onDrop={(accepted, rejected) => {
                this.handleSelectDocument(row, accepted[0]);
              }}
            >
              <p>Drop files here.</p>
            </Dropzone>
          </TableCell>
        </>
      );
    }
    return (
      <>
        <TableCell>
          {row.nume ? (
            <>
              <span>{row.nume} </span>
              {row.obligatoriu && (
                <span className="lvd-required">obligatoriu</span>
              )}
            </>
          ) : (
            "-"
          )}
          <span>{row?.descriere}</span>
        </TableCell>
        {this.props.type === "registration" && (
          <TableCell>
            {row.template ? (
              <>
                <span>
                  <a href={row.template} target="blank">
                    Download {row.nume}
                  </a>
                </span>
              </>
            ) : (
              "-"
            )}
          </TableCell>
        )}
        <TableCell>{row.tip || "-"}</TableCell>
        <TableCell>{row.dimensiune ? `${row.dimensiune} MB` : "-"}</TableCell>
        <TableCell>
          {row.dataIncarcare
            ? moment(row.dataIncarcare).format("DD.MM.YYYY HH:mm")
            : "-"}
        </TableCell>
        <TableCell>
          {row.dataValidare
            ? moment(row.dataValidare).format("DD.MM.YYYY HH:mm")
            : "-"}
          <Dropzone
            multiple={false}
            ref={(node) => {
              inputRefs[row.nume] = node;
            }}
            accept="image/jpeg,image/png,image/jpg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            style={{ display: "none" }}
            onDrop={(accepted, rejected) => {
              this.handleSelectDocument(row, accepted[0]);
            }}
          >
            <p>Drop files here.</p>
          </Dropzone>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ position: "relative" }}>
              <Button
                variant="contained"
                color="primary"
                key="download"
                disabled={this.state.isDownloading[row.documentId]}
                onClick={() => this.handleDownloadClick(row.documentId)}
                style={{ float: "right" }}
              >
                {this.props.t.download}
                {this.state.isDownloading[row.documentId] && (
                  <CircularProgress
                    style={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                    size={24}
                  />
                )}
              </Button>
            </div>
          </div>
        </TableCell>
      </>
    );
  };

  renderMenu = (inputRefs) => {
    const { doc } = this.state;
    if (this.props.type === "view") {
      return (
        <MenuItem
          key="download"
          onClick={() => this.handleDownloadClick()}
          disabled={!doc.dataIncarcare}
        >
          Descarca
        </MenuItem>
      );
    }
    if (!doc.dataIncarcare) {
      return (
        <MenuItem
          key="upload"
          onClick={() => {
            inputRefs[doc.nume].open();
            this.handleClose();
          }}
        >
          Adauga
        </MenuItem>
      );
    }
    if (!doc.dataValidare) {
      if (this.props.isAdmin) {
        return [
          <MenuItem key="download" onClick={() => this.handleDownloadClick()}>
            Descarca
          </MenuItem>,
          <MenuItem key="validation" onClick={this.handleValidateClick}>
            Valideaza
          </MenuItem>,
          <MenuItem key="delete" onClick={this.handleDeleteClick}>
            Sterge
          </MenuItem>,
        ];
      }
      return [
        <MenuItem
          key="upload"
          disabled={this.state.isUploading[doc.nume]}
          onClick={() => {
            inputRefs[doc.nume].open();
            this.handleClose();
          }}
        >
          Inlocuieste
        </MenuItem>,
        <MenuItem key="download" onClick={() => this.handleDownloadClick()}>
          Descarca
        </MenuItem>,
      ];
    }
    if (doc.dataValidare) {
      if (this.props.isAdmin) {
        return [
          <MenuItem key="download" onClick={() => this.handleDownloadClick()}>
            Descarca
          </MenuItem>,
          <MenuItem key="revokeValidation" onClick={this.handleInvalidateClick}>
            Revoca validare
          </MenuItem>,
          <MenuItem key="delete" onClick={this.handleDeleteClick}>
            Sterge
          </MenuItem>,
        ];
      }
      return (
        <MenuItem key="download" onClick={() => this.handleDownloadClick()}>
          Descarca
        </MenuItem>
      );
    }
  };

  render() {
    const { classes, tipuriDocumente, type, documente } = this.props;
    const { selected, anchorEl } = this.state;
    const inputRefs = documente
      ? documente.reduce((pv, cv) => ({ ...pv, [cv.nume]: null }), {})
      : tipuriDocumente.reduce((pv, cv) => ({ ...pv, [cv.nume]: null }), {});
    const rows = documente || tipuriDocumente;
    const sortedRows = _.orderBy(rows, ["dataIncarcare"], ["asc"]);
    return (
      <div className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} type={type} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              rows={this.state.headerRows}
              name={this.props.name}
              checkableRowsCount={
                documente.filter((doc) => !!doc.dataIncarcare).length
              }
              type={type}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {sortedRows.map((doc) => {
                const isSelected = this.isSelected(doc.documentId);
                return (
                  <TableRow
                    hover
                    // onClick={event => this.handleClick(event, n._id)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={doc._id}
                    selected={isSelected}
                  >
                    {/* {this.props.type !== "view" &&
                      this.props.type !== "registration" &&
                      this.props.name !== "inscrisi" && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected}
                            disabled={!doc.dataIncarcare}
                            onClick={(event) =>
                              this.handleClick(event, doc.documentId)
                            }
                          />
                        </TableCell>
                      )} */}
                    <TableCell component="th" scope="row" padding="checkbox">
                      <IconButton
                        aria-owns={anchorEl ? "simple-menu" : null}
                        aria-haspopup="true"
                        onClick={(event) => this.handleOpenMenu(event, doc)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                    {this.renderColumns(doc, inputRefs)}
                    <Menu
                      className="lvd-options-container"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                    >
                      {this.renderMenu(inputRefs)}
                    </Menu>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

DocumentTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: getRole(state),
  isAdmin: getIsAdmin(state),
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang || "ro"],
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    uploadDocument,
    downloadDocument,
    deleteDocument,
    validateDocument,
    invalidateDocument,
    showSnackbar,
  })(DocumentTable)
);
