import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import logger from "redux-logger";
import reducers from "../reducers/index";
import { getInfo } from "../utils/helpers";
import translations from "./translations";

const defaultI18nState = {
  lang: "ro",
  translations,
  forceRefresh: false,
};

const { jwt, refreshToken, email, role } = getInfo();

const preloadedState = {
  auth: {
    token: {
      accessToken: jwt,
      refreshToken,
    },
    user: { email, role, candidatType: {} },
    isLoggedIn: !!jwt,
  },
  i18nState: defaultI18nState,
  user: {},
  nomenclatoare: {
    tari: [],
    cetatenii: [],
    optiuniStudii: [],
  },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxStore = createStore(
  reducers,
  preloadedState,
  composeEnhancers(applyMiddleware(promise, thunk, logger))
);

export default reduxStore;
