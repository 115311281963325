import { Button, FormControlLabel, Paper, Switch } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  selectRegisterOptions,
  selectSettings,
} from "../../reducers/settingsReducer";
import { fetchSettings, updateSettings } from "../../actions/settings";

const Settings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSettings());
  }, []);
  const settings = useSelector(selectSettings);
  function handleSubmit(values) {
    dispatch(updateSettings(values));
  }
  return (
    <Formik
      initialValues={{
        ...settings,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Paper className="p-4">
            <Form>
              <FieldArray>
                {({ push, remove }) => (
                  <div>
                    {values.registerOptions.map((registerOption, index) => (
                      <div key={registerOption.name}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={registerOption.status === "ongoing"}
                              name={`registerOptions.${index}.status`}
                              onChange={(val, checked) => {
                                setFieldValue(
                                  `registerOptions.${index}.status`,
                                  checked ? "ongoing" : "finished"
                                );
                              }}
                            />
                          }
                          label={registerOption.name}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Form>
          </Paper>
        );
      }}
    </Formik>
  );
};
export default Settings;
