import React, { Component } from "react";
import { Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import LoginLayout from "./LoginLayout";
import RegisterForm from "../RegisterForm";
import LoginForm from "../LoginForm";
import logo1 from "./_logos/logo1.jpg";
import logo2 from "./_logos/logo2.jpg";
import logo3 from "./_logos/logo3.jpg";
import logo4 from "./_logos/logo4.jpg";
import logo5 from "./_logos/logo5.jpg";
import logo6 from "./_logos/logo6.jpg";
import logo7 from "./_logos/logo7.jpg";
import logo8 from "./_logos/logo8.jpg";
import logo9 from "./_logos/logo9.jpg";
import logo10 from "./_logos/logo10.jpg";
import footer from "../../svg/logo-footer.svg";
import RegisterTable from "../Register/RegisterTable";

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
];
class Login extends Component {
  state = {};

  render() {
    const { t, lang } = this.props;
    return (
      <LoginLayout>
        {/* <Grid
          container
          wrap="wrap"
          justify="center"
          style={{
            alignItems: "stretch",
          }}
        >
          <Grid item xs={12} sm={4}>
            <Paper className="lvd-login-paper">
              <Typography variant="h4">{t.registerMe}</Typography>
              <RegisterForm />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="lvd-login-paper">
              <Typography variant="h4">{t.alreadyHaveAccount}</Typography>
              <LoginForm />
            </Paper>
          </Grid>
        </Grid> */}
        <div className="max-w-screen-xl mx-auto">
          {(() => {
            switch (lang) {
              case "ro":
                return (
                  <Alert severity="info" className="my-4">
                    <span className="font-bold">
                      ATENȚIE la fluxul de înscriere pe care îl alegi. Odată ce
                      ai ales un flux de înscriere nu îl mai poți schimba!
                    </span>
                    <ul>
                      <li>
                        Fluxurile de Inginerie sunt pentru ramura facultăților
                        de inginerie
                      </li>
                      <li>
                        Fluxurile de traducere și interpretare sunt pentru
                        ramura facultății cu specific de filologie
                      </li>
                      <li>
                        Fluxurile de români de pretutindeni sunt pentru
                        candidații care au cetățenie română și domiciliul în
                        afara teritoriului României.
                      </li>
                      <li>
                        Fluxul de colegiu este pentru candidații care doresc să
                        urmeze specializări specifice învățământului
                        preuniversitar.
                      </li>
                      <li>
                        Fluxul Non-EU Engineering este pentru candidații străini
                        care doresc să urmeze o specializare în cadrul UTCB
                      </li>
                      <li>
                        Fluxul Non-EU Preparatory Year este pentru candidații
                        străini care doresc să învețe limba Română
                      </li>
                      <li>
                        Meniul din partea dreaptă (sau jos pe varianta mobile)
                        se utilizează doar pentru log-in în platformă după ce
                        contul a fost creat.
                      </li>
                    </ul>
                  </Alert>
                );
              case "en":
                return (
                  <Alert severity="info" className="my-4">
                    <span className="font-bold">
                      BEWARE of the enrollment flow you choose. Once you have
                      chosen a registration flow, you can no longer change it!
                    </span>
                    <ul>
                      <li>
                        Engineering streams are for the faculty branch
                        engineering
                      </li>
                      <li>
                        Translation and interpretation streams are for the
                        branch of the faculty with a specific focus on philology
                      </li>
                      <li>
                        The flows of Romanians from everywhere are in favor
                        candidates who have Romanian citizenship and domicile in
                        outside the territory of Romania.
                      </li>
                      <li>
                        The college stream is for candidates who want to follow
                        education-specific specializations pre-university
                      </li>
                      <li>
                        The Non-EU Engineering stream is for foreign candidates
                        who wish to pursue a specialization within UTCB
                      </li>
                      <li>
                        The Non-EU Preparatory Year stream is for applicants
                        foreigners who want to learn the Romanian language
                      </li>
                      <li>
                        The menu on the right (or bottom on the mobile version)
                        it is only used to log in to the platform after the
                        account has been created.
                      </li>
                    </ul>
                  </Alert>
                );
              case "fr":
                return (
                  <Alert severity="info" className="my-4">
                    <span className="font-bold">
                      Méfiez-vous du flux d&apos;inscription que vous
                      choisissez. Une fois vous avez choisi un flux
                      d&apos;inscription, vous ne pouvez plus le modifier !
                    </span>
                    <ul>
                      <li>
                        Les filières d&apos;ingénierie sont destinées à la
                        branche facultaire ingénierie
                      </li>
                      <li>
                        Les filières de traduction et d&apos;interprétation sont
                        destinées à la branche de la faculté avec un accent
                        particulier sur la philologie
                      </li>
                      <li>
                        Les flux de Roumains de partout sont favorables
                        candidats qui ont la nationalité roumaine et sont
                        domiciliés en dehors du territoire de la Roumanie.
                      </li>
                      <li>
                        Le volet collégial s&apos;adresse aux candidats qui
                        veulent suivre des spécialisations spécifiques à
                        l&apos;éducation pré-universitaire
                      </li>
                      <li>
                        Le volet Ingénierie hors UE est destiné aux candidats
                        étrangers qui souhaitent poursuivre une spécialisation
                        au sein de l&apos;UTCB
                      </li>
                      <li>
                        Le volet Année préparatoire non-UE est destiné aux
                        candidats les étrangers qui veulent apprendre la langue
                        roumaine
                      </li>

                      <li>
                        Le menu à droite (ou en bas sur la version mobile) il ne
                        sert qu&apos;à se connecter à la plateforme après le
                        compte a été créé.
                      </li>
                    </ul>
                  </Alert>
                );
              default:
                return null;
            }
          })()}

          <div className="flex flex-wrap justify-between px-4 xl:px-0">
            <Paper className="m-4 lvd-login-paper lg:m-0">
              <Typography variant="h4">{t.registerMe}</Typography>
              <RegisterTable />
              {/* <RegisterForm /> */}
            </Paper>
            <Paper className="m-4 lvd-login-paper lg:m-0">
              <Typography variant="h4">{t.alreadyHaveAccount}</Typography>
              <LoginForm />
            </Paper>
          </div>
        </div>
        {/* <div className="px-4 xl:px-0">
          <div className="max-w-screen-xl pb-8 mx-auto mt-8">
            <Paper style={{ borderRadius: "5px" }} className="p-4">
              <div className="mb-4 text-lg text-bold">Cu sprijinul</div>
              <div className="grid grid-cols-2 row-gap-4 col-gap-4 sm:grid-cols-5">
                {logos.map((logo, index) => (
                  <div key={index} className="col-span-1">
                    <img src={logo} alt={`logo-sponsor-${index}`} />
                  </div>
                ))}
              </div>
            </Paper>
          </div>
        </div> */}
        <div
          className="lvd-footer"
          style={{
            color: "#3F526C",
          }}
        >
          <img src={footer} alt="footer" />
          <span style={{ color: "#3F526C" }}>UTCB</span>
          <span style={{ color: "#3F526C" }}>
            Developed by{" "}
            <a
              style={{
                color: "#3F526C",
                textDecoration: "none",
              }}
              href="http://livedesign.ro"
            >
              Live Design
            </a>
          </span>
        </div>
      </LoginLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
