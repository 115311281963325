import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import {
  Button,
  Typography,
  Tabs,
  AppBar,
  Tab,
  Paper,
} from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import { showSnackbar } from "../../actions";
import { fetchDosarInscris } from "../../actions/inscrisi-ro";
import { serverUrl, mobilpayUrl } from "../../utils";
import {
  getToken,
  getCandidatTypeForLoggedInUser,
} from "../../reducers/authReducer";
import {
  getCandidatType,
  getDosarInscris,
} from "../../reducers/inscrisiReducer";

class Plata extends Component {
  state = {};

  signCanvas = null;

  async componentDidMount() {
    await this.props.fetchDosarInscris();
  }

  handleDone = async () => {
    if (!this.signCanvas.isEmpty()) {
      const base64Image = this.signCanvas.getTrimmedCanvas().toDataURL();
      const { jwt, candidatType, dosar } = this.props;
      const url =
        candidatType.candidatType === "ro"
          ? "/inscrisi-ro/signature"
          : "/inscrisi/signature";
      try {
        const res = await request
          .post(`${serverUrl}${url}`)
          .set("Authorization", `Bearer ${jwt}`)
          .send({
            signature: base64Image,
          });
        if (dosar && dosar.plataValidata) {
          this.props.handleNext(2);
        } else {
          this.props.handleNext();
        }
      } catch (e) {
        this.props.showSnackbar("Something went wrong CODE:sign-send", "error");
      }
    } else {
      this.props.showSnackbar("Please fill in your signature!", "error");
    }
  };

  handleClear = () => {
    this.signCanvas.clear();
  };

  render() {
    const { envKey, data, currentTab, paymentId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Typography>{t.signatureExplain}</Typography>
        <div style={{ paddingTop: "12px", paddingBottom: "12px" }}>
          <SignatureCanvas
            ref={(ref) => {
              this.signCanvas = ref;
            }}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleDone}
            style={{ marginRight: "24px" }}
          >
            {t.done}
          </Button>

          <Button variant="contained" onClick={this.handleClear}>
            {t.clear}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  jwt: getToken(state),
  candidatType: getCandidatType(state) || getCandidatTypeForLoggedInUser(state),
  dosar: getDosarInscris(state),
});

const mapDispatchToProps = {
  showSnackbar,
  fetchDosarInscris,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plata);
