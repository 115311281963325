import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { AppBar, Tabs, Tab, Button, Paper } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import * as yup from "yup";
import {
  setAppBarTitle,
  fetchNomenclatoare,
  fetchTipuriDeDocumente,
  fetchCandidatById,
  updateCandidat,
} from "../../actions";
import { getCandidatById } from "../../reducers/candidatiReducer";
import DocumentTable from "../General/DocumentTable";
import AddEditForm from "../General/AddEditForm";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";

class CandidatiEdit extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    fetchCandidatById: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        dosarId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    setAppBarTitle: PropTypes.func.isRequired,
    updateCandidat: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    inputs: [],
    currentTab: 0,
  };

  static getDerivedStateFromProps = (props, state) => ({
    inputs: [
      {
        name: "email",
        label: "Email",
        type: "text",
        disabled: true,
      },
      {
        name: "nume",
        label: "Nume",
        type: "text",
        required: true,
      },
      {
        name: "prenume",
        label: "Prenume",
        type: "text",
        required: true,
      },
      {
        name: "cnp",
        label: "CNP",
        type: "text",
      },
      {
        name: "titulatura",
        label: "Titulatura",
        type: "text",
      },
      {
        name: "anObtinereTitluDoctor",
        label: "An Obtinere Titlu Doctor",
        type: "number",
      },
      {
        name: "facultate",
        label: "Facultate",
        type: "select",
        options: props.nomenclatoare.facultati.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "departament",
        label: "Departament",
        type: "select",
        options: props.nomenclatoare.departamente.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "domeniuDoctorat",
        label: "Domeniu Doctorat",
        type: "select",
        options: props.nomenclatoare.domenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "specializare",
        label: "Specializare",
        type: "select",
        options: props.nomenclatoare.specializari.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "comisiaDeAbilitare",
        label: "Comisia de abilitare",
        type: "text",
      },
      {
        name: "domeniuDoctoratSolicitat",
        label: "Domeniu Doctorat Solicitat",
        type: "select",
        options: props.nomenclatoare.domenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "specializariDoctoratSolicitate",
        label: "Specializari Doctorat Solicitate",
        type: "chips",
        options: props.nomenclatoare.specializari.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "telefon",
        label: "Telefon",
        type: "text",
      },
      {
        name: "linkScopus",
        label: "Link Scopus",
        type: "text",
      },
      {
        name: "linkGoogleScholar",
        label: "Link Google Scholar",
        type: "text",
      },
      {
        name: "linkISIWebOfScience",
        label: "Link ISI Web Of Science",
        type: "text",
      },
      {
        name: "linkResearchGate",
        label: "Link Research Gate",
        type: "text",
      },
    ],
    tipuriDocumente: props.tipuriDeDocumente.candidati,
  });

  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchTipuriDeDocumente();
    this.props.fetchCandidatById(
      this.props.dosarId || this.props.match.params.dosarId
    );
  }

  componentWillUnmount() {
    this.props.setAppBarTitle(undefined);
  }

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  handleBackClick = () => {
    this.props.history.push("/candidati");
  };

  handleSubmit = async (values) => {
    await this.props.updateCandidat(values);
    if (!this.props.stayAfterEdit) {
      this.props.history.push("/candidati");
    }
  };

  renderTabContent = () => {
    const { dosar } = this.props;
    const { inputs, tipuriDocumente, currentTab } = this.state;
    switch (currentTab) {
      case 0:
        return (
          <AddEditForm
            name="candidati"
            inputs={inputs}
            isViewing={false}
            handleNext={this.handleNext}
            handleSubmit={this.handleSubmit}
            validationSchema={yup.object().shape({
              // cnp: yup.string().required('Camp obligatoriu'),
              email: yup.string().required("Camp obligatoriu"),
              nume: yup.string().required("Camp obligatoriu"),
              prenume: yup.string().required("Camp obligatoriu"),
              // titulatura: yup.string().required('Camp obligatoriu'),
              // anObtinereTitluDoctor: yup.number().required('Camp obligatoriu'),
              // facultate: yup.string().required('Camp obligatoriu'),
              // departament: yup.string().required('Camp obligatoriu'),
              // domeniuDoctorat: yup.string().required('Camp obligatoriu'),
              // specializare: yup.string().required('Camp obligatoriu'),
              // telefon: yup.string().required('Camp obligatoriu'),
              // email: yup.string().required('Camp obligatoriu'),
              // linkScopus: yup.string().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string().required('Camp obligatoriu'),
              // linkISIWebOfScience: yup.string().required('Camp obligatoriu'),
              // linkResearchGate: yup.string().required('Camp obligatoriu'),
            })}
            initialValues={{
              cnp: "",
              nume: "",
              prenume: "",
              titulatura: "",
              anObtinereTitluDoctor: "",
              facultate: {},
              departament: {},
              domeniuDoctorat: {},
              specializare: {},
              comisiaDeAbilitare: "",
              domeniuDoctoratSolicitat: {},
              specializariDoctoratSolicitate: [],
              telefon: "",
              email: "",
              linkScopus: "",
              linkGoogleScholar: "",
              linkISIWebOfScience: "",
              linkResearchGate: "",
              ...dosar,
            }}
          />
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            tipuriDocumente={tipuriDocumente}
            documente={dosar.documente}
            type="edit"
            name="candidati"
          />
        );
      default:
        return "Unknown tab";
    }
  };

  render() {
    const { currentTab } = this.state;
    return (
      <div>
        <div className="lvd-spacer20" />
        {!this.props.stayAfterEdit && (
          <div className="lvd-control-buttons">
            <Button
              onClick={this.handleBackClick}
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
            >
              <ArrowBackIos />
              Inapoi
            </Button>
          </div>
        )}
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label="Dosar" />
            <Tab label="Documente" />
          </Tabs>
        </AppBar>
        <Paper className="lvd-container-form">{this.renderTabContent()}</Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dosar: getCandidatById(state),
  nomenclatoare: state.nomenclatoare,
  tipuriDeDocumente: getTipuriDeDocumente(state),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchNomenclatoare,
    fetchTipuriDeDocumente,
    fetchCandidatById,
    setAppBarTitle,
    updateCandidat,
  })(CandidatiEdit)
);
