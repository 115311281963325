import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Hidden,
  Drawer,
  Typography,
  StepLabel,
  Step,
  Stepper,
  withStyles,
  // StepConnector,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import footer from "../../svg/logo-footer.svg";
import LoginAppBar from "./LoginAppBar";
import ContNou from "../Inscriere/ContNou";
import CompletareFisa from "../Inscriere/CompletareFisa";
import IncarcareDocumente from "../Inscriere/IncarcareDocumente";
import IncarcareDocumenteRo from "../Inscriere/IncarcareDocumenteRo";
import PendingValidation from "../Inscriere/PendingValidation";
import { refreshLogin } from "../../actions";
import {
  getUser,
  getIsLoggedIn,
  getCandidatTypeForLoggedInUser,
} from "../../reducers/authReducer";
import { getInfo } from "../../utils/helpers";
import Signature from "../Inscriere/Signature";
import ValidareFinala from "../Inscriere/ValidareFinala";
import ValidareFinalaRo from "../Inscriere/ValidareFinalaRo";
import { getCandidatType } from "../../reducers/inscrisiReducer";
import CompletareFisaRo from "../Inscriere/CompletareFisaRo";
import Payment from "../Inscriere/Payment";
import Eligibil from "../Inscriere/Eligibil";
import Admis from "../Inscriere/Admis";
import ConcursDeAdmitere from "../Inscriere/ConcursDeAdmitere";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    overflow: "auto",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: "64px",
      height: "calc(100% - 64px)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "58px",
      height: "calc(100% - 58px)",
    },
  },
  // stepperRoot: {},
  drawerRoot: {
    height: "100%",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "58px",
    },
    // backgroundColor: '#000',
  },
  drawerPaperWithoutMargin: {
    width: drawerWidth,
    height: "100%",
    // backgroundColor: '#000',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});
function getSteps(t, candidatType) {
  if (candidatType.candidatType === "ro" && candidatType.rdp) {
    return [
      {
        label: t.createAccount,
        description: t.createAccountDescription,
      },
      {
        label: t.completeRegistrationPaper,
        description: t.completeRegistrationPaperDescription,
      },
      {
        label: t.loadDocuments,
        description: t.loadDocumentsDescription,
      },
      // {
      //   label: t.registrationPayment,
      //   description: t.registrationPaymentDescription,
      // },
      {
        label: t.pendingValidation,
        description: t.pendingValidationDescription,
      },
      // {
      //   label: t.finalizeRegistration,
      //   description: t.finalizeRegistrationDescription,
      // },
      {
        label: "Candidat eligibil",
        description: "Afli daca esti eligibil",
      },
      {
        label: "Concurs de admitere",
        description: "Esti in concursul de admitere",
      },
    ];
  }
  if (candidatType.candidatType === "ro" && candidatType.colegiu) {
    return [
      {
        label: t.createAccount,
        description: t.createAccountDescription,
      },
      // {
      //   label: "Validare cont",
      //   description: "Primesti pe mail un link de activare"
      // },
      {
        label: t.completeRegistrationPaper,
        description: t.completeRegistrationPaperDescription,
      },
      {
        label: t.loadDocuments,
        description: t.loadDocumentsDescription,
      },
      // {
      //   label: t.signFisa,
      //   description: t.signDosarDescription,
      // },
      {
        label: t.registrationPayment,
        description: t.registrationPaymentDescription,
      },
      {
        label: t.pendingValidation,
        description: t.pendingValidationDescription,
      },
      // {
      //   label: t.finalizeRegistration,
      //   description: t.finalizeRegistrationDescription,
      // },
      {
        label: "Candidat eligibil",
        description: "Afli daca esti eligibil",
      },
      {
        label: "Admis/Respins",
        description: "Afli daca esti admis",
      },
    ];
  }
  if (candidatType.candidatType === "ro") {
    return [
      {
        label: t.createAccount,
        description: t.createAccountDescription,
      },
      // {
      //   label: "Validare cont",
      //   description: "Primesti pe mail un link de activare"
      // },
      {
        label: t.completeRegistrationPaper,
        description: t.completeRegistrationPaperDescription,
      },
      {
        label: t.loadDocuments,
        description: t.loadDocumentsDescription,
      },
      // {
      //   label: t.signFisa,
      //   description: t.signDosarDescription,
      // },
      {
        label: t.registrationPayment,
        description: t.registrationPaymentDescription,
      },
      {
        label: t.pendingValidation,
        description: t.pendingValidationDescription,
      },
      // {
      //   label: t.finalizeRegistration,
      //   description: t.finalizeRegistrationDescription,
      // },
      {
        label: "Candidat eligibil",
        description: "Afli daca esti eligibil",
      },
      {
        label: "Concurs de admitere",
        description: "Esti in concursul de admitere",
      },
    ];
  }
  if (candidatType.candidatType === "non-eu") {
    return [
      {
        label: t.createAccount,
        description: t.createAccountDescription,
      },
      // {
      //   label: "Validare cont",
      //   description: "Primesti pe mail un link de activare"
      // },
      {
        label: t.completeRegistrationPaper,
        description: t.completeRegistrationPaperDescription,
      },
      {
        label: t.loadDocuments,
        description: t.loadDocumentsDescription,
      },
      {
        label: t.signDosar,
        description: t.signDosarDescription,
      },
      {
        label: t.registrationPayment,
        description: t.registrationPaymentDescription,
      },
      {
        label: t.pendingValidation,
        description: t.pendingValidationDescription,
      },
      {
        label: t.acceptanceLetter,
        description: t.acceptanceLetterDescription,
      },
    ];
  }
  return [
    {
      label: t.createAccount,
      description: t.createAccountDescription,
    },
    // {
    //   label: "Validare cont",
    //   description: "Primesti pe mail un link de activare"
    // },
    {
      label: t.completeRegistrationPaper,
      description: t.completeRegistrationPaperDescription,
    },
    {
      label: t.loadDocuments,
      description: t.loadDocumentsDescription,
    },
    // {
    //   label: t.signDosar,
    //   description: t.signDosarDescription,
    // },
    {
      label: t.pendingValidation,
      description: t.pendingValidationDescription,
    },
    {
      label: t.acceptanceLetter,
      description: t.acceptanceLetterDescription,
    },
  ];
}
function getStepTitle(activeStep, t, candidatType) {
  const steps = getSteps(t, candidatType);
  if (activeStep === -1) return "";
  return steps[activeStep].label;
}
function getStepContent(step, functions, candidatType) {
  if (candidatType.candidatType === "ro" && candidatType.rdp) {
    switch (step) {
      case 0:
        return <ContNou {...functions} />;
      case 1:
        return <CompletareFisaRo {...functions} />;
      case 2:
        return <IncarcareDocumenteRo {...functions} />;
      // case 3:
      //   return <Signature {...functions} />;
      // case 3:
      //   return <Payment {...functions} />;
      case 3:
        return <PendingValidation {...functions} />;
      case 4:
        return <Eligibil {...functions} />;
      // return <ValidareFinalaRo {...functions} />;
      // case 7:
      //   return <Admis {...functions} />;
      case 5:
        return <ConcursDeAdmitere {...functions} />;
      default:
        return "";
    }
  } else if (candidatType.candidatType === "ro" && !candidatType.rdp) {
    switch (step) {
      case 0:
        return <ContNou {...functions} />;
      case 1:
        return <CompletareFisaRo {...functions} />;
      case 2:
        return <IncarcareDocumenteRo {...functions} />;
      // case 3:
      //   return <Signature {...functions} />;
      case 3:
        return <Payment {...functions} />;
      case 4:
        return <PendingValidation {...functions} />;
      case 5:
        return <Eligibil {...functions} />;
      // return <ValidareFinalaRo {...functions} />;
      // case 7:
      //   return <Admis {...functions} />;
      case 6:
        return <ConcursDeAdmitere {...functions} />;
      default:
        return "";
    }
  } else {
    switch (step) {
      case 0:
        return <ContNou {...functions} />;
      case 1:
        return <CompletareFisa {...functions} />;
      case 2:
        return <IncarcareDocumente {...functions} />;
      case 3:
        return <Signature {...functions} />;
      case 4:
        return <Payment {...functions} />;
      case 5:
        return <PendingValidation {...functions} />;
      case 6:
        return <ValidareFinala {...functions} />;
      default:
        return "";
    }
  }
}

class RegisterWizardLayout extends Component {
  state = {
    activeStep: -1,
    mobileOpen: false,
    isSaved: false,
    isEditing: true,
    isPendingEmailVerification: false,
    isLoading: 1,
  };

  async componentDidMount() {
    const { jwt, refreshToken, email } = getInfo();
    const { user, isLoggedIn } = this.props;

    if (
      !!jwt &&
      !!refreshToken &&
      !!email // &&
      //! user.role
    ) {
      await this.props.refreshLogin();
    }
    if (isLoggedIn && user.statusInscriere) {
      if (user.candidatType.candidatType === "ro" && user.candidatType.rdp) {
        switch (user.statusInscriere) {
          case "concursDeAdmitere":
            this.setActiveStep(5);
            break;
          case "eligibil":
          case "respinsFinal":
            this.setActiveStep(4);
            break;
          case "validat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(3);
            break;
          case "documenteIncarcate":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else if (user.candidatType.candidatType === "ro") {
        switch (user.statusInscriere) {
          case "concursDeAdmitere":
            this.setActiveStep(6);
            break;
          case "eligibil":
          case "respinsFinal":
            this.setActiveStep(5);
            break;
          case "validat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(4);
            break;
          case "documenteIncarcate":
          case "semnat":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else if (user.candidatType.candidatType === "non-eu") {
        switch (user.statusInscriere) {
          case "concursDeAdmitere":
          case "respinsFinal":
          case "eligibil":
            this.setActiveStep(6);
            break;
          case "validat":
          case "acceptat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(5);
            break;
          case "semnat":
            this.setActiveStep(4);
            break;
          case "uploadatDocumente":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else {
        switch (user.statusInscriere) {
          case "concursDeAdmitere":
          case "validat":
          case "eligibil":
            this.setActiveStep(6);
            break;
          case "semnat":
          case "respinsFinal":
          case "acceptat":
            this.setActiveStep(5);
            break;
          // this.setActiveStep(4);
          // break;
          case "trimisCatreValidare":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      }
    } else if (!isLoggedIn) {
      if (this.props.candidatType.registerFor === "") {
        this.props.history.push("/");
      }
      this.setActiveStep(0);
    }
    this.setIsLoading(-1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.emailVerified !== this.props.user.emailVerified &&
      !this.props.user.emailVerified &&
      this.state.isPendingEmailVerification !== true
    ) {
      this.setState({
        isPendingEmailVerification: true,
      });
    }
    if (
      this.props.isLoggedIn &&
      this.props.user.statusInscriere !== prevProps.user.statusInscriere
    ) {
      if (
        this.props.user.candidatType.candidatType === "ro" &&
        this.props.user.candidatType.rdp
      ) {
        switch (this.props.user.statusInscriere) {
          case "concursDeAdmitere":
            this.setActiveStep(5);
            break;
          case "eligibil":
          case "respinsFinal":
            this.setActiveStep(4);
            break;
          case "validat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(3);
            break;
          case "documenteIncarcate":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else if (this.props.user.candidatType.candidatType === "ro") {
        switch (this.props.user.statusInscriere) {
          case "concursDeAdmitere":
            this.setActiveStep(6);
            break;
          case "eligibil":
          case "respinsFinal":
            this.setActiveStep(5);
            break;
          case "validat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(4);
            break;
          case "semnat":
          case "documenteIncarcate":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else if (this.props.user.candidatType.candidatType === "non-eu") {
        switch (this.props.user.statusInscriere) {
          case "concursDeAdmitere":
          case "respinsFinal":
          case "eligibil":
            this.setActiveStep(6);
            break;
          case "validat":
          case "acceptat":
          case "trimisCatreValidare":
          case "platit":
            this.setActiveStep(5);
            break;
          case "semnat":
            this.setActiveStep(4);
            break;
          case "uploadatDocumente":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      } else {
        switch (this.props.user.statusInscriere) {
          case "concursDeAdmitere":
          case "eligibil":
            this.setActiveStep(6);
            break;
          case "semnat":
          case "respinsFinal":
          case "acceptat":
            this.setActiveStep(5);
            break;
          case "validat":
            this.setActiveStep(4);
            break;
          case "trimisCatreValidare":
            this.setActiveStep(3);
            break;
          case "completatDosar":
            this.setActiveStep(2);
            break;
          case "verificatEmail":
          case "respins":
            this.setActiveStep(1);
            break;
          case "neverificatEmail":
          case "":
            this.setActiveStep(0);
            break;
          default:
            this.setActiveStep(-1);
            break;
        }
      }
    }
  }

  setIsLoading = (loadingNumber) => {
    this.setState(({ isLoading }) => ({
      isLoading: isLoading + loadingNumber,
    }));
  };

  // componentDidUpdate(prevProps, prevState) {
  //   const { user, isLoggedIn } = this.props;
  //   if (isLoggedIn && user.emailVerified) {
  //     this.setActiveStep(1);
  //   }
  // }

  handleNext = (moreSteps) => {
    if (moreSteps) {
      this.setActiveStep(this.state.activeStep + moreSteps);
    } else {
      this.setActiveStep(this.state.activeStep + 1);
    }
  };

  handleBack = () => {
    this.setActiveStep(this.state.activeStep - 1);
  };

  handleReset = () => {
    this.setActiveStep(0);
  };

  setActiveStep = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { activeStep, mobileOpen, isLoading } = this.state;
    const { classes, theme, width, t, candidatType } = this.props;
    const steps = getSteps(t, candidatType);
    return (
      <>
        <LoginAppBar
          handleDrawerToggle={this.handleDrawerToggle}
          showButton={isWidthDown("md", width, true)}
        />
        <div className="lvd-register">
          <div className={classes.drawer} aria-label="mailbox folders">
            <Hidden mdUp implementation="js">
              <div className="lvd-drawer">
                <Drawer
                  variant="temporary"
                  className="lvd-drawer"
                  open={mobileOpen}
                  onClose={this.handleDrawerToggle}
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  classes={{
                    paper: classes.drawerPaperWithoutMargin,
                    root: classes.drawerRoot,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  <div className="lvd-stepper-left">
                    <Stepper
                      // connector={<QontoConnector />}
                      orientation="vertical"
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepperRoot,
                      }}
                    >
                      {steps.map(({ label, description }, index) => (
                        <Step key={label}>
                          <StepLabel>
                            {label}
                            <br />
                            <Typography variant="caption">
                              {description}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <div className="px-8">
                      <Typography variant="body2" className="opacity-75">
                        {/* {candidatType.registerFor !== "" &&
                          `${t.registerFormOne} ${t.registerFor(
                            candidatType.registerFor
                          )}${
                            candidatType.translationAndInterpretation
                              ? ` (${t.translationAndInterpretation}). `
                              : ". "
                          }`} */}
                        {candidatType.candidatType !== "" &&
                          `${
                            candidatType.candidatType === "ro"
                              ? t.registerFormRoCandidateType
                              : t.registerFormNonEuCandidateType
                          }`}
                      </Typography>
                    </div>
                  </div>
                </Drawer>
              </div>
            </Hidden>
            <Hidden smDown implementation="js">
              <div className="lvd-drawer">
                {/* Test */}
                <Drawer
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper,
                    root: classes.drawerRoot,
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="lvd-stepper-left">
                    <Stepper
                      // connector={<QontoConnector />}
                      orientation="vertical"
                      activeStep={activeStep}
                      classes={{
                        root: classes.stepperRoot,
                      }}
                    >
                      {steps.map(({ label, description }, index) => (
                        <Step key={label}>
                          <StepLabel>
                            {label}
                            <br />
                            <Typography variant="caption">
                              {description}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {/* <div className="px-8">
                      <Typography variant="body2" className="opacity-75">
                        {candidatType.registerFor !== "" &&
                          `${t.registerFormOne} ${t.registerFor(
                            candidatType.registerFor
                          )}${
                            candidatType.translationAndInterpretation
                              ? ` (${t.translationAndInterpretation}). `
                              : ". "
                          }`}
                        {candidatType.candidatType !== "" &&
                          `${
                            candidatType.candidatType === "ro"
                              ? t.registerFormRoCandidateType
                              : t.registerFormNonEuCandidateType
                          }`}
                      </Typography>
                    </div> */}
                  </div>
                </Drawer>
              </div>
            </Hidden>
          </div>
          <main className={classes.content}>
            {/* {this.props.children} */}
            {/* <StepContent> */}
            <div>
              <div className="text-lg text-tabBackground">
                {candidatType.registerFor === "licenta" ? "Licenta" : "Master"},{" "}
                {candidatType.candidatType === "ro"
                  ? "Candidat roman"
                  : "Non-eu"}
                {candidatType.translationAndInterpretation &&
                  ", Traducere si interpretare"}
                {candidatType.rdp && ", Romani de pretutindeni"}
                {candidatType.colegiu && ", Colegiu"}
                {candidatType.dppd && ", DDPD"}
                {candidatType.aplr && ", APLR"}
              </div>
              <h1 className="pb-4 lvd-register-title">
                {getStepTitle(activeStep, t, candidatType)}
              </h1>
              {candidatType.candidatType === "ro" && activeStep === 1 ? (
                <>
                  {isLoading !== 0 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  {getStepContent(
                    activeStep,
                    {
                      handleNext: this.handleNext,
                      isPendingEmailVerification: this.state
                        .isPendingEmailVerification,
                      setIsLoading: this.setIsLoading,
                      isLoading: this.state.isLoading,
                    },
                    candidatType
                  )}
                </>
              ) : (
                <Paper className="lvd-container-form">
                  {isLoading !== 0 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  {getStepContent(
                    activeStep,
                    {
                      handleNext: this.handleNext,
                      isPendingEmailVerification: this.state
                        .isPendingEmailVerification,
                      setIsLoading: this.setIsLoading,
                      isLoading: this.state.isLoading,
                    },
                    candidatType
                  )}
                </Paper>
              )}
            </div>
          </main>
        </div>
        <div
          className="lvd-footer"
          style={{
            marginLeft: isWidthDown("md", width, false) ? "0px" : "240px",
            color: "#3F526C",
          }}
        >
          <img src={footer} alt="footer" />
          <span style={{ color: "#3F526C" }}>UTCB</span>
          <br />
          <span style={{ color: "#3F526C" }}>
            Developed by{" "}
            <a
              style={{
                color: "#3F526C",
                textDecoration: "none",
              }}
              href="http://livedesign.ro"
            >
              Live Design
            </a>
          </span>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  user: getUser(state),
  isLoggedIn: getIsLoggedIn(state),
  candidatType: getCandidatType(state) || getCandidatTypeForLoggedInUser(state),
});

const mapDispatchToProps = { refreshLogin };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles, { withTheme: true })(RegisterWizardLayout)));
