import request from "superagent";
import _ from "lodash";
import FileSaver from "file-saver";
import { createAsyncThunk } from "redux-toolkit";
import * as TYPES from "../types";
import { serverUrl } from "../utils";
import { showSnackbar } from "./snackbar";
import { getToken, getIsAdmin } from "../reducers/authReducer";
import history from "../history";

export const getPosition = ({ dosarId }) => async (dispatch, getState) => {
  try {
    const jwt = getToken(getState());
    const res = await request
      .get(`${serverUrl}/inscrisi-ro/${dosarId}/position`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch({
      payload: res.body.pozitie,
      type: "getPosition",
    });
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};
export const rejectDosar = ({ dosarId, motiv }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/inscrisi-ro/${dosarId}/scrisoare/respinge`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({ motiv });
    dispatch(showSnackbar("Dosarul a fost respins", "success"));
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const sendNotification = ({ dosarId }) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .get(`${serverUrl}/inscrisi-ro/${dosarId}/scrisoare/notificare`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(showSnackbar("Notificarea a fost trimisa cu succes", "success"));
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const uploadScrisoare = ({ dosarId, doc }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    await request
      .post(`${serverUrl}/inscrisi-ro/${dosarId}/scrisoare`)
      .set("Authorization", `Bearer ${jwt}`)
      .attach("scrisoare", doc);
    dispatch(showSnackbar("Scrisoarea a fost incarcata cu succes", "success"));
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const removeScrisoare = ({ dosarId }) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    await request
      .del(`${serverUrl}/inscrisi-ro/${dosarId}/scrisoare`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(showSnackbar("Scrisoarea a fost stearsa cu succes", "success"));
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const downloadScrisoare = ({ dosarId }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/inscrisi-ro/${dosarId}/scrisoare`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const handleRejectPaymentDocument = (dosarId) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/payment/${dosarId}/reject-document-justificativ`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const downloadDocumentJustificativ = (dosarId) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/payment/${dosarId}/download-document-justificativ`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const rejectAnexa4 = (dosarId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/payment/${dosarId}/reject-anexa4`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchInscrisById(dosarId));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const downloadAnexa4 = (dosarId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/payment/${dosarId}/download-anexa4`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

const fetchInscrisiForDropdownRequest = () => ({
  type: TYPES.INSCRISI_DROPDOWN_FETCH_REQUEST,
});
const fetchInscrisiForDropdownSuccess = (payload) => ({
  type: TYPES.INSCRISI_DROPDOWN_FETCH_SUCCESS,
  payload,
});
const fetchInscrisiForDropdownFailure = (error) => ({
  type: TYPES.INSCRISI_DROPDOWN_FETCH_FAILURE,
  error,
});

export const fetchInscrisiForDropdown = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchInscrisiForDropdownRequest());
  try {
    const res = await request
      .get(`${serverUrl}/inscrisi-ro/dropdown`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchInscrisiForDropdownSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchInscrisiForDropdownFailure(e));
  }
};

const fetchInscrisiRequest = () => ({
  type: TYPES.INSCRISI_FETCH_REQUEST,
});
const fetchInscrisiSuccess = (payload) => ({
  type: TYPES.INSCRISI_FETCH_SUCCESS,
  payload,
});
const fetchInscrisiFailure = (error) => ({
  type: TYPES.INSCRISI_FETCH_FAILURE,
  error,
});

export const fetchInscrisi = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(fetchInscrisiRequest());
  try {
    const res = await request
      .post(`${serverUrl}/inscrisi-ro/search`)
      .send({})
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(fetchInscrisiSuccess(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(fetchInscrisiFailure(e));
  }
};

const requestAddInscris = () => ({
  type: TYPES.INSCRISI_ADD_REQUEST,
});
const successAddInscris = (payload) => ({
  type: TYPES.INSCRISI_ADD_SUCCESS,
  payload,
});
const failAddInscris = (error) => ({
  type: TYPES.INSCRISI_ADD_FAIL,
  error,
});

export const addInscris = (dosar) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  const { candidatType } = getState().inscrisi;
  dispatch(requestAddInscris());
  try {
    const res = await request
      .post(`${serverUrl}/inscrisi-ro`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({ ...dosar, candidatType });
    dispatch(successAddInscris(res.body));
    dispatch(
      showSnackbar("Fișa de înscriere a fost salvată cu succes.", "success")
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failAddInscris(e));
    throw e;
  }
};

export const requestInscrisById = () => ({
  type: TYPES.INSCRISI_FETCH_BYID_REQUEST,
});
export const successInscrisById = (payload) => ({
  type: TYPES.INSCRISI_FETCH_BYID_SUCCESS,
  payload,
});
export const failInscrisById = (error) => ({
  type: TYPES.INSCRISI_FETCH_BYID_FAIL,
  error,
});
export const fetchInscrisById = (id) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestInscrisById());
  try {
    const res = await request
      .get(`${serverUrl}/inscrisi-ro/${id}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successInscrisById(res.body));
    dispatch(successDosarInscris(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failInscrisById(e));
  }
};

export const requestDosarInscris = () => ({
  type: TYPES.INSCRIS_FETCH_REQUEST,
});
export const successDosarInscris = (payload) => ({
  type: TYPES.INSCRIS_FETCH_SUCCESS,
  payload,
});
export const failDosarInscris = (error) => ({
  type: TYPES.INSCRIS_FETCH_FAIL,
  error,
});
export const fetchDosarInscris = () => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDosarInscris());
  try {
    const res = await request
      .get(`${serverUrl}/inscrisi-ro`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDosarInscris(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDosarInscris(e));
  }
};
export const requestSendToValidation = () => ({
  type: TYPES.INSCRIS_SENDTOVALIDATION_REQUEST,
});
export const successSendToValidation = (payload) => ({
  type: TYPES.INSCRIS_SENDTOVALIDATION_SUCCESS,
  payload,
});
export const failSendToValidation = (error) => ({
  type: TYPES.INSCRIS_SENDTOVALIDATION_FAIL,
  error,
});

export const sendToValidation = () => async (dispatch, getState) => {
  dispatch(requestSendToValidation());
  const jwt = getToken(getState());
  try {
    const res = await request
      .get(`${serverUrl}/inscrisi-ro/sendToValidation`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successSendToValidation(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failSendToValidation(e));
  }
};

export const requestUpdateInscris = () => ({
  type: TYPES.INSCRISI_UPDATE_REQUEST,
});
export const successUpdateInscris = (payload) => ({
  type: TYPES.INSCRISI_UPDATE_SUCCESS,
  payload,
});
export const failUpdateInscris = (error) => ({
  type: TYPES.INSCRISI_UPDATE_FAIL,
  error,
});

export const setCandidatType = (payload) => ({
  type: TYPES.INSCRISI_SET_CANDIDAT_TYPE,
  payload,
});

export const updateInscris = (dosar) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateInscris());
  try {
    const res = await request
      .put(`${serverUrl}/inscrisi-ro/${dosar._id}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({
        ...dosar,
        documente: undefined,
        documentJustificativPlata: undefined,
      });
    dispatch(successUpdateInscris(res.body));
    dispatch(
      showSnackbar("Fișa de înscriere a fost salvată cu succes.", "success")
    );
    // const isAdmin = getIsAdmin(getState());
    // if (isAdmin) history.push("/inscrisi-ro");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failUpdateInscris(e));
  }
};

export const requestUpdateMyInscris = () => ({
  type: TYPES.INSCRISI_MY_UPDATE_REQUEST,
});
export const successUpdateMyInscris = (payload) => ({
  type: TYPES.INSCRISI_MY_UPDATE_SUCCESS,
  payload,
});
export const failUpdateMyInscris = (error) => ({
  type: TYPES.INSCRISI_MY_UPDATE_FAIL,
  error,
});

export const updateMyInscris = (dosar) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestUpdateInscris());
  try {
    const res = await request
      .put(`${serverUrl}/inscrisi-ro`)
      .set("Authorization", `Bearer ${jwt}`)
      .send(dosar);
    dispatch(successUpdateInscris(res.body));
    dispatch(
      showSnackbar("Fișa de înscriere a fost salvată cu succes", "success")
    );
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failUpdateInscris(e));
    throw e;
  }
};

export const requestSearchInscrisi = () => ({
  type: TYPES.INSCRISI_SEARCH_REQUEST,
});
export const successSearchInscrisi = (payload, query) => ({
  type: TYPES.INSCRISI_SEARCH_SUCCESS,
  payload,
  query,
});
export const failSearchInscrisi = (error) => ({
  type: TYPES.INSCRISI_SEARCH_FAIL,
  error,
});

export const searchInscrisi = (query, pageInfo, candidatType) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  // eslint-disable-next-line no-param-reassign
  query = _.isEmpty(query) ? undefined : query;
  dispatch(requestSearchInscrisi());
  switch (candidatType) {
    case "traducere": {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search/traducere`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
      break;
    }
    case "ro": {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search/ro`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
      break;
    }
    case "rdp": {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search/rdp`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
      break;
    }
    case "colegiu": {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search/colegiu`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
      break;
    }
    case "dppd": {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search/dppd`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
      break;
    }
    default: {
      try {
        const res = await request
          .post(`${serverUrl}/inscrisi-ro/search`)
          .send({ query, pageInfo })
          .set("Authorization", `Bearer ${jwt}`);
        dispatch(successSearchInscrisi(res.body, query));
      } catch (e) {
        dispatch(showSnackbar(e, "error"));
        dispatch(failSearchInscrisi(e));
      }
    }
  }
  // try {
  //   const res = await request
  //     .post(`${serverUrl}/inscrisi-ro/search`)
  //     .send({ query, pageInfo })
  //     .set("Authorization", `Bearer ${jwt}`);
  //   dispatch(successSearchInscrisi(res.body, query));
  // } catch (e) {
  //   dispatch(showSnackbar(e, "error"));
  //   dispatch(failSearchInscrisi(e));
  // }
};

const requestDeleteInscris = () => ({
  type: TYPES.INSCRISI_DELETE_REQUEST,
});
const successDeleteInscris = (payload) => ({
  type: TYPES.INSCRISI_DELETE_SUCCESS,
  payload,
});
const failDeleteInscris = (error) => ({
  type: TYPES.INSCRISI_DELETE_FAIL,
  error,
});
export const deleteInscris = (dosarId) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestDeleteInscris());
  try {
    await request
      .delete(`${serverUrl}/inscrisi-ro/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successDeleteInscris(dosarId));
    dispatch(showSnackbar("Dosar sters cu succes", "success"));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failDeleteInscris(e));
  }
};

export const inscrisiSetQuery = (query) => ({
  type: TYPES.INSCRISI_SET_QUERY,
  payload: query,
});

export const requestRejectDosar = () => ({
  type: TYPES.INSCRIS_REJECT_REQUEST,
});
export const successRejectDosar = () => ({
  type: TYPES.INSCRIS_REJECT_SUCCESS,
});
export const failRejectDosar = (error) => ({
  type: TYPES.INSCRIS_REJECT_FAIL,
  error,
});

export const handleReject = (dosar, values) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestRejectDosar());
  try {
    await request
      .post(`${serverUrl}/inscrisi-ro/reject/${dosar._id}`)
      .send(values)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(showSnackbar("Dosar respins", "success"));
    dispatch(successRejectDosar());
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failRejectDosar(e));
  }
};

export const requestValidateDosar = () => ({
  type: TYPES.INSCRIS_VALIDATE_REQUEST,
});
export const successValidateDosar = () => ({
  type: TYPES.INSCRIS_VALIDATE_SUCCESS,
});
export const failValidateDosar = (error) => ({
  type: TYPES.INSCRIS_VALIDATE_FAIL,
  error,
});
export const requestFinalizeDosar = () => ({
  type: TYPES.INSCRIS_FINALIZE_REQUEST,
});
export const successFinalizeDosar = () => ({
  type: TYPES.INSCRIS_FINALIZE_SUCCESS,
});
export const failFinalizeDosar = (error) => ({
  type: TYPES.INSCRIS_FINALIZE_FAIL,
  error,
});
export const handleValidate = (dosar) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestValidateDosar());
  try {
    await request
      .get(`${serverUrl}/inscrisi-ro/validate/${dosar._id}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(showSnackbar("Dosar validat", "success"));
    dispatch(successValidateDosar());
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failValidateDosar(e));
  }
};

export const finalizeDosar = (dosar) => async (dispatch, getState) => {
  const jwt = getToken(getState());
  dispatch(requestFinalizeDosar());
  try {
    await request
      .get(`${serverUrl}/inscrisi-ro/finalize/${dosar._id}`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(showSnackbar("Dosar finalizat", "success"));
    dispatch(successFinalizeDosar());
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
    dispatch(failFinalizeDosar(e));
  }
};

export const validateManually = ({ paymentType, dosarId }) => async (
  dispatch,
  getState
) => {
  const jwt = getToken(getState());
  try {
    const res = await request
      .post(`${serverUrl}/payment/validate/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({ paymentType });
    dispatch(successInscrisById(res.body));
    dispatch(successDosarInscris(res.body));
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

const successPaymentInfo = (payload) => ({
  type: TYPES.PAYMENT_INFO_SUCCESS,
  payload,
});

export const requestPaymentInfo = () => async (dispatch, getState) => {
  try {
    const jwt = getToken(getState());
    const res = await request
      .get(`${serverUrl}/payment/get-payment-portal`)
      .set("Authorization", `Bearer ${jwt}`);
    dispatch(successPaymentInfo(res.body));
    // this.props.history.push("/payment-redirect");
  } catch (e) {
    dispatch(showSnackbar(e, "error"));
  }
};

export const updatePlatit = () => ({
  type: TYPES.INSCRIS_PLATIT_UPDATE,
});

export const updateUploadAnexa4 = () => ({
  type: TYPES.INSCRIS_UPLOAD_ANEXA4_UPDATE,
});
