import React, { useEffect } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { getDosarInscris } from "../../reducers/inscrisiReducer";

import { fetchDosarInscris } from "../../actions/inscrisi-ro";

export default function ConcursDeAdmitere() {
  const dispatch = useDispatch();
  const dosar = useSelector(getDosarInscris);
  useEffect(() => {
    dispatch(fetchDosarInscris());
    // if (dosar?._id) {
    //   dispatch(getPosition({ dosarId: dosar._id }));
    // }
  }, [dosar._id]);
  if (!dosar.candidatType) {
    return <div>Loading...</div>;
  }
  const showContestatii =
    ((dosar.candidatType.registerFor === "master" && !dosar.candidatType.rdp) ||
      (dosar.candidatType.rdp &&
        dosar.candidatType.registerFor === "licenta")) &&
    dosar.candidatType.translationAndInterpretation &&
    dosar.candidatType.registerFor === "licenta";
  return (
    <div>
      <p className="text-center mb-4 text-3xl">Felicitări!</p>
      <div>
        Ai intrat în concursul de admitere. Verifică lista de rezultate, afișată
        conform calendarului, pe{" "}
        <a className="underline text-link" href="https://admitere.utcb.ro">
          https://admitere.utcb.ro
        </a>
        . Tot aici găsești și informații suplimentare.
      </div>
      <div>
        Id-ul dosarului tau este: <b>{dosar._id}</b>
      </div>
      {showContestatii && (
        <div>
          Contestatiile se primesc pe adresa de mail{" "}
          <a className="underline text-link" href="mailto:secretariat@utcb.ro">
            secretariat@utcb.ro
          </a>
        </div>
      )}
    </div>
  );
}
