import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { Button, Typography, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DocumentTable from "../General/DocumentTable";
import { fetchDosarInscris, sendToValidation } from "../../actions/inscrisi-ro";
import { fetchTipuriDeDocumente } from "../../actions";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import {
  getDosarInscris,
  getCandidatType,
} from "../../reducers/inscrisiReducer";
import { getCandidatTypeForLoggedInUser } from "../../reducers/authReducer";

yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
class IncarcareDocumente extends Component {
  state = {
    dosar: { _id: "" },
  };

  async componentDidMount() {
    this.props.setIsLoading(1);
    await this.props.fetchDosarInscris();
    await this.props.fetchTipuriDeDocumente();
    this.props.setIsLoading(-1);
  }

  handleSendToValidationClick = async () => {
    await this.props.sendToValidation();
    this.props.handleNext();
  };

  render() {
    const { t, tipuriDeDocumente, dosar, isLoading, candidatType } = this.props;
    let tipuriDoc = [];
    if (candidatType.candidatType === "ro") {
      if (candidatType.translationAndInterpretation) {
        tipuriDoc = tipuriDeDocumente.inscrisTraducere;
      } else if (candidatType.registerFor === "licenta") {
        tipuriDoc = tipuriDeDocumente.inscrisLicenta;
      } else if (candidatType.registerFor === "master") {
        tipuriDoc = tipuriDeDocumente.inscrisMaster;
      }
    } else {
      tipuriDoc = tipuriDeDocumente.inscrisi;
    }
    return (
      <div>
        {dosar &&
          dosar.candidatType &&
          dosar.candidatType.registerFor === "licenta" &&
          !dosar.candidatType.rdp &&
          !dosar.candidatType.colegiu && (
            <Alert variant="outlined" severity="info" className="my-4">
              Daca nu s-a eliberat inca diploma, poti incarca adeverinta cu
              media de la bacalaureat.
              <br />
              Daca documentul este doar fata, incarca acelasi document la ambele
              sectiuni.
              <br />
              Daca urmezi o a doua specializare, incarci adeverinta facultate
              din care sa rezulte calitatea de student si forma de finantare
              (buget sau taxa).
              <br />
              Daca ai absolvit o facultate si vrei o a doua specializare,
              incarci diploma de licenta sau echivalenta cu aceasta si
              adeverinta care sa ateste forma de finantare a anilor de studii.
            </Alert>
          )}
        {dosar &&
          dosar.candidatType &&
          dosar.candidatType.registerFor === "licenta" &&
          dosar.candidatType.rdp &&
          !dosar.candidatType.colegiu && (
            <Alert variant="outlined" severity="info" className="my-4">
              Diploma de bacalaureat sau diploma echivalenta cu aceasta, iar
              pentru absolventii de liceu din promotia anului curent, carora nu
              le-a fost eliberata diploma, se încarca adeverinta, care va
              contine media de la bacalureat. Daca documentul este doar fata se
              incarca acelasi document la ambele sectiuni.
              <br />
              Pașaportul trebuie să aibă o valabilitate de cel puțin 6 luni la
              data înscrierii și se încarcă primile 3 pagini
              <br />
              Atestatul de echivalare a diplomei de bacalaureat se primește de
              la Ministerul Educației din România direcția CNRED
            </Alert>
          )}
        {dosar &&
          dosar.candidatType &&
          dosar.candidatType.registerFor === "master" &&
          !dosar.candidatType.rdp && (
            <Alert variant="outlined" severity="info" className="my-4">
              Diploma de licenta sau diploma echivalenta cu aceasta (insotita de
              Suplimentul la Diploma), iar pentru absolventii de studii
              universitare de licenta din promotia anului curent, carora nu le-a
              fost eliberata diploma, se incarca adeverinta la toate cele 3
              documnte referitoare la diploma de facultate, care va contine
              media de la examenul de licenta/diploma si media anilor de studii.
              <br />
              Pasaportul trebuie sa aiba o valabilitate de cel putin 6 luni la
              data inscrierii si se incarca primile 3 pagini
              <br />
              Atestatul de echivalare a diplomei de bacalaureat se primește de
              la Ministerul Educației din România direcția CNRED
            </Alert>
          )}
        {dosar &&
          dosar.candidatType &&
          dosar.candidatType.registerFor === "master" &&
          !dosar.candidatType.rdp && (
            <Alert variant="outlined" severity="info" className="my-4">
              Diploma de licenta sau diploma echivalenta cu aceasta (insotita de
              Suplimentul la Diploma), iar pentru absolventii de studii
              universitare de licenta din promotia anului curent, carora nu le-a
              fost eliberata diploma, se incarca adeverinta la toate cele 3
              documnte referitoare la diploma de facultate, care va contine
              media de la examenul de licenta/diploma si media anilor de studii.
              <br />
              Pasaportul trebuie sa aiba o valabilitate de cel putin 6 luni la
              data inscrierii si se incarca primile 3 pagini
            </Alert>
          )}
        {dosar &&
          dosar.candidatType &&
          !dosar.candidatType.translationAndInterpretation && (
            <Alert variant="outlined" severity="info" className="my-4">
              Pentru specializările inginerești cu predare în limbi străine,
              dacă nu încărcați un certificat de competență lingvistică, veți
              susține un test eliminatoriu
            </Alert>
          )}
        {dosar &&
          dosar.candidatType &&
          dosar.candidatType.registerFor === "master" && (
            <div className="my-4">
              <Alert variant="outlined" severity="info">
                DOAR absolvenții din anul universitar curent din cadrul
                Universității Tehnice de Construcții București pot încărca în
                locul de documentelor (diplomă bacalaureat, diplomă licență,
                supliment) cererea atașată{" "}
                <a
                  style={{ color: "#81C534" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://utcb.ro/wp-content/uploads/2023/06/Cerere-candidat-master-absolvent-UTCB.pdf"
                >
                  aici
                </a>{" "}
                , în cazul în care nu ridicat adeverința de absolvire.
              </Alert>
            </div>
          )}
        <div className="my-4">
          <Alert variant="outlined" severity="info">
            Formate acceptate: PDF / JPG / JPEG / PNG / DOC / DOCX (max
            15MB/fișier)
          </Alert>
        </div>
        {isLoading === 0 && (
          <div>
            <DocumentTable
              dosarId={dosar._id}
              documente={dosar.documente || []}
              tipuriDocumente={tipuriDoc}
              name="inscrisi-ro"
              type="registration"
            />
            <div style={{ paddingTop: "24px" }}>
              <Button
                disabled={!dosar.areToateDocumenteleObligatorii}
                onClick={this.handleSendToValidationClick}
                variant="contained"
                color="primary"
              >
                Salveaza si trimite
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  dosar: getDosarInscris(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
  candidatType: getCandidatType(state) || getCandidatTypeForLoggedInUser(state),
});

const mapDispatchToProps = {
  fetchTipuriDeDocumente,
  fetchDosarInscris,
  sendToValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncarcareDocumente);
