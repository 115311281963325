import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import request from "superagent";
import { Button, Typography } from "@material-ui/core";
import FileSaver from "file-saver";
import { showSnackbar } from "../../../actions";
import { serverUrl } from "../../../utils";
import { getToken } from "../../../reducers/authReducer";
/* eslint-disable no-underscore-dangle */
class Cerere extends Component {
  requestPdf = async () => {
    const { jwt, dosar } = this.props;
    try {
      let res = {};
      if (dosar.candidatType.translationAndInterpretation) {
        res = await request
          .get(
            `${serverUrl}/inscrisi-ro/${dosar._id}/generate-fisa-inscriere-TSI`
          )
          .set("Authorization", `Bearer ${jwt}`)
          .responseType("blob");
        const { headers } = res;
        const contentDisposition = headers["content-disposition"];
        const blob = res.body;
        FileSaver.saveAs(
          blob,
          contentDisposition.substr(contentDisposition.indexOf("=") + 1)
        );
      } else {
        res = await request
          .get(`${serverUrl}/inscrisi-ro/${dosar._id}/generate-fisa-inscriere`)
          .set("Authorization", `Bearer ${jwt}`)
          .responseType("blob");
        const { headers } = res;
        const contentDisposition = headers["content-disposition"];
        const blob = res.body;
        FileSaver.saveAs(
          blob,
          contentDisposition.substr(contentDisposition.indexOf("=") + 1)
        );
      }
    } catch (e) {
      this.props.showSnackbar("Error: generate-pdf", "error");
    }
  };

  render() {
    const { dosar } = this.props;
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.requestPdf}>
          Descarca cerere
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jwt: getToken(state),
});

const mapDispatchToProps = { showSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(Cerere);
