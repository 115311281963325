import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import {
  TableHead,
  Menu,
  MenuItem,
  Select,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  withMobileDialog,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import DeleteModal from "../General/DeleteModal";
import {
  fetchNomenclatoare,
  deleteNomenclator,
  addNomenclator,
  editNomenclator,
} from "../../actions";

const nomenclatoare = [
  // { name: "facultati", label: "Facultati" },
  // { name: "specializari", label: "Specializari" },
  // { name: "departamente", label: "Departamente" },
  // { name: "domenii", label: "Domenii" },
  // { name: "discipline", label: "Discipline" },
  { name: "cetatenii", label: "Cetatenii" },
  // { name: "formeDeInvatamant", label: "Forme de invatamant" },
  // { name: "situatiiScolare", label: "Situatii scolare" },
  { name: "limbiStraine", label: "Limbi straine" },
  // { name: "calificative", label: "Calificative" },
  // { name: "categoriiELearning", label: "Categorii E-Learning" },
  // { name: "jurnale", label: "Jurnale" },
  // { name: "domeniiInscriere", label: "Domenii Inscriere" },
  { name: "optiuniStudii", label: "Optiuni Studii Non-UE" },
  { name: "optiuniStudiiRo", label: "Optiuni Studii RO" },
  { name: "tari", label: "Tari" },
];

const collectionNames = {
  calificative: "Calificative",
  categoriiELearning: "CategoriiELearning",
  cetatenii: "Cetatenii",
  discipline: "Discipline",
  departamente: "Departamente",
  domenii: "Domenii",
  facultati: "Facultati",
  formeDeInvatamant: "FormeDeInvatamant",
  limbiStraine: "LimbiStraine",
  situatiiScolare: "SituatiiScolare",
  specializari: "Specializari",
  jurnale: "Jurnale",
  domeniiInscriere: "DomeniiInscriere",
  optiuniStudii: "OptiuniStudii",
  optiuniStudiiRo: "OptiuniStudiiRo",
  tari: "Tari",
};

class NomenclatoareList extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    deleteNomenclator: PropTypes.func.isRequired,
    editNomenclator: PropTypes.func.isRequired,
    addNomenclator: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
  };

  state = {
    anchorEl: null,
    selectedNomenclator: "optiuniStudiiRo",
    current: [],
    openModal: false,
    action: "add",
    openDeleteModal: false,
    name: "",
    ordine: "",
    trans: { ro: "", en: "", fr: "" },
    page: 0,
    rowsPerPage: 25,
  };

  static getDerivedStateFromProps = (props, state) => {
    if (!Array.isArray(props.all[state.selectedNomenclator])) {
      return {
        current: [],
      };
    }
    return {
      current: props.all[state.selectedNomenclator] || [],
    };
  };

  componentDidMount() {
    this.props.fetchNomenclatoare();
  }

  handleViewClick = () => {
    this.handleCloseMenu();
  };

  handleEditClick = () => {
    this.handleCloseMenu();
    this.setState({
      action: "edit",
      openModal: true,
    });
  };

  handleDeleteClick = () => {
    this.handleCloseMenu();
    this.setState({
      openDeleteModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  handleDeleteAccept = () => {
    const { id, selectedNomenclator } = this.state;
    this.handleCloseModal();
    this.props.deleteNomenclator({
      id,
      type: collectionNames[selectedNomenclator],
      nomenclatorName: selectedNomenclator,
    });
  };

  handleOpenMenu = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      id: item._id,
      name: item.nume,
      ordine: item.ordine,
      trans: item.trans,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(() => ({
      [name]: value,
      current: this.props.all[value] || [],
    }));
  };

  openAddModal = () => {
    this.setState({
      openModal: true,
      action: "add",
      id: null,
      name: "",
      trans: { ro: "", en: "", fr: "" },
    });
  };

  handleClose = (action, func, handleReset) => {
    if (action === "reset") {
      this.setState(() => ({
        openModal: false,
        name: "",
      }));
    } else {
      this.setState({
        openModal: false,
        name: "",
      });
    }
    if (func) {
      func();
    }
  };

  handleSubmit = async (values, { resetForm }) => {
    this.setState({
      openModal: false,
    });
    const { id, selectedNomenclator, action } = this.state;
    if (action === "add") {
      this.props.addNomenclator({
        ...values,
        nomenclatorName: selectedNomenclator,
      });
    } else {
      this.props.editNomenclator({
        id,
        ...values,
        nomenclatorName: selectedNomenclator,
      });
    }
    this.setState({
      name: "",
    });
    resetForm();
  };

  handleChangePage = (e, page) => {
    this.setState({
      page,
    });
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({
      rowsPerPage: e.target.value,
      page: 0,
    });
  };

  render() {
    const {
      anchorEl,
      selectedNomenclator,
      current,
      openModal,
      action,
      name,
      ordine,
      page,
      trans,
      rowsPerPage,
    } = this.state;
    const { fullScreen } = this.props;
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Select
            value={selectedNomenclator}
            name="selectedNomenclator"
            onChange={this.handleChange}
            style={{ marginRight: "16px" }}
          >
            {nomenclatoare.map((nomenclator) => (
              <MenuItem value={nomenclator.name} key={nomenclator.name}>
                {nomenclator.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-padded-icon"
            onClick={this.openAddModal}
          >
            <AddIcon />
            Adauga
          </Button>
        </div>
        <Paper style={{ height: "100%", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50px" }}>Optiuni</TableCell>
                {selectedNomenclator === "optiuniStudii" ? (
                  <>
                    <TableCell>RO</TableCell>
                    <TableCell>EN</TableCell>
                    <TableCell>FR</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>Nume</TableCell>
                  </>
                )}

                {(selectedNomenclator === "optiuniStudiiRo" ||
                  selectedNomenclator === "optiuniStudii") && (
                  <TableCell>Ordine</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(current || [])
                .sort((a, b) => a.ordine - b.ordine)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell style={{ width: "50px" }}>
                      <IconButton
                        aria-owns={anchorEl ? "simple-menu" : null}
                        aria-haspopup="true"
                        onClick={(event) => this.handleOpenMenu(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                    {selectedNomenclator === "optiuniStudii" ? (
                      <>
                        <TableCell>{row.trans.ro}</TableCell>
                        <TableCell>{row.trans.en}</TableCell>
                        <TableCell>{row.trans.fr}</TableCell>
                      </>
                    ) : (
                      <TableCell>{row.nume}</TableCell>
                    )}
                    {(selectedNomenclator === "optiuniStudiiRo" ||
                      selectedNomenclator === "optiuniStudii") && (
                      <TableCell>{row.ordine}</TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={current.length}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "Anterioara",
            }}
            nextIconButtonProps={{
              "aria-label": "Urmatoare",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Menu
          className="lvd-options-container"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleEditClick}>Editeaza</MenuItem>
          <MenuItem onClick={this.handleDeleteClick}>Sterge</MenuItem>
        </Menu>
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            nume: name,
            ordine,
            type: collectionNames[selectedNomenclator],
            trans,
          }}
          render={({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
            handleReset,
            handleSubmit,
          }) => (
            <Dialog
              fullScreen={fullScreen}
              open={openModal}
              onClose={() => this.handleClose()}
              aria-labelledby="form-dialog-title"
              fullWidth
            >
              <DialogTitle id="form-dialog-title">
                {action === "add"
                  ? `Adauga element pentru nomenclator "${
                      this.state.selectedNomenclator
                        ? nomenclatoare.find(
                            (n) => n.name === this.state.selectedNomenclator
                          ).label
                        : ""
                    }"`
                  : "Editeaza element"}
                <Button
                  className="lvd-button-close-modal"
                  onClick={this.handleClose}
                >
                  Inchide
                </Button>
              </DialogTitle>
              <DialogContent style={{ minWidth: "550px" }}>
                {selectedNomenclator === "optiuniStudii" ? (
                  <>
                    <TextField
                      error={!!errors.trans?.ro && !!touched.trans?.ro}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="dense"
                      label="Traducere RO"
                      fullWidth
                      value={values.trans.ro}
                      name="trans.ro"
                      helperText="Format: LIMBA | LICENTA/MASTER | FACULTATE/UNIVERSITATE | SPECIALIZARE"
                    />
                    <TextField
                      error={!!errors.trans?.en && !!touched.trans?.en}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="dense"
                      label="Traducere EN"
                      fullWidth
                      value={values.trans.en}
                      name="trans.en"
                      helperText="Format: LIMBA | LICENTA/MASTER | FACULTATE/UNIVERSITATE | SPECIALIZARE"
                    />
                    <TextField
                      error={!!errors.trans?.fr && !!touched.trans?.fr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="dense"
                      label="Traducere FR"
                      fullWidth
                      value={values.trans.fr}
                      name="trans.fr"
                      helperText="Format: LIMBA | LICENTA/MASTER | FACULTATE/UNIVERSITATE | SPECIALIZARE"
                    />
                    <TextField
                      error={!!errors.ordine && !!touched.name}
                      type="number"
                      min="1"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Ordine"
                      fullWidth
                      value={values.ordine}
                      name="ordine"
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      error={!!errors.name && !!touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="dense"
                      label="Nume"
                      fullWidth
                      value={values.nume}
                      name="nume"
                      helperText={
                        selectedNomenclator === "optiuniStudiiRo"
                          ? "Format: LICENTA | FACULTATE | SPECIALIZARE | FINANTARE"
                          : ""
                      }
                    />
                    {selectedNomenclator === "optiuniStudiiRo" && (
                      <TextField
                        error={!!errors.ordine && !!touched.name}
                        type="number"
                        min="1"
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Ordine"
                        fullWidth
                        value={values.ordine}
                        name="ordine"
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions style={{ padding: "14px" }}>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {action === "add" ? "Adauga" : "Salveaza"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        />
        <DeleteModal
          open={this.state.openDeleteModal}
          handleClose={this.handleCloseModal}
          name={this.state.name}
          handleDelete={this.handleDeleteAccept}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  all: state.nomenclatoare,
});

export default withMobileDialog()(
  connect(mapStateToProps, {
    fetchNomenclatoare,
    deleteNomenclator,
    addNomenclator,
    editNomenclator,
  })(NomenclatoareList)
);
