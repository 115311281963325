import React, { Component } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { validateManually } from "../../../actions";
import {
  downloadDocumentJustificativ,
  handleRejectPaymentDocument,
} from "../../../actions/inscrisi-ro";

class Payments extends Component {
  state = { paymentType: "", isValidateDialogOpen: false };

  componentDidMount() {
    this.setState({
      paymentType: this.props.dosar.paymentType || "",
    });
  }

  closeDialog = () => {
    this.setState({
      isValidateDialogOpen: false,
    });
  };

  handleChange = (e) => {
    this.setState({ paymentType: e.target.value });
  };

  handleDownload = () => {
    this.props.downloadDocumentJustificativ(this.props.dosar._id);
  };

  handleRejectPaymentDocument = () => {
    this.props.handleRejectPaymentDocument(this.props.dosar._id);
    this.closeDialog();
  };

  handleValidatePayment = () => {
    const { paymentType } = this.state;
    const { dosar } = this.props;
    this.props.validateManually({ paymentType, dosarId: dosar._id });
  };

  handleOpenDialog = () => {
    this.setState({
      isValidateDialogOpen: true,
    });
  };

  render() {
    const { dosar } = this.props;
    const { isValidateDialogOpen } = this.state;
    return (
      <div>
        {!dosar.paymentId && !dosar.documentJustificativPlata && (
          <Typography>Neplatit/Plata invalidata</Typography>
        )}
        {dosar.paymentId && (
          <Grid container>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                label="Id plata"
                disabled
                value={dosar.paymentId || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Total de platit"
                disabled
                margin="normal"
                value={dosar.paymentAmount || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mentiuni plata"
                disabled={dosar.status !== "plataInitiata"}
                margin="normal"
                onChange={this.handleChange}
                value={this.state.paymentType}
              />
            </Grid>
          </Grid>
        )}
        {dosar.documentJustificativPlata && (
          <>
            <div>
              <Typography gutterBottom>
                Motiv upload: {dosar.documentJustificativPlata.motiv}
              </Typography>
            </div>
            <div className="flex">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownload}
                >
                  Download document justificativ
                </Button>
              </div>
              {dosar.status !== "eligibil" && (
                <div className="ml-4">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.handleOpenDialog}
                  >
                    Respinge document justificativ
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        {dosar.status === "plataInitiata" && (
          <Grid item xs={12}>
            <FormControl margin="normal">
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleValidatePayment}
              >
                Valideaza manual
              </Button>
            </FormControl>
          </Grid>
        )}
        <Dialog
          open={isValidateDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog}
        >
          <DialogContent>
            Esti sigur ca vrei sa refuzi acest document?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="secondary"
              onClick={this.closeDialog}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleRejectPaymentDocument}
            >
              Respinge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  validateManually,
  downloadDocumentJustificativ,
  handleRejectPaymentDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
