/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { setCandidatType, showSnackbar } from "../../actions";
import { fetchSettings } from "../../actions/settings";
import { selectRegisterOptions } from "../../reducers/settingsReducer";

// const registerOptions = [
//   {
//     name: "Inginerie (Licenta)",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "licenta",
//       candidatType: "ro",
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs: "?registerFor=licenta&candidatType=ro",
//   },
//   {
//     name: "Inginerie (Master)",
//     // timespan: "12-23 Iul",
//     candidatType: {
//       registerFor: "master",
//       candidatType: "ro",
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs: "?registerFor=master&candidatType=ro",
//   },
//   {
//     name: "Traducere si Interpretare (Licenta)",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "licenta",
//       candidatType: "ro",
//       translationAndInterpretation: true,
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs:
//       "?registerFor=licenta&candidatType=ro&translationAndInterpretation=true",
//   },
//   {
//     name: "Traducere si Interpretare (Master)",
//     // timespan: "12-23 Iul",
//     candidatType: {
//       registerFor: "master",
//       candidatType: "ro",
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs: "?registerFor=master&candidatType=ro",
//   },
//   {
//     name: "Departamentul Pentru Pregatirea Personalului Didactic",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "master",
//       candidatType: "ro",
//       dppd: true,
//     },
//     status: "ongoing",
//     // status: "finished",
//     qs: "?registerFor=master&candidatType=ro&dppd=true",
//   },
//   {
//     name: "Romani de pretutindeni - Inginerie (Licenta)",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "licenta",
//       candidatType: "ro",
//       rdp: true,
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs: "?registerFor=licenta&candidatType=ro&rdp=true",
//   },
//   {
//     name: "Romani de pretutindeni - Inginerie (Master)",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "master",
//       candidatType: "ro",
//       rdp: true,
//     },
//     status: "finished",
//     // status: "ongoing",
//     qs: "?registerFor=master&candidatType=ro&rdp=true",
//   },
//   {
//     name: "Non-eu",
//     // timespan: "7-27 Iul",
//     candidatType: {
//       registerFor: "licenta",
//       candidatType: "non-eu",
//     },
//     // status: "finished",
//     status: "ongoing",
//     qs: "?registerFor=licenta&candidatType=non-eu",
//   },
// ];

export default function RegisterTable() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSettings());
  }, []);
  const registerOptions = useSelector(selectRegisterOptions);
  // const qq = {
  //   ...queryString.parse(location.search),
  // };
  // delete qq._ga;
  // const [query, setQuery] = useState(qq);
  const [query, setQuery] = useState(location.search);
  useEffect(() => {
    setQuery(location.search);
  }, [location.search]);
  // useEffect(() => {
  //   setQuery(qq);
  // }, [qq]);
  const cnx = (status, index) =>
    classnames(
      {
        "bg-primary text-white": status === "ongoing",
        "bg-finishedRegistration text-tabBackground": status === "finished",
        "mb-4": index !== registerOptions.length - 1,
      },
      "flex justify-between w-full px-4 py-1 rounded-md cursor-pointer"
    );
  const handleRegister = (registerOption) => () => {
    if (registerOption.status === "ongoing") {
      dispatch(setCandidatType(registerOption.candidatType));
      history.push("/inscriere");
    } else {
      dispatch(showSnackbar("Perioada de inscrieri s-a incheiat"));
    }
  };
  return (
    <div className="mt-4">
      {query ? (
        <div>
          {registerOptions
            .filter(
              (registerOption) =>
                // isEqual(registerOption.candidatType, query)
                registerOption.qs === query
            )
            .map((registerOption) => (
              <div
                key={registerOption.name}
                className={cnx(registerOption.status)}
                onClick={handleRegister(registerOption)}
              >
                <div className="font-semibold">{registerOption.name}</div>
                {/* <div>
                  {registerOption.status === "finished"
                    ? "finalizat"
                    : registerOption.timespan}
                </div> */}
              </div>
            ))}
        </div>
      ) : (
        registerOptions
          .filter((registerOption) => !registerOption.hideByDefault)
          .map((registerOption) => (
            <div
              key={registerOption.name}
              className={cnx(registerOption.status)}
              onClick={handleRegister(registerOption)}
            >
              <div className="font-semibold">{registerOption.name}</div>
              <div className="flex whitespace-no-wrap">
                {/* {registerOption.status === "finished" && "finalizat"} */}
                <ChevronRight />
              </div>
            </div>
          ))
      )}
    </div>
  );
}
