import * as TYPES from "../types";

const settingsReducer = (
  state = {
    registerOptions: [],
  },
  action
) => {
  const { payload } = action;
  switch (action.type) {
    case TYPES.SETTINGS_FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const selectRegisterOptions = (state) => state.settings.registerOptions;
export const selectSettings = (state) => state.settings;

export default settingsReducer;
