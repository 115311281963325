import React, { useState, createRef, useEffect } from "react";
import {
  Typography,
  Button,
  Tabs,
  AppBar,
  Tab,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import Dropzone from "react-dropzone";
import { getToken, getUser } from "../../reducers/authReducer";
import * as helpers from "../../utils";
import {
  fetchDosarInscris,
  requestPaymentInfo,
  updatePlatit,
} from "../../actions/inscrisi-ro";
import { selectPaymentInfo } from "../../reducers/inscrisiReducer";
import { showSnackbar } from "../../actions";

const uploadDocumentJustificativ = async (jwt, file, motiv) => {
  try {
    const res = await request
      .post(`${helpers.serverUrl}/payment/document-justificativ`)
      .set("Authorization", `Bearer ${jwt}`)
      .field("motiv", motiv)
      .attach("scrisoare", file);
    return null;
  } catch (e) {
    return e;
  }
};

export default function Payment({ handleNext }) {
  const user = useSelector(getUser);
  const lang = useSelector((state) => state.i18nState.lang);
  const [currentTab, setCurrentTab] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const tabs = () => [];
  const dispatch = useDispatch();
  const jwt = useSelector(getToken);
  useEffect(() => {
    dispatch(fetchDosarInscris());
  }, []);
  const handleUpload = (motiv) => async (file) => {
    setIsUploading(true);
    const x = await uploadDocumentJustificativ(jwt, file, motiv);
    if (x) {
      dispatch(showSnackbar(x, "error"));
    } else {
      dispatch(updatePlatit());
    }
    setIsUploading(false);
  };
  const renderTabContent = () => {
    switch (currentTab) {
      case 1:
        return (
          <OP
            user={user}
            lang={lang}
            handleUpload={handleUpload("op")}
            isUploading={isUploading}
          />
        );
      case 2:
        return (
          <ScutitDePlata
            handleUpload={handleUpload("scutit")}
            isUploading={isUploading}
          />
        );
      default:
      case 0:
        return <Plata />;
    }
  };
  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          indicatorColor="primary"
          value={currentTab}
          onChange={(e, tab) => setCurrentTab(tab)}
        >
          <Tab label="Platesc cu cardul" />
          <Tab label="Platesc prin OP" />
          {user.candidatType.candidatType !== "non-eu" && (
            <Tab label="Sunt scutit de taxa" />
          )}
        </Tabs>
      </AppBar>
      <Paper className="py-8 px-4">{renderTabContent()}</Paper>
    </div>
  );
}

function Plata() {
  const dispatch = useDispatch();
  const paymentInfo = useSelector(selectPaymentInfo);
  const user = useSelector(getUser);
  const lang = useSelector((state) => state.i18nState.lang);
  const requestPayment = () => {
    dispatch(requestPaymentInfo());
  };
  // const ed = paymentInfo && JSON.parse(paymentInfo.data.ExtraData);
  return (
    <>
      {(() => {
        if (paymentInfo) {
          switch (lang) {
            case "ro":
              return (
                <Typography gutterBottom>
                  Vei achita suma de {paymentInfo.data.amount}{" "}
                  {paymentInfo.data.curr} (indiferent de numărul de specializări
                  pentru care aplici). Apasa pe buton pentru a fi redirectionat
                  catre pagina de plata securizata.
                </Typography>
              );
            case "fr":
              return (
                <Typography gutterBottom>
                  Vous allez payer le montant de {paymentInfo.data.amount}{" "}
                  {paymentInfo.data.curr} (peu importe le nombre de
                  spécialisations pour lesquelles vous postulez). Appuyez sur le
                  bouton pour être redirigé vers la page de paiement sécurisé.
                </Typography>
              );
            case "en":
              return (
                <Typography gutterBottom>
                  You will pay the amount of {paymentInfo.data.amount}{" "}
                  {paymentInfo.data.curr} (regardless of the number of
                  specializations you apply for). Press the button to be
                  redirected to the secure payment page.
                </Typography>
              );
            default:
              return null;
          }
        }
      })()}
      {paymentInfo ? (
        <form method="post" action={paymentInfo.redirectUrl}>
          {Object.keys(paymentInfo.data).map((key) => (
            <input
              style={{ display: "none" }}
              defaultValue={paymentInfo.data[key]}
              name={key}
              key={key}
            />
          ))}
          {/* <input
            style={{ display: "none" }}
            name="ExtraData[test]"
            value={ed.test}
          /> */}
          <Button type="submit" variant="contained" color="primary">
            Click pentru a continua
          </Button>
        </form>
      ) : (
        <Button variant="contained" color="primary" onClick={requestPayment}>
          Plateste securizat
        </Button>
      )}
    </>
  );
}

function ScutitDePlata({ handleUpload, isUploading }) {
  let uploadRef = createRef(null);
  return (
    <>
      <Alert variant="outlined" severity="warning" className="mb-4">
        Incarca dovada scutirii de plata mai jos
      </Alert>
      <Typography variant="body1" gutterBottom>
        Adeverință de la locul de muncă al părinților, pentru aceia care
        lucrează în sistemul de învățământ
        <br />
        sau
        <br />
        Document care dovedește că elevul este orfan de unul sau ambii părinți
      </Typography>
      <Button
        onClick={() => {
          uploadRef.open();
        }}
        disabled={isUploading}
        variant="contained"
        color="primary"
      >
        Incarca dovada
      </Button>
      <Dropzone
        multiple={false}
        ref={(node) => {
          uploadRef = node;
        }}
        style={{ display: "none" }}
        onDrop={(accepted, rejected) => {
          handleUpload(accepted[0]);
        }}
      >
        <p>Drop files here.</p>
      </Dropzone>
    </>
  );
}

function OP({ user, handleUpload, isUploading, lang }) {
  let uploadRef = createRef(null);
  return (
    <>
      {(() => {
        if (user.candidatType.candidatType === "non-eu")
          switch (lang) {
            case "ro":
              return (
                <Typography variant="body1" gutterBottom>
                  La Banca Comercială Română (BCR) sector 2, cu următoarele
                  detalii
                  <br />
                  <br />
                  Cont IBAN RO73RNCB0073005630380059 EUR
                  <br />
                  deschis la BCR sector 2
                  <br />
                  Beneficiar UTCB
                  <br />
                  Cod SWIFT: RNCBROBUXXX
                  <br />
                  Cod fiscal 4266570
                  <br />
                  Detalii plată: taxă admitere, număr pașaport, nume și prenume
                  candidat
                  <br />
                  <br />
                  <br />
                  Suma: 75 euro
                </Typography>
              );
            case "fr":
              return (
                <Typography variant="body1" gutterBottom>
                  Au secteur 2 de la Banque Commerciale Roumaine (BCR), avec les
                  détails
                  <br />
                  <br />
                  Cont IBAN RO73RNCB0073005630380059 EUR
                  <br />
                  Bénéficiaire : UTCB
                  <br />
                  Cod fiscal 4266570
                  <br />
                  Code SWIFT : RNCBROBUXXX
                  <br />
                  Données de paiement : frais d`admission, numéro de passeport,
                  nom et prénom du candidat
                  <br />
                  <br />
                  <br />
                  Montant : 75 euro
                </Typography>
              );
            case "en":
              return (
                <Typography variant="body1" gutterBottom>
                  At Banca Comercială Română (BCR) sector 2, with the following
                  details
                  <br />
                  <br />
                  code IBAN : RO73RNCB0073005630380059 EUR
                  <br />
                  opened at BCR sector 2
                  <br />
                  Beneficiary: UTCB
                  <br />
                  Code fiscal : 4266570
                  <br />
                  SWIFT code: RNCBROBUXXX
                  <br />
                  Payment details: admission fee, passport number,
                  candidate&apos;s name and surname
                  <br />
                  <br />
                  <br />
                  Amount: 75 euro
                </Typography>
              );
            default:
              return null;
          }
        else {
          return (
            <>
              <Alert variant="outlined" severity="warning" className="mb-4">
                Incarca dovada platii prin OP mai jos
              </Alert>
              <Typography variant="body1" gutterBottom>
                La Banca Comercială Română (BCR) sector 2, cu următoarele
                detalii
                <br />
                <br />
                Cont IBAN RO06RNCB0073005630380004
                <br />
                <br />
                Beneficiar UTCB
                <br />
                Cod fiscal 4266570
                <br />
                Explicație: nume și prenume student, ce reprezintă taxa
                achitată, facultate și CNP
                <br />
                <br />
                <br />
                La Trezoreria statului sector 2, cu următoarele detalii
                <br />
                <br />
                Cont IBAN RO57TREZ70220F330500XXXX
                <br />
                Beneficiar UTCB
                <br />
                Cod fiscal 4266570
                <br />
                Explicație: nume și prenume student, ce reprezintă taxa
                achitată, facultate și CNP
                <br />
                <br />
                <br />
                Suma: 150 lei
              </Typography>
            </>
          );
        }
      })()}

      <Button
        onClick={() => {
          uploadRef.open();
        }}
        disabled={isUploading}
        variant="contained"
        color="primary"
      >
        Incarca dovada
      </Button>
      <Dropzone
        multiple={false}
        ref={(node) => {
          uploadRef = node;
        }}
        style={{ display: "none" }}
        onDrop={(accepted, rejected) => {
          handleUpload(accepted[0]);
        }}
      >
        <p>Drop files here.</p>
      </Dropzone>
    </>
  );
}
