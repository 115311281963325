import React, { Component } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { validateManually } from "../../../actions";
import { downloadAnexa4, rejectAnexa4 } from "../../../actions/inscrisi-ro";

class Anexa4 extends Component {
  state = { paymentType: "", isValidateDialogOpen: false };

  componentDidMount() {
    this.setState({
      paymentType: this.props.dosar.paymentType || "",
    });
  }

  closeDialog = () => {
    this.setState({
      isValidateDialogOpen: false,
    });
  };

  handleChange = (e) => {
    this.setState({ paymentType: e.target.value });
  };

  handleDownload = () => {
    this.props.downloadAnexa4(this.props.dosar._id);
  };

  handleRejectAnexa4 = () => {
    this.props.rejectAnexa4(this.props.dosar._id);
    this.closeDialog();
  };

  handleValidatePayment = () => {
    const { paymentType } = this.state;
    const { dosar } = this.props;
    this.props.validateManually({ paymentType, dosarId: dosar._id });
  };

  handleOpenDialog = () => {
    this.setState({
      isValidateDialogOpen: true,
    });
  };

  render() {
    const { dosar } = this.props;
    const { isValidateDialogOpen } = this.state;
    if (!dosar.uploadatAnexa4) {
      return <div>Nu a incarcat anexa 4</div>;
    }
    return (
      <div>
        <>
          <div className="flex">
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDownload}
              >
                Download anexa 4
              </Button>
            </div>
            <div className="ml-4">
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleOpenDialog}
              >
                Respinge anexa 4
              </Button>
            </div>
          </div>
        </>
        {/* {dosar.status === "plataInitiata" && (
          <Grid item xs={12}>
            <FormControl margin="normal">
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleValidatePayment}
              >
                Valideaza manual
              </Button>
            </FormControl>
          </Grid>
        )} */}
        <Dialog
          open={isValidateDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog}
        >
          <DialogContent>
            Esti sigur ca vrei sa refuzi acest document?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="secondary"
              onClick={this.closeDialog}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleRejectAnexa4}
            >
              Respinge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  validateManually,
  downloadAnexa4,
  rejectAnexa4,
};

export default connect(mapStateToProps, mapDispatchToProps)(Anexa4);
