import request from "superagent";
import FileSaver from "file-saver";
import { serverUrl } from ".";

export const saveInfo = ({ jwt, email, refreshToken, role }) => {
  localStorage.setItem("utcbJWT", jwt);
  localStorage.setItem("utcbEmail", email);
  localStorage.setItem("utcbRefreshToken", refreshToken);
  localStorage.setItem("utcbRole", role);
};

export const getInfo = () => ({
  jwt: localStorage.getItem("utcbJWT"),
  email: localStorage.getItem("utcbEmail"),
  refreshToken: localStorage.getItem("utcbRefreshToken"),
  role: localStorage.getItem("utcbRole"),
});

export const deleteInfo = () => {
  localStorage.removeItem("utcbJWT");
  localStorage.removeItem("utcbEmail");
  localStorage.removeItem("utcbRefreshToken");
  localStorage.removeItem("utcbRole");
};

export const exportDosar = async (jwt, dosarId, showSnackbar) => {
  try {
    const res = await request(`${serverUrl}/export/doctoranzi/${dosarId}`)
      .set("Authorization", `Bearer ${jwt}`)
      .responseType("blob");
    const { headers } = res;
    const contentDisposition = headers["content-disposition"];
    const blob = res.body;
    FileSaver.saveAs(
      blob,
      contentDisposition.substr(contentDisposition.indexOf("=") + 1)
    );
  } catch (e) {
    showSnackbar(e, "error");
  }
};

export const countFilters = (query) => {
  if (typeof query === "undefined" || query === null) {
    return 0;
  }
  let count = 0;
  let complex = false;
  if (Array.isArray(query)) {
    complex = true;
    query.forEach((q) => {
      count += countFilters(q);
    });
  } else if (typeof query === "symbol") {
    return 1;
  } else if (typeof query === "object") {
    const keys = Object.keys(query);
    complex = true;
    keys.forEach((key) => {
      count += countFilters(query[key]);
    });
  }
  return complex ? count : 1;
};

export const getReadableStatusRo = (
  status,
  hasSignedContract,
  hasDeliveredDiploma
) => {
  switch (status) {
    case "completatDosar":
      return "Fisa completata";
    case "documenteIncarcate":
      return "Documente incarcate";
    case "semnat":
      return "Ajuns la plata";
    case "incomplet":
      return "Incomplet";
    case "trimisCatreValidare":
      return "Trimis catre validare";
    case "validat":
      return "Validat";
    case "respins":
      return "Revizuire";
    case "respinsFinal":
      return "Respins";
    case "acceptat":
      return "Acceptat";
    case "admis":
      return "Admis";
    case "eligibil":
      if (hasDeliveredDiploma) {
        return "A adus diploma";
      }
      if (hasSignedContract) {
        return "Semnat Contract";
      }
      return "Eligibil";
    case "concursDeAdmitere":
      return "Concurs de admitere";
    // case "plataInitiata":
    //   return "Plata initiata";
    // case "plataValidata":
    //   return "Plata validata";
    case "finalizat":
      return "Finalizat";
    case "transformat":
      return "Transformat";
    case "":
      return "Incomplet";
    default:
      return "-";
  }
};

export const getReadableStatusNonEu = (status, hasSignedContract) => {
  switch (status) {
    case "incomplet":
      return "Incomplet";
    case "trimisCatreValidare":
      return "Trimis catre validare";
    case "validat":
      return "Validat";
    case "respins":
      return "Revizuire";
    case "semnat":
      return "Ajuns la plata";
    case "respinsFinal":
      return "Respins";
    case "acceptat":
      return "Acceptat";
    case "eligibil":
      if (hasSignedContract) {
        return "Semnat";
      }
      return "Eligibil";
    // case "plataInitiata":
    //   return "Plata initiata";
    // case "plataValidata":
    //   return "Plata validata";
    case "finalizat":
      return "Finalizat";
    case "transformat":
      return "Transformat";
    case "":
      return "Incomplet";
    default:
      return "-";
  }
};
