import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getIsLoggedIn, getToken, getIsAdmin } from "../reducers/authReducer";
import { setReturnUrl, refreshLogin } from "../actions";

const AuthRoute = ({
  component: Component,
  adminOnly,
  isSignedIn,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isSignedIn) {
        if (adminOnly) {
          if (isAdmin) {
            return <Component {...props} />;
          }
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
        return <Component {...props} />;

        // return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />;
      }
      rest.setReturnUrl(props.location.pathname);
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
const mapStateToProps = (state) => ({
  isSignedIn: getIsLoggedIn(state) || !!getToken(state),
  isAdmin: getIsAdmin(state),
});

export default connect(mapStateToProps, { setReturnUrl, refreshLogin })(
  AuthRoute
);

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  adminOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSignedIn: PropTypes.bool.isRequired,
};

AuthRoute.defaultProps = {
  adminOnly: false,
  isAdmin: false,
};
