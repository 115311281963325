import React, { Component, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Formik, Form, FieldArray } from "formik";
import { connect } from "react-redux";
import { v4 } from "uuid";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import {
  Button,
  Paper,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormHelperText,
  FormGroup,
  withWidth,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import { DatePicker } from "@material-ui/pickers";
import { isWidthUp } from "@material-ui/core/withWidth";
import { Alert } from "@material-ui/lab";
import DragAndDropSelect from "./DragAndDropSelect";
import { showSnackbar } from "../../actions";
import Chips from "./Chips";
import Select from "./Select";
import CreatableSelect from "./CreatableSelect";
import FormTextField from "./FormTextField";
import OnSubmitValidationError from "./OnSubmitValidationError";
import StudyOptionsTable from "../Inscriere/StudyOptionsTable";

const filter = createFilterOptions();
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const ro = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
class AddEditForm extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    inputs: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    validationSchema: PropTypes.shape({}).isRequired,
    isViewing: PropTypes.bool,
  };

  static defaultProps = {
    inputs: [],
    isStudyOptionsModalOpen: false,
    renderCustomInputs: () => {},
  };

  state = {
    anchorEl: null,
  };

  handleBackClick = () => {
    this.props.history.push(`/${this.props.name}`);
  };

  handleOpenMenu = (event, id) => {
    this.setState({
      anchorEl: event.currentTarget,
      id,
    });
  };

  handleOpenStudyOptionsModal = () => {
    this.setState({
      isStudyOptionsModalOpen: true,
    });
  };

  handleCloseStudyOptionsModal = () => {
    this.setState({
      isStudyOptionsModalOpen: false,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      id: null,
    });
  };

  handleDownloadClick = () => {};

  handleInvalidateClick = () => {};

  handleDeleteClick = () => {};

  onSubmitValidationError = () => {
    this.props.showSnackbar("Formular incomplet");
    // TODO: english wording as well
  };

  renderFields = (
    isViewing,
    {
      type,
      name,
      label,
      options,
      children,
      disabled,
      required,
      multiline,
      addOption,
      childrenTypes,
      helperText,
      captionText,
      filterBy,
      filterByName,
      optionName,
      ...rest
    },
    { handleChange, handleBlur, values, setFieldValue, errors, touched }
  ) => {
    if (type == "none") {
      return null;
    }
    if (name === undefined) {
      throw new Error(`Label ${label}, Type ${type} does not have name`);
    }
    const { alwaysEditable } = rest;
    let val = values[name];
    let err = errors[name];
    let tou = touched[name];
    if (name.includes(".")) {
      // split complex name into parts
      // educatieAnterioara.0.certificat -> educatieAnterioara[0].certificat
      const splits = name.split(".");
      val =
        values[splits[0]] &&
        values[splits[0]][splits[1]] &&
        values[splits[0]][splits[1]][splits[2]];
      err =
        errors[splits[0]] &&
        errors[splits[0]][splits[1]] &&
        errors[splits[0]][splits[1]][splits[2]];
      tou =
        touched[splits[0]] &&
        touched[splits[0]][splits[1]] &&
        touched[splits[0]][splits[1]][splits[2]];
    }
    switch (type) {
      case "smallText":
        return (
          <div key="unique-1" className="my-2">
            <Typography>{label}</Typography>
          </div>
        );
      case "alert":
        return (
          <div className="mt-8" key={name}>
            <Alert variant="outlined" severity="warning">
              {label}
            </Alert>
          </div>
        );
      case "createableSelectV2": {
        const opts = filterBy
          ? options
              .filter((c) => c[filterByName] === values[filterBy])
              .map((c) => c[optionName])
              .filter(onlyUnique)
          : options.map((c) => c[optionName]).filter(onlyUnique);
        return (
          <Autocomplete
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={opts}
            value={val}
            onChange={(event, newValue) => {
              setFieldValue(name, newValue);
              if (rest.clearOnSelect) {
                setFieldValue(rest.clearOnSelect, "");
              }
            }}
            filterOptions={(x, params) => {
              const filtered = filter(x, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push(params.inputValue);
              }

              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                required={required}
                error={!!err}
                helperText={!!err && err}
                FormHelperTextProps={{
                  error: true,
                }}
                touched={tou}
                {...params}
                label={label}
                margin="dense"
              />
            )}
          />
        );
      }
      case "notaGdpr":
        return (
          <div key="unique" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Typography>
              Notă: Am luat la cunoștință că Universitatea Tehnică de
              Construcții București colectează și prelucrează datele cu caracter
              personal ale persoanelor fizice care doresc să se înscrie la
              studii universitare în cadrul Universității, sau care sunt admise
              la studii universitare în cadrul Universității și asigură
              drepturile persoanelor vizate conform prevederilor Regulamentului
              (UE) 2016/679 cu modificările si completările ulterioare din
              legislația națională. Nota de informare privind prelucrarea
              datelor cu caracter personal pentru studenți este afișată public
              la secretariatele din cadrul Universității. Informații detaliate
              și actualizate referitoare la prelucrarea datelor cu caracter
              personal în cadrul Universității pot fi găsite si pe site-ul:
              www.utcb.ro. Responsabilul cu protecția datelor personale în
              cadrul Universității Tehnice de Construcții București poate fi
              contactat folosind datele de contact de pe site-ul: www.utcb.ro.
            </Typography>
          </div>
        );
      case "optiuniDistribuireV2": {
        const opts = options.map((opt) => ({
          value: opt._id,
          label: opt.nume,
        }));
        const chosenOptions = values.optiuniDistribuire;
        const computedOptions = opts.filter(
          (opt) => !chosenOptions.map((o) => o.value).includes(opt.value)
        );
        const hasMoreOptions = opts.length !== chosenOptions.length;
        const isNotRdp =
          !rest.isRdp || (rest.isRdp && chosenOptions.length !== 1);
        const { isColegiu } = rest;
        const reorder = (result) => {
          if (!result.destination) {
            return;
          }

          const items = ro(
            values.optiuniDistribuire,
            result.source.index,
            result.destination.index
          );

          setFieldValue("optiuniDistribuire", items);
        };
        const rItems = computedOptions.reduce((pv, cv) => {
          const splitLabel = cv.label.split(" | ");
          return [
            ...pv,
            {
              id: cv.value,
              value: cv.value,
              fullName: cv.label,
              label: `${splitLabel[2]} (${splitLabel[0].trim()})`,
              name: `${splitLabel[2]} (${splitLabel[0].trim()})`,
              tipFinantare: splitLabel[0],
              domeniu: splitLabel[1],
              program: splitLabel[2],
              facultate: splitLabel[3],
              selectable: true,
              onClick: (index) => () => {
                setFieldValue(`optiuniDistribuire.${index}`, {
                  id: cv.value,
                  value: cv.value,
                  label: cv.label,
                });
              },
            },
          ];
        }, []);
        const groupedByFacultyName = _.groupBy(
          rItems,
          (value) => value.facultate
        );
        const faculties = Object.keys(groupedByFacultyName);
        const test = faculties
          .map((faculty) => ({
            label: faculty,
            name: faculty.includes("An pregătitor de limba română")
              ? faculty
              : isColegiu
              ? `Colegiul ${faculty}`
              : `Facultatea de ${faculty}`,
            value: faculty,
            id: faculty,
            selectable: false,
            // disabled: true,
            children: groupedByFacultyName[faculty],
          }))
          .sort((a, b) => a.ordine > b.ordine);
        const test2 = {
          id: "root",
          name: "Alege/schimba optiunea",
          children: test,
        };
        return (
          <FieldArray
            key={name}
            name="optiuniDistribuire"
            render={(arrayHelpers) => (
              <div style={{ marginTop: "1rem" }}>
                {errors.optiuniDistribuire &&
                  values.optiuniDistribuire.length === 0 && (
                    <div className="mb-4">
                      <Alert severity="error" variant="outlined">
                        Trebuie sa adaugi minim o optiune de studiu
                      </Alert>
                    </div>
                  )}
                {false && hasMoreOptions && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => arrayHelpers.push({ id: v4() })}
                  >
                    Adauga optiuni
                  </Button>
                )}
                <DragAndDropSelect
                  arrayHelpers={arrayHelpers}
                  onDragEnd={reorder}
                  items={values.optiuniDistribuire}
                  setFieldValue={setFieldValue}
                  computedOptions={test2}
                  // computedOptions={computedOptions}
                />
                {hasMoreOptions && isNotRdp && (
                  // {values.optiuniDistribuire.length >= 1 && hasMoreOptions && (
                  <div className="mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => arrayHelpers.push({ id: v4() })}
                    >
                      <AddIcon className="mr-4" /> Adauga optiune
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        );
      }
      // case "optiuniDistribuire":
      //   const opts = options.map((opt) => ({
      //     value: opt._id,
      //     label: opt.nume,
      //   }));
      //   const chosenOptions = values.optiuniDistribuire;
      //   const computedOptions = opts.filter(
      //     (opt) => !chosenOptions.map((o) => o.value).includes(opt.value)
      //   );
      //   const hasMoreOptions = opts.length !== chosenOptions.length;
      //   const reorder = (result) => {
      //     if (!result.destination) {
      //       return;
      //     }

      //     const items = ro(
      //       values.optiuniDistribuire,
      //       result.source.index,
      //       result.destination.index
      //     );

      //     setFieldValue("optiuniDistribuire", items);
      //   };
      //   return (
      //     <FieldArray
      //       key={name}
      //       name="optiuniDistribuire"
      //       render={(arrayHelpers) => (
      //         <div style={{ marginTop: "1rem" }}>
      //           {hasMoreOptions && (
      //             <Button
      //               variant="contained"
      //               color="primary"
      //               onClick={() => arrayHelpers.push({ id: v4() })}
      //             >
      //               Adauga optiuni
      //             </Button>
      //           )}
      //           <DragAndDropSelect
      //             arrayHelpers={arrayHelpers}
      //             onDragEnd={reorder}
      //             items={values.optiuniDistribuire}
      //             setFieldValue={setFieldValue}
      //             computedOptions={computedOptions}
      //           />
      //           {values.optiuniDistribuire.length >= 1 && hasMoreOptions && (
      //             <IconButton
      //               variant="contained"
      //               color="primary"
      //               onClick={() => arrayHelpers.push({ id: v4() })}
      //             >
      //               <AddIcon />
      //             </IconButton>
      //           )}
      //         </div>
      //       )}
      //     />
      //   );
      case "text":
        if (val === null) {
          val = "";
        }
        return (
          !rest.hide && (
            <FormTextField
              fullWidth
              key={name}
              required={required}
              error={err}
              touched={tou}
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={val}
              name={name}
              label={label}
              disabled={disabled || isViewing}
              isViewing={isViewing}
              multiline={!!multiline}
              helperText={helperText}
              type="text"
              {...rest}
            />
          )
        );
      case "number":
        return (
          <FormTextField
            fullWidth
            key={name}
            required={required}
            error={errors[name]}
            touched={touched[name]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values[name]}
            name={name}
            margin="dense"
            label={label}
            disabled={!alwaysEditable && (disabled || isViewing)}
            isViewing={!alwaysEditable && isViewing}
            type="number"
            {...rest}
          />
        );
      case "textDelimiter":
        return (
          <div className="lvd-form-text-delimiter" key={label}>
            <Typography variant="h5">{label}</Typography>
            {captionText && (
              <Alert className="mt-2" variant="outlined" severity="info">
                {captionText}
              </Alert>
              // <Typography variant="body1">{captionText}</Typography>
            )}
          </div>
        );
      case "datepicker":
        return (
          <DatePicker
            margin="dense"
            required={required}
            disabled={isViewing}
            format="DD/MM/YYYY"
            key={name}
            fullWidth
            clearable
            openTo="year"
            views={["year", "month", "date"]}
            label={label}
            onChange={(date) => setFieldValue(name, date)}
            value={values[name]}
            name={name}
            error={!!errors[name] && !!touched[name]}
            helperText={!!errors[name] && !!touched[name] && errors[name]}
            {...rest}
          />
        );
      case "chips":
        return (
          <Chips
            key={name}
            readOnly={isViewing}
            required={required}
            value={values[name]}
            options={options}
            name={name}
            label={label}
            error={errors[name]}
            touched={touched[name]}
            onChange={setFieldValue}
            margin="dense"
            disabled={isViewing || disabled}
            {...rest}
          />
        );
      case "select":
        return (
          <Select
            disabled={isViewing || disabled}
            required={required}
            key={name}
            readOnly={isViewing}
            value={val}
            helperText={helperText}
            options={options}
            name={name}
            label={label}
            error={err}
            touched={tou}
            margin="dense"
            onChange={setFieldValue}
            {...rest}
          />
        );
      case "creatableSelect":
        return (
          <CreatableSelect
            margin="dense"
            disabled={isViewing || disabled}
            required={required}
            key={name}
            placeholder="Incepe sa scrii un nume..."
            addOption={addOption}
            readOnly={isViewing}
            value={values[name]}
            options={options}
            name={name}
            label={label}
            error={errors[name]}
            touched={touched[name]}
            onChange={setFieldValue}
            {...rest}
          />
        );
      case "radio":
        return (
          <FormControl
            component="fieldset"
            // className={classes.formControl}
          >
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={values[name]}
              onChange={handleChange}
            >
              {options.map((opt) => (
                <FormControlLabel
                  value={opt.value}
                  control={<Radio />}
                  label={opt.label}
                  key={opt.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "childrenWithPaper":
        return (
          <Paper className="p-4 mb-5" key={name}>
            {children.map((child) =>
              this.renderFields(
                isViewing,
                { ...child },
                {
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  errors,
                  touched,
                }
              )
            )}
          </Paper>
        );
      case "children":
        return (
          <div key={name}>
            <Grid spacing={2} container wrap="wrap">
              {children.map((child) => (
                <Grid xs={12} sm item key={child.name}>
                  {this.renderFields(
                    isViewing,
                    { ...child },
                    {
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      errors,
                      touched,
                    }
                  )}
                  {/* <FormTextField
                    type="number"
                    fullWidth
                    required={required}
                    key={child.name}
                    readOnly={isViewing}
                    name={child.name}
                    // style={{ marginRight: "5px" }}
                    margin="dense"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values[child.name]}
                    label={child.label}
                    touched={touched[child.name]}
                    error={errors[child.name]}
                  /> */}
                </Grid>
              ))}
            </Grid>
          </div>
        );
      case "studyOptionsModal":
        return (
          <>
            <Button onClick={this.handleOpenStudyOptionsModal}>{label}</Button>
            <Dialog
              open={this.state.isStudyOptionsModalOpen}
              fullWidth
              maxWidth="sm"
              onClose={this.handleCloseStudyOptionsModal}
            >
              <DialogContent>
                <StudyOptionsTable />
              </DialogContent>
            </Dialog>
          </>
        );
      case "checkbox":
        return (
          <FormControlLabel
            key={name}
            control={
              <Switch
                required={required}
                disabled={isViewing}
                checked={values[name]}
                onChange={(e, v) => setFieldValue(name, v)}
                value={name}
                name={name}
                color="primary"
              />
            }
            label={label}
          />
        );
      case "realCheckbox":
        return (
          <FormControl
            key={name}
            margin="dense"
            required
            error={!!errors[name] && !!touched[name]}
          >
            <FormControlLabel
              control={
                <Checkbox
                  required={required}
                  checked={values[name]}
                  disabled={isViewing}
                  onChange={(e, v) => setFieldValue(name, v)}
                  value={name}
                  name={name}
                  color="primary"
                />
              }
              label={label}
            />
            {!!errors[name] && !!touched[name] && (
              <FormHelperText error>{errors[name]}</FormHelperText>
            )}
          </FormControl>
        );
      case "multipleCheckbox":
        return (
          <FormControl component="fieldset" key={name} margin="dense" required>
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup row={isWidthUp("sm", this.props.width)}>
              {children.map((child) => (
                <FormControlLabel
                  key={child.name}
                  control={
                    <Checkbox
                      required={child.required}
                      checked={values[child.name]}
                      disabled={isViewing}
                      onChange={(e, v) => setFieldValue(child.name, v)}
                      value={values[child.name]}
                      name={child.name}
                      color="primary"
                    />
                  }
                  label={child.label}
                />
              ))}
            </FormGroup>
            {!!errors[name] && !!touched[name] && (
              <FormHelperText error>{errors[name]}</FormHelperText>
            )}
          </FormControl>
        );
      case "situatiaTaxelorPlatiteArray":
        return (
          <FieldArray
            name={name}
            key={name}
            render={(arrayHelpers) => (
              <div>
                {values[name].map((child, index) => (
                  <div key={name + index} style={{ display: "flex", flex: 1 }}>
                    <FormTextField
                      required={required}
                      // error={errors[name][index].suma}
                      // touched={touched[name][index].suma}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].suma}
                      name={`${name}.${index}.suma`}
                      label="Suma"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="number"
                      style={{ marginRight: "20px" }}
                      {...rest}
                    />
                    <FormTextField
                      required={required}
                      // error={errors[name][index].detaliiPlata}
                      // touched={touched[name][index].detaliiPlata}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].detaliiPlata}
                      name={`${name}.${index}.detaliiPlata`}
                      label="Detalii Plata"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="text"
                      {...rest}
                    />
                    <Button onClick={() => arrayHelpers.remove(index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      detaliiPlata: "",
                      suma: "",
                    })
                  }
                >
                  Adauga plata
                </Button>
              </div>
            )}
          />
        );
      case "dePlataTaxaCrediteRestanteArray":
        return (
          <FieldArray
            name={name}
            key={name}
            render={(arrayHelpers) => (
              <div>
                {values[name].map((child, index) => (
                  <div key={name + index} style={{ display: "flex", flex: 1 }}>
                    <FormTextField
                      required={required}
                      // error={errors[name][index].suma}
                      // touched={touched[name][index].suma}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].suma}
                      name={`${name}.${index}.suma`}
                      label="Suma"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="number"
                      style={{ marginRight: "20px" }}
                      {...rest}
                    />
                    <FormTextField
                      required={required}
                      // error={errors[name][index].detaliiPlata}
                      // touched={touched[name][index].detaliiPlata}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[name][index].detaliiPlata}
                      name={`${name}.${index}.detaliiPlata`}
                      label="Detalii Plata"
                      disabled={disabled || isViewing}
                      isViewing={isViewing}
                      type="text"
                      {...rest}
                    />
                    <Button onClick={() => arrayHelpers.remove(index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      detaliiPlata: "",
                      suma: "",
                    })
                  }
                >
                  Adauga plata
                </Button>
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      initialValues,
      inputs,
      validationSchema,
      isViewing,
      handleSubmit,
      saveButtonLabel,
      isLoading,
      enableForceEdit,
    } = this.props;
    return (
      <div>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          render={({
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched,
          }) => {
            return (
              <Form>
                <OnSubmitValidationError
                  callback={this.onSubmitValidationError}
                />
                {inputs.map((input) =>
                  this.renderFields(isViewing, input, {
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  })
                )}
                {this.props.renderCustomInputs({
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                })}
                <div className="lvd-spacer10" />
                {(!isViewing || enableForceEdit) && (
                  <Button
                    disabled={isLoading}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {saveButtonLabel || "Salveaza"}
                  </Button>
                )}
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  showSnackbar,
};

export default withWidth()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEditForm))
);
