import React, { Component } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DocumentTable from "../General/DocumentTable";
import {
  fetchTipuriDeDocumente,
  fetchDosarInscris,
  sendToValidation,
} from "../../actions";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";

yup.addMethod(yup.mixed, "sameAs", function a(ref, message) {
  return this.test("sameAs", message, function b(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});
class IncarcareDocumente extends Component {
  state = {
    dosar: { _id: "" },
  };

  async componentDidMount() {
    this.props.setIsLoading(1);
    await this.props.fetchDosarInscris();
    await this.props.fetchTipuriDeDocumente();
    this.props.setIsLoading(-1);
  }

  handleSendToValidationClick = async () => {
    await this.props.sendToValidation();
    this.props.handleNext();
  };

  render() {
    const { t, tipuriDeDocumente, dosar, isLoading } = this.props;
    const tipuriDoc = tipuriDeDocumente.inscrisi;
    return (
      <div>
        {/* <Alert variant="outlined" severity="info">
          Candidates must pay a file processing fee amounting to 75 EURO in the
          following bank account:
          <br />
          UNIVERSITATEA TEHNICA DE CONSTRUCTII BUCURESTI
          <br />
          C.I.F. 4266570
          <br />
          EURO, IBAN: RO76RNCB0073005630380005
          <br />
          Mention on the bank transfer: TAXĂ PROCESARE DOSAR ÎNSCRIERE
          <br />
          Attention!
          <br />
          When you make the payment the commission fee is not included in the
          payment for the file processing. The file processing fee is
          NONREFUNDABLE!
          <br />
          <br />
          For direct payment by bank card use the following{" "}
          <a
            href="https://secure.euplatesc.ro/hi/forms/utcb4.php"
            style={{
              color: "#81C534",
              textDecoration: "none",
            }}
            target="blank"
          >
            link
          </a>
          , and upload proof of payment received by email after making the
          payment to the &apos;Proof of payment for data processing&apos;
          document below
        </Alert>
        <div className="my-4">
          <Alert variant="outlined" severity="info">
            Les candidats doivent payer des frais de traitement de dossier d’un
            montant de 75 euros par virement bancaire sur le compte suivant:
            <br />
            UNIVERSITATEA TEHNICA DE CONSTRUCTII BUCURESTI
            <br />
            C.I.F. 4266570
            <br />
            EURO, IBAN: RO76RNCB0073005630380005
            <br />
            Mention à préciser pour le virement: TAXĂ PROCESARE DOSAR ÎNSCRIERE
            <br />
            Attention!
            <br />
            Les frais de commissions bancaires ne sont pas inclus dans le
            montant des frais de traitement de dossier. Par ailleurs, ces frais
            NE SONT PAS REMBOURSABLES!
            <br />
            <br />
            Pour le paiement direct par carte bancaire, utilisez le{" "}
            <a
              href="https://secure.euplatesc.ro/hi/forms/utcb4.php"
              style={{
                color: "#81C534",
                textDecoration: "none",
              }}
              target="blank"
            >
              lien
            </a>{" "}
            suivant, et dans le document &apos;Preuve de paiement pour le
            traitement des données&apos; téléchargez la preuve de paiement reçue
            par e-mail après avoir effectué le paiement
          </Alert>
        </div> */}
        <div className="my-4">
          <Alert variant="outlined" severity="info">
            Accepted formats: PDF / JPG / JPEG / PNG / DOC / DOCX (max
            15MB/file)
          </Alert>
        </div>
        {isLoading === 0 && (
          <div>
            <DocumentTable
              dosarId={dosar._id}
              documente={dosar.documente || []}
              tipuriDocumente={tipuriDoc}
              name="inscrisi"
              type="registration"
            />
            <div style={{ paddingTop: "24px" }}>
              <Button
                disabled={!dosar.areToateDocumenteleObligatorii}
                onClick={this.handleSendToValidationClick}
                variant="contained"
                color="primary"
              >
                {t.sendToValidation}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  dosar: getDosarInscris(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
});

const mapDispatchToProps = {
  fetchTipuriDeDocumente,
  fetchDosarInscris,
  sendToValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncarcareDocumente);
