import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Typography,
  LinearProgress,
  DialogActions,
  TextField,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  uploadScrisoare,
  showSnackbar,
  removeScrisoare,
  downloadScrisoare,
  rejectDosar,
  sendNotification,
} from "../../../actions";

class Scrisoare extends Component {
  scrisoareRef = null;

  state = {
    isLoading: false,
    isRejectDialogOpen: false,
  };

  handleUploadScrisoare = async (scrisoare) => {
    this.setState({ isLoading: true });
    await this.props.uploadScrisoare({
      dosarId: this.props.dosar._id,
      doc: scrisoare,
    });
    this.setState({ isLoading: false });
  };

  handleRemoveScrisoare = async () => {
    this.setState({ isLoading: true });
    await this.props.removeScrisoare({
      dosarId: this.props.dosar._id,
    });
    this.setState({ isLoading: false });
  };

  handleDownloadScrisoare = async () => {
    this.setState({ isLoading: true });
    this.props.downloadScrisoare({
      dosarId: this.props.dosar._id,
    });
    this.setState({ isLoading: false });
  };

  handleSendNotification = async () => {
    this.props.sendNotification({
      dosarId: this.props.dosar._id,
    });
  };

  handleRejectDosar = async (values) => {
    this.props.rejectDosar({
      dosarId: this.props.dosar._id,
      motiv: values.motiv,
    });
  };

  handleCloseRejectDialog = () => {
    this.setState({
      isRejectDialogOpen: false,
    });
  };

  handleOpenRejectDialog = () => {
    this.setState({
      isRejectDialogOpen: true,
    });
  };

  render() {
    const { dosar } = this.props;
    const { isLoading, isRejectDialogOpen } = this.state;
    if (dosar.status === "respinsFinal") {
      return (
        <div>
          <Typography>Candidatul a fost respins</Typography>
        </div>
      );
    }
    return (
      <div>
        {isLoading && (
          <LinearProgress style={{ top: "-13px" }} color="primary" />
        )}
        <Grid>
          {dosar.scrisoare && (
            <>
              <Grid xs="auto">{dosar.scrisoare.originalName}</Grid>
              <Grid xs="auto" style={{ marginTop: "24px" }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  color="secondary"
                  onClick={this.handleRemoveScrisoare}
                  style={{ marginRight: "12px" }}
                >
                  Sterge scrisoare
                </Button>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={this.handleDownloadScrisoare}
                >
                  Descarca scrisoare
                </Button>
              </Grid>
            </>
          )}
          {!dosar.scrisoare && (
            <Grid xs="auto">
              <Button
                onClick={() => {
                  this.scrisoareRef.open();
                }}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                Incarca scrisoare
              </Button>
              <Dropzone
                multiple={false}
                ref={(node) => {
                  this.scrisoareRef = node;
                }}
                style={{ display: "none" }}
                onDrop={(accepted, rejected) => {
                  this.handleUploadScrisoare(accepted[0]);
                }}
              >
                <p>Drop files here.</p>
              </Dropzone>
              <Button
                disabled={isLoading}
                onClick={this.handleOpenRejectDialog}
                variant="contained"
                color="secondary"
                style={{ marginLeft: "12px" }}
              >
                Respinge dosar
              </Button>
            </Grid>
          )}
        </Grid>
        {dosar.scrisoare && (
          <div style={{ marginTop: "24px" }}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={this.handleSendNotification}
              color="primary"
            >
              Trimite notificare
            </Button>
          </div>
        )}
        <Formik
          onSubmit={this.handleRejectDosar}
          initialValues={{ motiv: "" }}
          validationSchema={yup.object().shape({
            motiv: yup.string().required("Camp obligatoriu"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            handleSubmit,
          }) => (
            <Dialog
              open={isRejectDialogOpen}
              onClose={this.handleCloseRejectDialog}
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Form>
                  <TextField
                    label="Motiv respingere"
                    fullWidth
                    name="motiv"
                    error={!!errors.motiv && !!touched.motiv}
                    FormHelperTextProps={{ error: true }}
                    helperText={
                      !!errors.motiv && !!touched.motiv && errors.motiv
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.motivRespingere}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.handleCloseRejectDialog}
                >
                  Renunta
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Respinge
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  uploadScrisoare,
  showSnackbar,
  removeScrisoare,
  downloadScrisoare,
  rejectDosar,
  sendNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scrisoare);
