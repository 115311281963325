import judeteLocalitati from "../../../judeteLocalitati";
import licee from "../../../licee";

/* eslint-disable no-underscore-dangle */
export default function (t, props, candidatType) {
  return [
    {
      name: "cwp01",
      type: "childrenWithPaper",
      children: [
        {
          label: t.identificationData,
          name: "a01",
          type: "textDelimiter",
        },
        {
          label: "Data trimitere validare",
          name: "dataTrimitereValidare",
          type: "datepicker",
        },
        {
          type: "children",
          name: "a02",
          children: [
            {
              name: "nume",
              label: t.familyName,
              type: "text",
              required: true,
              helperText: t.familyNameExplanation,
            },
            {
              name: "prenume",
              label: t.firstName,
              type: "text",
              required: true,
            },
            {
              name: "initialaTata",
              label: "Initiala tatalui",
              type: "text",
              required: true,
            },
          ],
        },
        {
          type: "children",
          name: "cnpAndSex",
          children: [
            {
              type: "text",
              label: "CNP",
              name: "cnp",
              required: true,
            },
            {
              name: "sex",
              label: "Sex",
              type: "select",
              options: [
                {
                  label: "M",
                  value: "masculin",
                },
                {
                  label: "F",
                  value: "feminin",
                },
              ],
              required: true,
            },
            {
              name: "nationalitate",
              label: t.nationality,
              type: "text",
              required: true,
            },
          ],
        },
        {
          type: "children",
          name: "c01",
          children: [
            {
              name: "dataNasterii",
              label: t.dateOfBirth,
              type: "datepicker",
              required: true,
            },
            {
              name: "localitateaNasterii",
              label: t.placeOfBirth,
              required: true,
              type: "text",
            },
            {
              type: "text",
              label: "Numar telefon",
              name: "nrTelefon",
              required: true,
            },
          ],
        },
        {
          type: "children",
          name: "a03",
          children: [
            {
              type: "text",
              name: "carteIdentitateSerie",
              label: "Serie carte de identitate",
              required: true,
            },
            {
              type: "text",
              name: "carteIdentitateNumar",
              label: "Nr carte de identitate",
              required: true,
            },
            {
              type: "text",
              name: "carteIdentitateEliberatDe",
              label: "Eliberata de",
              required: true,
            },
          ],
        },
        {
          type: "children",
          name: "a04",
          children: [
            {
              type: "datepicker",
              name: "carteIdentitateEliberatData",
              label: "Eliberata la data",
              required: true,
            },
            {
              type: "datepicker",
              name: "carteIdentitateExpiraLaData",
              label: "Expira la data",
              required: true,
            },
          ],
        },
      ],
    },
    {
      type: "childrenWithPaper",
      name: "cwp02",
      children: [
        {
          type: "textDelimiter",
          name: "domiciliuLabel",
          label: "Domiciliu",
        },
        {
          type: "children",
          name: "a05",
          children: [
            {
              name: "domiciliuStabilJudetSector",
              label: "Judet/Sector",
              type: "createableSelectV2",
              options: judeteLocalitati,
              optionName: "judet",
              clearOnSelect: "domiciliuStabilLocalitate",
              required: true,
            },
            {
              name: "domiciliuStabilLocalitate",
              label: "Localitate",
              type: "createableSelectV2",
              options: judeteLocalitati,
              optionName: "localitate",
              filterByName: "judet",
              filterBy: "domiciliuStabilJudetSector",
              required: true,
            },
            {
              name: "domiciliuStabilStrada",
              label: "Strada",
              type: "text",
              required: true,
            },
          ],
        },
        {
          type: "children",
          name: "a06",
          children: [
            {
              name: "domiciliuStabilNr",
              label: "Numar",
              type: "text",
              required: true,
            },
            {
              name: "domiciliuStabilBloc",
              label: "Bloc",
              type: "text",
            },
            {
              name: "domiciliuStabilEtaj",
              label: "Etaj",
              type: "text",
            },
          ],
        },
        {
          type: "children",
          name: "c02",
          children: [
            {
              name: "domiciliuStabilScara",
              label: "Scara",
              type: "text",
            },
            {
              name: "domiciliuStabilApartament",
              label: "Apartament",
              type: "text",
            },
            {
              name: "domiciliuStabilCodPostal",
              label: "Cod postal",
              type: "text",
            },
          ],
        },
      ],
    },
    candidatType.dppd
      ? { type: "none" }
      : {
          type: "childrenWithPaper",
          name: "cwp03",
          children: [
            {
              label: "Apartinator legal",
              name: "td02",
              type: "textDelimiter",
            },
            {
              type: "children",
              name: "a10",
              children: [
                {
                  name: "prenumeTata",
                  label: "Nume Prenume Tata/sustinator legal",
                  type: "text",
                  required: true,
                },
                {
                  name: "prenumeMama",
                  label: "Nume Prenume Mama/sustinator legal",
                  type: "text",
                  required: true,
                },
                {
                  type: "text",
                  label: "Adresa parintilor sau a sustinatorilor legali",
                  name: "adresaParinti",
                  required: true,
                },
              ],
            },
          ],
        },
    {
      type: "childrenWithPaper",
      name: "cwp04",
      children: [
        {
          label: "Studii",
          name: "td02",
          type: "textDelimiter",
        },
        candidatType.registerFor === "licenta"
          ? {
              type: "children",
              name: "a193",
              children: [
                {
                  name: "liceuAbsolvitLocalitate",
                  label: "Localitate liceu",
                  type: "createableSelectV2",
                  required: true,
                  options: licee,
                  clearOnSelect: "liceuAbsolvitNume",
                  optionName: "localitate",
                },
                {
                  name: "liceuAbsolvitNume",
                  label: "Liceu absolvit",
                  type: "createableSelectV2",
                  required: true,
                  options: licee,
                  optionName: "nume",
                  filterByName: "localitate",
                  filterBy: "liceuAbsolvitLocalitate",
                },
                {
                  name: "liceuAbsolvitSectie",
                  label: "Sectie",
                  type: "text",
                  required: true,
                },
                {
                  name: "liceuAbsolvitAn",
                  label: "An",
                  type: "text",
                  required: true,
                },
              ],
            }
          : { type: "none" },
        {
          type: "children",
          name: "a07",
          children: [
            {
              name: "facultateAbsolvitaNume",
              label: "Facultate absolvita",
              type: "text",
              required: candidatType.registerFor === "master",
            },
            {
              name: "facultateAbsolvitaLocalitate",
              label: "Localitate",
              type: "text",
              required: candidatType.registerFor === "master",
            },
            {
              name: "facultateAbsolvitaAn",
              label: "An",
              type: "text",
              required: candidatType.registerFor === "master",
            },
            {
              name: "facultateAbsolvitaTipFinantare",
              label: "Tip finantare",
              type: "select",
              options: [
                {
                  label: "Buget",
                  value: "buget",
                },
                {
                  label: "Taxa",
                  value: "taxa",
                },
              ],
              required: candidatType.registerFor === "master",
            },
          ],
        },
        candidatType.registerFor === "licenta"
          ? {
              type: "children",
              name: "a08",
              children: [
                {
                  name: "facultateStudentNume",
                  label: "Am fost student la facultatea",
                  type: "text",
                },
                {
                  name: "facultateStudentLocalitate",
                  label: "Localitate",
                  type: "text",
                },
                {
                  name: "facultateStudentAnStudii",
                  label: "An",
                  type: "text",
                },
                {
                  name: "facultateStudentTipFinantare",
                  label: "Tip finantare",
                  type: "select",
                  options: [
                    {
                      label: "Buget",
                      value: "buget",
                    },
                    {
                      label: "Taxa",
                      value: "taxa",
                    },
                  ],
                },
              ],
            }
          : {
              type: "children",
              name: "a08",
              children: [
                {
                  name: "masterAbsolvitNume",
                  label:
                    "Am mai absolvit Masterat/Studii aprofundate (după caz)",
                  type: "text",
                },
                {
                  name: "masterAbsolvitLocalitate",
                  label: "Localitate",
                  type: "text",
                },
                {
                  name: "masterAbsolvitAn",
                  label: "An",
                  type: "text",
                },
                {
                  name: "masterAbsolvitTipFinantare",
                  label: "Tip finantare",
                  type: "select",
                  options: [
                    {
                      label: "Buget",
                      value: "buget",
                    },
                    {
                      label: "Taxa",
                      value: "taxa",
                    },
                  ],
                },
              ],
            },
        candidatType.registerFor === "master"
          ? {
              name: "a100",
              type: "children",
              children: [
                {
                  name: "masterStudentNume",
                  label:
                    "Am fost student la Masterat/Studii aprofundate (după caz)",
                  type: "text",
                },
                {
                  name: "masterStudentLocalitate",
                  label: "Localitate",
                  type: "text",
                },
                {
                  name: "masterStudentPerioada",
                  label: "An",
                  type: "text",
                },
                {
                  name: "masterStudentTipFinantare",
                  label: "Tip finantare",
                  type: "select",
                  options: [
                    {
                      label: "Buget",
                      value: "buget",
                    },
                    {
                      label: "Taxa",
                      value: "taxa",
                    },
                  ],
                },
              ],
            }
          : { type: "none" },
        candidatType.registerFor === "licenta"
          ? {
              type: "children",
              name: "a09",
              children: [
                {
                  name: "facultateCurentNume",
                  label: "Sunt student si la facultatea",
                  type: "text",
                },
                {
                  name: "facultateCurentLocalitate",
                  label: "Localitate",
                  type: "text",
                },
                {
                  name: "facultateCurentAnStudii",
                  label: "An",
                  type: "text",
                },
                {
                  name: "facultateCurentTipFinantare",
                  label: "Tip finantare",
                  type: "select",
                  options: [
                    {
                      label: "Buget",
                      value: "buget",
                    },
                    {
                      label: "Taxa",
                      value: "taxa",
                    },
                  ],
                },
              ],
            }
          : {
              type: "children",
              name: "a09",
              children: [
                {
                  name: "masterCurentNume",
                  label:
                    "Sunt student și la Masterat/Studii aprofundate (după caz)",
                  type: "text",
                },
                {
                  name: "masterCurentLocalitate",
                  label: "Localitate",
                  type: "text",
                },
                {
                  name: "masterCurentAn",
                  label: "An",
                  type: "text",
                },
                {
                  name: "masterCurentTipFinantare",
                  label: "Tip finantare",
                  type: "select",
                  options: [
                    {
                      label: "Buget",
                      value: "buget",
                    },
                    {
                      label: "Taxa",
                      value: "taxa",
                    },
                  ],
                },
              ],
            },
      ],
    },
    candidatType.dppd
      ? { type: "none" }
      : {
          type: "childrenWithPaper",
          name: "cwp05",
          children: [
            candidatType.translationAndInterpretation
              ? {
                  label: "Alege cea de-a doua limbă de studiu",
                  captionText:
                    "Scrie în ordinea pe care o dorești cele trei opțiuni (Franceză, Germană, Spaniolă)",
                  name: "td02",
                  type: "textDelimiter",
                }
              : {
                  label: "Competente lingvistice",
                  captionText:
                    "Aceste campuri trebuie completate doar in cazul in care optati pentru studii intr-o limba straina",
                  name: "td02",
                  type: "textDelimiter",
                },
            candidatType.translationAndInterpretation
              ? {
                  name: "ordineaLimbilorDeStudiu",
                  label: "Ordinea limbilor de studiu",
                  type: "text",
                  required: true,
                }
              : {
                  type: "children",
                  name: "a11",
                  children: [
                    {
                      type: "text",
                      label: "Tip certificat de competenta lingvistica",
                      name: "certificatCompetentaLingvisticaTip",
                    },
                    {
                      type: "text",
                      label: "Limba certificat de competenta lingvistica",
                      name: "certificatCompetentaLingvisticaLimba",
                    },
                  ],
                },
            candidatType.translationAndInterpretation
              ? { type: "none" }
              : {
                  name: "a12",
                  type: "children",
                  children: [
                    {
                      type: "select",
                      label: "Engleza",
                      name: "engleza",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Franceza",
                      name: "franceza",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Germana",
                      name: "germana",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                  ],
                },
            candidatType.translationAndInterpretation
              ? { type: "none" }
              : {
                  name: "a13",
                  type: "children",
                  children: [
                    {
                      type: "select",
                      label: "Spaniola",
                      name: "spaniola",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Rusa",
                      name: "rusa",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Italiana",
                      name: "italiana",
                      options: [
                        {
                          label: "Incepator",
                          value: 1,
                        },
                        {
                          label: "Mediu",
                          value: 2,
                        },
                        {
                          label: "Avansat",
                          value: 3,
                        },
                      ],
                    },
                  ],
                },
            candidatType.translationAndInterpretation
              ? {
                  label: "Alege nivelul de cunoaștere",
                  name: "td03",
                  type: "textDelimiter",
                }
              : { type: "none" },
            candidatType.translationAndInterpretation
              ? {
                  type: "children",
                  name: "tsi1",
                  children: [
                    {
                      type: "select",
                      label: "Franceză",
                      name: "franceza",
                      required: true,
                      options: [
                        {
                          label: "A1",
                          value: 1,
                        },
                        {
                          label: "A2",
                          value: 2,
                        },
                        {
                          label: "B1",
                          value: 3,
                        },
                        {
                          label: "B2",
                          value: 4,
                        },
                        {
                          label: "C1",
                          value: 5,
                        },
                        {
                          label: "C2",
                          value: 6,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Germană",
                      name: "germana",
                      required: true,
                      options: [
                        {
                          label: "A1",
                          value: 1,
                        },
                        {
                          label: "A2",
                          value: 2,
                        },
                        {
                          label: "B1",
                          value: 3,
                        },
                        {
                          label: "B2",
                          value: 4,
                        },
                        {
                          label: "C1",
                          value: 5,
                        },
                        {
                          label: "C2",
                          value: 6,
                        },
                      ],
                    },
                    {
                      type: "select",
                      label: "Spaniolă",
                      name: "spaniola",
                      required: true,
                      options: [
                        {
                          label: "A1",
                          value: 1,
                        },
                        {
                          label: "A2",
                          value: 2,
                        },
                        {
                          label: "B1",
                          value: 3,
                        },
                        {
                          label: "B2",
                          value: 4,
                        },
                        {
                          label: "C1",
                          value: 5,
                        },
                        {
                          label: "C2",
                          value: 6,
                        },
                      ],
                    },
                  ],
                }
              : { type: "none" },
          ],
        },
    {
      type: "childrenWithPaper",
      name: "cwp06",
      children: [
        candidatType.translationAndInterpretation
          ? {
              label: "Note pentru admitere",
              name: "td03",
              type: "textDelimiter",
            }
          : {
              label: "Note pentru admitere",
              //       captionText:
              //         candidatType.registerFor === "licenta"
              //           ? `Alege media care știi că te avantajează în fața altor candidați!
              // Daca vei completa proba la alegere, media de admitere va fi 50% nota probei + 50% media generala de la Bac.
              // Daca vei completa doar media generala Bac, aceasta va fi 100% media de admitere.`
              //           : `Vom folosi pentru calculul mediei și nota eseului`,
              name: "td03",
              type: "textDelimiter",
            },
        candidatType.registerFor === "master"
          ? {
              type: "children",
              name: "mediiMaster",
              children: [
                {
                  type: "text",
                  name: "mediaAnilorDeStudiuLicenta",
                  label: "Media anilor de studii universitare de licenta",
                  required: true,
                },
                {
                  type: "text",
                  name: "mediaExamenLicenta",
                  label: "Media examenului de licenta",
                  required: true,
                },
              ],
            }
          : candidatType.colegiu
          ? {
              type: "text",
              name: "medieGeneralaBacalaureat",
              label: "Media generala de absolvire a studiilor liceale",
              required: true,
            }
          : {
              type: "text",
              name: "medieGeneralaBacalaureat",
              label: "Media generala a examenului de bacalaureat",
              required: true,
            },
        // eslint-disable-next-line no-nested-ternary

        (candidatType.registerFor === "licenta" && !candidatType.colegiu) ||
        (candidatType.translationAndInterpretation &&
          candidatType.registerFor === "licenta")
          ? {
              type: "children",
              name: "a14",
              children: [
                {
                  type: "select",
                  name: "probaAlegere",
                  label: "Proba la alegere",
                  options: [
                    {
                      label: "Nu include nota probei la alegere",
                      value: "doarMediaGenerala",
                    },
                    ...(candidatType.translationAndInterpretation &&
                    candidatType.registerFor === "licenta"
                      ? [
                          {
                            label: "Romana",
                            value: "romana",
                          },
                        ]
                      : [
                          {
                            label: "Matematica",
                            value: "matematica",
                          },
                          {
                            label: "Fizica",
                            value: "fizica",
                          },
                          {
                            label: "Informatica",
                            value: "informatica",
                          },
                          {
                            label: "Chimie",
                            value: "chimie",
                          },
                          {
                            label: "Economie",
                            value: "economie",
                          },
                        ]),
                  ],
                },
                {
                  type: "text",
                  name: "notaProbaAlegere",
                  label: "Nota la proba la alegere",
                },
              ],
            }
          : { type: "none", name: "n1" },
        candidatType.registerFor === "master"
          ? {
              type: "number",
              name: "notaEseu",
              label: "Nota Eseu",
              alwaysEditable: true,
            }
          : { type: "none" },
        {
          type: "number",
          name: "medieAdmitere",
          label: "Medie Admitere",
          editable: false,
          disabled: true,
        },
        {
          type: "realCheckbox",
          name: "areGradesVerified",
          label: "Autentic",
        },
        props.role === "administrator"
          ? {
              type: "select",
              name: "sesiune",
              label: "Sesiune",
              options: [
                {
                  label: "Sesiunea 1",
                  value: "sesiune1",
                },
                {
                  label: "Sesiunea 2",
                  value: "sesiune2",
                },
              ],
            }
          : { type: "none" },
        props.role === "administrator"
          ? {
              type: "optiuniDistribuireV2",
              name: "optiuniDistribuireV2",
              isColegiu: candidatType.colegiu,
              options: candidatType.translationAndInterpretation
                ? candidatType.registerFor === "master"
                  ? props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretareM
                  : props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretare
                : candidatType.registerFor === "licenta"
                ? candidatType.rdp
                  ? props.nomenclatoare.optiuniStudiiRo.rdp
                  : candidatType.colegiu
                  ? props.nomenclatoare.optiuniStudiiRo.colegiu
                  : props.nomenclatoare.optiuniStudiiRo.licenta
                : candidatType.dppd
                ? props.nomenclatoare.optiuniStudiiRo.dppd
                : candidatType.registerFor === "master" && candidatType.rdp
                ? props.nomenclatoare.optiuniStudiiRo.rdpM
                : props.nomenclatoare.optiuniStudiiRo.master,
            }
          : { type: "none" },
      ],
    },
    // {
    //   type: "optiuniDistribuire",
    //   name: "optiuniDistribuire",
    //   options: candidatType.translationAndInterpretation
    //     ? props.nomenclatoare.optiuniStudiiRo.traducereSiInterpretare
    //     : candidatType.registerFor === "licenta"
    //     ? props.nomenclatoare.optiuniStudiiRo.licenta
    //     : props.nomenclatoare.optiuniStudiiRo.master,
    // },

    // {
    //   type: "realCheckbox",
    //   name: "hasDeliveredDiploma",
    //   label: "A adus diploma in original",
    // },
  ];
}
