import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Paper,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { Form, Formik } from "formik";
import { addUtilizator } from "../../actions";

class AddUtilizator extends Component {
  static propTypes = { addUtilizator: PropTypes.func.isRequired };

  handleSubmit = async (values) => {
    await this.props.addUtilizator(values);
    this.props.history.push("/utilizatori");
  };

  handleBackClick = () => {
    this.props.history.push("/utilizatori");
  };

  render() {
    return (
      <div>
        <div className="lvd-spacer20" />
        <div className="lvd-control-buttons">
          <Button
            variant="contained"
            color="primary"
            className="lvd-button-green-text"
            onClick={this.handleBackClick}
          >
            <ArrowBackIos />
            Inapoi
          </Button>
        </div>
        <Paper className="lvd-container-form">
          <Formik
            onSubmit={this.handleSubmit}
            enableReinitialize
            initialValues={{ email: "", role: "" }}
            render={({ handleChange, handleBlur, errors, values, touched }) => (
              <Form>
                <TextField
                  fullWidth
                  margin="dense"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!touched.email && !!errors.email}
                  label="Email"
                />
                <FormControl fullWidth margin="dense">
                  <InputLabel>Rol</InputLabel>
                  <Select
                    value={values.role}
                    name="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.role && !!errors.role}
                    label="Rol"
                  >
                    {/* <MenuItem value="others">Altii</MenuItem> */}
                    <MenuItem value="utilizator">Utilizator</MenuItem>
                    {/* <MenuItem value="masterand">Masterand</MenuItem>
        <MenuItem value="secretara">Secretara</MenuItem> */}
                    <MenuItem value="secretara">Comisie de examinare</MenuItem>
                    <MenuItem value="administrator">Administrator</MenuItem>
                  </Select>
                </FormControl>
                <div className="lvd-spacer10" />
                <Button type="submit" variant="contained" color="primary">
                  Salveaza
                </Button>
              </Form>
            )}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addUtilizator })(AddUtilizator);
