import React, { Component } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  withMobileDialog,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import request from "superagent";
import {
  fetchCandidati,
  fetchNomenclatoare,
  searchCandidati,
  deleteCandidat,
  showSnackbar
} from "../../actions";
import { serverUrl } from "../../utils";
import {
  getCandidati,
  getIsFetchingCandidati,
  getCandidatiQuery,
  getCandidatiPageInfo
} from "../../reducers/candidatiReducer";
import TableWithFilter from "../General/TableWithFilter";
import { getToken } from "../../reducers/authReducer";

class CandidatiList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fetchCandidati: PropTypes.func.isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    searchCandidati: PropTypes.func.isRequired,
    deleteCandidat: PropTypes.func.isRequired
  };
  state = {
    filterConfig: []
  };
  static getDerivedStateFromProps = (props, state) => ({
    filterConfig: [
      {
        id: "email",
        label: "Email",
        type: "string",
        input: "text"
      },
      {
        id: "nume",
        label: "Nume",
        type: "string",
        input: "text"
      },
      {
        id: "prenume",
        label: "Prenume",
        type: "string",
        input: "text"
      },
      {
        id: "cnp",
        label: "CNP",
        type: "string",
        input: "text"
      },
      {
        id: "titulatura",
        label: "Titulatura",
        type: "string",
        input: "text"
      },
      {
        id: "anObtinereTitluDoctor",
        label: "An Obtinere Titlu Doctor",
        type: "string",
        input: "text"
      },
      {
        id: "facultate.label",
        label: "Facultate",
        type: "string",
        input: "select",
        values: props.nomenclatoare.facultati.map(n => n.nume)
      },
      {
        id: "departament.label",
        label: "Departament",
        type: "string",
        input: "select",
        values: props.nomenclatoare.departamente.map(n => n.nume)
      },
      {
        id: "domeniuDoctorat.label",
        label: "Domeniu Doctorat",
        type: "string",
        input: "select",
        values: props.nomenclatoare.domenii.map(n => n.nume)
      },
      {
        id: "specializare.label",
        label: "Specializare",
        type: "string",
        input: "select",
        values: props.nomenclatoare.specializari.map(n => n.nume)
      },
      {
        id: "telefon",
        label: "Telefon",
        type: "string",
        input: "text"
      },
      {
        id: "linkScopus",
        label: "Link Scopus",
        type: "string",
        input: "text"
      },
      {
        id: "linkGoogleScholar",
        label: "Link Google Scholar",
        type: "string",
        input: "text"
      },
      {
        id: "linkISIWebOfScience",
        label: "Link ISI Web Of Science",
        type: "string",
        input: "text"
      },
      {
        id: "linkResearchGate",
        label: "Link Research Gate",
        type: "string",
        input: "text"
      }
    ]
  });
  componentDidMount() {
    this.props.fetchNomenclatoare();
  }
  handleExport = async query => {
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/candidati`)
        .set("Authorization", `Bearer ${jwt}`)
        .send(query)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleExportDosar = async userId => {
    const { jwt } = this.props;
    try {
      const res = await request
        .get(`${serverUrl}/export/candidati/${userId}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };
  handleDelete = dosarId => {
    this.props.deleteCandidat(dosarId);
  };
  render() {
    const {
      items,
      isFetching,
      isFetchingFilters,
      query,
      pageInfo
    } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        Header: "Optiuni",
        width: 65,
        resizable: false,
        sortable: false,
        accessor: row => (
          <React.Fragment>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={event => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className="lvd-options-container"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={onClose}
            >
              <MenuItem onClick={handleViewClick}>Vizualizeaza</MenuItem>
              <MenuItem onClick={handleEditClick}>Editeaza</MenuItem>
              <MenuItem onClick={handleExportDosar}>Exporta</MenuItem>
              <MenuItem onClick={handleDeleteClick}>Sterge</MenuItem>
            </Menu>
          </React.Fragment>
        )
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false
      },
      {
        accessor: "email",
        Header: "Email",
        show: true
      },
      {
        accessor: "nume",
        Header: "Nume",
        show: true
      },
      {
        accessor: "prenume",
        Header: "Prenume",
        show: true
      },
      {
        accessor: "cnp",
        Header: "CNP",
        show: true
      },
      {
        accessor: "titulatura",
        Header: "Titulatura",
        show: true
      },
      {
        accessor: "anObtinereTitluDoctor",
        Header: "An Obtinere Titlu Doctor",
        show: true
      },
      {
        accessor: "facultate.label",
        Header: "Facultate"
      },
      {
        accessor: "departament.label",
        Header: "Departament"
      },
      {
        accessor: "domeniuDoctorat.label",
        Header: "Domeniu Doctorat",
        show: true
      },
      {
        accessor: "specializare.label",
        Header: "Specializare",
        show: true
      },
      {
        accessor: "domeniuDoctoratSolicitat.label",
        Header: "Domeniu Doctorat Solicitat",
        show: true
      },
      {
        id: "specializariDoctoratSolicitate",
        accessor: ({ specializariDoctoratSolicitate }) =>
          specializariDoctoratSolicitate &&
          specializariDoctoratSolicitate.length > 0
            ? specializariDoctoratSolicitate.reduce((pv, cv) => {
                if (pv === "") return `${cv.label}`;
                return `${pv}, ${cv.label}`;
              }, "")
            : "",
        Header: "Specializare Doctorat Solicitata",
        show: true
      },
      {
        accessor: "telefon",
        Header: "Telefon"
      },
      {
        accessor: "linkScopus",
        Header: "Link Scopus"
      },
      {
        accessor: "linkGoogleScholar",
        Header: "Link Google Scholar"
      },
      {
        accessor: "linkISIWebOfScience",
        Header: "Link ISI Web Of Science"
      },
      {
        accessor: "linkResearchGate",
        Header: "Link Research Gate"
      }
    ];
    return (
      <TableWithFilter
        name="candidati"
        isFetching={isFetching}
        query={query}
        pageInfo={pageInfo}
        isFetchingFilters={isFetchingFilters}
        addButtonText="Adauga Candidat"
        baseColumns={baseColumns}
        filterConfig={filterConfig}
        fetchItems={this.props.searchCandidati}
        items={items}
        applyFilter={this.props.searchCandidati}
        onExport={this.handleExport}
        onDelete={this.handleDelete}
        onExportDosar={this.handleExportDosar}
        menuShowView
        menuShowExport
        menuShowEdit
        menuShowDelete
      />
    );
  }
}
const mapStateToProps = state => ({
  items: getCandidati(state),
  nomenclatoare: state.nomenclatoare,
  isFetchingFilters: state.nomenclatoare.isFetching,
  isFetching: getIsFetchingCandidati(state),
  jwt: getToken(state),
  query: getCandidatiQuery(state),
  pageInfo: getCandidatiPageInfo(state)
});

export default withMobileDialog()(
  connect(
    mapStateToProps,
    {
      fetchCandidati,
      fetchNomenclatoare,
      searchCandidati,
      deleteCandidat,
      showSnackbar
    }
  )(CandidatiList)
);
