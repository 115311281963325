import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  withMobileDialog,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { uniq, isArray, sortBy } from "lodash";
import FileSaver from "file-saver";
import request from "superagent";
import moment from "moment";

import {
  fetchInscrisi,
  searchInscrisi,
  deleteInscris,
} from "../../../actions/inscrisi-ro";
import {
  fetchConducatori,
  fetchNomenclatoare,
  showSnackbar,
} from "../../../actions";
import {
  getInscrisi,
  getInscrisiQuery,
  getIsFetchingInscrisi,
  getInscrisiPageInfo,
} from "../../../reducers/inscrisiReducer";
import { serverUrl } from "../../../utils";
import { getRole, getToken } from "../../../reducers/authReducer";
import TableWithFilter from "../../General/TableWithFilter";
import { getConducatori } from "../../../reducers/conducatoriReducer";
import { getReadableStatusRo } from "../../../utils/helpers";

class InscrisiList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fetchInscrisi: PropTypes.func.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchConducatori: PropTypes.func.isRequired,
    searchInscrisi: PropTypes.func.isRequired,
    deleteInscris: PropTypes.func.isRequired,
  };

  state = {
    filterconfig: [],
  };

  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchConducatori();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  static getDerivedStateFromProps = (props, state) => {
    const safeOpt =
      props.nomenclatoare &&
      props.nomenclatoare.optiuniStudiiRo &&
      isArray(props.nomenclatoare.optiuniStudiiRo)
        ? props.nomenclatoare.optiuniStudiiRo
        : [];
    let optLicenta = [];
    let optMaster = [];
    if (safeOpt.length > 0) {
      optLicenta = safeOpt
        .filter((opt) => opt.nume.includes("LICENTA"))
        .map((opt) => ({
          ...opt,
          nume: opt.nume.split("|").slice(1).join("|"),
        }));
      optMaster = safeOpt
        .filter((opt) => opt.nume.includes("MASTER"))
        .map((opt) => ({
          ...opt,
          nume: opt.nume.split("|").slice(1).join("|"),
        }));
    }

    return {
      filterConfig: [
        {
          id: "candidatType.registerFor",
          label: "Licenta/Master",
          type: "string",
          input: "select",
          operators: ["equal"],
          values: [
            {
              licenta: "Licenta",
            },
            {
              master: "Master",
            },
          ],
        },
        {
          id: "optiuniDistribuire.0.label",
          label: "Prima optiune (facultate)",
          type: "string",
          input: "select",
          operators: ["contains"],
          values: sortBy(
            uniq(
              safeOpt.map((opt) =>
                opt.nume.split(" | ")[4]
                  ? opt.nume.split(" | ")[4].trim()
                  : undefined
              ),
              (v) => {
                return v[Object.keys(v)[0]];
              }
            )
          ).map((opt) => ({
            [opt]: opt,
          })),
          // operators: ["contains"],
        },
        {
          id: "optiuniDistribuire.0.value",
          label: "Prima optiune (specializare)",
          type: "string",
          input: "select",
          operators: ["equal"],
          values: safeOpt.map((opt) => ({
            // [opt.nume.split(" | ")[4]]: opt.nume.split(" | ")[4],
            [opt._id]: opt.nume,
          })),
          // operators: ["contains"],
        },
        {
          id: "inEditareLa",
          label: "In Editare",
          type: "string",
          input: "text",
        },
        {
          id: "hasSignedContract",
          label: "Semnat contract",
          type: "boolean",
          input: "radio",
          operators: ["equal", "not_equal"],
          values: [
            {
              true: "Da",
            },
            {
              false: "Nu",
            },
          ],
        },
        {
          id: "status",
          label: "Status",
          type: "string",
          input: "select",
          values: [
            {
              semnat: "Ajuns la plata",
            },
            {
              documenteIncarcate: "Documente incarcate",
            },
            {
              eligibil: "Eligibil",
            },
            {
              completatDosar: "Fisa completata",
            },
            {
              incomplet: "Incomplet",
            },
            {
              respins: "Revizuire",
            },
            {
              trimisCatreValidare: "Trimis catre validare",
            },
            {
              concursDeAdmitere: "Concurs de admitere",
            },
            {
              validat: "Validat",
            },
          ],
          operators: ["equal", "not_equal"],
          // TODO: Add year as selector (number type)
        },
        {
          id: "sesiune.label",
          label: "Sesiune",
          type: "string",
          input: "select",
          values: [
            {
              "Sesiunea 1": "Sesiunea 1",
            },
            {
              "Sesiunea 2": "Sesiunea 2",
            },
          ],
          operators: ["equal", "not_equal"],
          // TODO: Add year as selector (number type)
        },
        {
          id: "areGradesVerified",
          label: "Autentic",
          type: "boolean",
          input: "radio",
          operators: ["equal", "not_equal"],
          values: [
            {
              true: "Da",
            },
            {
              false: "Nu",
            },
          ],
        },
        {
          id: "hasDeliveredDiploma",
          label: "A adus diploma",
          type: "boolean",
          input: "radio",
          operators: ["equal", "not_equal"],
          values: [
            {
              true: "Da",
            },
            {
              false: "Nu",
            },
          ],
        },
        {
          id: "uploadatAnexa4",
          label: "A uploadat anexa 4",
          type: "boolean",
          input: "radio",
          operators: ["equal", "not_equal"],
          values: [
            {
              true: "Da",
            },
            {
              false: "Nu",
            },
          ],
        },
        {
          id: "noDiplomaReminders",
          label: "Nr. remindere diploma",
          type: "integer",
          input: "number",
          operators: ["equal", "not_equal"],
        },
        {
          id: "userId",
          label: "Id",
          type: "string",
          input: "text",
          operators: ["equal"],
        },
        {
          id: "email",
          label: "Email",
          type: "string",
          input: "text",
        },
        {
          id: "cnp",
          label: "CNP",
          type: "string",
          input: "text",
        },
        {
          id: "nume",
          label: "Nume",
          type: "string",
          input: "text",
        },
        {
          id: "prenume",
          label: "Prenume",
          type: "string",
          input: "text",
        },
        {
          id: "dataTrimitereValidare",
          label: "Luna inscriere",
          type: "string",
          // input: "number",
          operators: ["equal"],
          input: "select",
          values: [
            {
              1: "Ianuarie",
            },
            {
              2: "Februarie",
            },
            {
              3: "Martie",
            },
            {
              4: "Aprilie",
            },
            {
              5: "Mai",
            },
            {
              6: "Iunie",
            },
            {
              7: "Iulie",
            },
            {
              8: "August",
            },
            {
              9: "Septembrie",
            },
            {
              10: "Octombrie",
            },
            {
              11: "Noiembrie",
            },
            {
              12: "Decembrie",
            },
          ],
        },
      ],
    };
  };

  handleExportDosar = async (id) => {
    try {
      const { jwt } = this.props;
      const res = await request
        .get(`${serverUrl}/inscrisi/export/${id}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleDelete = (dosarId) => {
    this.props.deleteInscris(dosarId);
  };

  handleConfirmDeleteAll = async () => {
    try {
      const { jwt } = this.props;
      await request
        .delete(`${serverUrl}/inscrisi/delete-all-rejected`)
        .set("Authorization", `Bearer ${jwt}`);
      this.props.showSnackbar(
        "Toate dosarele respinse au fost sterse",
        "success"
      );
    } catch (e) {
      this.props.showSnackbar(e);
    }
  };

  handleTransformInscrisi = async () => {
    try {
      const { jwt } = this.props;
      await request
        .get(`${serverUrl}/inscrisi/transform-all-finalized`)
        .set("Authorization", `Bearer ${jwt}`);
      this.props.showSnackbar(
        "Dosarele finalizate au fost transformate",
        "success"
      );
    } catch (e) {
      this.props.showSnackbar(e);
    }
  };

  handleExport = async (query) => {
    const { jwt } = this.props;
    try {
      const res = await request
        .post(`${serverUrl}/export/inscrisi-ro`)
        .send(query)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleTransformValidatiToEligibili = async () => {
    const { jwt } = this.props;
    try {
      const res = await request
        .get(`${serverUrl}/inscrisi-ro/admin/move-to-eligibili`)
        .set("Authorization", `Bearer ${jwt}`);
    } catch (e) {
      this.props.showSnackbar(e.message);
    }
  };

  openDialog = (dialogType) => () => {
    this.setState({
      [dialogType]: true,
    });
  };

  closeDialog = (dialogType) => () => {
    this.setState({
      [dialogType]: false,
    });
  };

  render() {
    const {
      items,
      isFetching,
      query,
      isFetchingFilters,
      pageInfo,
    } = this.props;
    const { filterConfig } = this.state;
    const baseColumns = (
      anchorEl,
      openMenu,
      onClose,
      handleViewClick,
      handleEditClick,
      handleDeleteClick,
      handleExportDosar
    ) => [
      {
        id: "optiuni",
        width: 65,
        resizable: false,
        Header: "Optiuni",
        accessor: (row) => (
          <>
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={(event) => openMenu(event, row._id)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        accessor: "_id",
        Header: "Id",
        show: false,
      },
      {
        id: "inEditareLa",
        Header: "In Editare La",
        accessor: ({ inEditareLa }) => inEditareLa,
        show: true,
      },
      {
        accessor: "email",
        Header: "Email",
        show: true,
      },
      {
        id: "candidatType",
        accessor: ({ candidatType }) => candidatType.registerFor,
        Header: "Licenta/Master",
        show: true,
      },
      {
        accessor: "nume",
        Header: "Nume",
        show: true,
      },
      {
        accessor: "prenume",
        Header: "Prenume",
        show: true,
      },
      {
        accessor: ({ createdAt }) =>
          moment(createdAt).format("DD/MM/YYYY HH:mm"),
        id: "createdAt",
        Header: "Data creare cont",
      },
      {
        accessor: ({ uploadatAnexa4 }) => (uploadatAnexa4 ? "Da" : "Nu"),
        Header: "A adus anexa 4",
        show: true,
      },
      {
        accessor: ({
          status,
          hasSignedContract,
          hasDeliveredDiploma,
          areGradesVerified,
        }) =>
          areGradesVerified
            ? `${getReadableStatusRo(
                status,
                hasSignedContract,
                hasDeliveredDiploma
              )} (Autentic)`
            : getReadableStatusRo(
                status,
                hasSignedContract,
                hasDeliveredDiploma
              ),
        id: "status",
        Header: "Status",
      },
      {
        accessor: ({ dataTrimitereValidare }) =>
          dataTrimitereValidare
            ? moment(dataTrimitereValidare).format("DD/MM/YYYY HH:mm")
            : "-",
        id: "dataTrimitereValidare",
        Header: "Data trimitere validare",
      },
    ];

    return (
      <div>
        <TableWithFilter
          showDeleteAllButton={false}
          deleteAll={this.handleConfirmDeleteAll}
          transformInscrisi={this.handleTransformInscrisi}
          name="inscrisi-ro"
          baseColumns={baseColumns}
          pageInfo={pageInfo}
          query={query}
          filterConfig={filterConfig}
          fetchItems={this.props.searchInscrisi}
          onExport={this.handleExport}
          onDelete={this.handleDelete}
          isFetchingFilters={isFetchingFilters}
          isFetching={isFetching}
          applyFilter={this.props.searchInscrisi}
          addButtonText="Adauga Inscris"
          // onExportDosar={this.handleExportDosar}
          menuShowEdit
          menuShowExport={false}
          items={items}
          hideAddButton
        />
        <Dialog
          open={this.state.transform}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog("transform")}
        >
          <DialogContent>
            Esti sigur ca vrei sa transformi toate dosarele in status eligibil?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.closeDialog("transform")}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleTransformValidatiToEligibili}
            >
              Transforma
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.role === "administrator" && (
          <div className="mt-8">
            <Button
              variant="contained"
              color="primary"
              onClick={this.openDialog("transform")}
            >
              Transforma validati in eligibili
            </Button>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  items: getInscrisi(state),
  role: getRole(state),
  isFetchingFilters: state.nomenclatoare.isFetching,
  isFetching: getIsFetchingInscrisi(state),
  nomenclatoare: state.nomenclatoare,
  conducatori: getConducatori(state),
  jwt: getToken(state),
  query: getInscrisiQuery(state),
  pageInfo: getInscrisiPageInfo(state),
});

export default withMobileDialog()(
  connect(mapStateToProps, {
    fetchInscrisi,
    showSnackbar,
    fetchNomenclatoare,
    fetchConducatori,
    searchInscrisi,
    deleteInscris,
  })(InscrisiList)
);
