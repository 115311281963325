/* eslint-disable no-underscore-dangle */
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import request from "superagent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { groupBy } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import latinise from "../../latinise";
import { serverUrl } from "../../utils";
import { fetchNomenclatoarePublice } from "../../actions";

const renderTree = (nodes, index) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    className={nodes.selectable ? "must-bold" : ""}
    label={nodes.name}
    // label={nodes.selectable ? nodes.name : `${nodes.name} ${index + 1}`}
    onClick={nodes.onClick ? nodes.onClick(index) : () => {}}
  >
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderTree(node, index))
      : null}
  </TreeItem>
);

const getComputedOptions = (optiuniDistribuire, setDistribuireOption) => {
  const computedOptions = [...optiuniDistribuire];
  if (computedOptions.length === 0) {
    return [];
  }
  const rItems = computedOptions.reduce((pv, cv) => {
    const splitLabel = cv.nume.split(" | ");
    return [
      ...pv,
      {
        id: cv._id,
        value: cv._id,
        fullName: cv.nume,
        label: `${splitLabel[2]} (${splitLabel[0].trim()})`,
        name: `${splitLabel[2]} (${splitLabel[0].trim()})`,
        tipFinantare: splitLabel[0],
        domeniu: splitLabel[1],
        program: splitLabel[2],
        facultate: splitLabel[3],
        selectable: true,
        onClick: () => () => {
          setDistribuireOption(cv);
        },
      },
    ];
  }, []);
  const groupedByFacultyName = groupBy(rItems, (value) => value.facultate);
  const faculties = Object.keys(groupedByFacultyName);
  const test = faculties.map((faculty) => ({
    label: faculty,
    name: faculty.toLowerCase().includes("aplr")
      ? faculty
      : `Facultatea de ${faculty}`,
    value: faculty,
    id: faculty,
    selectable: false,
    children: groupedByFacultyName[faculty],
  }));
  const test2 = {
    id: "root",
    name: "Alege optiunea",
    children: test,
  };
  return test2;
};

export default function Distribuire() {
  const [isLoading, setIsLoading] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    page: 0,
    perPage: 25,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNomenclatoarePublice());
  }, []);
  const optiuniDistribuire =
    useSelector((state) => state.nomenclatoare.optiuniStudiiRo) || {};
  const [studenti, setStudenti] = useState([]);
  const [studentiOptions, setStudentiOptions] = useState({
    rdp: false,
    dppd: false,
    registerFor: "licenta",
  });
  const [filterOptions, setFilterOptions] = useState({
    id: "",
    nume: "",
    cnp: "",
  });
  const [distribuireOption, setDistribuireOption] = useState({});
  useEffect(() => {
    async function getReadyForDistribuire() {
      setIsLoading(true);
      const res = await request
        .post(`${serverUrl}/inscrisi-ro/admin/get/distribuire`)
        .send({
          ...studentiOptions,
        });
      setStudenti(
        res.body.map((s) => ({
          ...s,
          searchableName: latinise(s.nume).toLowerCase(),
        }))
      );
      setIsLoading(false);
    }
    getReadyForDistribuire();
  }, [studentiOptions]);
  const handleSelectStudent = (student) => (e) => {
    setStudenti((students) =>
      students.map((s) =>
        s._id === student._id ? { ...s, checked: e.target.checked } : s
      )
    );
  };
  const handleChangePage = (event, page) => {
    setPaginationOptions((to) => ({ ...to, page }));
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationOptions((to) => ({ ...to, perPage: event.target.value }));
  };
  const handleFilter = (values) => {
    setStudentiOptions((so) => ({
      ...so,
      registerFor: values.registerFor,
      rdp: values.rdp === "da",
      dppd: values.dppd === "da",
    }));
  };
  const [showDistribuireModal, setShowDistribuireModal] = useState(false);
  const resetSelected = () => {
    setStudenti((s) => s.map((student) => ({ ...student, checked: false })));
  };
  const selectedStudents = studenti.filter((s) => !!s.checked);
  return (
    <Paper>
      <div className="p-4">
        <Formik
          onSubmit={handleFilter}
          initialValues={{ registerFor: "licenta", rdp: "nu", dppd: "nu" }}
        >
          {({ values, errors, handleChange }) => (
            <Form>
              <div className="flex">
                <FormControl fullWidth className="mr-4">
                  <InputLabel variant="outlined">Licenta/Master</InputLabel>
                  <Select
                    label="Licenta/Master"
                    variant="outlined"
                    value={values.registerFor}
                    name="registerFor"
                    onChange={(e) => {
                      handleChange(e);
                      setDistribuireOption({});
                      setStudentiOptions((so) => ({
                        ...so,
                        registerFor: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value="licenta">Licenta</MenuItem>
                    <MenuItem value="master">Master</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth className="mr-4">
                  <InputLabel variant="outlined">RDP</InputLabel>
                  <Select
                    label="RDP"
                    variant="outlined"
                    value={values.rdp}
                    name="rdp"
                    onChange={(e) => {
                      handleChange(e);
                      setDistribuireOption({});
                      setStudentiOptions((so) => ({
                        ...so,
                        rdp: e.target.value === "da",
                      }));
                    }}
                  >
                    <MenuItem value="da">Da</MenuItem>
                    <MenuItem value="nu">Nu</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel variant="outlined">DPPD</InputLabel>
                  <Select
                    label="DPPD"
                    variant="outlined"
                    value={values.dppd}
                    name="dppd"
                    onChange={(e) => {
                      handleChange(e);
                      setDistribuireOption({});
                      setStudentiOptions((so) => ({
                        ...so,
                        dppd: e.target.value === "da",
                      }));
                    }}
                  >
                    <MenuItem value="da">Da</MenuItem>
                    <MenuItem value="nu">Nu</MenuItem>
                  </Select>
                </FormControl>
                {/* <Button type="submit">Filtreaza</Button> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="p-4">
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          // defaultExpanded={[]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(
            getComputedOptions(
              optiuniDistribuire[
                studentiOptions.dppd
                  ? "dppd"
                  : studentiOptions.rdp
                  ? "rdp"
                  : studentiOptions.registerFor
              ] || [],
              setDistribuireOption
            ),
            0
          )}
        </TreeView>
      </div>
      <Formik
        initialValues={{
          id: "",
          nume: "",
        }}
      >
        {({ values, errors, handleChange }) => (
          <>
            <div className="p-4">
              <Form>
                <div className="flex">
                  <TextField
                    fullWidth
                    className="mr-4"
                    margin="dense"
                    variant="outlined"
                    label="Dosar Id"
                    name="id"
                    onChange={(e) => {
                      handleChange(e);
                      setPaginationOptions((po) => ({ ...po, page: 0 }));
                      setFilterOptions((fo) => ({ ...fo, id: e.target.value }));
                    }}
                    value={values.id}
                  />
                  <TextField
                    fullWidth
                    className="mr-4"
                    margin="dense"
                    variant="outlined"
                    name="cnp"
                    label="CNP"
                    onChange={(e) => {
                      handleChange(e);
                      setPaginationOptions((po) => ({ ...po, page: 0 }));
                      setFilterOptions((fo) => ({
                        ...fo,
                        cnp: e.target.value,
                      }));
                    }}
                    value={values.cnp}
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    name="nume"
                    label="Nume"
                    onChange={(e) => {
                      handleChange(e);
                      setPaginationOptions((po) => ({ ...po, page: 0 }));
                      setFilterOptions((fo) => ({
                        ...fo,
                        nume: e.target.value,
                      }));
                    }}
                    value={values.nume}
                  />
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>

      <div className="p-4">
        Nr. studenti selectati: {selectedStudents.length} pentru optiunea:{" "}
        {distribuireOption.nume ? (
          <span className="font-bold">{distribuireOption.nume}</span>
        ) : (
          <span className="text-red-500">
            SELECTEAZA O OPTIUNE PENTRU DISTRIBUIRE
          </span>
        )}
      </div>
      <div className="p-4">
        <Button onClick={resetSelected}>Reseteaza selectie</Button>
      </div>
      {isLoading ? (
        <>
          <div className="relative h-16">
            <div
              style={{
                position: "absolute",
                left: "50%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Selectat</TableCell>
                <TableCell>Dosar Id</TableCell>
                <TableCell>CNP</TableCell>
                <TableCell>Nume</TableCell>
                <TableCell>Initiala Tatalui</TableCell>
                <TableCell>Prenume</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ minHeight: "32rem" }}>
              {studenti
                .filter(
                  (s) =>
                    s._id.includes(filterOptions.id) &&
                    s.cnp.includes(filterOptions.cnp) &&
                    s.searchableName.includes(
                      latinise(filterOptions.nume).toLowerCase()
                    )
                )
                .slice(
                  paginationOptions.page * paginationOptions.perPage,
                  paginationOptions.page * paginationOptions.perPage +
                    paginationOptions.perPage
                )
                .map((student) => (
                  <TableRow key={student._id}>
                    <TableCell>
                      <Checkbox
                        checked={!!student.checked}
                        onChange={handleSelectStudent(student)}
                      />
                    </TableCell>
                    <TableCell>{student._id}</TableCell>
                    <TableCell>{student.cnp}</TableCell>
                    <TableCell>{student.nume}</TableCell>
                    <TableCell>{student.initialaTata}</TableCell>
                    <TableCell>{student.prenume}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={studenti.length}
            page={paginationOptions.page}
            onChangePage={handleChangePage}
            rowsPerPage={paginationOptions.perPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <Dialog
        onClose={() => setShowDistribuireModal(false)}
        open={showDistribuireModal}
      >
        <DialogTitle>{distribuireOption.nume}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dosar Id</TableCell>
                  <TableCell>CNP</TableCell>
                  <TableCell>Nume</TableCell>
                  <TableCell>Initiala Tatalui</TableCell>
                  <TableCell>Prenume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStudents.map((student) => (
                  <TableRow key={student._id}>
                    <TableCell>{student._id}</TableCell>
                    <TableCell>{student.cnp}</TableCell>
                    <TableCell>{student.nume}</TableCell>
                    <TableCell>{student.initialaTata}</TableCell>
                    <TableCell>{student.prenume}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDistribuireModal(false)}
          >
            Renunta
          </Button>
          <Button variant="contained" color="primary">
            Distribuie
          </Button>
        </DialogActions>
      </Dialog>
      <div className="p-4">
        <Button
          variant="contained"
          onClick={() => setShowDistribuireModal(true)}
        >
          Distribuie
        </Button>
      </div>
    </Paper>
  );
}
