import React, { Component } from "react";
import CheckIcon from "@material-ui/icons/Check";
import { Typography, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import request from "superagent";
import FileSaver from "file-saver";
import { getUser, getToken } from "../../reducers/authReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";
import { fetchDosarInscris } from "../../actions/inscrisi-ro";
import { showSnackbar } from "../../actions";
import { serverUrl } from "../../utils";

class ValidareFinala extends Component {
  async componentDidMount() {
    const { t, user } = this.props;
    await this.props.fetchDosarInscris();
  }

  handleDownloadContract = async () => {
    const { jwt, dosar } = this.props;
    try {
      const res = await request
        .get(
          `${serverUrl}/inscrisi-ro/${dosar._id}/generate-contract-scolarizare`
        )
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar("Error: generate-pdf", "error");
    }
  };

  handleAcceptContract = async () => {
    const { jwt, dosar } = this.props;
    try {
      await request
        .get(`${serverUrl}/inscrisi-ro/${dosar._id}/accepta-contract`)
        .set("Authorization", `Bearer ${jwt}`);
      await this.props.fetchDosarInscris();
    } catch (e) {
      this.props.showSnackbar(e.message, "error");
    }
  };

  render() {
    const { t, dosar } = this.props;
    if (dosar.status === "eligibil") {
      return (
        <div className="text-center">
          <div>
            <CheckIcon color="primary" fontSize="large" />
          </div>
          <p className="pb-8 text-3xl">Dosarul tau a fost validat</p>
          <Alert severity="info" variant="outlined">
            Revino în aplicație să vezi lista de candidaților eligibili pentru
            proba de admitere conform calendarului afișat pe
            <a className="underline" href="https://admitere.utcb.ro">
              https://admitere.utcb.ro
            </a>
            !
          </Alert>
          <Alert severity="info" variant="outlined" className="mt-4">
            Id-ul tau este: <strong>{dosar.userId}</strong>
          </Alert>
        </div>
      );
    }
    if (dosar.status === "eligibil") {
      if (dosar.hasSignedContract) {
        return (
          <div>
            <Typography gutterBottom variant="h5">
              Dosarul tau este eligibil
            </Typography>
            <Typography gutterBottom>
              Toate informațiile despre pașii următori le poți consulta la acest
              link:{" "}
              <a
                style={{ color: "#81C534" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://admitere.utcb.ro"
              >
                https://admitere.utcb.ro/
              </a>
            </Typography>
            <Alert severity="info" variant="outlined" className="mt-4">
              Id-ul tau este: <strong>{dosar.userId}</strong>
            </Alert>
            <Alert severity="warning" variant="outlined" className="my-4">
              Atenție, conform Legii educației naționale poți opta pentru o
              singură formă de învățământ cu frecvență, prin urmare nu poți
              trimite contractul de școlarizare la mai multe universități.
            </Alert>
            <div className="mb-4">
              <Button variant="contained" onClick={this.handleDownloadContract}>
                Descarca contract
              </Button>
            </div>
            <Typography>
              Ti-ai dat acordul pentru semnarea contractului.
            </Typography>
          </div>
        );
      }
      return dosar.candidatType.registerFor === "licenta" ? (
        <div>
          <Typography gutterBottom variant="h5">
            Dosarul tau este eligibil
          </Typography>
          <Typography gutterBottom variant="body1">
            În acest moment ești candidat eligibil pentru unul din locurile
            solicitate de tine. Pentru a începe procesul de a fi admis pe acel
            loc trebuie să trimiți contractul de școlarizare, care înseamnă
            intenția ta fermă că vrei să urmezi cursurile universității noastre.
          </Typography>
          <Alert severity="warning" variant="outlined" className="my-4">
            Atenție, conform Legii educației naționale poți opta pentru o
            singură formă de învățământ cu frecvență la buget, prin urmare nu
            poți trimite contractul de școlarizare la mai multe universități
            {/* La data de 16.07.2020 vei afla daca esti admis sau respins.
            <br />
            Id-ul tau este: {dosar.userId}
            <br />
            ATENTIE! <br />
            Pana atunci este obligatoriu sa ne trimiti acceptul tau pentru
            contractul de scolarizare, de mai jos! Fara el, nu poti fi
            considerat admis. */}
          </Alert>
          <div className="my-2">
            <Button variant="contained" onClick={this.handleDownloadContract}>
              Descarca contract
            </Button>
          </div>
          <Alert severity="error" variant="outlined" className="my-4">
            Apasarea butonului de mai jos inseamna ca esti de acord cu
            contractul. Semnatura ta facuta electronic va fi tiparita pe
            contract
          </Alert>
          <div className="mt-2">
            <Button
              onClick={this.handleAcceptContract}
              variant="contained"
              color="primary"
            >
              Accept contractul
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Typography gutterBottom variant="h5">
            Dosarul tau este eligibil
          </Typography>
          <div>
            Pentru cei care NU sunt absolvenți ai UTCB în perioada 2019-2020 vă
            așteptăm cu documentele în original, în perioada 20-22 iulie 2020
            pentru a confirma locul la facultate și pentru a putea fi declarat
            admis. Poți alege una din variantele următoare: aduci documentele
            personal la sediul facultății la care te-ai înscris, unde se va
            păstra distanțarea și normele de igienă
            <br />
            sau
            <br />
            trimiți documentele în original, prin serviciul special de curierat
            agreat la nivel de universitate (serviciu de transport documente).
            Lista finală cu studenții admiși va fi afișată pe pagina de
            internet:{" "}
            <a
              style={{ color: "#81C534" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://admitere.utcb.ro/master"
            >
              https://admitere.utcb.ro/master
            </a>
          </div>
        </div>
      );
    }
    if (dosar.status === "semnat") {
      return (
        <div>
          <Typography gutterBottom variant="h4">
            {t.finalValidationRejected}
          </Typography>

          <Typography>{t.finalValidationRejectedDescription}</Typography>
        </div>
      );
    }
    if (dosar.status === "acceptat") {
      return (
        <div>
          <Typography gutterBottom variant="h4">
            {t.finalValidationAccepted}
          </Typography>
          <div style={{ marginBottom: "12px" }}>
            <Button
              // onClick={this.handleDownloadScrisoare}
              variant="contained"
              color="primary"
            >
              {t.finalValidationDownloadLetter}
            </Button>
          </div>
          <Typography>{t.finalValidationAcceptedDescription}</Typography>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  nomenclatoare: state.nomenclatoare,
  user: getUser(state),
  dosar: getDosarInscris(state),
  jwt: getToken(state),
});

const mapDispatchToProps = {
  fetchDosarInscris,
  showSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidareFinala);
