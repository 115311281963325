import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Alert } from "@material-ui/lab";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { fetchDosarInscris } from "../../actions/inscrisi-ro";
import { fetchDosarInscris as fetchDosarInscrisNonEu } from "../../actions/inscrisi";
import { getUser } from "../../reducers/authReducer";

export default function PendingValidation() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  useEffect(() => {
    if (user.candidatType.candidatType === "ro") dispatch(fetchDosarInscris());
    else dispatch(fetchDosarInscrisNonEu());
  }, []);
  const t = useSelector(
    (state) => state.i18nState.translations[state.i18nState.lang]
  );
  if (user.statusInscriere === "validat") {
    if (user.candidatType.candidatType === "non-eu" && user.candidatType.aplr) {
      return (
        <div className="text-center">
          <div>
            <CheckIcon color="primary" fontSize="large" />
          </div>
          <p className="pb-8 text-3xl">{t.validationReceived}</p>
          <Alert severity="info" variant="outlined" className="text-left">
            {t.validationReceivedMinister}
          </Alert>
        </div>
      );
    }
    return (
      <div className="text-center">
        <div>
          <CheckIcon color="primary" fontSize="large" />
        </div>
        <p className="pb-8 text-3xl">{t.validationReceived}</p>
        <Alert severity="info" variant="outlined">
          {t.validationReceived2}
        </Alert>
      </div>
    );
  }
  return (
    <div className="text-center">
      <div>
        <WatchLaterIcon color="primary" fontSize="large" />
      </div>
      <div className="text-3xl">{t.awaitingValidation}</div>
    </div>
  );
}

// class PendingValidation extends Component {
//   async componentDidMount() {
//     await this.props.fetchDosarInscris();
//   }

//   render() {
//     const { t } = this.props;

//     if (user.status)
//       return (
//         <div>
//           <Typography gutterBottom variant="h6">
//             {t.awaitingValidation}
//           </Typography>
//         </div>
//       );
//   }
// }

// const mapStateToProps = (state) => ({
//   lang: state.i18nState.lang,
//   t: state.i18nState.translations[state.i18nState.lang],
// });

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(PendingValidation);
