export default [
  { nume: 'LICEUL "HOREA CLOSCA SI CRISAN" ABRUD', localitate: "ABRUD" },
  { nume: 'LICEUL TEHNOLOGIC "JEAN DINU" ADAMCLISI', localitate: "ADAMCLISI" },
  { nume: 'LICEUL TEORETIC "EMIL BOTTA" ADJUD', localitate: "ADJUD" },
  { nume: 'COLEGIUL TEHNIC "GHEORGHE BALS" ADJUD', localitate: "ADJUD" },
  {
    nume: "LICEUL TEHNOLOGIC ADJUDENI COMUNA TAMĂȘENI",
    localitate: "ADJUDENI",
  },
  {
    nume: 'LICEUL TEOLOGIC ORTODOX "CUVIOASA PARASCHEVA" COMUNA AGAPIA',
    localitate: "AGAPIA",
  },
  { nume: 'COLEGIUL TEHNIC "A.T.LAURIAN" AGNITA', localitate: "AGNITA" },
  { nume: 'COLEGIUL NATIONAL "TITU MAIORESCU" AIUD', localitate: "AIUD" },
  { nume: 'COLEGIUL NATIONAL "BETHLEN GABOR" AIUD', localitate: "AIUD" },
  { nume: "COLEGIUL TEHNIC AIUD", localitate: "AIUD" },
  {
    nume: 'COLEGIUL NATIONAL "HOREA CLOSCA SI CRISAN" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'COLEGIUL ECONOMIC "DIONISIE POP MARTIAN" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'COLEGIUL TEHNIC "ALEXANDRU DOMSA" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'COLEGIUL TEHNIC "DORIN PAVEL" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  { nume: 'COLEGIUL TEHNIC "APULUM" ALBA IULIA', localitate: "ALBA IULIA" },
  {
    nume:
      'LICEUL TEOLOGIC ROMANO-CATOLIC "GROF MAJLATH GUSZTAV KAROLY" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  { nume: "COLEGIUL DE AFACERI ALBA IULIA", localitate: "ALBA IULIA" },
  { nume: "LICEUL CU PROGRAM SPORTIV ALBA IULIA", localitate: "ALBA IULIA" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SFANTUL SIMION STEFAN" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'COLEGIUL NATIONAL MILITAR "MIHAI VITEAZUL" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'LICEUL DE ARTE "REGINA MARIA" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  {
    nume: 'LICEUL TEORETIC "SFANTUL IOSIF" ALBA IULIA',
    localitate: "ALBA IULIA",
  },
  { nume: 'LICEUL TEHNOLOGIC "TARA MOTILOR" ALBAC', localitate: "ALBAC" },
  { nume: 'COLEGIUL TEHNIC "ALEXANDRU ROMAN" ALEȘD', localitate: "ALEŞD" },
  {
    nume: 'LICEUL TEORETIC "CONSTANTIN NOICA" ALEXANDRIA',
    localitate: "ALEXANDRIA",
  },
  {
    nume: 'LICEUL PEDAGOGIC "MIRCEA SCARLAT" ALEXANDRIA',
    localitate: "ALEXANDRIA",
  },
  { nume: "LICEUL TEHNOLOGIC NR. 1 ALEXANDRIA", localitate: "ALEXANDRIA" },
  { nume: 'LICEUL TEORETIC "AL.I. CUZA" ALEXANDRIA', localitate: "ALEXANDRIA" },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE BĂLCESCU" ALEXANDRIA',
    localitate: "ALEXANDRIA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "AL.D. GHICA" ALEXANDRIA',
    localitate: "ALEXANDRIA",
  },
  { nume: "LICEUL TEORETIC AMARASTII DE JOS", localitate: "AMARAŞTII DE JOS" },
  { nume: 'LICEUL "MATHIAS HAMMER“ ANINA', localitate: "ANINA" },
  { nume: 'LICEUL TEHNOLOGIC "ȘTEFAN PASCU" APAHIDA', localitate: "APAHIDA" },
  { nume: "LICEUL CU PROGRAM SPORTIV ARAD", localitate: "ARAD" },
  {
    nume: 'LICEUL TEORETIC "ADAM MULLER GUTTENBRUNN" ARAD',
    localitate: "ARAD",
  },
  { nume: "LICEUL TEOLOGIC PENTICOSTAL ARAD", localitate: "ARAD" },
  { nume: "COLEGIUL ECONOMIC ARAD", localitate: "ARAD" },
  { nume: 'COLEGIUL NATIONAL "MOISE NICOARA" ARAD', localitate: "ARAD" },
  { nume: 'COLEGIUL NATIONAL "ELENA GHIBA BIRTA" ARAD', localitate: "ARAD" },
  {
    nume: "COLEGIUL TEHNIC DE CONSTRUCTII SI PROTECTIA MEDIULUI ARAD",
    localitate: "ARAD",
  },
  { nume: 'COLEGIUL TEHNIC "AUREL VLAICU" ARAD', localitate: "ARAD" },
  { nume: 'COLEGIUL NATIONAL "VASILE GOLDIS" ARAD', localitate: "ARAD" },
  {
    nume: 'LICEUL TEHNOLOGIC DE ELECTRONICA SI AUTOMATIZARI "CAIUS IACOB" ARAD',
    localitate: "ARAD",
  },
  {
    nume: 'LICEUL TEHNOLOGIC DE TRANSPORTURI AUTO "HENRI COANDA" ARAD',
    localitate: "ARAD",
  },
  { nume: 'COLEGIUL PARTICULAR "VASILE GOLDIS" ARAD', localitate: "ARAD" },
  { nume: 'LICEUL SPECIAL "SFANTA MARIA" ARAD', localitate: "ARAD" },
  { nume: 'LICEUL TEHNOLOGIC "FRANCISC NEUMAN" ARAD', localitate: "ARAD" },
  { nume: 'LICEUL TEHNOLOGIC "IULIU MANIU" ARAD', localitate: "ARAD" },
  { nume: 'LICEUL TEHNOLOGIC "IULIU MOLDOVAN" ARAD', localitate: "ARAD" },
  {
    nume: 'COLEGIUL NATIONAL "PREPARANDIA-DIMITRIE TICHINDEAL" ARAD',
    localitate: "ARAD",
  },
  { nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" ARAD', localitate: "ARAD" },
  { nume: 'LICEUL TEOLOGIC BAPTIST "ALEXA POPOVICI" ARAD', localitate: "ARAD" },
  { nume: "SEMINARUL TEOLOGIC ORTODOX ARAD", localitate: "ARAD" },
  { nume: 'LICEUL DE ARTE "SABIN DRAGOI" ARAD', localitate: "ARAD" },
  { nume: 'COLEGIUL"CSIKY GERGELY" ARAD', localitate: "ARAD" },
  {
    nume: "COLEGIUL TEHNOLOGIC DE INDUSTRIE ALIMENTARA ARAD",
    localitate: "ARAD",
  },
  { nume: "LICEUL TEHNOLOGIC ARDUD", localitate: "ARDUD" },
  {
    nume: "LICEUL TEHNOLOGIC DE TURISM SI ALIMENTATIE ARIESENI",
    localitate: "ARIEŞENI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IORDACHE ZOSSIMA" ARMĂŞEŞTI',
    localitate: "ARMAŞEŞTI",
  },
  { nume: 'LICEUL TEORETIC "GH.LAZAR" AVRIG', localitate: "AVRIG" },
  { nume: "LICEUL TEHNOLOGIC MIRSA", localitate: "AVRIG" },
  { nume: "LICEUL TEORETIC AZUGA", localitate: "AZUGA" },
  { nume: 'LICEUL "DIMITRIE CANTEMIR" BABADAG', localitate: "BABADAG" },
  { nume: "LICEUL GEORGE TARNEA", localitate: "BĂBENI" },
  { nume: "LICEUL CU PROGRAM SPORTIV BACAU", localitate: "BACĂU" },
  { nume: 'COLEGIUL TEHNIC "ANGHEL SALIGNY" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL "HENRI COANDA" BACAU', localitate: "BACĂU" },
  {
    nume: 'COLEGIUL NATIONAL PEDAGOGIC "STEFAN CEL MARE" BACAU',
    localitate: "BACĂU",
  },
  { nume: 'COLEGIUL TEHNIC "DUMITRU MANGERON" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL ECONOMIC "ION GHICA" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL NATIONAL "GHEORGHE VRANCEANU" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL NATIONAL "FERDINAND I" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL NAŢIONAL "VASILE ALECSANDRI" BACAU', localitate: "BACĂU" },
  {
    nume: 'COLEGIUL TEHNIC DE COMUNICATII "N.VASILESCU-KARPEN" BACAU',
    localitate: "BACĂU",
  },
  { nume: 'COLEGIUL NATIONAL CATOLIC "SF.IOSIF" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL "MIHAI EMINESCU" BACAU', localitate: "BACĂU" },
  { nume: 'COLEGIUL "GRIGORE ANTIPA" BACAU', localitate: "BACĂU" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRANCOVEANU" BACAU',
    localitate: "BACĂU",
  },
  { nume: 'LICEUL TEHNOLOGIC "PETRU RARES" BACAU', localitate: "BACĂU" },
  { nume: 'LICEUL TEORETIC "GRIGORE TABACARU" BACAU', localitate: "BACĂU" },
  {
    nume: 'COLEGIUL NATIONAL DE ARTA "GEORGE APOSTU" BACAU',
    localitate: "BACĂU",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCOVEANU"',
    localitate: "BAIA DE ARAMĂ",
  },
  {
    nume: 'LICEUL "DR. LAZAR CHIRILA" BAIA DE ARIES',
    localitate: "BAIA DE ARIEŞ",
  },
  { nume: "LICEUL TEHNOLOGIC BAIA DE FIER", localitate: "BAIA DE FIER" },
  {
    nume: 'LICEUL TEORETIC "EMIL RACOVITA" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL TEHNIC "ANGHEL SALIGNY" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL ECONOMIC "NICOLAE TITULESCU" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL NATIONAL "MIHAI EMINESCU" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'LICEUL TEORETIC "NEMETH LASZLO" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL NATIONAL "GHEORGHE SINCAI" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL NATIONAL "VASILE LUCACIU" BAIA MARE',
    localitate: "BAIA MARE",
  },
  {
    nume: 'COLEGIUL TEHNIC "GEORGE BARITIU" BAIA MARE',
    localitate: "BAIA MARE",
  },
  { nume: 'COLEGIUL TEHNIC "AUREL VLAICU" BAIA MARE', localitate: "BAIA MARE" },
  { nume: 'COLEGIUL TEHNIC "TRANSILVANIA" BAIA MARE', localitate: "BAIA MARE" },
  {
    nume: 'COLEGIUL TEHNIC "C.D. NENITESCU" BAIA MARE',
    localitate: "BAIA MARE",
  },
  { nume: 'LICEUL UCECOM "SPIRU HARET" BAIA MARE', localitate: "BAIA MARE" },
  { nume: "LICEUL TEOLOGIC PENTICOSTAL BAIA MARE", localitate: "BAIA MARE" },
  {
    nume: 'LICEUL TEORETIC "GEORGE POP DE BASESTI" BAIA MARE',
    localitate: "BAIA MARE",
  },
  { nume: "LICEUL DE ARTE BAIA MARE", localitate: "BAIA MARE" },
  { nume: 'LICEUL TEORETIC "HENRI COANDA" BAIA MARE', localitate: "BAIA MARE" },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL "SF. IOSIF MARTURISITORUL" BAIA MARE',
    localitate: "BAIA MARE",
  },
  { nume: "LICEUL CU PROGRAM SPORTIV BAIA MARE", localitate: "BAIA MARE" },
  {
    nume: "COLEGIUL TEHNIC DE TRANSPORTURI AUTO BAIA SPRIE",
    localitate: "BAIA SPRIE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN CANTACUZINO" BĂICOI',
    localitate: "BĂICOI",
  },
  { nume: 'LICEUL "HERCULES" BAILE HERCULANE', localitate: "BĂILE HERCULANE" },
  { nume: 'LICEUL TEORETIC "MIHAI VITEAZUL" BAILESTI', localitate: "BĂILEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "STEFAN ANGHEL" BAILESTI',
    localitate: "BĂILEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "LIVIU REBREANU" BĂLAN', localitate: "BĂLAN" },
  {
    nume: 'LICEUL TEHNOLOGIC "PETRACHE POENARU" BALCESTI',
    localitate: "BĂLCEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "UDREA BĂLEANU" BĂLENI',
    localitate: "BĂLENI-ROMÂNI",
  },
  { nume: "COLEGIUL TEHNIC BALS", localitate: "BALŞ" },
  { nume: 'COLEGIUL TEHNIC "NICOLAE BALCESCU" BALS', localitate: "BALŞ" },
  { nume: 'LICEUL TEORETIC "PETRE PANDREA" BALS', localitate: "BALŞ" },
  { nume: "LICEUL TEHNOLOGIC COMUNA BĂLȚEȘTI", localitate: "BALŢEŞTI" },
  { nume: "LICEUL TEHNOLOGIC BAND", localitate: "BAND" },
  { nume: "LICEUL TEORETIC BĂNEASA", localitate: "BĂNEASA" },
  {
    nume: 'LICEUL TEHNOLOGIC "BARÓTI SZABÓ DÁVID" BARAOLT',
    localitate: "BARAOLT",
  },
  {
    nume: "LICEUL TEHNOLOGIC AGRICOL COMUNA BĂRCĂNEȘTI",
    localitate: "BARCANEŞTI",
  },
  { nume: "LICEUL TEORETIC SAT MĂRGĂRIŢI COMUNA BECENI", localitate: "BECENI" },
  { nume: "LICEUL TEORETIC BECHET", localitate: "BECHET" },
  { nume: "LICEUL TEHNOLOGIC AGRICOL BECLEAN", localitate: "BECLEAN" },
  { nume: 'LICEUL TEHNOLOGIC "HENRI COANDĂ" BECLEAN', localitate: "BECLEAN" },
  { nume: 'COLEGIUL NAȚIONAL "PETRU RAREȘ" BECLEAN', localitate: "BECLEAN" },
  { nume: 'COLEGIUL TEHNIC "IOAN CIORDAȘ" BEIUȘ', localitate: "BEIUŞ" },
  {
    nume: 'LICEUL VOCAȚIONAL PEDAGOGIC "NICOLAE BOLCAȘ" BEIUȘ',
    localitate: "BEIUŞ",
  },
  { nume: 'COLEGIUL NAȚIONAL "SAMUIL VULCAN" BEIUȘ', localitate: "BEIUŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "VICTOR MIHĂILESCU CRAIU" BELCEŞTI',
    localitate: "BELCEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC BELIU", localitate: "BELIU" },
  { nume: "LICEUL PREDA BUZESCU BERBESTI", localitate: "BERBEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "SF. MUCENIC SAVA" COMUNA BERCA',
    localitate: "BERCA",
  },
  { nume: 'LICEUL TEHNOLOGIC "PAUL BUJOR" BERESTI', localitate: "BEREŞTI" },
  { nume: 'LICEUL "CAROL I" ORAȘ BICAZ', localitate: "BICAZ" },
  { nume: 'LICEUL TEHNOLOGIC "PETRE MITROI"COM.BILED', localitate: "BILED" },
  {
    nume: 'LICEUL TEHNOLOGIC "G-RAL C-TIN SANDRU" BILTA RUNCU',
    localitate: "BÎLTA",
  },
  { nume: "LICEUL TEHNOLOGIC BILTENI", localitate: "BÎLTENI" },
  { nume: 'LICEUL TEORETIC "ADRIAN PAUNESCU" BARCA', localitate: "BÎRCA" },
  {
    nume: 'COLEGIUL NATIONAL "GH. ROSCA CODREANU" BARLAD',
    localitate: "BÎRLAD",
  },
  { nume: 'LICEUL TEORETIC "MIHAI EMINESCU" BARLAD', localitate: "BÎRLAD" },
  { nume: 'LICEUL PEDAGOGIC "IOAN POPESCU" BARLAD', localitate: "BÎRLAD" },
  {
    nume: 'COLEGIUL TEHNIC "ALEXANDRU IOAN CUZA" BARLAD',
    localitate: "BÎRLAD",
  },
  { nume: 'LICEUL TEHNOLOGIC "PETRU RARES" BARLAD', localitate: "BÎRLAD" },
  { nume: "LICEUL TEHNOLOGIC BIRSESTI", localitate: "BÎRSEŞTI" },
  { nume: "LICEUL TEORETIC SANITAR BISTRIȚA", localitate: "BISTRIŢA" },
  { nume: "LICEUL TEHNOLOGIC DE SERVICII BISTRIȚA", localitate: "BISTRIŢA" },
  { nume: "LICEUL TEHNOLOGIC FORESTIER BISTRIȚA", localitate: "BISTRIŢA" },
  { nume: 'COLEGIUL TEHNIC "INFOEL" BISTRIȚA', localitate: "BISTRIŢA" },
  { nume: 'COLEGIUL TEHNIC "GRIGORE MOISIL" BISTRIȚA', localitate: "BISTRIŢA" },
  { nume: "LICEUL CU PROGRAM SPORTIV BISTRIȚA", localitate: "BISTRIŢA" },
  {
    nume: 'COLEGIUL NAȚIONAL "LIVIU REBREANU" BISTRIȚA',
    localitate: "BISTRIŢA",
  },
  {
    nume: "LICEUL PARTICULAR NR. 1 SAT BISTRIȚA COMUNA ALEXANDRU CEL BUN",
    localitate: "BISTRIŢA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "ANDREI MUREȘANU" BISTRIȚA',
    localitate: "BISTRIŢA",
  },
  { nume: 'LICEUL DE ARTE "CORNELIU BABA" BISTRIȚA', localitate: "BISTRIŢA" },
  { nume: 'LICEUL DE MUZICĂ "TUDOR JARDA" BISTRIȚA', localitate: "BISTRIŢA" },
  { nume: "LICEUL TEHNOLOGIC AGRICOL BISTRIȚA", localitate: "BISTRIŢA" },
  { nume: "LICEUL TEHNOLOGIC SPECIAL BIVOLARIE", localitate: "BIVOLĂRIA" },
  {
    nume: 'COLEGIUL NATIONAL "INOCHENTIE MICU CLAIN" BLAJ',
    localitate: "BLAJ",
  },
  {
    nume: 'LICEUL TEOLOGIC GRECO-CATOLIC "SFANTUL VASILE CEL MARE" BLAJ',
    localitate: "BLAJ",
  },
  { nume: 'LICEUL TEHNOLOGIC "STEFAN MANCIULEA" BLAJ', localitate: "BLAJ" },
  { nume: 'LICEUL TEHNOLOGIC "TIMOTEI CIPARIU" BLAJ', localitate: "BLAJ" },
  { nume: 'LICEUL TEORETIC "TATA OANCEA" BOCŞA', localitate: "BOCŞA" },
  { nume: "LICEUL TEHNOLOGIC BOCŞA", localitate: "BOCŞA" },
  {
    nume: 'LICEUL TEHNOLOGIC "TEODOR DIAMANT" BOLDEȘTI-SCĂENI',
    localitate: "BOLDEŞTI-SCĂENI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE BOLINTINEANU"',
    localitate: "BOLINTIN-VALE",
  },
  { nume: 'LICEUL "MIHAIL SADOVEANU" COMUNA BORCA', localitate: "BORCA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 BORCEA", localitate: "BORCEA" },
  {
    nume: 'LICEUL TEHNOLOGIC AGROINDUSTRIAL "TAMASI ARON" BORȘ',
    localitate: "BORŞ",
  },
  { nume: "LICEUL BORSA", localitate: "BORŞA" },
  { nume: 'LICEUL TEHNOLOGIC "ZIMMETHAUSEN" BORSEC', localitate: "BORSEC" },
  {
    nume: 'LICEUL TEHNOLOGIC "ANDREI ȘAGUNA" BOTOROAGA',
    localitate: "BOTOROAGA",
  },
  {
    nume: 'COLEGIUL ECONOMIC "OCTAV ONICESCU" BOTOSANI',
    localitate: "BOTOŞANI",
  },
  {
    nume: 'COLEGIUL TEHNIC "GHEORGHE ASACHI" BOTOSANI',
    localitate: "BOTOŞANI",
  },
  { nume: 'LICEUL PEDAGOGIC "NICOLAE IORGA" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: 'COLEGIUL NATIONAL "A.T.LAURIAN" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: "LICEUL CU PROGRAM SPORTIV BOTOSANI", localitate: "BOTOŞANI" },
  {
    nume: 'COLEGIUL NATIONAL "MIHAI EMINESCU" BOTOSANI',
    localitate: "BOTOŞANI",
  },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL ORTODOX "SF.GHEORGHE" BOTOSANI',
    localitate: "BOTOŞANI",
  },
  { nume: 'LICEUL DE ARTA "STEFAN LUCHIAN" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: "LICEUL TEHNOLOGIC DE COOPERATIE BOTOSANI", localitate: "BOTOŞANI" },
  { nume: 'LICEUL TEORETIC "GRIGORE ANTIPA" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: 'LICEUL "ALEXANDRU CEL BUN"" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: 'LICEUL "DIMITRIE NEGREANU" BOTOSANI', localitate: "BOTOŞANI" },
  {
    nume:
      'LICEUL TEORETIC CENTRUL DE STUDII STEFAN CEL MARE SI SFANT""- BOTOSANI',
    localitate: "BOTOŞANI",
  },
  { nume: 'LICEUL TEHNOLOGIC "PETRU RAREŞ"" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: 'LICEUL TEHNOLOGIC "ELIE RADU" BOTOSANI', localitate: "BOTOŞANI" },
  { nume: 'LICEUL TEORETIC "EFTIMIE MURGU" BOZOVICI', localitate: "BOZOVICI" },
  { nume: 'COLEGIUL NATIONAL "AVRAM IANCU" BRAD', localitate: "BRAD" },
  { nume: 'LICEUL TEORETIC "NICOLAE IORGA" BRĂILA', localitate: "BRĂILA" },
  { nume: 'LICEUL TEORETIC "PANAIT CERNA" BRĂILA', localitate: "BRĂILA" },
  { nume: 'COLEGIUL ECONOMIC "ION GHICA" BRĂILA', localitate: "BRĂILA" },
  { nume: "LICEUL CU PROGRAM SPORTIV BRĂILA", localitate: "BRĂILA" },
  { nume: 'COLEGIUL NAŢIONAL "NICOLAE BĂLCESCU" BRĂILA', localitate: "BRĂILA" },
  { nume: 'LICEUL TEORETIC "MIHAIL SEBASTIAN" BRĂILA', localitate: "BRĂILA" },
  { nume: 'LICEUL PEDAGOGIC "D.P.PERPESSICIUS" BRĂILA', localitate: "BRĂILA" },
  { nume: 'COLEGIUL NAŢIONAL "ANA ASLAN" BRĂILA', localitate: "BRĂILA" },
  { nume: 'COLEGIUL TEHNIC "PANAIT ISTRATI" BRĂILA', localitate: "BRĂILA" },
  { nume: 'LICEUL DE ARTE "HARICLEA DARCLEE" BRĂILA', localitate: "BRĂILA" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCOVEANU" BRĂILA',
    localitate: "BRĂILA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GH.K.CONSTANTINESCU" BRĂILA',
    localitate: "BRĂILA",
  },
  { nume: 'LICEUL TEHNOLOGIC "GRIGORE MOISIL" BRĂILA', localitate: "BRĂILA" },
  { nume: 'LICEUL TEHNOLOGIC "A.SALIGNY" BRĂILA', localitate: "BRĂILA" },
  { nume: 'COLEGIUL TEHNIC "EDMOND NICOLAU" BRĂILA', localitate: "BRĂILA" },
  {
    nume: 'COLEGIUL TEHNIC "COSTIN D. NENIŢESCU" BRĂILA',
    localitate: "BRĂILA",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "GHEORGHE MUNTEANU MURGOCI" BRĂILA',
    localitate: "BRĂILA",
  },
  { nume: 'LICEUL TEHNOLOGIC "SEXTIL PUȘCARIU" BRAN', localitate: "BRAN" },
  { nume: 'LICEUL TEORETIC "TRAIAN LALESCU“', localitate: "BRANEŞTI" },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL ORTODOX "SFÂNTA FILOFTEIA"',
    localitate: "BRANEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "CEZAR NICOLAU"', localitate: "BRANEŞTI" },
  { nume: "COLEGIUL SILVIC “THEODOR PIETRARU“", localitate: "BRANEŞTI" },
  { nume: 'COLEGIUL TEHNIC "MARIA BAIULESCU" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL TEHNIC "MIRCEA CRISTEA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL NAȚIONAL "UNIREA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL NAȚIONAL "DR. IOAN MEȘOTĂ" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL NAȚIONAL "APRILY LAJOS" BRAȘOV', localitate: "BRAŞOV" },
  {
    nume: 'COLEGIUL NAȚIONAL DE INFORMATICĂ "GRIGORE MOISIL" BRAȘOV',
    localitate: "BRAŞOV",
  },
  { nume: 'COLEGIUL NAȚIONAL "ANDREI ȘAGUNA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL TEHNIC "REMUS RĂDULEȚ" BRAȘOV', localitate: "BRAŞOV" },
  {
    nume: 'LICEUL TEHNOLOGIC SILVIC "DR. NICOLAE RUCĂREANU" BRAȘOV',
    localitate: "BRAŞOV",
  },
  { nume: 'COLEGIUL TEHNIC "TRANSILVANIA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: "LICEUL CU PROGRAM SPORTIV BRAȘOV", localitate: "BRAŞOV" },
  {
    nume: 'LICEUL DE ARTE PLASTICE "HANS MATTIS-TEUTSCH" BRAȘOV',
    localitate: "BRAŞOV",
  },
  { nume: "COLEGIUL TEHNIC DE TRANSPORTURI BRAȘOV", localitate: "BRAŞOV" },
  { nume: 'LICEUL TEORETIC "JOHANNES HONTERUS" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL DE ȘTIINȚE "GRIGORE ANTIPA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL "NICOLAE TITULESCU" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'COLEGIUL "EMIL RACOVIȚĂ" BRAȘOV', localitate: "BRAŞOV" },
  {
    nume: 'COLEGIUL NAȚIONAL ECONOMIC "ANDREI BÂRSEANU" BRAȘOV',
    localitate: "BRAŞOV",
  },
  { nume: 'LICEUL "ANDREI MUREȘANU" BRAȘOV', localitate: "BRAŞOV" },
  { nume: 'LICEUL DE MUZICĂ "TUDOR CIORTEA" BRAȘOV', localitate: "BRAŞOV" },
  { nume: "LICEUL TEORETIC NR.1 BRATCA", localitate: "BRATCA" },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" BREAZA',
    localitate: "BREAZA DE SUS",
  },
  {
    nume: 'COLEGIUL NAŢIONAL MILITAR LICEAL "DIMITRIE CANTEMIR" BREAZA',
    localitate: "BREAZA DE SUS",
  },
  {
    nume: 'LICEUL TEORETIC "AUREL VLAICU" BREAZA',
    localitate: "BREAZA DE SUS",
  },
  { nume: "LICEUL GHEORGHE SURDU BREZOI", localitate: "BREZOI" },
  { nume: 'LICEUL TEHNOLOGIC "NICOLAI NANU" BROSTENI', localitate: "BROŞTENI" },
  { nume: "LICEUL TEHNOLOGIC BUCECEA", localitate: "BUCECEA" },
  {
    nume: 'COLEGIUL NAŢIONAL DE INFORMATICĂ "TUDOR VIANU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC "CONSTANTIN BRÂNCOVEANU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "AUREL VLAICU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC "ALEXANDRU VLAHUŢĂ"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC BULGAR "HRISTO BOTEV"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: 'COLEGIUL TEHNIC "MEDIA"', localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume:
      'COLEGIUL TEHNIC DE ARHITECTURĂ ŞI LUCRĂRI PUBLICE "IOAN N. SOCOLESCU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: 'COLEGIUL GERMAN "GOETHE"', localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: 'LICEUL DE ARTE PLASTICE "NICOLAE TONITZA"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC "NICOLAE IORGA"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "SFÂNTUL SAVA"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: 'LICEUL TEORETIC "JEAN MONNET"', localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: 'COLEGIUL TEHNIC MECANIC "GRIVIŢA"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL GRECO-CATOLIC "TIMOTEI CIPARIU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL TEHNIC DE AERONAUTICĂ "HENRI COANDĂ"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL TEHNIC FEROVIAR "MIHAI I"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL PARTICULAR "ONICESCU-MIHOC"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL TEHNIC "MIRCEA CEL BĂTRÂN"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "ION NECULCE"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: "LICEUL FEG BUCURESTI", localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: 'COLEGIUL NAŢIONAL DE MUZICĂ "GEORGE ENESCU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL ECONOMIC "V. MADGEARU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE CĂLINESCU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'LICEUL TEORETIC BILINGV "MIGUEL DE CERVANTES"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL TEHNOLOGIC "VIACESLAV HARNAJ"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: 'LICEUL "DIMITRIE PACIUREA"', localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: 'COLEGIUL TEHNIC "DINICU GOLESCU"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "I.L.CARAGIALE"',
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: "COMPLEXUL SCOLAR CRONOS", localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: "LICEUL TEORETIC SCOALA EUROPEANA BUCURESTI",
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: "LICEUL TEORETIC NATIONAL", localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: "LYCÉE FRANÇAIS ANNA DE NOAILLES",
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: "CAMBRIDGE SCHOOL OF BUCHAREST", localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: "INTERNATIONAL LEBANESE SCHOOL - CAMBRIDGE SCHOOL OF BUCHAREST",
    localitate: "BUCUREŞTI SECTORUL 1",
  },
  { nume: "ȘCOALA ARABĂ TISHRIN", localitate: "BUCUREŞTI SECTORUL 1" },
  {
    nume: 'LICEUL TEORETIC "C.A. ROSETTI"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: 'LICEUL TEORETIC "ADY ENDRE"', localitate: "BUCUREŞTI SECTORUL 2" },
  { nume: 'LICEUL "ECOPROF"', localitate: "BUCUREŞTI SECTORUL 2" },
  { nume: 'COLEGIUL ECONOMIC "HERMES"', localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'COLEGIUL TEHNIC "DIMITRIE LEONIDA"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL TEHNIC "EDMOND NICOLAU"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEORETIC "MIHAIL SADOVEANU"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEORETIC "LUCIAN BLAGA"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEHNOLOGIC SPECIAL "REGINA ELISABETA"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: "LICEUL TEHNOLOGIC SPECIAL NR. 3",
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: "LICEUL TEORETIC WALDORF", localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'LICEUL TEHNOLOGIC "ELECTRONICA INDUSTRIALĂ"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SFÂNTUL PANTELIMON"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL BILINGV "GEORGE COȘBUC"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: 'LICEUL TEORETIC "TRAIAN"', localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'COLEGIUL ECONOMIC "A.D.XENOPOL"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "CANTEMIR VODĂ"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: "ȘCOALA CENTRALĂ", localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCUȘI"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEORETIC "NEW GENERATION SCHOOL"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: 'LICEUL TEORETIC "VICTORIA"', localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'COLEGIUL NAȚIONAL "EMIL RACOVIȚĂ"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ION I.C. BRĂTIANU"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'LICEUL TEORETIC BILINGV "ITA WEGMAN"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "IULIA HAȘDEU"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "VICTOR BABEȘ"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "SPIRU HARET"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "MIHAI VITEAZUL"',
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: "BUCHAREST-BEIRUT INTERNATIONAL SCHOOL",
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  {
    nume: "LEBANESE MODERN PROGRESSION SCHOOL",
    localitate: "BUCUREŞTI SECTORUL 2",
  },
  { nume: "JERUSALEM SCHOOL", localitate: "BUCUREŞTI SECTORUL 2" },
  {
    nume: 'LICEUL TEORETIC "DANTE ALIGHIERI"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'LICEUL TEORETIC "BENJAMIN FRANKLIN"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  { nume: 'LICEUL TEORETIC "HYPERION"', localitate: "BUCUREŞTI SECTORUL 3" },
  { nume: "LICEUL TEOLOGIC BAPTIST LOGOS", localitate: "BUCUREŞTI SECTORUL 3" },
  { nume: 'LICEUL "PROFESIA"', localitate: "BUCUREŞTI SECTORUL 3" },
  {
    nume: 'COLEGIUL TEHNIC "ANGHEL SALIGNY"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  { nume: 'COLEGIUL TEHNIC "MIHAI BRAVU"', localitate: "BUCUREŞTI SECTORUL 3" },
  {
    nume: 'LICEUL TEORETIC "NICHITA STĂNESCU"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: "COMPLEXUL EDUCATIONAL LAUDER REUT",
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DRAGOMIR HURMUZESCU"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  { nume: 'LICEUL TEHNOLOGIC "ELIE RADU"', localitate: "BUCUREŞTI SECTORUL 3" },
  {
    nume: 'LICEUL TEHNOLOGIC "TRAIAN LALESCU"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  { nume: 'LICEUL TEORETIC "DECEBAL"', localitate: "BUCUREŞTI SECTORUL 3" },
  {
    nume: "LICEUL INTERNAȚIONAL DE INFORMATICĂ",
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "THEODOR PALLADY"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "MATEI BASARAB"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'COLEGIUL TEHNIC "COSTIN D. NENIȚESCU"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'ȘCOALA SUPERIOARĂ COMERCIALĂ "NICOLAE KRETZULESCU"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'LICEUL TEORETIC "ALEXANDRU IOAN CUZA"',
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  { nume: "LICEUL TEORETIC MIHAI IONESCU", localitate: "BUCUREŞTI SECTORUL 3" },
  {
    nume: "INTERNATIONAL SCHOOL OF BUCHAREST",
    localitate: "BUCUREŞTI SECTORUL 3",
  },
  {
    nume: 'LICEUL TEOLOGIC PENTICOSTAL "EMANUEL"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL TEHNIC "MIRON NICOLESCU"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'LICEUL DE COREGRAFIE "FLORIA CAPSALI"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "MIHAI EMINESCU"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "GHEORGHE ŞINCAI"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "OCTAV ONICESCU"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "ION CREANGĂ"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'LICEUL TEOLOGIC ADVENTIST "ŞTEFAN DEMETRESCU"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'COLEGIUL NAŢIONAL DE ARTE "DINU LIPATTI"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'LICEUL TEHNOLOGIC DE METROLOGIE "TRAIAN VUIA"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  { nume: "SEMINARUL TEOLOGIC ORTODOX", localitate: "BUCUREŞTI SECTORUL 4" },
  { nume: "LICEUL TEHNOLOGIC DACIA", localitate: "BUCUREŞTI SECTORUL 4" },
  {
    nume: 'LICEUL TEHNOLOGIC "MIRCEA VULCĂNESCU"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  { nume: 'COLEGIUL TEHNIC "PETRU RAREȘ"', localitate: "BUCUREŞTI SECTORUL 4" },
  {
    nume: 'COLEGIUL ROMANO-CATOLIC "SF. IOSIF"',
    localitate: "BUCUREŞTI SECTORUL 4",
  },
  {
    nume: 'LICEUL TEORETIC "DIMITRIE BOLINTINEANU"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  { nume: 'COLEGIUL ECONOMIC "VIILOR"', localitate: "BUCUREŞTI SECTORUL 5" },
  {
    nume: 'COLEGIUL TEHNIC DE INDUSTRIE ALIMENTARĂ "DUMITRU MOŢOC"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  { nume: "COLEGIUL TEHNIC ENERGETIC", localitate: "BUCUREŞTI SECTORUL 5" },
  {
    nume: 'COLEGIUL NAŢIONAL "GHEORGHE LAZĂR"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  { nume: 'LICEUL TEORETIC "ION BARBU"', localitate: "BUCUREŞTI SECTORUL 5" },
  {
    nume: 'COLEGIUL TEHNOLOGIC "GRIGORE CERCHEZ"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE GUSTI"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  {
    nume: 'LICEUL TEORETIC "ȘTEFAN ODOBLEJA"',
    localitate: "BUCUREŞTI SECTORUL 5",
  },
  {
    nume: 'LICEUL TEORETIC "EUGEN LOVINESCU"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  {
    nume: 'LICEUL TEORETIC "DR. CONSTANTIN ANGELESCU"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'COLEGIUL TEHNIC "PETRU MAIOR"', localitate: "BUCUREŞTI SECTORUL 6" },
  { nume: 'LICEUL TEORETIC "MARIN PREDA"', localitate: "BUCUREŞTI SECTORUL 6" },
  {
    nume: 'LICEUL TEORETIC "TUDOR VLADIMIRESCU"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "ELENA CUZA"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'COLEGIUL TEHNIC "IULIU MANIU"', localitate: "BUCUREŞTI SECTORUL 6" },
  {
    nume: 'COLEGIUL TEHNIC "GHEORGHE ASACHI"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'COLEGIUL TEHNIC "CAROL I"', localitate: "BUCUREŞTI SECTORUL 6" },
  {
    nume: 'COLEGIUL NAŢIONAL "GRIGORE MOISIL"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'LICEUL TEORETIC "PHOENIX"', localitate: "BUCUREŞTI SECTORUL 6" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "MIRCEA ELIADE"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'COLEGIUL UCECOM "SPIRU HARET"', localitate: "BUCUREŞTI SECTORUL 6" },
  {
    nume: 'LICEUL TEHNOLOGIC "PETRU PONI"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SF. ANTIM IVIREANU"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  {
    nume: 'COLEGIUL ECONOMIC "COSTIN C. KIRIŢESCU"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  {
    nume: 'COLEGIUL TEHNIC DE POŞTĂ ȘI TELECOMUNICAŢII "GHEORGHE AIRINEI"',
    localitate: "BUCUREŞTI SECTORUL 6",
  },
  { nume: 'LICEUL TEORETIC "ATLAS"', localitate: "BUCUREŞTI SECTORUL 6" },
  { nume: 'LICEUL TEORETIC "JOZEF KOZACEK" BUDOI', localitate: "BUDOI" },
  { nume: 'LICEUL TEHNOLOGIC "BARBU A. ŞTIRBEY"', localitate: "BUFTEA" },
  { nume: 'LICEUL TEHNOLOGIC "DUMITRU DUMITRESCU"', localitate: "BUFTEA" },
  { nume: 'COLEGIUL TEHNIC "ION BORCEA" BUHUSI', localitate: "BUHUŞI" },
  {
    nume: 'COLEGIUL "MIHAI VITEAZUL" BUMBESTI-JIU',
    localitate: "BUMBEŞTI-JIU",
  },
  { nume: 'COLEGIUL "ION KALINDERU" BUȘTENI', localitate: "BUŞTENI" },
  { nume: "LICEUL TEHNOLOGIC BUSTUCHIN", localitate: "BUSTUCHIN" },
  { nume: 'COLEGIUL AGRICOL "DR. C-TIN ANGELESCU" BUZĂU', localitate: "BUZĂU" },
  { nume: 'COLEGIUL NAŢIONAL "MIHAI EMINESCU" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL PEDAGOGIC "SPIRU HARET" BUZĂU', localitate: "BUZĂU" },
  { nume: "COLEGIUL ECONOMIC BUZĂU", localitate: "BUZĂU" },
  {
    nume: 'LICEUL TEORETIC "ALEXANDRU MARGHILOMAN" BUZĂU',
    localitate: "BUZĂU",
  },
  { nume: "COLEGIUL TEHNIC BUZĂU", localitate: "BUZĂU" },
  { nume: 'COLEGIUL NAŢIONAL "B. P. HASDEU" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL "PRIMA SCHOOL" BUZĂU', localitate: "BUZĂU" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "IOLANDA BALAŞ ŞOTER" BUZĂU',
    localitate: "BUZĂU",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE MESERII SI SERVICII BUZĂU",
    localitate: "BUZĂU",
  },
  { nume: 'LICEUL TEHNOLOGIC "GRIGORE C. MOISIL" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL TEHNOLOGIC" HENRI COANDĂ" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL TEHNOLOGIC "COSTIN NENIŢESCU" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL TEHNOLOGIC "DIMITRIE FILIPESCU" BUZĂU', localitate: "BUZĂU" },
  { nume: 'LICEUL DE ARTE "MARGARETA STERIAN" BUZĂU', localitate: "BUZĂU" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "CHESARIE EPISCOPUL" BUZĂU',
    localitate: "BUZĂU",
  },
  {
    nume: "LICEUL SPECIAL PENTRU DEFICIENTI DE VEDERE BUZAU",
    localitate: "BUZĂU",
  },
  { nume: "LICEUL TEORETIC BUZIAS", localitate: "BUZIAŞ" },
  { nume: "LICEUL TEHNOLOGIC NR. 1 CADEA", localitate: "CADEA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ION CĂIAN ROMÂNUL" CĂIANU MIC',
    localitate: "CAIANU MIC",
  },
  { nume: "LICEUL TEHNOLOGIC CAJVANA", localitate: "CAJVANA" },
  { nume: 'LICEUL TEORETIC "INDEPENDENTA" CALAFAT', localitate: "CALAFAT" },
  { nume: 'COLEGIUL TEHNIC "STEFAN MILCU" CALAFAT', localitate: "CALAFAT" },
  { nume: 'LICEUL TEHNOLOGIC "OVID DENSUSIANU" CALAN', localitate: "CĂLAN" },
  { nume: 'LICEUL TEHNOLOGIC "PETRE BANITA" CALARASI', localitate: "CALARAŞI" },
  {
    nume: 'COLEGIUL NATIONAL "BARBU STIRBEI" CALARASI',
    localitate: "CĂLĂRAŞI",
  },
  { nume: "COLEGIUL ECONOMIC CALARASI", localitate: "CĂLĂRAŞI" },
  { nume: 'COLEGIUL AGRICOL "SANDU ALDEA" CALARASI', localitate: "CĂLĂRAŞI" },
  { nume: 'LICEUL TEORETIC "MIHAI EMINESCU" CALARASI', localitate: "CĂLĂRAŞI" },
  {
    nume: 'COLEGIUL TEHNIC "STEFAN BANULESCU" CALARASI',
    localitate: "CĂLĂRAŞI",
  },
  { nume: 'LICEUL "DANUBIUS" CALARASI', localitate: "CĂLĂRAŞI" },
  { nume: 'LICEUL TEHNOLOGIC "DAN MATEESCU" CALARASI', localitate: "CĂLĂRAŞI" },
  {
    nume: "LICEUL TEHNOLOGIC DE TRANSPORTURI AUTO CALARASI",
    localitate: "CĂLĂRAŞI",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE TURISM CALIMANESTI",
    localitate: "CĂLIMĂNEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "MIHAI VITEAZUL" CALUGARENI',
    localitate: "CALUGARENI",
  },
  {
    nume: 'LICEUL TEORETIC "PAVEL DAN" CÂMPIA TURZII',
    localitate: "CÂMPIA TURZII",
  },
  {
    nume: 'COLEGIUL TEHNIC "VICTOR UNGUREANU" CÂMPIA TURZII',
    localitate: "CÂMPIA TURZII",
  },
  { nume: "LICEUL TEHNOLOGIC ENERGETIC CÂMPINA", localitate: "CÂMPINA" },
  { nume: "LICEUL TEHNOLOGIC MECANIC CÂMPINA", localitate: "CÂMPINA" },
  {
    nume: 'COLEGIUL TEHNIC "CONSTANTIN ISTRATI" CÂMPINA',
    localitate: "CÂMPINA",
  },
  { nume: "COLEGIUL TEHNIC FORESTIER CÂMPINA", localitate: "CÂMPINA" },
  {
    nume: 'COLEGIUL NAȚIONAL "NICOLAE GRIGORESCU" CÂMPINA',
    localitate: "CÂMPINA",
  },
  { nume: "COLEGIUL TEHNIC CÂMPULUNG", localitate: "CÂMPULUNG" },
  { nume: "LICEUL TEHNOLOGIC AUTO CAMPULUNG", localitate: "CÂMPULUNG" },
  { nume: "LICEUL CU PROGRAM SPORTIV CÂMPULUNG", localitate: "CÂMPULUNG" },
  { nume: 'COLEGIUL NAŢIONAL PEDAGOGIC "CAROL I"', localitate: "CÂMPULUNG" },
  { nume: 'COLEGIUL NAŢIONAL "DINICU GOLESCU"', localitate: "CÂMPULUNG" },
  { nume: 'LICEUL TEORETIC "DAN BARBILIAN"', localitate: "CÂMPULUNG" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "IUSTIN PATRIARHUL"',
    localitate: "CÂMPULUNG",
  },
  {
    nume: 'COLEGIUL NATIONAL "DRAGOS VODA" CAMPULUNG MOLDOVENESC',
    localitate: "CÂMPULUNG MOLDOVENESC",
  },
  {
    nume:
      'CENTRUL DE STUDII "STEFAN CEL MARE SI SFANT" BUCOVINA CIMPULUNG MOLDOVENESC',
    localitate: "CÂMPULUNG MOLDOVENESC",
  },
  {
    nume: 'COLEGIUL SILVIC "BUCOVINA" CAMPULUNG MOLDOVENESC',
    localitate: "CÂMPULUNG MOLDOVENESC",
  },
  {
    nume: "LICEUL TEHNOLOGIC NR. 1 CAMPULUNG MOLDOVENESC",
    localitate: "CÂMPULUNG MOLDOVENESC",
  },
  {
    nume: 'COLEGIUL NATIONAL MILITAR "STEFAN CEL MARE" CAMPULUNG MOLDOVENESC',
    localitate: "CÂMPULUNG MOLDOVENESC",
  },
  { nume: 'LICEUL TEORETIC "MIHAI VITEAZUL" CARACAL', localitate: "CARACAL" },
  { nume: 'COLEGIUL NATIONAL "IONITA ASAN" CARACAL', localitate: "CARACAL" },
  {
    nume: 'COLEGIUL AGRICOL "DIMITRIE PETRESCU" CARACAL',
    localitate: "CARACAL",
  },
  { nume: 'COLEGIUL TEHNIC "MATEI BASARAB" CARACAL', localitate: "CARACAL" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN FILIPESCU" CARACAL',
    localitate: "CARACAL",
  },
  { nume: 'LICEUL TEHNOLOGIC "DACIA" CARANSEBEŞ', localitate: "CARANSEBEŞ" },
  { nume: 'LICEUL TEHNOLOGIC "DECEBAL" CARANSEBEŞ', localitate: "CARANSEBEŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "TRANDAFIR COCÂRLĂ" CARANSEBEŞ',
    localitate: "CARANSEBEŞ",
  },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL "EPISCOP IOAN POPASU" CARANSEBEŞ',
    localitate: "CARANSEBEŞ",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "CONSTANTIN DIACONOVICI LOGA" CARANSEBEŞ',
    localitate: "CARANSEBEŞ",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "TRAIAN DODA" CARANSEBEŞ',
    localitate: "CARANSEBEŞ",
  },
  {
    nume: "LICEUL TEORETIC BILINGV ROMÂNO-CROAT CARAŞOVA",
    localitate: "CARAŞOVA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN IANCULESCU" CARCEA',
    localitate: "CÂRCEA",
  },
  { nume: "LICEUL TEORETIC CAREI", localitate: "CAREI" },
  { nume: 'COLEGIUL TEHNIC "IULIU MANIU" CAREI', localitate: "CAREI" },
  {
    nume: 'LICEUL ROMANO-CATOLIC "JOSEPHUS CALASANTIUS" CAREI',
    localitate: "CAREI",
  },
  { nume: 'LICEUL TEHNOLOGIC "SIMION BARNUTIU" CAREI', localitate: "CAREI" },
  { nume: 'COLEGIUL "DOBROGEA" CASTELU', localitate: "CASTELU" },
  { nume: 'COLEGIUL ECONOMIC "PINTEA VITEAZUL" CAVNIC', localitate: "CAVNIC" },
  { nume: "LICEUL TEHNOLOGIC CĂZĂNEŞTI", localitate: "CĂZĂNEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "GHEORGHE POP DE BĂSEȘTI" CEHU SILVANIEI',
    localitate: "CEHU SILVANIEI",
  },
  { nume: "LICEUL TEHNOLOGIC COMUNA CERAȘU", localitate: "CERAŞU" },
  { nume: "LICEUL TEORETIC CERMEI", localitate: "CERMEI" },
  { nume: 'LICEUL TEHNOLOGIC "AXIOPOLIS" CERNAVODĂ', localitate: "CERNAVODĂ" },
  {
    nume: 'LICEUL TEORETIC "ANGHEL SALIGNY" CERNAVODĂ',
    localitate: "CERNAVODĂ",
  },
  { nume: 'LICEUL TEORETIC "GH. VASILICHI" CETATE', localitate: "CETATE" },
  {
    nume: 'LICEUL TEORETIC "MIHAI VELICIU" CHISINEU-CRIS',
    localitate: "CHIŞINEU-CRIŞ",
  },
  { nume: "LICEUL TEHNOLOGIC CHISINEU CRIS", localitate: "CHIŞINEU-CRIŞ" },
  { nume: 'LICEUL TEORETIC"ALEXANDRU MOCIONI" CIACOVA', localitate: "CIACOVA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ION POPESCU-CILIENI" CILIENI',
    localitate: "CILIENI",
  },
  { nume: "LICEUL TEHNOLOGIC SILVIC CIMPENI", localitate: "CÎMPENI" },
  { nume: 'COLEGIUL NATIONAL "AVRAM IANCU" CIMPENI', localitate: "CÎMPENI" },
  { nume: 'LICEUL TEORETIC "DR. LIND" CÂMPENIȚA', localitate: "CÎMPENIŢA" },
  { nume: "LICEUL TEHNOLOGIC CIOBANU", localitate: "CIOBANU" },
  {
    nume: "LICEUL TEHNOLOGIC SAT CIORANII DE JOS COMUNA CIORANI",
    localitate: "CIORANII DE JOS",
  },
  { nume: 'LICEUL TEHNOLOGIC "PAMFIL ȘEICARU"', localitate: "CIOROGÎRLA" },
  {
    nume: 'LICEUL TEORETIC "GUSTAV GUNDISCH" CISNADIE',
    localitate: "CISNĂDIE",
  },
  { nume: "LICEUL TEHNOLOGIC CISNADIE", localitate: "CISNĂDIE" },
  {
    nume: 'LICEUL TEHNOLOGIC AGRICOL "ALEXANDRU BORZA" CIUMBRUD',
    localitate: "CIUMBRUD",
  },
  {
    nume: 'LICEUL TEORETIC "BRASSAI SAMUEL" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "BATHORY ISTVAN" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "AVRAM IANCU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "APACZAI CSERE JANOS" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "LICEUL TEOLOGIC REFORMAT CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  {
    nume: 'LICEUL TEOLOGIC BAPTIST "EMANUEL" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEOLOGIC ADVENTIST "MARANATHA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL ECONOMIC "IULIAN POP" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL DE INFORMATICĂ "TIBERIU POPOVICIU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL TEHNIC DE TRANSPORTURI "TRANSILVANIA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "ONISIFOR GHIBU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "NICOLAE BĂLCESCU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "COLEGIUL TEHNIC ENERGETIC CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  {
    nume: 'LICEUL TEORETIC "MIHAI EMINESCU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "LUCIAN BLAGA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "EUGEN PORA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: 'COLEGIUL TEHNIC "NAPOCA" CLUJ-NAPOCA', localitate: "CLUJ-NAPOCA" },
  { nume: "LICEUL CU PROGRAM SPORTIV CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  {
    nume: 'COLEGIUL TEHNIC "ANA ASLAN" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL TEHNIC "ANGHEL SALIGNY" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL GRECO-CATOLIC "INOCHENTIE MICU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "LICEUL TEHNOLOGIC NR. 1 CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL TEHNIC "RALUCA RIPAN" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL DE ARTE VIZUALE "ROMULUS LADEA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "SEMINARUL TEOLOGIC ORTODOX CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  { nume: 'LICEUL "JANOS ZSIGMOND" CLUJ-NAPOCA', localitate: "CLUJ-NAPOCA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ALEXANDRU BORZA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "AUREL VLAICU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DIDACTICA NOVA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VIRGIL MADGEARU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL TEHNIC DE COMUNICAȚII "AUGUSTIN MAIOR" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "GHEORGHE ȘINCAI" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "GEORGE COȘBUC" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "GEORGE BARIȚIU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume:
      'LICEUL DE COREGRAFIE ȘI ARTĂ DRAMATICĂ "OCTAVIAN STROIA" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "EMIL RACOVIȚĂ" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: "LICEUL TEHNOLOGIC SPECIAL PENTRU DEFICIENȚI DE AUZ CLUJ-NAPOCA",
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL DE MUZICĂ "SIGISMUND TODUȚĂ" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC "VICTOR BABEȘ" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "HOREA CLOȘCA ȘI CRIȘAN" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: "TRANSYLVANIA COLLEGE THE CAMBRIDGE INTERNATIONAL SCHOOL IN CLUJ",
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL TEHNIC "EDMOND NICOLAU" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "ȘCOALA GIMNAZIALĂ WALDORF CLUJ-NAPOCA", localitate: "CLUJ-NAPOCA" },
  {
    nume: "LICEUL SPECIAL PENTRU DEFICIENȚI DE VEDERE CLUJ-NAPOCA",
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'COLEGIUL NAȚIONAL PEDAGOGIC "GHEORGHE LAZĂR" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  {
    nume: 'LICEUL TEORETIC CREȘTIN "PRO DEO" CLUJ-NAPOCA',
    localitate: "CLUJ-NAPOCA",
  },
  { nume: "LICEUL COBADIN", localitate: "COBADIN" },
  { nume: 'LICEUL "STEFAN CEL MARE" SAT CODAESTI', localitate: "CODAEŞTI" },
  { nume: "LICEUL TEORETIC CODLEA", localitate: "CODLEA" },
  { nume: 'COLEGIUL TEHNIC "SIMION MEHEDINȚI" CODLEA', localitate: "CODLEA" },
  { nume: "LICEUL TEHNOLOGIC COGEALAC", localitate: "COGEALAC" },
  { nume: "LICEUL TEHNOLOGIC NR. 1 COMANA", localitate: "COMANA" },
  {
    nume: 'COLEGIUL TEHNIC "DIMITRIE GHIKA" COMANESTI',
    localitate: "COMĂNEŞTI",
  },
  { nume: 'LICEUL "SIMION STOLNICU" COMARNIC', localitate: "COMARNIC" },
  { nume: 'LICEUL TEORETIC "TRAIAN" CONSTANŢA', localitate: "CONSTANŢA" },
  { nume: 'LICEUL TEORETIC "LUCIAN BLAGA" CONSTANŢA', localitate: "CONSTANŢA" },
  { nume: 'LICEUL TEORETIC "OVIDIUS" CONSTANŢA', localitate: "CONSTANŢA" },
  { nume: 'COLEGIUL TEHNIC "TOMIS" CONSTANŢA', localitate: "CONSTANŢA" },
  { nume: 'COLEGIUL COMERCIAL "CAROL I" CONSTANŢA', localitate: "CONSTANŢA" },
  { nume: 'LICEUL TEORETIC "DECEBAL" CONSTANŢA', localitate: "CONSTANŢA" },
  {
    nume: 'COLEGIUL NAŢIONAL PEDAGOGIC "CONSTANTIN BRĂTESCU" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  { nume: "COLEGIUL TEHNIC ENERGETIC CONSTANŢA", localitate: "CONSTANŢA" },
  { nume: 'LICEUL "OMNIA" CONSTANŢA', localitate: "CONSTANŢA" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "NICOLAE ROTARU" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'COLEGIUL TEHNIC "VASILE PÂRVAN" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  { nume: 'COLEGIUL TEHNIC "PONTICA" CONSTANŢA', localitate: "CONSTANŢA" },
  {
    nume: 'COLEGIUL TEHNIC DE MARINĂ "ALEXANDRU IOAN CUZA" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'COLEGIUL NAŢIONAL DE ARTE "REGINA MARIA" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE CĂLINESCU" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "MIRCEA CEL BĂTRÂN" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE COOPERATIE CONSTANTA",
    localitate: "CONSTANŢA",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE ELECTROTEHNICĂ ŞI TELECOMUNICAŢII CONSTANŢA",
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume:
      'SEMINARUL TEOLOGIC ORTODOX "SFÂNTUL CUVIOS DIONISIE EXIGUUL" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE EMIL PALADE" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: "LICEUL TEORETIC INTERNAŢIONAL DE INFORMATICĂ CONSTANŢA",
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "C.A.ROSETTI" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  { nume: 'LICEUL TEHNOLOGIC "CONCORD" CONSTANŢA', localitate: "CONSTANŢA" },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE LEONIDA" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  { nume: 'LICEUL TEHNOLOGIC "GAUDEAMUS" CONSTANŢA', localitate: "CONSTANŢA" },
  {
    nume: 'LICEUL TEHNOLOGIC "GHEORGHE DUCA" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GHEORGHE MIRON COSTIN" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IOAN N.ROMAN" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VIRGIL MADGEARU" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "MIHAI EMINESCU" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'CENTRUL ŞCOLAR DE EDUCAŢIE INCLUZIVĂ "ALBATROS" CONSTANŢA',
    localitate: "CONSTANŢA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE TECLU" COPSA MICA',
    localitate: "COPŞA MICĂ",
  },
  { nume: 'COLEGIUL TEHNIC "DANUBIUS" CORABIA', localitate: "CORABIA" },
  { nume: 'COLEGIUL NATIONAL "AL.I.CUZA" CORABIA', localitate: "CORABIA" },
  { nume: "LICEUL TEHNOLOGIC CORBU", localitate: "CORBU" },
  { nume: "LICEUL TEHNOLOGIC NR.1 COMUNA COROD", localitate: "COROD" },
  { nume: "LICEUL TEHNOLOGIC CORUND", localitate: "CORUND" },
  { nume: "LICEUL TEORETIC COSTEŞTI", localitate: "COSTEŞTI" },
  { nume: "LICEUL TEHNOLOGIC COSTESTI", localitate: "COSTEŞTI" },
  { nume: "LICEUL TEHNOLOGIC COTUSCA", localitate: "COŢUŞCA" },
  { nume: 'LICEUL "KŐRÖSI CSOMA SÁNDOR" COVASNA', localitate: "COVASNA" },
  { nume: "LICEUL TEHNOLOGIC COZMESTI", localitate: "COZMEŞTI" },
  { nume: 'COLEGIUL NATIONAL "CAROL I" CRAIOVA', localitate: "CRAIOVA" },
  {
    nume: 'COLEGIUL TEHNIC DE ARTE SI MESERII "CONSTANTIN BRANCUSI" CRAIOVA',
    localitate: "CRAIOVA",
  },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "PETRACHE TRISCU" CRAIOVA',
    localitate: "CRAIOVA",
  },
  { nume: 'LICEUL TEORETIC "HENRI COANDA" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'LICEUL TEORETIC "TUDOR ARGHEZI" CRAIOVA', localitate: "CRAIOVA" },
  { nume: "LICEUL TEOLOGIC ADVENTIST CRAIOVA", localitate: "CRAIOVA" },
  {
    nume: 'COLEGIUL NATIONAL "NICOLAE TITULESCU" CRAIOVA',
    localitate: "CRAIOVA",
  },
  { nume: 'COLEGIUL NATIONAL "FRATII BUZESTI" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'COLEGIUL NATIONAL "ELENA CUZA" CRAIOVA', localitate: "CRAIOVA" },
  {
    nume: 'COLEGIUL NATIONAL ECONOMIC "GHEORGHE CHITU" CRAIOVA',
    localitate: "CRAIOVA",
  },
  {
    nume: "COLEGIUL TEHNIC DE INDUSTRIE ALIMENTARA CRAIOVA",
    localitate: "CRAIOVA",
  },
  {
    nume: 'COLEGIUL TEHNIC "COSTIN D. NENITESCU" CRAIOVA',
    localitate: "CRAIOVA",
  },
  { nume: "COLEGIUL TEHNIC ENERGETIC CRAIOVA", localitate: "CRAIOVA" },
  { nume: 'COLEGIUL "STEFAN ODOBLEJA" CRAIOVA', localitate: "CRAIOVA" },
  { nume: "LICEUL TEHNOLOGIC AUTO CRAIOVA", localitate: "CRAIOVA" },
  {
    nume: "LICEUL TEHNOLOGIC DE TRANSPORTURI AUTO CRAIOVA",
    localitate: "CRAIOVA",
  },
  {
    nume: "LICEUL TEHNOLOGIC TRANSPORTURI CAI FERATE CRAIOVA",
    localitate: "CRAIOVA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC SPECIAL "BEETHOVEN" CRAIOVA',
    localitate: "CRAIOVA",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SFANTUL GRIGORIE TEOLOGUL" CRAIOVA',
    localitate: "CRAIOVA",
  },
  { nume: 'LICEUL DE ARTE "MARIN SORESCU" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'LICEUL "MATEI BASARAB" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'LICEUL "TRAIAN VUIA" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'LICEUL "CHARLES LAUGIER" CRAIOVA', localitate: "CRAIOVA" },
  { nume: 'LICEUL TEHNOLOGIC "GEORGE BIBESCU" CRAIOVA', localitate: "CRAIOVA" },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" CRAIOVA',
    localitate: "CRAIOVA",
  },
  {
    nume: 'COLEGIUL NATIONAL PEDAGOGIC "STEFAN VELOVAN" CRAIOVA',
    localitate: "CRAIOVA",
  },
  { nume: 'LICEUL TEHNOLOGIC "CSEREY-GOGA"', localitate: "CRASNA" },
  { nume: "LICEUL TEHNOLOGIC CRIMPOIA", localitate: "CRÎMPOIA" },
  { nume: 'LICEUL TEHNOLOGIC "CRISAN" CRISCIOR', localitate: "CRIŞCIOR" },
  {
    nume: 'LICEUL TEORETIC "ORBÁN BALÁZS" CRISTURU SECUIESC',
    localitate: "CRISTURU SECUIESC",
  },
  {
    nume: 'LICEUL TEOLOGIC UNITARIAN "BERDE MÓZES" CRISTURU SECUIESC',
    localitate: "CRISTURU SECUIESC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ZEYK DOMOKOS" CRISTURU SECUIESC',
    localitate: "CRISTURU SECUIESC",
  },
  { nume: "LICEUL TEHNOLOGIC CRUCEA", localitate: "CRUCEA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 COMUNA CUDALBI", localitate: "CUDALBI" },
  { nume: 'COLEGIUL NATIONAL "DAVID PRODAN" CUGIR', localitate: "CUGIR" },
  { nume: 'COLEGIUL TEHNIC "I. D. LAZARESCU" CUGIR', localitate: "CUGIR" },
  { nume: "LICEUL TEORETIC CUJMIR", localitate: "CUJMIR" },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE DUMITRESCU" CUMPĂNA',
    localitate: "CUMPANA",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "NEAGOE VODĂ BASARAB"',
    localitate: "CURTEA DE ARGEŞ",
  },
  { nume: 'COLEGIUL NAŢIONAL "VLAICU VODĂ"', localitate: "CURTEA DE ARGEŞ" },
  {
    nume: "LICEUL TEHNOLOGIC FORESTIER CURTEA DE ARGEŞ",
    localitate: "CURTEA DE ARGEŞ",
  },
  {
    nume: "LICEUL TEHNOLOGIC CONSTANTIN DOBRESCU",
    localitate: "CURTEA DE ARGEŞ",
  },
  { nume: 'LICEUL TEHNOLOGIC "FERDINAND I"', localitate: "CURTEA DE ARGEŞ" },
  {
    nume: "LICEUL TEHNOLOGIC AUTO CURTEA DE AG",
    localitate: "CURTEA DE ARGEŞ",
  },
  { nume: 'LICEUL TEHNOLOGIC "ION CREANGA" CURTICI', localitate: "CURTICI" },
  {
    nume: 'LICEUL TEORETIC "CONSTANTIN BRANCOVEANU" DABULENI',
    localitate: "DĂBULENI",
  },
  { nume: 'LICEUL TEHNOLOGIC "PETŐFI SÁNDOR" DĂNEŞTI', localitate: "DANEŞTI" },
  { nume: 'LICEUL "DIMITRIE CANTEMIR" DARABANI', localitate: "DARABANI" },
  { nume: "LICEUL TEHNOLOGIC DARMANESTI", localitate: "DĂRMĂNEŞTI" },
  { nume: 'LICEUL TEHNOLOGIC "VASILE NETEA" DEDA', localitate: "DEDA" },
  { nume: 'LICEUL TEORETIC "ALEXANDRU PAPIU ILARIAN" DEJ', localitate: "DEJ" },
  { nume: 'LICEUL TEHNOLOGIC "HENRI COANDĂ" DEJ', localitate: "DEJ" },
  { nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCUȘI" DEJ', localitate: "DEJ" },
  { nume: 'LICEUL TEHNOLOGIC "SOMEȘ" DEJ', localitate: "DEJ" },
  { nume: 'COLEGIUL NAȚIONAL "ANDREI MUREȘANU" DEJ', localitate: "DEJ" },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE ISTRĂŢOIU" DELENI',
    localitate: "DELENI",
  },
  { nume: 'LICEUL TEHNOLOGIC "SFANTUL NICOLAE" DETA', localitate: "DETA" },
  { nume: 'COLEGIUL NATIONAL SPORTIV "CETATE" DEVA', localitate: "DEVA" },
  { nume: 'COLEGIUL NATIONAL "DECEBAL" DEVA', localitate: "DEVA" },
  { nume: 'COLEGIUL TEHNIC "TRANSILVANIA" DEVA', localitate: "DEVA" },
  {
    nume: 'COLEGIUL TEHNIC ENERGETIC "DRAGOMIR HURMUZESCU" DEVA',
    localitate: "DEVA",
  },
  { nume: 'LICEUL DE ARTE "SIGISMUND TODUTA" DEVA', localitate: "DEVA" },
  { nume: 'LICEUL TEORETIC "TEGLAS GABOR" DEVA', localitate: "DEVA" },
  { nume: 'LICEUL TEHNOLOGIC "GRIGORE MOISIL" DEVA', localitate: "DEVA" },
  { nume: 'COLEGIUL NATIONAL "SABIN DRAGOI" DEVA', localitate: "DEVA" },
  { nume: 'LICEUL TEHNOLOGIC "PUSKÁS TIVADAR" DITRĂU', localitate: "DITRAU" },
  { nume: "LICEUL TEHNOLOGIC NR.1 DOBREŞTI", localitate: "DOBREŞTI" },
  { nume: 'LICEUL TEHNOLOGIC "RADU PRIŞCU" DOBROMIR', localitate: "DOBROMIR" },
  { nume: 'LICEUL TEHNOLOGIC "OLTEA DOAMNA" DOLHASCA', localitate: "DOLHASCA" },
  { nume: "LICEUL TEHNOLOGIC PETRE IONESCU MUSCEL", localitate: "DOMNEŞTI" },
  {
    nume: "LICEUL TEHNOLOGIC DORNA CANDRENILOR",
    localitate: "DORNA CANDRENILOR",
  },
  { nume: 'COLEGIUL NATIONAL "GRIGORE GHICA" DOROHOI', localitate: "DOROHOI" },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL ORTODOX "SF.IOAN IACOB" DOROHOI',
    localitate: "DOROHOI",
  },
  { nume: 'LICEUL "REGINA MARIA" DOROHOI', localitate: "DOROHOI" },
  {
    nume: 'LICEUL TEHNOLOGIC "DUILIU ZAMFIRESCU" DRAGALINA',
    localitate: "DRAGALINA",
  },
  {
    nume: 'LICEUL TEORETIC "TUDOR VLADIMIRESCU" DRAGANESTI-OLT',
    localitate: "DRĂGĂNEŞTI-OLT",
  },
  { nume: "LICEUL TEHNOLOGIC DRAGANESTI-OLT", localitate: "DRĂGĂNEŞTI-OLT" },
  {
    nume: "LICEUL TEHNOLOGIC DRĂGĂNEȘTI VLAȘCA",
    localitate: "DRAGANEŞTI-VLAŞCA",
  },
  {
    nume: 'COLEGIUL NATIONAL "GIB MIHAESCU" DRAGASANI',
    localitate: "DRĂGĂŞANI",
  },
  { nume: "LICEUL TEHNOLOGIC BRATIANU DRAGASANI", localitate: "DRĂGĂŞANI" },
  {
    nume: 'LICEUL TEHNOLOGIC "VINTILĂ BRĂTIANU"',
    localitate: "DRAGOMIREŞTI-VALE",
  },
  { nume: 'COLEGIUL TEHNIC "DECEBAL"', localitate: "DROBETA-TURNU SEVERIN" },
  {
    nume: 'COLEGIUL TEHNIC "DOMNUL TUDOR"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'COLEGIUL TEHNIC "LORIN SĂLĂGEAN"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: "COLEGIUL TEHNIC DE TRANSPORTURI AUTO",
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'COLEGIUL NAȚIONAL PEDAGOGIC "ȘTEFAN ODOBLEJA"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  { nume: 'COLEGIUL NAȚIONAL "TRAIAN"', localitate: "DROBETA-TURNU SEVERIN" },
  {
    nume: 'COLEGIUL NAȚIONAL ECONOMIC "THEODOR COSTESCU"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'LICEUL TEORETIC "ȘERBAN CIOCULESCU"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'LICEUL DE ARTĂ "I.ȘT.PAULIAN"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "GHEORGHE ȚIȚEICA"',
    localitate: "DROBETA-TURNU SEVERIN",
  },
  {
    nume: 'LICEUL TEORETIC "SFINTII KIRIL SI METODII" COM.DUDESTII VECHI',
    localitate: "DUDEŞTII VECHI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "MIHAI EMINESCU" DUMBRAVENI',
    localitate: "DUMBRAVENI",
  },
  { nume: "LICEUL TEORETIC DUMBRAVENI", localitate: "DUMBRĂVENI" },
  {
    nume: 'LICEUL TEHNOLOGIC "TIMOTEI CIPARIU" DUMBRAVENI',
    localitate: "DUMBRĂVENI",
  },
  { nume: "LICEUL TEHNOLOGIC DUMBRĂVIOARA", localitate: "DUMBRĂVIOARA" },
  {
    nume: 'LICEUL TEORETIC "GRIGORE GHEBA" DUMITRESTI',
    localitate: "DUMITREŞTI",
  },
  { nume: 'LICEUL TEORETIC "CARMEN SYLVA" EFORIE', localitate: "EFORIE SUD" },
  { nume: "LICEUL TEHNOLOGIC FĂCĂENI", localitate: "FACAENI" },
  { nume: 'COLEGIUL NAȚIONAL "RADU NEGRU" FĂGĂRAȘ', localitate: "FĂGĂRAŞ" },
  { nume: 'COLEGIUL NAȚIONAL "DOAMNA STANCA" FĂGĂRAȘ', localitate: "FĂGĂRAŞ" },
  {
    nume: 'LICEUL TEOLOGIC ORTODOX "SF. CONSTANTIN BRÂNCOVEANU" FĂGĂRAȘ',
    localitate: "FĂGĂRAŞ",
  },
  { nume: 'COLEGIUL "AUREL VIJOLI" FĂGĂRAȘ', localitate: "FĂGĂRAŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "DR. IOAN ȘENCHEA" FĂGĂRAȘ',
    localitate: "FĂGĂRAŞ",
  },
  { nume: "LICEUL TEHNOLOGIC FAGET", localitate: "FĂGET" },
  { nume: 'LICEUL TEORETIC "TRAIAN VUIA" FAGET', localitate: "FĂGET" },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE CANTEMIR" SAT FALCIU',
    localitate: "FALCIU",
  },
  { nume: 'COLEGIUL NATIONAL "NICU GANE" FALTICENI', localitate: "FĂLTICENI" },
  {
    nume: 'COLEGIUL TEHNIC "MIHAI BACESCU" FALTICENI',
    localitate: "FĂLTICENI",
  },
  { nume: 'COLEGIUL "VASILE LOVINESCU" FALTICENI', localitate: "FĂLTICENI" },
  { nume: "LICEUL TEHNOLOGIC FÂNTÂNELE", localitate: "FÂNTÂNELE" },
  { nume: 'SCOALA GIMNAZIALA "LUCIAN BLAGA" FARCASA', localitate: "FARCAŞA" },
  { nume: 'LICEUL TEORETIC "GEORGE VÂLSAN" FĂUREI', localitate: "FĂUREI" },
  { nume: 'LICEUL "PETRU RAREȘ" FELDIOARA', localitate: "FELDIOARA" },
  { nume: "LICEUL TEHNOLOGIC FELDRU", localitate: "FELDRU" },
  { nume: 'LICEUL TEORETIC "HENRI COANDĂ" FELDRU', localitate: "FELDRU" },
  { nume: 'LICEUL TEORETIC "CAROL I" FETEŞTI', localitate: "FETEŞTI" },
  {
    nume: "LICEUL TEHNOLOGIC DE INDUSTRIE ALIMENTARĂ FETEŞTI",
    localitate: "FETEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "ANGHEL SALIGNY" FETEŞTI', localitate: "FETEŞTI" },
  { nume: 'LICEUL TEHNOLOGIC "AUREL RAINU" FIENI', localitate: "FIENI" },
  { nume: "LICEUL TEHNOLOGIC FIERBINŢI-TÂRG", localitate: "FIERBINŢI-TÂRG" },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE FILISANU" FILIASI',
    localitate: "FILIAŞI",
  },
  {
    nume: "LICEUL TEORETIC COMUNA FILIPEȘTII DE PĂDURE",
    localitate: "FILIPEŞTII DE PADURE",
  },
  {
    nume: "LICEUL TEHNOLOGIC COMUNA FILIPEȘTII DE PĂDURE",
    localitate: "FILIPEŞTII DE PADURE",
  },
  {
    nume: 'COLEGIUL ECONOMIC "MIHAIL KOGALNICEANU" FOCSANI',
    localitate: "FOCŞANI",
  },
  { nume: 'COLEGIUL NATIONAL "UNIREA" FOCSANI', localitate: "FOCŞANI" },
  { nume: "LICEUL CU PROGRAM SPORTIV FOCSANI", localitate: "FOCŞANI" },
  { nume: 'LICEUL PEDAGOGIC "SPIRU HARET" FOCSANI', localitate: "FOCŞANI" },
  { nume: 'COLEGIUL NATIONAL "AL. I. CUZA" FOCSANI', localitate: "FOCŞANI" },
  {
    nume: 'CENTRUL SCOLAR PENTRU EDUCATIE INCLUZIVA "ELENA DOAMNA" FOCSANI',
    localitate: "FOCŞANI",
  },
  { nume: 'COLEGIUL TEHNIC "EDMOND NICOLAU" FOCSANI', localitate: "FOCŞANI" },
  { nume: 'COLEGIUL TEHNIC "VALERIU D. COTEA" FOCSANI', localitate: "FOCŞANI" },
  { nume: 'COLEGIUL TEHNIC "ION MINCU" FOCSANI', localitate: "FOCŞANI" },
  {
    nume: 'LICEUL DE ARTA "GHEORGHE TATTARESCU" FOCSANI',
    localitate: "FOCŞANI",
  },
  { nume: 'COLEGIUL TEHNIC AUTO "TRAIAN VUIA" FOCSANI', localitate: "FOCŞANI" },
  { nume: 'COLEGIUL TEHNIC "GHEORGHE ASACHI" FOCSANI', localitate: "FOCŞANI" },
  {
    nume: 'LICEUL TEHNOLOGIC "G.G. LONGINESCU" FOCSANI',
    localitate: "FOCŞANI",
  },
  { nume: "LICEUL TEHNOLOGIC FOCURI", localitate: "FOCURI" },
  { nume: "LICEUL TEHNOLOGIC NR.1 FUNDULEA", localitate: "FUNDULEA" },
  { nume: 'LICEUL TEHNOLOGIC "DR.C. ANGELESCU" GĂEȘTI', localitate: "GĂEŞTI" },
  { nume: 'LICEUL TEHNOLOGIC "IORDACHE GOLESCU" GĂEȘTI', localitate: "GĂEŞTI" },
  { nume: 'COLEGIUL NAȚIONAL "VLADIMIR STREINU" GĂEȘTI', localitate: "GĂEŞTI" },
  { nume: 'LICEUL TEORETIC "DUNAREA" A GALATI', localitate: "GALAŢI" },
  { nume: "LICEUL TEHNOLOGIC DE MARINA A GALATI", localitate: "GALAŢI" },
  {
    nume: 'COLEGIUL NATIONAL "ALEXANDRU IOAN CUZA" A GALATI',
    localitate: "GALAŢI",
  },
  { nume: 'LICEUL TEHNOLOGIC "ANGHEL SALIGNY" A GALATI', localitate: "GALAŢI" },
  {
    nume: 'COLEGIUL NATIONAL "MIHAIL KOGALNICEANU" A GALATI',
    localitate: "GALAŢI",
  },
  {
    nume: "LICEUL TEHNOLOGIC TRANSPORTURI CAI FERATE A GALATI",
    localitate: "GALAŢI",
  },
  { nume: 'LICEUL TEORETIC "MIRCEA ELIADE" A GALATI', localitate: "GALAŢI" },
  { nume: 'LICEUL TEORETIC "EMIL RACOVITA" A GALATI', localitate: "GALAŢI" },
  {
    nume: 'COLEGIUL NATIONAL "VASILE ALECSANDRI" A GALATI',
    localitate: "GALAŢI",
  },
  {
    nume: 'COLEGIUL DE INDUSTRIE ALIMENTARA "ELENA DOAMNA" A GALATI',
    localitate: "GALAŢI",
  },
  {
    nume: 'COLEGIUL ECONOMIC "VIRGIL MADGEARU" A GALATI',
    localitate: "GALAŢI",
  },
  { nume: 'COLEGIUL NATIONAL "COSTACHE NEGRI" A GALATI', localitate: "GALAŢI" },
  { nume: "LICEUL CU PROGRAM SPORTIV A GALATI", localitate: "GALAŢI" },
  { nume: 'COLEGIUL TEHNIC "TRAIAN VUIA" A GALATI', localitate: "GALAŢI" },
  {
    nume: 'LICEUL TEORETIC “DIMITRIE CANTEMIR" A GALATI',
    localitate: "GALAŢI",
  },
  { nume: 'LICEUL TEORETIC “MARIN COMAN" A GALATI', localitate: "GALAŢI" },
  {
    nume: 'COLEGIUL TEHNIC DE ALIMENTATIE SI TURISM "DUMITRU MOTOC" A GALATI',
    localitate: "GALAŢI",
  },
  { nume: 'COLEGIUL TEHNIC "PAUL DIMO" A GALATI', localitate: "GALAŢI" },
  { nume: 'COLEGIUL TEHNIC "AUREL VLAICU" A GALATI', localitate: "GALAŢI" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. ANDREI" A GALATI',
    localitate: "GALAŢI",
  },
  { nume: 'COLEGIUL TEHNIC "RADU NEGRU" A GALATI', localitate: "GALAŢI" },
  { nume: 'LICEUL TEORETIC "SFANTA MARIA" A GALATI', localitate: "GALAŢI" },
  { nume: 'LICEUL DE ARTE "DIMITRIE CUCLIN" A GALATI', localitate: "GALAŢI" },
  { nume: "LICEUL TEORETIC GATAIA", localitate: "GĂTAIA" },
  {
    nume: 'COLEGIUL TEHNIC AGRICOL "ALEXANDRU BORZA" GEOAGIU',
    localitate: "GEOAGIU",
  },
  { nume: "LICEUL TEORETIC GHELARI", localitate: "GHELARI" },
  {
    nume: 'LICEUL TEORETIC "SFÂNTU NICOLAE" GHEORGHENI',
    localitate: "GHEORGHENI",
  },
  {
    nume: 'LICEUL TEORETIC "SALAMON ERNŐ" GHEORGHENI',
    localitate: "GHEORGHENI",
  },
  {
    nume: 'COLEGIUL TEHNIC "BATTHYÁNY IGNÁC" GHEORGHENI',
    localitate: "GHEORGHENI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "FOGARASY MIHÁLY" GHEORGHENI',
    localitate: "GHEORGHENI",
  },
  { nume: 'LICEUL TEORETIC "ANA IPĂTESCU" GHERLA', localitate: "GHERLA" },
  { nume: 'LICEUL TEORETIC "PETRU MAIOR" GHERLA', localitate: "GHERLA" },
  { nume: "LICEUL TEHNOLOGIC GHERLA", localitate: "GHERLA" },
  {
    nume: "LICEUL TEORETIC CU PREDARE ÎN LIMBA MAGHIARĂ",
    localitate: "GHERLA",
  },
  { nume: 'LICEUL TEORETIC "GELU VOIEVOD" GILĂU', localitate: "GILAU" },
  { nume: "LICEUL TEHNOLOGIC NR.1 GÎLGĂU", localitate: "GÎLGAU" },
  {
    nume: 'COLEGIUL TEHNIC "VICEAMIRAL IOAN BALANESCU"',
    localitate: "GIURGIU",
  },
  { nume: "LICEUL TEHNOLOGIC GIURGIU", localitate: "GIURGIU" },
  { nume: 'LICEUL TEORETIC "TUDOR VIANU"', localitate: "GIURGIU" },
  { nume: 'LICEUL TEHNOLOGIC "ION BARBU"', localitate: "GIURGIU" },
  { nume: 'LICEUL TEORETIC "NICOLAE CARTOJAN"', localitate: "GIURGIU" },
  { nume: 'SEMINARUL TEOLOGIC "TEOCTIST PATRIARHUL"', localitate: "GIURGIU" },
  { nume: 'LICEUL TEHNOLOGIC "MIRON NICOLESCU"', localitate: "GIURGIU" },
  { nume: 'COLEGIUL NATIONAL "ION MAIORESCU"', localitate: "GIURGIU" },
  { nume: "LICEUL TEORETIC GRADISTEA", localitate: "GRADIŞTEA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ARETA TEODORESCU" GRIVIŢA',
    localitate: "GRIVIŢA",
  },
  {
    nume: 'COLEGIUL "ALEXANDRU CEL BUN" GURA HUMORULUI',
    localitate: "GURA HUMORULUI",
  },
  { nume: 'LICEUL "IOAN BUTEANU" GURAHONT', localitate: "GURAHONŢ" },
  { nume: "COLEGIUL SILVIC GURGHIU", localitate: "GURGHIU" },
  {
    nume: 'LICEUL TEORETIC "BOGDAN VODĂ" HĂLĂUCEŞTI',
    localitate: "HALAUCEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC HALÂNGA", localitate: "HALÎNGA" },
  { nume: 'LICEUL TEHNOLOGIC "MOGA VOIEVOD" HALMAGIU', localitate: "HALMAGIU" },
  { nume: 'LICEUL TEORETIC "IOAN COTOVU" HÂRŞOVA', localitate: "HÂRŞOVA" },
  { nume: 'LICEUL TEHNOLOGIC "CARSIUM" HÂRŞOVA', localitate: "HÂRŞOVA" },
  { nume: 'COLEGIUL NATIONAL "I.C. BRATIANU" HATEG', localitate: "HAŢEG" },
  { nume: 'LICEUL TEHNOLOGIC "LIVIU REBREANU" HIDA', localitate: "HIDA" },
  { nume: "LICEUL TEHNOLOGIC HÎRLĂU", localitate: "HÎRLĂU" },
  { nume: 'COLEGIUL NAȚIONAL "ȘTEFAN CEL MARE" HÎRLĂU', localitate: "HÎRLĂU" },
  { nume: "LICEUL TEHNOLOGIC HOLBOCA", localitate: "HOLBOCA" },
  { nume: 'LICEUL "CONSTANTIN BRANCOVEANU" HOREZU', localitate: "HOREZU" },
  {
    nume: 'LICEUL TEHNOLOGIC "ION IONESCU DE LA BRAD" COMUNA HORIA',
    localitate: "HORIA",
  },
  { nume: 'LICEUL "UDRISTE NASTUREL" HOTARELE', localitate: "HOTARELE" },
  { nume: 'LICEUL TEORETIC "OCTAVIAN GOGA" HUEDIN', localitate: "HUEDIN" },
  { nume: 'LICEUL TEHNOLOGIC "VLĂDEASA" HUEDIN', localitate: "HUEDIN" },
  {
    nume: 'COLEGIUL ECONOMIC "EMANUIL GOJDU" HUNEDOARA',
    localitate: "HUNEDOARA",
  },
  { nume: 'COLEGIUL TEHNIC "MATEI CORVIN" HUNEDOARA', localitate: "HUNEDOARA" },
  {
    nume: 'COLEGIUL NATIONAL DE INFORMATICA "TRAIAN LALESCU" HUNEDOARA',
    localitate: "HUNEDOARA",
  },
  {
    nume: 'COLEGIUL NATIONAL "IANCU DE HUNEDOARA" HUNEDOARA',
    localitate: "HUNEDOARA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BURSAN" HUNEDOARA',
    localitate: "HUNEDOARA",
  },
  { nume: 'COLEGIUL AGRICOL "DIMITRIE CANTEMIR" HUSI', localitate: "HUŞI" },
  { nume: 'COLEGIUL NATIONAL "CUZA VODA" HUSI', localitate: "HUŞI" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. IOAN GURA DE AUR" HUSI',
    localitate: "HUŞI",
  },
  { nume: 'LICEUL TEHNOLOGIC "IOAN CORIVAN" HUSI', localitate: "HUŞI" },
  { nume: 'LICEUL TEHNOLOGIC "LIVIU SUHAR" IACOBENI', localitate: "IACOBENI" },
  { nume: "LICEUL TEHNOLOGIC IACOBENI", localitate: "IACOBENI" },
  { nume: 'LICEUL TEORETIC "CONSTANTIN ANGELESCU" IANCA', localitate: "IANCA" },
  { nume: 'LICEUL TEHNOLOGIC "NICOLAE ONCESCU" IANCA', localitate: "IANCA" },
  { nume: "LICEUL TEHNOLOGIC IANCU JIANU", localitate: "IANCU JIANU" },
  { nume: 'LICEUL TEORETIC "VASILE ALECSANDRI" IAŞI', localitate: "IAŞI" },
  { nume: 'LICEUL TEORETIC "MIRON COSTIN" IAŞI', localitate: "IAŞI" },
  { nume: "LICEUL CU PROGRAM SPORTIV IAŞI", localitate: "IAŞI" },
  { nume: 'COLEGIUL TEHNIC "DIMITRIE LEONIDA" IAŞI', localitate: "IAŞI" },
  { nume: 'COLEGIUL "COSTACHE NEGRUZZI" IAŞI', localitate: "IAŞI" },
  { nume: 'LICEUL TEORETIC "DIMITRIE CANTEMIR" IAŞI', localitate: "IAŞI" },
  { nume: 'LICEUL TEORETIC "ALEXANDRU IOAN CUZA" IAŞI', localitate: "IAŞI" },
  { nume: 'COLEGIUL TEHNIC "GHEORGHE ASACHI" IAŞI', localitate: "IAŞI" },
  { nume: "COLEGIUL ECONOMIC ADMINISTRATIV IAŞI", localitate: "IAŞI" },
  { nume: 'COLEGIUL "RICHARD WURMBRAND" IASI', localitate: "IAŞI" },
  { nume: 'COLEGIUL TEHNIC "IOAN C. ŞTEFĂNESCU" IAŞI', localitate: "IAŞI" },
  { nume: 'COLEGIUL TEHNIC "MIHAIL STURDZA" IAŞI', localitate: "IAŞI" },
  {
    nume: 'COLEGIUL AGRICOL ŞI DE INDUSTRIE ALIMENTARĂ "VASILE ADAMACHI" IAŞI',
    localitate: "IAŞI",
  },
  { nume: 'COLEGIUL TEHNIC "ION HOLBAN" IASI', localitate: "IAŞI" },
  {
    nume: 'LICEUL TEHNOLOGIC ECONOMIC "VIRGIL MADGEARU" IAŞI',
    localitate: "IAŞI",
  },
  { nume: "LICEUL TEHNOLOGIC ECONOMIC DE TURISM IAŞI", localitate: "IAŞI" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. VASILE CEL MARE" IAŞI',
    localitate: "IAŞI",
  },
  { nume: "LICEUL TEORETIC WALDORF IAŞI", localitate: "IAŞI" },
  {
    nume: 'LICEUL TEORETIC DE INFORMATICĂ "GRIGORE MOISIL" IAŞI',
    localitate: "IAŞI",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE MECATRONICĂ ȘI AUTOMATIZĂRI IAȘI",
    localitate: "IAŞI",
  },
  {
    nume:
      'COLEGIUL TEHNIC DE ELECTRONICĂ ŞI TELECOMUNICAŢII "GHEORGHE MÂRZESCU" IAŞI',
    localitate: "IAŞI",
  },
  { nume: 'COLEGIUL NAȚIONAL "GARABET IBRĂILEANU" IAȘI', localitate: "IAŞI" },
  { nume: 'COLEGIUL NAȚIONAL "EMIL RACOVIŢĂ" IAŞI', localitate: "IAŞI" },
  { nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" IASI', localitate: "IAŞI" },
  { nume: 'COLEGIUL NAȚIONAL "MIHAI EMINESCU" IAŞI', localitate: "IAŞI" },
  { nume: 'LICEUL PEDAGOGIC "VASILE LUPU" IAȘI', localitate: "IAŞI" },
  {
    nume: "COLEGIUL TEHNIC DE TRANSPORTURI ȘI CONSTRUCȚII IAŞI",
    localitate: "IAŞI",
  },
  {
    nume: 'COLEGIUL NAȚIONAL DE ARTĂ "OCTAV BĂNCILĂ" IAŞI',
    localitate: "IAŞI",
  },
  { nume: 'LICEUL TEHNOLOGIC "PETRU PONI" IAȘI', localitate: "IAŞI" },
  { nume: "COLEGIUL NAȚIONAL IAŞI", localitate: "IAŞI" },
  { nume: "LICEUL TEHNOLOGIC IERNUT", localitate: "IERNUT" },
  { nume: 'LICEUL TEHNOLOGIC "IOACHIM POP" ILEANDA', localitate: "ILEANDA" },
  { nume: 'LICEUL TEORETIC "SILVIU DRAGOMIR" ILIA', localitate: "ILIA" },
  { nume: "LICEUL TEHNOLOGIC INDEPENDENŢA", localitate: "INDEPENDENŢA" },
  { nume: 'LICEUL "MIHAI VITEAZUL" INEU', localitate: "INEU" },
  { nume: 'LICEUL TEHNOLOGIC "SAVA BRANCOVICI" INEU', localitate: "INEU" },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE TITULESCU" ÎNSURĂŢEI',
    localitate: "ÎNSURĂŢEI",
  },
  {
    nume: 'LICEUL TEORETIC "MIRCEA ELIADE" ÎNTORSURA BUZĂULUI',
    localitate: "ÎNTORSURA BUZĂULUI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE BĂLCESCU" ÎNTORSURA BUZĂULUI',
    localitate: "ÎNTORSURA BUZĂULUI",
  },
  {
    nume: 'LICEUL TEORETIC "CONSTANTIN BRĂTESCU" ISACCEA',
    localitate: "ISACCEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "HORTENSIA PAPADAT BENGESCU" COMUNA IVESTI',
    localitate: "IVEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC IZVOARELE", localitate: "IZVOARELE" },
  { nume: 'LICEUL TEORETIC "ION AGARBICEANU" JIBOU', localitate: "JIBOU" },
  { nume: 'LICEUL TEHNOLOGIC "OCTAVIAN GOGA" JIBOU', localitate: "JIBOU" },
  { nume: "LICEUL TEHNOLOGIC JIDVEI", localitate: "JIDVEI" },
  { nume: "LICEUL TEHNOLOGIC JIMBOLIA", localitate: "JIMBOLIA" },
  { nume: 'LICEUL TEHNOLOGIC "SÖVÉR ELEK" JOSENI', localitate: "JOSENI" },
  { nume: "LICEUL TEORETIC LADESTI", localitate: "LADEŞTI" },
  { nume: "LICEUL TEHNOLOGIC LECHINȚA", localitate: "LECHINŢA" },
  {
    nume: 'LICEUL "ALEXANDRU ODOBESCU" LEHLIU GARA',
    localitate: "LEHLIU-GARĂ",
  },
  { nume: "LICEUL TEHNOLOGIC LESPEZI", localitate: "LESPEZI" },
  { nume: 'LICEUL "SEVER BOCU" LIPOVA', localitate: "LIPOVA" },
  { nume: 'LICEUL "ATANASIE MARIENESCU" LIPOVA', localitate: "LIPOVA" },
  {
    nume: 'LICEUL TEHNOLOGIC "IORGU VIRNAV LITEANU" LITENI',
    localitate: "LITENI",
  },
  { nume: 'LICEUL TEHNOLOGIC "GEORGE BARITIU" LIVADA', localitate: "LIVADA" },
  { nume: "LICEUL TEHNOLOGIC JUSTINIAN MARINA", localitate: "LIVADIA" },
  { nume: "LICEUL TEHNOLOGIC COMUNA LOPĂTARI", localitate: "LOPATARI" },
  {
    nume: 'LICEUL TEHNOLOGIC "ROMULUS PARASCHIVOIU" COM.LOVRIN',
    localitate: "LOVRIN",
  },
  { nume: "LICEUL TEHNOLOGIC NR.1 LUDUȘ", localitate: "LUDUŞ" },
  { nume: 'LICEUL TEHNOLOGIC "AUREL VLAICU" LUGOJ', localitate: "LUGOJ" },
  { nume: 'COLEGIUL NATIONAL "IULIA HASDEU" LUGOJ', localitate: "LUGOJ" },
  {
    nume: 'COLEGIUL NATIONAL "CORIOLAN BREDICEANU" LUGOJ',
    localitate: "LUGOJ",
  },
  { nume: 'COLEGIUL TEHNIC "VALERIU BRANISTE" LUGOJ', localitate: "LUGOJ" },
  { nume: 'LICEUL TEORETIC "PONTUS EUXINUS" LUMINA', localitate: "LUMINA" },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "SZENT ERZSÉBET" LUNCA DE SUS',
    localitate: "LUNCA DE SUS",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SIMION LEONESCU" LUNCAVIŢA',
    localitate: "LUNCAVIŢA",
  },
  { nume: "LICEUL TEHNOLOGIC LUNGANI", localitate: "LUNGANI" },
  { nume: "LICEUL TEHNOLOGIC LUPENI", localitate: "LUPENI" },
  { nume: 'LICEUL TEORETIC "MIRCEA ELIADE" LUPENI', localitate: "LUPENI" },
  {
    nume: 'LICEUL TEORETIC "GHEORGHE MUNTEANU MURGOCI" MĂCIN',
    localitate: "MĂCIN",
  },
  { nume: "LICEUL TEHNOLOGIC MĂCIN", localitate: "MĂCIN" },
  { nume: 'LICEUL TEORETIC "HORIA HULUBEI"', localitate: "MĂGURELE" },
  {
    nume: 'LICEUL TEHNOLOGIC "VASILE BACALU" MAHMUDIA',
    localitate: "MAHMUDIA",
  },
  { nume: 'LICEUL TEHNOLOGIC "LIVIU REBREANU" MAIERU', localitate: "MAIERU" },
  {
    nume: 'LICEUL TEHNOLOGIC "ING. IONETE AURELIAN" MALU MARE',
    localitate: "MALU MARE",
  },
  {
    nume:
      'SEMINARUL TEOLOGIC ORTODOX "VENIAMIN COSTACHI" SAT MÂNĂSTIREA NEAMȚ COMUNA VÂNĂTORI NEAMȚ',
    localitate: "MÂNĂSTIREA NEAMŢ",
  },
  {
    nume: "LICEUL TEHNOLOGIC SAT GHEABA COMUNA MĂNECIU",
    localitate: "MĂNECIU-UNGURENI",
  },
  { nume: "COLEGIUL ECONOMIC MANGALIA", localitate: "MANGALIA" },
  { nume: 'LICEUL TEORETIC "CALLATIS" MANGALIA', localitate: "MANGALIA" },
  { nume: 'LICEUL TEHNOLOGIC "ION BĂNESCU" MANGALIA', localitate: "MANGALIA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 MĂRĂCINENI", localitate: "MARACINENI" },
  {
    nume: 'LICEUL TEHNOLOGIC "EREMIA GRIGORESCU" MARASESTI',
    localitate: "MĂRĂŞEŞTI",
  },
  { nume: 'LICEUL TEORETIC "HORVATH JANOS" MARGHITA', localitate: "MARGHITA" },
  { nume: 'LICEUL TEHNOLOGIC "HOREA" MARGHITA', localitate: "MARGHITA" },
  {
    nume: 'COLEGIUL NAȚIONAL "OCTAVIAN GOGA" MARGHITA',
    localitate: "MARGHITA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VASILE GHERASIM" MARGINEA',
    localitate: "MARGINEA",
  },
  { nume: "COLEGIUL TEHNIC MATASARI", localitate: "MATASARI" },
  { nume: 'LICEUL TEHNOLOGIC "M.BASARAB"', localitate: "MAXINENI" },
  {
    nume: 'LICEUL TEORETIC "NICOLAE BĂLCESCU" MEDGIDIA',
    localitate: "MEDGIDIA",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "KEMAL ATATURK" MEDGIDIA',
    localitate: "MEDGIDIA",
  },
  {
    nume: 'COLEGIUL TEHNIC "NICOLAE TITULESCU" MEDGIDIA',
    localitate: "MEDGIDIA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DRAGOMIR HURMUZESCU" MEDGIDIA',
    localitate: "MEDGIDIA",
  },
  { nume: 'LICEUL TEORETIC "AXENTE SEVER" MEDIAS', localitate: "MEDIAŞ" },
  { nume: 'LICEUL TEORETIC "ST.L.ROTH" MEDIAS', localitate: "MEDIAŞ" },
  { nume: 'COLEGIUL TEHNIC "MEDIENSIS" MEDIAS', localitate: "MEDIAŞ" },
  { nume: "LICEUL TEHNOLOGIC AUTOMECANICA MEDIAS", localitate: "MEDIAŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "SCOALA NATIONALA DE GAZ" MEDIAS',
    localitate: "MEDIAŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE STOICA DE HAŢEG" MEHADIA',
    localitate: "MEHADIA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ALEXANDRU MACEDONSKI" MELINESTI',
    localitate: "MELINEŞTI",
  },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "SEGITŐ MÁRIA" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEORETIC "MÁRTON ÁRON" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "OCTAVIAN GOGA" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "JOANNES KÁJONI" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "KÓS KÁROLY" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SZÉKELY KÁROLY" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VENCZEL JÓZSEF" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL DE ARTE "NAGY ISTVÁN" MIERCUREA CIUC',
    localitate: "MIERCUREA CIUC",
  },
  {
    nume: 'LICEUL TEORETIC "BOCSKAI ISTVAN" MIERCUREA NIRAJULUI',
    localitate: "MIERCUREA NIRAJULUI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ILIE MACELARIU" MIERCUREA SIBIULUI',
    localitate: "MIERCUREA SIBIULUI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "MIHAI VITEAZUL" MIHAI VITEAZU',
    localitate: "MIHAI VITEAZU",
  },
  {
    nume: "LICEUL TEORETIC MIHAIL KOGĂLNICEANU",
    localitate: "MIHAIL KOGALNICEANU",
  },
  {
    nume: "LICEUL TEHNOLOGIC MIHAIL KOGĂLNICEANU",
    localitate: "MIHAIL KOGALNICEANU",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "TIU DUMITRESCU" MIHAILESTI',
    localitate: "MIHĂILEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "VASILE JUNCU" MINIS', localitate: "MINIŞ" },
  {
    nume: "LICEUL TEHNOLOGIC CONSTRUCŢII DE MAŞINI COLIBAŞI",
    localitate: "MIOVENI",
  },
  { nume: 'LICEUL TEORETIC "IULIA ZAMFIRESCU"', localitate: "MIOVENI" },
  {
    nume: 'LICEUL TEHNOLOGIC AGRICOL "MIHAIL KOGALNICEANU" MIROSLAVA',
    localitate: "MIROSLAVA",
  },
  { nume: 'LICEUL TEHNOLOGIC "TASE DUMITRESCU" MIZIL', localitate: "MIZIL" },
  { nume: 'LICEUL TEORETIC "GRIGORE TOCILESCU" MIZIL', localitate: "MIZIL" },
  {
    nume: 'LICEUL TEHNOLOGIC "DUMITRU POPA" MOGOSESTI',
    localitate: "MOGOŞEŞTI",
  },
  { nume: 'LICEUL TEORETIC "S.HARET" MOINESTI', localitate: "MOINEŞTI" },
  {
    nume: 'COLEGIUL TEHNIC "GRIGORE COBALCESCU" MOINESTI',
    localitate: "MOINEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CLISURA DUNARII" MOLDOVA NOUĂ',
    localitate: "MOLDOVA NOUĂ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VASILE COCEA" MOLDOVITA',
    localitate: "MOLDOVIŢA",
  },
  { nume: "LICEUL TEHNOLOGIC PETROL MORENI", localitate: "MORENI" },
  { nume: 'LICEUL TEORETIC "I.L. CARAGIALE" MORENI', localitate: "MORENI" },
  { nume: "COLEGIUL TEHNIC MOTRU", localitate: "MOTRU" },
  { nume: 'COLEGIUL NATIONAL "GEORGE COSBUC" MOTRU', localitate: "MOTRU" },
  { nume: "LICEUL TEHNOLOGIC LIVIU REBREANU", localitate: "MOZACENI" },
  { nume: "LICEUL TEORETIC MURFATLAR", localitate: "MURFATLAR" },
  { nume: 'LICEUL TEHNOLOGIC "GHENUTA COMAN" MURGENI', localitate: "MURGENI" },
  {
    nume: 'LICEUL TEORETIC "JOZEF GREGOR TAJOVSKY" NADLAC',
    localitate: "NĂDLAC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "TRAIAN GROZAVESCU" COM.NADRAG',
    localitate: "NADRAG",
  },
  { nume: "COLEGIUL ECONOMIC NĂSĂUD", localitate: "NĂSĂUD" },
  { nume: 'COLEGIUL NAȚIONAL "GEORGE COȘBUC" NĂSĂUD', localitate: "NĂSĂUD" },
  { nume: 'COLEGIUL SILVIC "TRANSILVANIA" NĂSĂUD', localitate: "NĂSĂUD" },
  {
    nume: 'LICEUL TEHNOLOGIC "LAZĂR EDELEANU" NĂVODARI',
    localitate: "NĂVODARI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE IORGA" NEGRESTI',
    localitate: "NEGREŞTI",
  },
  { nume: "LICEUL TEORETIC NEGRESTI OAS", localitate: "NEGREŞTI-OAŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "IONITA G. ANDRON" NEGRESTI-OAS',
    localitate: "NEGREŞTI-OAŞ",
  },
  { nume: "LICEUL TEORETIC NEGRU VODĂ", localitate: "NEGRU VODĂ" },
  { nume: 'LICEUL TEORETIC "NICOLAE IORGA" ORAŞ NEHOIU', localitate: "NEHOIU" },
  {
    nume: 'LICEUL TEHNOLOGIC "I.C.BRĂTIANU" NICOLAE BĂLCESCU',
    localitate: "NICOLAE BALCESCU",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE BALCESCU" FLAMINZI',
    localitate: "NICOLAE BĂLCESCU",
  },
  {
    nume: "LICEUL TEHNOLOGIC NISIPOREȘTI COMUNA BOTEȘTI",
    localitate: "NISIPOREŞTI",
  },
  { nume: "LICEUL TEORETIC NOVACI", localitate: "NOVACI" },
  { nume: "LICEUL TEHNOLOGIC NUCET", localitate: "NUCET" },
  { nume: "LICEUL TEHNOLOGIC NR.1 NUȘFALĂU", localitate: "NUŞFALAU" },
  {
    nume: 'LICEUL TEORETIC "PETRU MAIOR" OCNA MURES',
    localitate: "OCNA MUREŞ",
  },
  { nume: "LICEUL TEHNOLOGIC OCNA MURES", localitate: "OCNA MUREŞ" },
  { nume: "LICEUL TEHNOLOGIC OCNA SUGATAG", localitate: "OCNA ŞUGATAG" },
  {
    nume: 'LICEUL TEORETIC "DUILIU ZAMFIRESCU" ODOBESTI',
    localitate: "ODOBEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC ODOBESTI", localitate: "ODOBEŞTI" },
  {
    nume: 'LICEUL TEORETIC "TAMÁSI ÁRON" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'COLEGIUL TEHNIC "BÁNYAI JÁNOS" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'LICEUL PEDAGOGIC "BENEDEK ELEK" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'COLEGIUL REFORMAT "BACZKAMADARASI KIS GERGELY" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'LICEUL "MARIN PREDA" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "EÖTVÖS JÓZSEF" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "KÓS KÁROLY" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  {
    nume: 'LICEUL DE ARTE "PALLÓ IMRE" ODORHEIU SECUIESC',
    localitate: "ODORHEIU SECUIESC",
  },
  { nume: "LICEUL TEHNOLOGIC OGLINZI COMUNA RĂUCEȘTI", localitate: "OGLINZI" },
  { nume: "LICEUL TEORETIC OLTENI", localitate: "OLTENI" },
  { nume: 'LICEUL TEORETIC "NEAGOE BASARAB" OLTENITA', localitate: "OLTENIŢA" },
  { nume: 'LICEUL TEHNOLOGIC "ION GHICA" OLTENITA', localitate: "OLTENIŢA" },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE BALCESCU" OLTENITA',
    localitate: "OLTENIŢA",
  },
  { nume: 'COLEGIUL TEHNIC "PETRU PONI" ONESTI', localitate: "ONEŞTI" },
  {
    nume: 'COLEGIUL NATIONAL "DIMITRIE CANTEMIR" ONESTI',
    localitate: "ONEŞTI",
  },
  { nume: 'LICEUL TEOLOGIC "FERICITUL IEREMIA" ONESTI', localitate: "ONEŞTI" },
  { nume: 'COLEGIUL NATIONAL "GR.MOISIL" ONESTI', localitate: "ONEŞTI" },
  { nume: 'COLEGIUL TEHNIC "GHEORGHE ASACHI" ONESTI', localitate: "ONEŞTI" },
  { nume: 'COLEGIUL SPORTIV "NADIA COMANECI" ONESTI', localitate: "ONEŞTI" },
  { nume: "LICEUL TEHNOLOGIC ONESTI", localitate: "ONEŞTI" },
  { nume: 'LICEUL TEOLOGIC BAPTIST "EMANUEL" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL TEORETIC "AUREL LAZĂR" ORADEA', localitate: "ORADEA" },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "SZENT LASZLO" ORADEA',
    localitate: "ORADEA",
  },
  { nume: 'LICEUL TEOLOGIC PENTICOSTAL "BETEL" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL TEHNIC "TRANSILVANIA" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL CU PROGRAM SPORTIV "BIHORUL" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL "DON ORIONE" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL TEHNIC "TRAIAN VUIA" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL TEHNIC "MIHAI VITEAZUL" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL TEORETIC "ADY ENDRE" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL TEORETIC "LUCIAN BLAGA" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL ECONOMIC "PARTENIE COSMA" ORADEA', localitate: "ORADEA" },
  { nume: 'LICEUL TEORETIC "ONISIFOR GHIBU" ORADEA', localitate: "ORADEA" },
  {
    nume: 'LICEUL TEORETIC GERMAN "FRIEDRICH SCHILLER" ORADEA',
    localitate: "ORADEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC SANITAR "VASILE VOICULESCU" ORADEA',
    localitate: "ORADEA",
  },
  { nume: "LICEUL PARTICULAR HENRI COANDĂ ORADEA", localitate: "ORADEA" },
  {
    nume: 'LICEUL ORTODOX "EPISCOP ROMAN CIOROGARIU" ORADEA',
    localitate: "ORADEA",
  },
  { nume: 'LICEUL GRECO-CATOLIC "IULIU MANIU" ORADEA', localitate: "ORADEA" },
  { nume: "LICEUL DE ARTE ORADEA", localitate: "ORADEA" },
  {
    nume: 'LICEUL REFORMAT "LORANTFFY ZSUZSANNA" ORADEA',
    localitate: "ORADEA",
  },
  { nume: 'LICEUL TEHNOLOGIC "IOAN BOCOCI" ORADEA', localitate: "ORADEA" },
  {
    nume:
      'FUNDAŢIA CULTURAL UMANITARĂ "HENRI COANDĂ" ORADEA - LICEUL TEORETIC "HENRI COANDĂ" ORADEA',
    localitate: "ORADEA",
  },
  { nume: 'LICEUL TEHNOLOGIC "GEORGE BARIȚIU" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL NAȚIONAL "EMANUIL GOJDU" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL NAȚIONAL "MIHAI EMINESCU" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL NAȚIONAL "IOSIF VULCAN" ORADEA', localitate: "ORADEA" },
  {
    nume: 'COLEGIUL TEHNIC "CONSTANTIN BRÂNCUȘI" ORADEA',
    localitate: "ORADEA",
  },
  { nume: 'COLEGIUL TEHNIC "ANDREI ȘAGUNA" ORADEA', localitate: "ORADEA" },
  { nume: 'COLEGIUL NATIONAL "AUREL VLAICU" TIE', localitate: "ORĂŞTIE" },
  { nume: 'LICEUL TEHNOLOGIC "NICOLAUS OLAHUS" TIE', localitate: "ORĂŞTIE" },
  {
    nume: 'LICEUL TEORETIC "GENERAL DRAGALINA" ORAVITA',
    localitate: "ORAVIŢA",
  },
  { nume: 'LICEUL TEHNOLOGIC "MIHAI NOVAC“ ORAVIŢA', localitate: "ORAVIŢA" },
  { nume: 'LICEUL TEORETIC "TRAIAN LALESCU"', localitate: "ORŞOVA" },
  { nume: 'COLEGIUL TEHNIC "DIERNA"', localitate: "ORŞOVA" },
  { nume: "LICEUL TEHNOLOGIC AGRICOL COM.ORTISOARA", localitate: "ORŢIŞOARA" },
  {
    nume: 'LICEUL TEORETIC "ION GH. ROSCA" OSICA DE SUS',
    localitate: "OSICA DE SUS",
  },
  { nume: 'LICEUL "REGELE CAROL I" OSTROV', localitate: "OSTROV" },
  { nume: "LICEUL BĂNĂŢEAN OŢELU ROŞU", localitate: "OŢELU ROŞU" },
  { nume: 'LICEUL TEORETIC "IOAN PETRUŞ"', localitate: "OTOPENI" },
  { nume: "LICEUL TEORETIC MACIUCA", localitate: "OVESELU" },
  { nume: 'LICEUL TEHNOLOGIC "ION PODARU" OVIDIU', localitate: "OVIDIU" },
  { nume: 'LICEUL TEORETIC "IOAN SLAVICI" PANCIU', localitate: "PANCIU" },
  {
    nume: 'LICEUL TEHNOLOGIC "ALEXANDRU IOAN CUZA" PANCIU',
    localitate: "PANCIU",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GERGETA J.CANCICOV" PARINCEA',
    localitate: "PARINCEA",
  },
  { nume: 'LICEUL TEORETIC "MIRON COSTIN" PAŞCANI', localitate: "PAŞCANI" },
  {
    nume: 'COLEGIUL TEHNIC DE CĂI FERATE "UNIREA" PAŞCANI',
    localitate: "PAŞCANI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC ECONOMIC "NICOLAE IORGA" PAŞCANI',
    localitate: "PAŞCANI",
  },
  { nume: 'LICEUL TEHNOLOGIC "MIHAI BUSUIOC" PAŞCANI', localitate: "PAŞCANI" },
  {
    nume: 'COLEGIUL NAȚIONAL "MIHAIL SADOVEANU" PAŞCANI',
    localitate: "PAŞCANI",
  },
  {
    nume:
      'ȘCOALA POSTLICEALĂ SANITARĂ CENTRUL DE STUDII "ȘTEFAN CEL MARE ȘI SFÂNT" PASCANI',
    localitate: "PAŞCANI",
  },
  {
    nume: 'LICEUL TEORETIC "RADU VLĂDESCU" ORAŞ PĂTÂRLAGELE',
    localitate: "PĂTÂRLAGELE",
  },
  { nume: "LICEUL TEHNOLOGIC ORAŞ PĂTÂRLAGELE", localitate: "PĂTÂRLAGELE" },
  {
    nume: 'LICEUL TEHNOLOGIC "COSTACHE CONACHI" COMUNA PECHEA',
    localitate: "PECHEA",
  },
  { nume: 'LICEUL TEORETIC "GHEORGHE LAZAR" PECICA', localitate: "PECICA" },
  { nume: "LICEUL TEORETIC COM.PECIU NOU", localitate: "PECIU NOU" },
  { nume: "LICEUL TEORETIC COM.PERIAM", localitate: "PERIAM" },
  { nume: "LICEUL TEORETIC NR. 1 PERIȘ", localitate: "PERIŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "C-TIN BRANCUSI"- PESTISANI',
    localitate: "PEŞTIŞANI",
  },
  { nume: "LICEUL TEHNOLOGIC COMUNA PETRICANI", localitate: "PETRICANI" },
  {
    nume: 'COLEGIUL TEHNIC "CONSTANTIN BRANCUSI" PETRILA',
    localitate: "PETRILA",
  },
  { nume: 'COLEGIUL ECONOMIC "HERMES" PETROSANI', localitate: "PETROŞANI" },
  {
    nume: 'COLEGIUL TEHNIC "DIMITRIE LEONIDA" PETROSANI',
    localitate: "PETROŞANI",
  },
  {
    nume: 'COLEGIUL NATIONAL DE INFORMATICA "CARMEN SYLVA" PETROSANI',
    localitate: "PETROŞANI",
  },
  {
    nume: 'COLEGIUL NATIONAL "MIHAI EMINESCU" PETROSANI',
    localitate: "PETROŞANI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "APOLLONIUS" PETROSANI PARTICULAR',
    localitate: "PETROŞANI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ALEXANDRU FILIPASCU" PETROVA',
    localitate: "PETROVA",
  },
  { nume: "LICEUL TEORETIC PIATRA", localitate: "PIATRA" },
  {
    nume: 'COLEGIUL NAȚIONAL "GHEORGHE ASACHI" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "PETRU RAREȘ" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: "COLEGIUL NAȚIONAL DE INFORMATICĂ PIATRA-NEAMȚ",
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: "LICEUL TEHNOLOGIC ECONOMIC - ADMINISTRATIV PIATRA-NEAMȚ",
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume:
      'SEMINARUL TEOLOGIC ORTODOX "SFINȚII ÎMPARAȚI CONSTANTIN ȘI ELENA" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'COLEGIUL TEHNIC "GHEORGHE CARTIANU" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: "LICEUL CU PROGRAM SPORTIV PIATRA-NEAMȚ",
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'LICEUL DE ARTE "VICTOR BRAUNER" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "CALISTRAT HOGAȘ" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DIMITRIE LEONIDA" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SPIRU HARET" PIATRA-NEAMȚ',
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: "COLEGIUL TEHNIC DE TRANSPORTURI PIATRA-NEAMȚ",
    localitate: "PIATRA-NEAMŢ",
  },
  {
    nume: "COLEGIUL TEHNIC FORESTIER PIATRA-NEAMȚ",
    localitate: "PIATRA-NEAMŢ",
  },
  { nume: "LICEUL TEHNOLOGIC PIATRA-OLT", localitate: "PIATRA-OLT" },
  {
    nume:
      'LICEUL TEHNOLOGIC "SFÂNTUL IOAN DE LA SALLE" SAT PILDEȘTI COMUNA CORDUN',
    localitate: "PILDEŞTI",
  },
  { nume: "LICEUL TEORETIC PANCOTA", localitate: "PÎNCOTA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ION CREANGĂ" COMUNA PIPIRIG',
    localitate: "PIPIRIG",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICANOR MOROSAN" PARTESTII DE JOS',
    localitate: "PÎRTEŞTII DE JOS",
  },
  { nume: 'COLEGIUL TEHNIC "DIMITRIE DIMA"', localitate: "PITEŞTI" },
  { nume: 'LICEUL TEORETIC "ION BARBU"', localitate: "PITEŞTI" },
  { nume: "COLEGIUL ECONOMIC MARIA TEIULEANU", localitate: "PITEŞTI" },
  { nume: "LICEUL TEHNOLOGIC CONSTANTIN BRÂNCUŞI", localitate: "PITEŞTI" },
  { nume: "LICEUL TEHNOLOGIC DACIA", localitate: "PITEŞTI" },
  { nume: "LICEUL TEHNOLOGIC MIHAI VITEAZUL", localitate: "PITEŞTI" },
  { nume: 'COLEGIUL NAŢIONAL "ALEXANDRU ODOBESCU"', localitate: "PITEŞTI" },
  { nume: 'COLEGIUL NAŢIONAL "ZINCA GOLESCU"', localitate: "PITEŞTI" },
  { nume: "LICEUL PENTICOSTAL TEOLOGIC ELIM", localitate: "PITEŞTI" },
  { nume: 'LICEUL TEORETIC "ION CANTACUZINO"', localitate: "PITEŞTI" },
  { nume: 'COLEGIUL TEHNIC "COSTIN D. NENIŢESCU"', localitate: "PITEŞTI" },
  { nume: 'COLEGIUL NAŢIONAL "ION C. BRĂTIANU"', localitate: "PITEŞTI" },
  { nume: 'LICEUL CU PROGRAM SPORTIV "VIITORUL"', localitate: "PITEŞTI" },
  {
    nume: 'COLEGIUL ECOLOGIC "PROF.UNIV.DR.ALEXANDRU IONESCU"',
    localitate: "PITEŞTI",
  },
  { nume: 'COLEGIUL TEHNIC "ARMAND CĂLINESCU"', localitate: "PITEŞTI" },
  { nume: "FEG PITESTI", localitate: "PITEŞTI" },
  { nume: 'LICEUL DE ARTE "DINU LIPATTI"', localitate: "PITEŞTI" },
  { nume: "LICEUL TEHNOLOGIC ASTRA", localitate: "PITEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN NICOLAESCU-PLOPSOR" PLENITA',
    localitate: "PLENIŢA",
  },
  { nume: 'LICEUL ECONOMIC "SANSA" PLOIESTI', localitate: "PLOIEŞTI" },
  { nume: 'COLEGIUL NATIONAL "JEAN MONNET" PLOIESTI', localitate: "PLOIEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC DE SERVICII "SFÂNTUL APOSTOL ANDREI" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE TRANSPORTURI PLOIEȘTI",
    localitate: "PLOIEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "1 MAI" PLOIEȘTI', localitate: "PLOIEŞTI" },
  {
    nume:
      'LICEUL TEHNOLOGIC ADMINISTRATIV ȘI DE SERVICII "VICTOR SLĂVESCU" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "NICHITA STĂNESCU" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ANGHEL SALIGNY" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "MIHAI VITEAZUL" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'COLEGIUL ECONOMIC "VIRGIL MADGEARU" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "MIHAIL MANOILESCU" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  { nume: 'COLEGIUL TEHNIC "ELIE RADU" PLOIEȘTI', localitate: "PLOIEŞTI" },
  {
    nume: 'COLEGIUL NAȚIONAL "ION LUCA CARAGIALE" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  { nume: 'COLEGIUL TEHNIC "LAZĂR EDELEANU" PLOIEȘTI', localitate: "PLOIEŞTI" },
  { nume: 'COLEGIUL DE ARTĂ "CARMEN SYLVA" PLOIEȘTI', localitate: "PLOIEŞTI" },
  { nume: 'COLEGIUL "SPIRU HARET" PLOIEȘTI', localitate: "PLOIEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "LUDOVIC MRAZEK" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'COLEGIUL TEHNIC "TOMA N. SOCOLESCU" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "ALEXANDRU IOAN CUZA" PLOIEȘTI',
    localitate: "PLOIEŞTI",
  },
  { nume: 'COLEGIUL TEHNIC "GHEORGHE LAZĂR" PLOPENI', localitate: "PLOPENI" },
  { nume: "LICEUL TEHNOLOGIC PLOPENII MARI", localitate: "PLOPENII MARI" },
  { nume: "COLEGIUL AGRICOL POARTA ALBĂ", localitate: "POARTA ALBA" },
  {
    nume: 'LICEUL TEHNOLOGIC "HARALAMB VASILIU" PODU ILOAIEI',
    localitate: "PODU ILOAIEI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ALEXANDRU VLAHUTA" PODU TURCULUI',
    localitate: "PODU TURCULUI",
  },
  { nume: "LICEUL TEORETIC ORAŞ POGOANELE", localitate: "POGOANELE" },
  {
    nume: 'LICEUL TEORETIC "GEORGE ST. MARINCU" POIANA MARE',
    localitate: "POIANA MARE",
  },
  {
    nume: "LICEUL TEHNOLOGIC POIENILE DE SUB MUNTE",
    localitate: "POIENILE DE SUB MUNTE",
  },
  { nume: 'LICEUL TEORETIC "ANASTASIE BASOTA" POMIRLA', localitate: "POMÎRLA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 POPEȘTI", localitate: "POPEŞTI" },
  { nume: 'LICEUL "STEFAN DIACONESCU" POTCOAVA', localitate: "POTCOAVA" },
  { nume: "LICEUL TEHNOLOGIC BAILE GOVORA", localitate: "PRAJILA" },
  { nume: 'LICEUL TEORETIC "MIHAIL SĂULESCU" PREDEAL', localitate: "PREDEAL" },
  {
    nume:
      'COLEGIUL PENTRU AGRICULTURĂ ȘI INDUSTRIE ALIMENTARĂ "ȚARA BÂRSEI" PREJMER',
    localitate: "PREJMER",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IOSIF CORIOLAN BURACU“ PRIGOR',
    localitate: "PRIGOR",
  },
  { nume: "LICEUL TEHNOLOGIC NR.1 PRUNDU", localitate: "PRUNDU" },
  {
    nume: 'LICEUL "RADU PETRESCU" PRUNDU BÎRGĂULUI',
    localitate: "PRUNDU BÎRGAULUI",
  },
  { nume: "LICEUL TEHNOLOGIC PUCIOASA", localitate: "PUCIOASA" },
  {
    nume: 'COLEGIUL NAȚIONAL "NICOLAE TITULESCU" PUCIOASA',
    localitate: "PUCIOASA",
  },
  { nume: "LICEUL TEHNOLOGIC SAT PUIESTI", localitate: "PUIEŞTI" },
  { nume: 'LICEUL TEORETIC "ION GHICA" RĂCARI', localitate: "RĂCARI" },
  { nume: "LICEUL TEHNOLOGIC RACHITOASA", localitate: "RACHITOASA" },
  {
    nume: 'COLEGIUL NATIONAL "EUDOXIU HURMUZACHI" RADAUTI',
    localitate: "RĂDĂUŢI",
  },
  { nume: "COLEGIUL TEHNIC RADAUTI", localitate: "RĂDĂUŢI" },
  { nume: 'COLEGIUL "ANDRONIC MOTRESCU" RADAUTI', localitate: "RĂDĂUŢI" },
  {
    nume: 'LICEUL TEORETIC "LASCAR ROSETTI" RADUCĂNENI',
    localitate: "RADUCANENI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC ECONOMIC "ELINA MATEI BASARAB" RÎMNICU SĂRAT',
    localitate: "RÂMNICU SĂRAT",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VICTOR FRUNZĂ" RÎMNICU SĂRAT',
    localitate: "RÂMNICU SĂRAT",
  },
  {
    nume: 'LICEUL TEORETIC "ŞTEFAN CEL MARE" RÂMNICU SĂRAT',
    localitate: "RÂMNICU SĂRAT",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "ALEXANDRU VLAHUŢĂ" RÎMNICU SĂRAT',
    localitate: "RÂMNICU SĂRAT",
  },
  { nume: "COLEGIUL ENERGETIC RM. VALCEA", localitate: "RÂMNICU VÂLCEA" },
  { nume: "LICEUL TEHNOLOGIC FERDINAND I", localitate: "RÂMNICU VÂLCEA" },
  {
    nume: "COLEGIUL NATIONAL DE INFORMATICA MATEI BASARAB",
    localitate: "RÂMNICU VÂLCEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GENERAL MAGHERU" RM. VALCEA',
    localitate: "RÂMNICU VÂLCEA",
  },
  { nume: "LICEUL TEHNOLOGIC OLTCHIM", localitate: "RÂMNICU VÂLCEA" },
  {
    nume: 'COLEGIUL NATIONAL "MIRCEA CEL BATRIN" RM. VALCEA',
    localitate: "RÂMNICU VÂLCEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CAPITAN NICOLAE PLESOIANU" RM. VALCEA',
    localitate: "RÂMNICU VÂLCEA",
  },
  { nume: 'LICEUL TEHNOLOGIC "HENRI COANDA"', localitate: "RÂMNICU VÂLCEA" },
  {
    nume: "SEMINARUL TEOLOGIC ORTODOX SF NICOLAE",
    localitate: "RÂMNICU VÂLCEA",
  },
  { nume: 'LICEUL "ANTIM IVIREANU"', localitate: "RÂMNICU VÂLCEA" },
  { nume: "COLEGIUL ECONOMIC", localitate: "RÂMNICU VÂLCEA" },
  {
    nume: 'LICEUL DE ARTE "VICTOR GIULEANU" RM. VALCEA',
    localitate: "RÂMNICU VÂLCEA",
  },
  {
    nume: "COLEGIUL DE SILVICULTURA SI PROTECTIA MEDIULUI",
    localitate: "RÂMNICU VÂLCEA",
  },
  {
    nume: 'COLEGIUL NATIONAL "ALEXANDRU LAHOVARI"',
    localitate: "RÂMNICU VÂLCEA",
  },
  { nume: "LICEUL TEHNOLOGIC RÂȘNOV", localitate: "RÂŞNOV" },
  { nume: "LICEUL TEORETIC RECAS", localitate: "RECAŞ" },
  { nume: 'LICEUL TEORETIC "EUGEN NICOARĂ" REGHIN', localitate: "REGHIN" },
  { nume: 'LICEUL TEHNOLOGIC "IOAN BOJOR" REGHIN', localitate: "REGHIN" },
  { nume: 'LICEUL TEHNOLOGIC "LUCIAN BLAGA" REGHIN', localitate: "REGHIN" },
  { nume: 'LICEUL TEHNOLOGIC "PETRU MAIOR" REGHIN', localitate: "REGHIN" },
  { nume: "LICEUL TEHNOLOGIC REPEDEA", localitate: "REPEDEA" },
  { nume: 'LICEUL TEORETIC "TRAIAN VUIA" REŞIŢA', localitate: "REŞIŢA" },
  { nume: "LICEUL TEOLOGIC BAPTIST REŞIŢA", localitate: "REŞIŢA" },
  { nume: 'LICEUL TEORETIC "DIACONOVICI TIETZ" REŞIŢA', localitate: "REŞIŢA" },
  {
    nume: "COLEGIUL ECONOMIC AL BANATULUI MONTAN REŞIŢA",
    localitate: "REŞIŢA",
  },
  { nume: "COLEGIUL TEHNIC REŞIŢA", localitate: "REŞIŢA" },
  { nume: "COLEGIUL TEHNIC CĂRĂŞAN REŞIŢA", localitate: "REŞIŢA" },
  { nume: 'COLEGIUL NAŢIONAL "TRAIAN LALESCU“ REŞIŢA', localitate: "REŞIŢA" },
  { nume: 'LICEUL DE ARTE "SABIN PĂUŢA“ REŞIŢA', localitate: "REŞIŢA" },
  { nume: 'COLEGIUL NAŢIONAL "MIRCEA ELIADE“ REŞIŢA', localitate: "REŞIŢA" },
  { nume: 'COLEGIUL NAȚIONAL "ROMAN VODĂ" ROMAN', localitate: "ROMAN" },
  { nume: 'LICEUL TEOLOGIC "EPISCOP MELCHISEDEC" ROMAN', localitate: "ROMAN" },
  { nume: "LICEUL CU PROGRAM SPORTIV ROMAN", localitate: "ROMAN" },
  { nume: 'LICEUL TEHNOLOGIC "VASILE SAV" ROMAN', localitate: "ROMAN" },
  { nume: 'COLEGIUL TEHNIC "MIRON COSTIN" ROMAN', localitate: "ROMAN" },
  { nume: 'COLEGIUL TEHNIC "DANUBIANA" ROMAN', localitate: "ROMAN" },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "SFÂNTUL FRANCISC DE ASSISI" ROMAN',
    localitate: "ROMAN",
  },
  { nume: 'COLEGIUL TEHNIC "PETRU PONI" ROMAN', localitate: "ROMAN" },
  {
    nume: "LICEUL TEHNOLOGIC ROSIA DE AMARADIA",
    localitate: "ROŞIA DE AMARADIA",
  },
  {
    nume: "LICEUL TEHNOLOGIC NR. 2 ROȘIORI DE VEDE",
    localitate: "ROŞIORI DE VEDE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VIRGIL MADGEARU" ROȘIORI DE VEDE',
    localitate: "ROŞIORI DE VEDE",
  },
  {
    nume: 'COLEGIUL TEHNIC "ANGHEL SALIGNY" ROȘIORI DE VEDE',
    localitate: "ROŞIORI DE VEDE",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "ANASTASESCU" ROȘIORI DE VEDE',
    localitate: "ROŞIORI DE VEDE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "EMIL RACOVIȚĂ" ROȘIORI DE VEDE',
    localitate: "ROŞIORI DE VEDE",
  },
  { nume: 'LICEUL TEHNOLOGIC "DOAMNA CHIAJNA"', localitate: "ROŞU" },
  { nume: 'COLEGIUL "GHEORGHE TATARESCU" ROVINARI', localitate: "ROVINARI" },
  {
    nume: 'LICEUL TEHNOLOGIC "OCTAVIAN GOGA" ROZAVLEA',
    localitate: "ROZAVLEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GH. RUSET ROZNOVANU" ORAȘ ROZNOV',
    localitate: "ROZNOV",
  },
  { nume: "LICEUL TEHNOLOGIC VICTOR SLĂVESCU", localitate: "RUCAR" },
  { nume: 'LICEUL "ȘT.O.IOSIF" RUPEA', localitate: "RUPEA" },
  { nume: "LICEUL TEHNOLOGIC RUSCOVA", localitate: "RUSCOVA" },
  { nume: "LICEUL TEHNOLOGIC COMUNA RUŞEŢU", localitate: "RUŞEŢU" },
  {
    nume: 'LICEUL TEORETIC "VASILE ALECSANDRI" COMUNA SĂBĂOANI',
    localitate: "SABAOANI",
  },
  { nume: 'LICEUL TEORETIC "GEORGE MOROIANU" SĂCELE', localitate: "SĂCELE" },
  { nume: 'LICEUL TEORETIC "ZAJZONI RAB ISTVAN" SĂCELE', localitate: "SĂCELE" },
  { nume: 'LICEUL TEHNOLOGIC "VICTOR JINGA" SĂCELE', localitate: "SĂCELE" },
  { nume: 'LICEUL TEORETIC "PETOFI SANDOR" SĂCUENI', localitate: "SĂCUENI" },
  { nume: 'LICEUL TEHNOLOGIC "IOAN LUPAS" SALISTE', localitate: "SĂLIŞTE" },
  { nume: "LICEUL TEHNOLOGIC NR.1 SALONTA", localitate: "SALONTA" },
  { nume: 'LICEUL TEORETIC "ARANY JANOS" SALONTA', localitate: "SALONTA" },
  { nume: 'COLEGIUL NAȚIONAL "TEODOR NEȘ" SALONTA', localitate: "SALONTA" },
  {
    nume: 'LICEUL TEHNOLOGIC "SFÂNTUL GHEORGHE" SÂNGEORGIU DE PĂDURE',
    localitate: "SÂNGEORGIU DE PĂDURE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "TIVAI NAGY IMRE" SÂNMARTIN',
    localitate: "SÂNMARTIN",
  },
  {
    nume: 'LICEUL TEORETIC "IOAN JEBELEAN" SANNICOLAU MARE',
    localitate: "SÂNNICOLAU MARE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CRISTOFOR NAKO" SANNICOLAU MARE',
    localitate: "SÂNNICOLAU MARE",
  },
  { nume: "LICEUL TEHNOLOGIC SANTANA", localitate: "SÂNTANA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 SĂRMĂȘAG", localitate: "ŞARMAŞAG" },
  { nume: 'LICEUL TEORETIC "SAMUIL MICU" SĂRMAȘU', localitate: "SARMAŞU" },
  { nume: 'LICEUL TEHNOLOGIC "JACQUES M. ELIAS" SASCUT', localitate: "SASCUT" },
  {
    nume: 'COLEGIUL NATIONAL "MIHAI EMINESCU" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'COLEGIUL NATIONAL "KOLCSEY FERENC" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'COLEGIUL NAŢIONAL "IOAN SLAVICI" SATU MARE',
    localitate: "SATU MARE",
  },
  { nume: "LICEUL CU PROGRAM SPORTIV SATU MARE", localitate: "SATU MARE" },
  {
    nume: 'COLEGIUL NATIONAL "DOAMNA STANCA" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'LICEUL TEORETIC GERMAN "JOHANN ETTINGER" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'COLEGIUL TEHNIC "ELISA ZAMFIRESCU" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'LICEUL TEOLOGIC ORTODOX "NICOLAE STEINHARDT" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume:
      'COLEGIUL TEHNIC DE TRANSPORTURI SI TELECOMUNICATII "ION I. C. BRATIANU" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'COLEGIUL ECONOMIC "GHEORGHE DRAGOS" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume:
      'LICEUL TEHNOLOGIC DE INDUSTRIE ALIMENTARA "GEORGE EMIL PALADE" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "HAM JANOS" SATU MARE',
    localitate: "SATU MARE",
  },
  { nume: 'LICEUL DE ARTE "AUREL POPP" SATU MARE', localitate: "SATU MARE" },
  { nume: "LICEUL REFORMAT SATU MARE", localitate: "SATU MARE" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRANCUSI" SATU MARE',
    localitate: "SATU MARE",
  },
  {
    nume: 'COLEGIUL TEHNIC "UNIO-TRAIAN VUIA" SATU MARE',
    localitate: "SATU MARE",
  },
  { nume: 'LICEUL TEORETIC "DR. MIHAI CIUCA" SAVENI', localitate: "SĂVENI" },
  { nume: "LICEUL TEHNOLOGIC SAVARSIN", localitate: "SAVÎRŞIN" },
  { nume: "LICEUL TEHNOLOGIC SCÂNTEIA", localitate: "SCÂNTEIA" },
  { nume: 'LICEUL TEHNOLOGIC "NICOLAE BĂLĂUŢĂ" ŞCHEIA', localitate: "SCHEIA" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRINCOVEANU" SCORNICESTI',
    localitate: "SCORNICEŞTI",
  },
  { nume: 'COLEGIUL NATIONAL "LUCIAN BLAGA" SEBES', localitate: "SEBEŞ" },
  { nume: "LICEUL CU PROGRAM SPORTIV SEBES", localitate: "SEBEŞ" },
  { nume: "LICEUL TEHNOLOGIC SEBES", localitate: "SEBEŞ" },
  { nume: "LICEUL TEORETIC SEBIS", localitate: "SEBIŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "HORIA VINTILA" SEGARCEA',
    localitate: "SEGARCEA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC AGRICOL "ALEXIU BERINDE" SEINI',
    localitate: "SEINI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "AL.VLAHUTA" SENDRICENI',
    localitate: "ŞENDRICENI",
  },
  {
    nume: 'LICEUL TEORETIC "SZÉKELY MIKÓ" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "PUSKÁS TIVADAR" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume:
      'LICEUL TEHNOLOGIC ECONOMIC- ADMINISTRATIV "BERDE ÁRON" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "KÓS KÁROLY" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'LICEUL TEORETIC "MIKES KELEMEN" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "MIHAI VITEAZUL" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCUȘI" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: "LICEUL TEOLOGIC REFORMAT SFÂNTU GHEORGHE",
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'LICEUL DE ARTE "PLUGOR SÁNDOR" SFÂNTU GHEORGHE',
    localitate: "SFÎNTU GHEORGHE",
  },
  {
    nume: 'COLEGIUL NATIONAL "SAMUEL VON BRUKENTHAL" SIBIU',
    localitate: "SIBIU",
  },
  { nume: 'LICEUL TEORETIC "ONISIFOR GHIBU" SIBIU', localitate: "SIBIU" },
  { nume: "LICEUL DE ARTA SIBIU", localitate: "SIBIU" },
  {
    nume: 'COLEGIUL TEHNIC DE INDUSTRIE ALIMENTARA "TEREZIANUM" SIBIU',
    localitate: "SIBIU",
  },
  { nume: 'LICEUL TEORETIC "C.NOICA" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL NATIONAL PEDAGOGIC "A.SAGUNA" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL AGRICOL "D.P.BARCIANU" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL ECONOMIC "G.BARITIU" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL NATIONAL "O.GOGA" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL NATIONAL "GH.LAZAR" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL TEHNIC "INDEPENDENTA" SIBIU', localitate: "SIBIU" },
  { nume: 'COLEGIUL TEHNIC "CIBINIUM" SIBIU', localitate: "SIBIU" },
  { nume: "COLEGIUL TEHNIC ENERGETIC SIBIU", localitate: "SIBIU" },
  {
    nume: 'LICEUL TEHNOLOGIC CONSTRUCTII SI ARHITECTURA "CAROL I" SIBIU',
    localitate: "SIBIU",
  },
  { nume: 'LICEUL TEHNOLOGIC "AVRAM IANCU" SIBIU', localitate: "SIBIU" },
  { nume: 'LICEUL TEHNOLOGIC "HENRI COANDA" SIBIU', localitate: "SIBIU" },
  { nume: 'LICEUL TEHNOLOGIC "TRANSCOM" SIBIU', localitate: "SIBIU" },
  { nume: "LICEUL TEHNOLOGIC NR.1 SÎG", localitate: "SÎG" },
  {
    nume: 'COLEGIUL NATIONAL "DRAGOS VODA" SIGHETU MARMATIEI',
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: "LICEUL TEHNOLOGIC FORESTIER SIGHETU MARMATIEI",
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: "LICEUL TEHNOLOGIC MARMATIA SIGHETU MARMATIEI",
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: 'LICEUL PEDAGOGIC "TARAS SEVCENKO" SIGHETU MARMATIEI',
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: 'LICEUL PEDAGOGIC "REGELE FERDINAND" SIGHETU MARMATIEI',
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE POP DE BASESTI" SIGHETU MARMATIEI',
    localitate: "SIGHETU MARMAŢIEI",
  },
  {
    nume: 'LICEUL TEORETIC "LEOWEY KLARA" SIGHETU MARMATIEI',
    localitate: "SIGHETU MARMAŢIEI",
  },
  { nume: "LICEUL TEHNOLOGIC NR.1 SIGHIȘOARA", localitate: "SIGHIŞOARA" },
  { nume: "LICEUL TEHNOLOGIC NR.2 SIGHIȘOARA", localitate: "SIGHIŞOARA" },
  {
    nume: 'COLEGIUL NAȚIONAL "MIRCEA ELIADE" SIGHIȘOARA',
    localitate: "SIGHIŞOARA",
  },
  {
    nume: 'LICEUL TEORETIC "JOSEPH HALTRICH" SIGHIȘOARA',
    localitate: "SIGHIŞOARA",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SFANTA ECATERINA" PRISLOP',
    localitate: "SILVAŞU DE SUS",
  },
  {
    nume: 'COLEGIUL TEHNIC DE TRANSPORT FEROVIAR "ANGHEL SALIGNY" SIMERIA',
    localitate: "SIMERIA",
  },
  { nume: 'LICEUL TEHNOLOGIC "TUDOR VLADIMIRESCU"', localitate: "ŞIMIAN" },
  {
    nume: 'COLEGIUL TEHNIC "IULIU MANIU" ȘIMLEU SILVANIEI',
    localitate: "ŞIMLEU SILVANIEI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IOAN OSSIAN" ȘIMLEU SILVANIEI',
    localitate: "ŞIMLEU SILVANIEI",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "SIMION BĂRNUȚIU" ȘIMLEU SILVANIEI',
    localitate: "ŞIMLEU SILVANIEI",
  },
  { nume: 'COLEGIUL "MIHAIL CANTACUZINO" SINAIA', localitate: "SINAIA" },
  {
    nume: 'LICEUL TEORETIC "SOLOMON HALIȚĂ" SÎNGEORZ-BĂI',
    localitate: "SÎNGEORZ-BĂI",
  },
  { nume: "LICEUL TEHNOLOGIC COMUNA SÎNGERU", localitate: "SÎNGERU" },
  { nume: 'COLEGIUL TEHNIC "LATCU VODA" SIRET', localitate: "SIRET" },
  { nume: 'LICEUL TEORETIC "GHEORGHE IONESCU ȘIȘEȘTI"', localitate: "ŞIŞEŞTI" },
  { nume: 'LICEUL TEORETIC "ȘERBAN VODĂ" SLĂNIC', localitate: "SLĂNIC" },
  {
    nume: 'COLEGIUL NATIONAL AGRICOL "CAROL I" SLATINA',
    localitate: "SLATINA",
  },
  { nume: 'COLEGIUL ECONOMIC "P.S.AURELIAN" SLATINA', localitate: "SLATINA" },
  { nume: 'COLEGIUL NATIONAL "RADU GRECEANU" SLATINA', localitate: "SLATINA" },
  { nume: 'COLEGIUL NATIONAL "ION MINULESCU" SLATINA', localitate: "SLATINA" },
  { nume: "LICEUL CU PROGRAM SPORTIV SLATINA", localitate: "SLATINA" },
  {
    nume: 'COLEGIUL NATIONAL VOCATIONAL "NICOLAE TITULESCU" SLATINA',
    localitate: "SLATINA",
  },
  { nume: 'COLEGIUL TEHNIC "ALEXE MARIN" SLATINA', localitate: "SLATINA" },
  { nume: "COLEGIUL TEHNIC METALURGIC SLATINA", localitate: "SLATINA" },
  { nume: 'LICEUL PEDAGOGIC "MATEI BASARAB" SLOBOZIA', localitate: "SLOBOZIA" },
  {
    nume: 'COLEGIUL NAŢIONAL "MIHAI VITEAZUL" SLOBOZIA',
    localitate: "SLOBOZIA",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF.IOAN GURĂ DE AUR" SLOBOZIA',
    localitate: "SLOBOZIA",
  },
  { nume: 'LICEUL DE ARTE "IONEL PERLEA" SLOBOZIA', localitate: "SLOBOZIA" },
  { nume: 'LICEUL TEHNOLOGIC "AL.IOAN CUZA" SLOBOZIA', localitate: "SLOBOZIA" },
  {
    nume: 'LICEUL TEHNOLOGIC "ÎNĂLŢAREA DOMNULUI" SLOBOZIA',
    localitate: "SLOBOZIA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "MIHAI EMINESCU" SLOBOZIA',
    localitate: "SLOBOZIA",
  },
  { nume: "LICEUL TEHNOLOGIC AGRICOL COMUNA SMEENI", localitate: "SMEENI" },
  { nume: 'LICEUL TEORETIC "MIHAIL KOGĂLNICEANU"', localitate: "SNAGOV" },
  { nume: 'LICEUL TEHNOLOGIC "TOMSA VODA" SOLCA', localitate: "SOLCA" },
  {
    nume: 'LICEUL TEORETIC "IOAN BUTEANU" SOMCUTA MARE',
    localitate: "ŞOMCUŢA MARE",
  },
  { nume: 'LICEUL TEHNOLOGIC "DOMOKOS KAZMER" SOVATA', localitate: "SOVATA" },
  { nume: "LICEUL TEHNOLOGIC COMUNA STARCHIOJD", localitate: "STARCHIOJD" },
  {
    nume: 'LICEUL TEORETIC "STEFAN D. LUCHIAN"" STEFANESTI',
    localitate: "ŞTEFĂNEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC DINU BRĂTIANU", localitate: "ŞTEFĂNEŞTI" },
  { nume: 'COLEGIUL TEHNIC "UNIREA" ȘTEI', localitate: "ŞTEI" },
  { nume: 'COLEGIUL NAȚIONAL "AVRAM IANCU" ȘTEI', localitate: "ŞTEI" },
  { nume: "LICEUL TEHNOLOGIC I.C. PETRESCU", localitate: "STÎLPENI" },
  { nume: "LICEUL TEHNOLOGIC STOINA", localitate: "STOINA" },
  { nume: 'LICEUL TEHNOLOGIC "MATEI BASARAB"', localitate: "STREHAIA" },
  { nume: 'LICEUL "MIRON CRISTEA" SUBCETATE', localitate: "SUBCETATE" },
  {
    nume: 'COLEGIUL NATIONAL "STEFAN CEL MARE" SUCEAVA',
    localitate: "SUCEAVA",
  },
  { nume: 'COLEGIUL NATIONAL "PETRU RARES" SUCEAVA', localitate: "SUCEAVA" },
  { nume: "LICEUL CU PROGRAM SPORTIV SUCEAVA", localitate: "SUCEAVA" },
  { nume: 'COLEGIUL NATIONAL "MIHAI EMINESCU" SUCEAVA', localitate: "SUCEAVA" },
  {
    nume: 'COLEGIUL ECONOMIC "DIMITRIE CANTEMIR" SUCEAVA',
    localitate: "SUCEAVA",
  },
  {
    nume: 'SEMINARUL TEOLOGIC LICEAL ORTODOX "MITROPOLITUL DOSOFTEI" SUCEAVA',
    localitate: "SUCEAVA",
  },
  { nume: 'COLEGIUL TEHNIC "PETRU MUSAT" SUCEAVA', localitate: "SUCEAVA" },
  {
    nume: 'COLEGIUL NATIONAL DE INFORMATICA "SPIRU HARET" SUCEAVA',
    localitate: "SUCEAVA",
  },
  {
    nume: "COLEGIUL TEHNIC DE INDUSTRIE ALIMENTARA SUCEAVA",
    localitate: "SUCEAVA",
  },
  { nume: 'COLEGIUL TEHNIC "AL. I. CUZA" SUCEAVA', localitate: "SUCEAVA" },
  { nume: 'COLEGIUL TEHNIC "SAMUIL ISOPESCU" SUCEAVA', localitate: "SUCEAVA" },
  {
    nume: 'COLEGIUL DE ARTA "CIPRIAN PORUMBESCU" SUCEAVA',
    localitate: "SUCEAVA",
  },
  { nume: 'LICEUL TEORETIC "FILADELFIA" SUCEAVA', localitate: "SUCEAVA" },
  {
    nume: 'LICEUL TEHNOLOGIC "VIRGIL MADGEARU" SUCEAVA',
    localitate: "SUCEAVA",
  },
  { nume: 'LICEUL TEHNOLOGIC "MOLDOVA" SUCEAVA', localitate: "SUCEAVA" },
  { nume: 'LICEUL TEORETIC "JEAN BART" SULINA', localitate: "SULINA" },
  { nume: "LICEUL TEHNOLOGIC NR.1 ŞUNCUIUŞ", localitate: "ŞUNCUIUŞ" },
  {
    nume: "LICEUL TEHNOLOGIC NR.1 SUPLACU DE BARCĂU",
    localitate: "SUPLACU DE BARCAU",
  },
  { nume: "LICEUL TEHNOLOGIC NR.1 SURDUC", localitate: "SURDUC" },
  {
    nume: 'LICEUL TEHNOLOGIC "JOHANNES LEBEL" TALMACIU',
    localitate: "TĂLMACIU",
  },
  { nume: 'LICEUL TEORETIC "PAUL GEORGESCU" ŢĂNDĂREI', localitate: "ŢĂNDĂREI" },
  { nume: "LICEUL TEHNOLOGIC ŢĂNDĂREI", localitate: "ŢĂNDĂREI" },
  {
    nume: 'LICEUL TEORETIC "ION NECULCE" TÂRGU FRUMOS',
    localitate: "TÂRGU FRUMOS",
  },
  { nume: 'LICEUL SPECIAL "MOLDOVA" TG. FRUMOS', localitate: "TÂRGU FRUMOS" },
  {
    nume: 'LICEUL TEHNOLOGIC "PETRU RAREȘ" TÂRGU FRUMOS',
    localitate: "TÂRGU FRUMOS",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GÁBOR ÁRON" TÂRGU SECUIESC',
    localitate: "TÂRGU SECUIESC",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "APOR PÉTER" TÂRGU SECUIESC',
    localitate: "TÂRGU SECUIESC",
  },
  {
    nume: 'LICEUL TEORETIC "NAGY MÓZES" TÂRGU SECUIESC',
    localitate: "TÂRGU SECUIESC",
  },
  {
    nume: "LICEUL TEOLOGIC REFORMAT TÂRGU SECUIESC",
    localitate: "TÂRGU SECUIESC",
  },
  {
    nume: 'LICEUL PEDAGOGIC "BOD PÉTER" TÂRGU SECUIESC',
    localitate: "TÂRGU SECUIESC",
  },
  {
    nume: 'LICEUL TEORETIC "ANDREI BÂRSEANU" TÂRNAVENI',
    localitate: "TÂRNĂVENI",
  },
  { nume: "COLEGIUL TEHNIC TARNAVENI", localitate: "TÂRNĂVENI" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCUŞI" TÂRNĂVENI',
    localitate: "TÂRNĂVENI",
  },
  { nume: "LICEUL TEHNOLOGIC TĂRTĂȘEȘTI", localitate: "TARTAŞEŞTI" },
  { nume: "LICEUL TEHNOLOGIC TASNAD", localitate: "TĂŞNAD" },
  { nume: "LICEUL TEHNOLOGIC TĂTĂRUȘI", localitate: "TATARUŞI" },
  {
    nume: 'LICEUL TEHNOLOGIC "TRAIAN VUIA" TAUTII MAGHERAUS',
    localitate: "TĂUŢII-MĂGHERĂUŞ",
  },
  {
    nume: 'LICEUL TEORETIC "CONSTANTIN ROMANU VIVU" TEACA',
    localitate: "TEACA",
  },
  {
    nume: 'LICEUL TEORETIC "EMIL RACOVIŢĂ" TECHIRGHIOL',
    localitate: "TECHIRGHIOL",
  },
  { nume: 'LICEUL TEHNOLOGIC "OVID CALEDONIU" A TECUCI', localitate: "TECUCI" },
  {
    nume: 'COLEGIUL NATIONAL "CALISTRAT HOGAS" A TECUCI',
    localitate: "TECUCI",
  },
  { nume: 'COLEGIUL NATIONAL "SPIRU HARET" A TECUCI', localitate: "TECUCI" },
  {
    nume: "COLEGIUL NATIONAL DE AGRICULTURA SI ECONOMIE A TECUCI",
    localitate: "TECUCI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ELENA CARAGIANI" A TECUCI',
    localitate: "TECUCI",
  },
  { nume: "LICEUL TEORETIC TEIUS", localitate: "TEIUŞ" },
  { nume: "LICEUL TEHNOLOGIC TELCIU", localitate: "TELCIU" },
  { nume: 'LICEUL TEHNOLOGIC "SF.DIMITRIE“ TEREGOVA', localitate: "TEREGOVA" },
  {
    nume: 'LICEUL TEHNOLOGIC "CAROL I" COMUNA VALEA DOFTANEI',
    localitate: "TEŞILA",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE INDUSTRIE ALIMENTARA ȚIBANA",
    localitate: "ŢIBANA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "PETRE P.CARP" ȚIBĂNEȘTI',
    localitate: "ŢIBANEŞTI",
  },
  { nume: "LICEUL TEHNOLOGIC TICLENI", localitate: "ŢICLENI" },
  { nume: "COLEGIUL TEHNIC ELECTROTIMIS TIMISOARA", localitate: "TIMIŞOARA" },
  { nume: "LICEUL TEORETIC SPECIAL IRIS TIMISOARA", localitate: "TIMIŞOARA" },
  { nume: "COLEGIUL NATIONAL BANATEAN TIMISOARA", localitate: "TIMIŞOARA" },
  {
    nume: "LICEUL TEHNOLOGIC DE INDUSTRIE ALIMENTARA TIMISOARA",
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL TEORETIC"GRIGORE MOISIL" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'COLEGIUL TEHNIC ENERGETIC "REGELE FERDINAND I " TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  { nume: 'COLEGIUL ECONOMIC "F.S. NITTI" TIMISOARA', localitate: "TIMIŞOARA" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "BANATUL" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC PARTICULAR "HENRI COANDA" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: "LICEUL TEHNOLOGIC TRANSPORTURI AUTO TIMISOARA",
    localitate: "TIMIŞOARA",
  },
  { nume: "LICEUL WALDORF TIMISOARA", localitate: "TIMIŞOARA" },
  {
    nume: 'LICEUL TEHNOLOGIC UCECOM "SPIRU HARET" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'COLEGIUL TEHNIC "ION.I.C.BRATIANU" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  { nume: "LICEUL DE ARTE PLASTICE TIMISOARA", localitate: "TIMIŞOARA" },
  { nume: 'LICEUL TEORETIC "VLAD TEPES" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: 'COLEGIUL TEHNIC "HENRI COANDA" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: "LICEUL TEOLOGIC BAPTIST TIMISOARA", localitate: "TIMIŞOARA" },
  {
    nume: 'LICEUL TEORETIC "NIKOLAUS LENAU" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  { nume: "LICEUL TEORETIC MILLENIUM TIMISOARA", localitate: "TIMIŞOARA" },
  {
    nume: 'COLEGIUL TEHNIC "EMANUIL UNGUREANU" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: "LICEUL TEOLOGIC PENTICOSTAL LOGOS TIMISOARA",
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'COLEGIUL NATIONAL "CONSTANTIN DIACONOVICI LOGA" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL TEORETIC "WILLIAM SHAKESPEARE" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'COLEGIUL TEHNIC "DIMITRIE LEONIDA" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL TEOLOGIC ROMANO-CATOLIC "GERHARDINUM" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEULTEORETIC "DOSITEI OBRADOVICI" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'COLEGIUL NATIONAL DE ARTA "ION VIDU" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IOAN SLAVICI" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  {
    nume: 'LICEUL PEDAGOGIC "CARMEN SYLVA" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  { nume: 'COLEGIUL TEHNIC "ION MINCU" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: 'COLEGIUL TEHNIC "AZUR" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: 'LICEUL TEORETIC "J.L.CALDERON" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: 'LICEUL TEORETIC "BARTOK BELA" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: "LICEUL TEORETIC SOCRATES TIMISOARA", localitate: "TIMIŞOARA" },
  { nume: 'COLEGIUL SILVIC "CASA VERDE" TIMISOARA', localitate: "TIMIŞOARA" },
  { nume: 'COLEGIUL NATIONAL "ANA ASLAN" TIMISOARA', localitate: "TIMIŞOARA" },
  {
    nume: 'LICEUL TEHNOLOGIC AGRICOL "PETRU BOTIS" TIMISOARA',
    localitate: "TIMIŞOARA",
  },
  { nume: "COLEGIUL TEHNIC DE VEST TIMISOARA", localitate: "TIMIŞOARA" },
  { nume: 'LICEUL TEORETIC "NICOLAE JIGA" TINCA', localitate: "TINCA" },
  {
    nume: 'COLEGIUL ECONOMIC "ION GHICA" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: "LICEUL TEHNOLOGIC DE TRANSPORTURI AUTO TÂRGOVIȘTE",
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "CONSTANTIN CANTACUZINO" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "IENĂCHIȚĂ VĂCĂRESCU" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL DE ARTE "BĂLAȘA DOAMNA" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRÂNCOVEANU" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE CIORĂNESCU" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "NICOLAE MIHĂESCU" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SPIRU HARET" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VOIEVODUL MIRCEA" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "CONSTANTIN CARABELLA" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. IOAN GURĂ DE AUR" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEORETIC "ION HELIADE RĂDULESCU" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEORETIC "PETRU CERCEL" TÂRGOVIȘTE',
    localitate: "TÎRGOVIŞTE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "EREMIA GRIGORESCU" TG.BUJOR',
    localitate: "TÎRGU BUJOR",
  },
  {
    nume: 'COLEGIUL NATIONAL "TUDOR ARGHEZI" - TIRGU CARBUNESTI',
    localitate: "TÎRGU CĂRBUNEŞTI",
  },
  { nume: "LICEUL CU PROGRAM SPORTIV TG-JIU", localitate: "TÎRGU JIU" },
  { nume: 'COLEGIUL TEHNIC "HENRI COANDA" TG-JIU', localitate: "TÎRGU JIU" },
  { nume: 'COLEGIUL NATIONAL "SPIRU HARET" TG-JIU', localitate: "TÎRGU JIU" },
  {
    nume: 'COLEGIUL NATIONAL "TUDOR VLADIMIRESCU" TG-JIU',
    localitate: "TÎRGU JIU",
  },
  {
    nume: 'COLEGIUL NATIONAL "ECATERINA TEODOROIU" TG-JIU',
    localitate: "TÎRGU JIU",
  },
  { nume: "LICEUL TEOLOGIC TG-JIU", localitate: "TÎRGU JIU" },
  {
    nume: 'COLEGIUL TEHNIC "G-RAL GHEORGHE MAGHERU" TIRGU-JIU',
    localitate: "TÎRGU JIU",
  },
  { nume: 'COLEGIUL AUTO "TRAIAN VUIA" TG-JIU', localitate: "TÎRGU JIU" },
  { nume: "LICEUL ENERGETIC TG-JIU", localitate: "TÎRGU JIU" },
  { nume: 'COLEGIUL TEHNIC "ION MINCU" TG JIU', localitate: "TÎRGU JIU" },
  {
    nume: 'COLEGIUL ECONOMIC "VIRGIL MADGEARU" TG-JIU',
    localitate: "TÎRGU JIU",
  },
  { nume: "COLEGIUL TEHNIC NR 2 TG JIU", localitate: "TÎRGU JIU" },
  {
    nume: 'LICEUL DE ARTE "CONSTANTIN BRAILOIU" TG-JIU',
    localitate: "TÎRGU JIU",
  },
  {
    nume: 'LICEUL TEORETIC "PETRU RARES" TARGU LAPUS',
    localitate: "TÎRGU LĂPUŞ",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE POP DE BASESTI" TARGU LAPUS',
    localitate: "TÎRGU LĂPUŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GRIGORE MOISIL" TARGU LAPUS',
    localitate: "TÎRGU LĂPUŞ",
  },
  {
    nume: 'LICEUL TEORETIC "GHEORGHE MARINESCU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "AUREL PERSU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "GHEORGHE ȘINCAI" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "AVRAM IANCU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "TRAIAN VUIA" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  { nume: 'COLEGIUL NAȚIONAL "UNIREA" TÎRGU MUREȘ', localitate: "TÎRGU MUREŞ" },
  {
    nume: 'LICEUL TEHNOLOGIC "CONSTANTIN BRANCUSI" TARGU MURES',
    localitate: "TÎRGU MUREŞ",
  },
  { nume: 'LICEUL TEORETIC "OMEGA" TÎRGU MUREŞ', localitate: "TÎRGU MUREŞ" },
  {
    nume: 'LICEUL VOCAȚIONAL PEDAGOGIC "MIHAI EMINESCU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'COLEGIUL ECONOMIC "TRANSILVANIA" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  { nume: "LICEUL VOCAȚIONAL REFORMAT TÎRGU MUREȘ", localitate: "TÎRGU MUREŞ" },
  { nume: "LICEUL VOCAȚIONAL DE ARTĂ TÎRGU MUREȘ", localitate: "TÎRGU MUREŞ" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "SZASZ ADALBERT" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'COLEGIUL AGRICOL "TRAIAN SĂVULESCU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ION VLASIU" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ELECTROMUREȘ" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'LICEUL TEORETIC "BOLYAI FARKAS" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "ALEXANDRU PAPIU ILARIAN" TÎRGU MUREȘ',
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: "LICEUL TEOLOGIC ROMANO-CATOLIC TÎRGU MUREȘ",
    localitate: "TÎRGU MUREŞ",
  },
  {
    nume: 'COLEGIUL NAȚIONAL "ȘTEFAN CEL MARE" ORAȘ TÂRGU NEAMȚ',
    localitate: "TÎRGU NEAMŢ",
  },
  { nume: 'LICEUL "VASILE CONTA" ORAȘ TÂRGU NEAMȚ', localitate: "TÎRGU NEAMŢ" },
  {
    nume: 'COLEGIUL TEHNIC "ION CREANGĂ" ORAȘ TÂRGU NEAMȚ',
    localitate: "TÎRGU NEAMŢ",
  },
  { nume: 'COLEGIUL NATIONAL "C.NEGRI" TIRGU OCNA', localitate: "TÎRGU OCNA" },
  { nume: "LICEUL TEHNOLOGIC TARGU OCNA", localitate: "TÎRGU OCNA" },
  {
    nume: "LICEUL TEHNOLOGIC PENTRU TRANSPORT RUTIER TIRNAVA",
    localitate: "TÎRNAVA",
  },
  { nume: "LICEUL TEHNOLOGIC TISMANA", localitate: "TISMANA" },
  { nume: 'LICEUL TEORETIC "IANCU C VISSARION" TITU', localitate: "TITU" },
  { nume: 'LICEUL TEHNOLOGIC "GOGA IONESCU" TITU', localitate: "TITU" },
  { nume: "LICEUL TEHNOLOGIC TODIRENI", localitate: "TODIRENI" },
  { nume: 'LICEUL TEORETIC "KEMÉNY JÁNOS" TOPLIŢA', localitate: "TOPLIŢA" },
  { nume: 'LICEUL TEORETIC "O.C.TĂSLĂUANU" TOPLIŢA', localitate: "TOPLIŢA" },
  { nume: 'COLEGIUL NAȚIONAL "MIHAI EMINESCU" TOPLIŢA', localitate: "TOPLIŢA" },
  { nume: "LICEUL TEHNOLOGIC TOPOLOG", localitate: "TOPOLOG" },
  { nume: 'LICEUL TEORETIC "ION MIHALACHE"', localitate: "TOPOLOVENI" },
  { nume: "LICEUL TEHNOLOGIC TOPRAISAR", localitate: "TOPRAISAR" },
  { nume: 'LICEUL "DEMOSTENE BOTEZ" TRUSESTI', localitate: "TRUŞEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "TUDOR VLADIMIRESCU" COMUNA TUDOR VLADIMIRESCU',
    localitate: "TUDOR VLADIMIRESCU",
  },
  { nume: "LICEUL TEHNOLOGIC TUFENI", localitate: "TUFENI" },
  { nume: 'LICEUL TEORETIC "GRIGORE MOISIL" TULCEA', localitate: "TULCEA" },
  { nume: 'COLEGIUL ECONOMIC "DELTA DUNĂRII" TULCEA', localitate: "TULCEA" },
  { nume: 'COLEGIUL DOBROGEAN "SPIRU HARET" TULCEA', localitate: "TULCEA" },
  { nume: 'COLEGIUL TEHNIC "HENRI COANDĂ" TULCEA', localitate: "TULCEA" },
  { nume: 'COLEGIUL "BRAD SEGAL" TULCEA', localitate: "TULCEA" },
  { nume: 'COLEGIUL "ANGHEL SALIGNY" TULCEA', localitate: "TULCEA" },
  { nume: 'LICEUL TEORETIC "ION CREANGĂ" TULCEA', localitate: "TULCEA" },
  { nume: 'LICEUL DE ARTE "GEORGE GEORGESCU" TULCEA', localitate: "TULCEA" },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. IOAN CASIAN" TULCEA',
    localitate: "TULCEA",
  },
  { nume: 'LICEUL TEHNOLOGIC "ION MINCU" TULCEA', localitate: "TULCEA" },
  {
    nume: 'COLEGIUL AGRICOL "NICOLAE CORNĂȚEANU" TULCEA',
    localitate: "TULCEA",
  },
  { nume: "LICEUL TEHNOLOGIC TURCENI", localitate: "TURCENI" },
  { nume: "COLEGIUL TEHNIC TURDA", localitate: "TURDA" },
  { nume: 'LICEUL TEORETIC "LIVIU REBREANU" TURDA', localitate: "TURDA" },
  { nume: 'LICEUL TEORETIC "JOSIKA MIKLOS" TURDA', localitate: "TURDA" },
  { nume: 'COLEGIUL "EMIL NEGRUȚIU" TURDA', localitate: "TURDA" },
  { nume: 'COLEGIUL NAȚIONAL "MIHAI VITEAZUL" TURDA', localitate: "TURDA" },
  { nume: 'COLEGIUL TEHNIC "DR. IOAN RAȚIU" TURDA', localitate: "TURDA" },
  {
    nume: 'LICEUL TEORETIC "MARIN PREDA" TURNU MĂGURELE',
    localitate: "TURNU MĂGURELE",
  },
  {
    nume: 'COLEGIUL NATIONAL "UNIREA" TURNU MĂGURELE',
    localitate: "TURNU MĂGURELE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "SF. HARALAMBIE" TURNU MĂGURELE',
    localitate: "TURNU MĂGURELE",
  },
  {
    nume: 'SEMINARUL TEOLOGIC ORTODOX "SF. CALINIC CERNICANUL" TURNU MĂGURELE',
    localitate: "TURNU MĂGURELE",
  },
  {
    nume: 'COLEGIUL TEHNIC "G-RAL D. PRAPORGESCU" TURNU MĂGURELE',
    localitate: "TURNU MĂGURELE",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "DR. FLORIAN ULMEANU" ULMENI',
    localitate: "ULMENI",
  },
  {
    nume: 'LICEUL TEORETIC "GEORGE POP DE BASESTI" ULMENI',
    localitate: "ULMENI",
  },
  { nume: 'LICEUL TEHNOLOGIC "RETEZAT" URICANI', localitate: "URICANI" },
  { nume: 'LICEUL TEORETIC "BRÂNCOVEANU VODĂ" URLAȚI', localitate: "URLAŢI" },
  { nume: "LICEUL TEHNOLOGIC URZICENI", localitate: "URZICENI" },
  { nume: 'LICEUL TEHNOLOGIC "SF.ECATERINA" URZICENI', localitate: "URZICENI" },
  {
    nume: 'COLEGIUL NAŢIONAL"GRIGORE MOISIL" URZICENI',
    localitate: "URZICENI",
  },
  { nume: "COLEGIUL TEHNIC NR.1 VADU CRIŞULUI", localitate: "VADU CRIŞULUI" },
  {
    nume:
      'COLEGIUL AGRICOL "GHEORGHE IONESCU-SISEȘTI" COMUNA VALEA CĂLUGĂREASCĂ',
    localitate: "VALEA CALUGAREASCA",
  },
  {
    nume: "LICEUL TEHNOLOGIC ROSIA JIU FARCASESTI",
    localitate: "VALEA CU APĂ",
  },
  {
    nume: "COLEGIUL AGRICOL NR. 1 VALEA LUI MIHAI",
    localitate: "VALEA LUI MIHAI",
  },
  { nume: "LICEUL TEHNOLOGIC VALENI", localitate: "VĂLENI" },
  {
    nume: 'COLEGIUL NAȚIONAL "NICOLAE IORGA" VĂLENII DE MUNTE',
    localitate: "VĂLENII DE MUNTE",
  },
  {
    nume:
      'LICEUL TEHNOLOGIC AGROMONTAN "ROMEO CONSTANTINESCU" VĂLENII DE MUNTE',
    localitate: "VĂLENII DE MUNTE",
  },
  {
    nume: 'LICEUL TEORETIC "MIHAIL KOGALNICEANU" VASLUI',
    localitate: "VASLUI",
  },
  { nume: 'LICEUL TEORETIC "EMIL RACOVITA" VASLUI', localitate: "VASLUI" },
  { nume: 'LICEUL "STEFAN PROCOPIU" VASLUI', localitate: "VASLUI" },
  { nume: 'COLEGIUL ECONOMIC "ANGHEL RUGINA" VASLUI', localitate: "VASLUI" },
  { nume: "LICEUL CU PROGRAM SPORTIV VASLUI", localitate: "VASLUI" },
  { nume: 'LICEUL TEHNOLOGIC "ION MINCU" VASLUI', localitate: "VASLUI" },
  {
    nume: 'LICEUL TEORETIC "ION LUCA" VATRA DORNEI',
    localitate: "VATRA DORNEI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "TARA DE SUS" VATRA DORNEI',
    localitate: "VATRA DORNEI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "VASILE DEAC" VATRA DORNEI',
    localitate: "VATRA DORNEI",
  },
  { nume: "LICEUL TEHNOLOGIC", localitate: "VEDEA" },
  { nume: "LICEUL TEHNOLOGIC COMUNA VERNEŞTI", localitate: "VERNEŞTI" },
  {
    nume: 'LICEUL TEHNOLOGIC "PETRU RARES" SAT VETRISOAIA',
    localitate: "VETRIŞOAIA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "ION NISTOR" VICOVU DE SUS',
    localitate: "VICOVU DE SUS",
  },
  {
    nume: 'COLEGIUL TEHNIC "ALEXANDRU BĂRBAT" VICTORIA',
    localitate: "VICTORIA",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "IONEL TEODOREANU" VICTORIA',
    localitate: "VICTORIA",
  },
  {
    nume: 'LICEUL TEORETIC "ION CODRU DRĂGUȘANU" VICTORIA',
    localitate: "VICTORIA",
  },
  { nume: "LICEUL TEORETIC VIDELE", localitate: "VIDELE" },
  { nume: 'LICEUL "SIMION MEHEDINTI" VIDRA', localitate: "VIDRA" },
  { nume: 'LICEUL TEORETIC "ALEXANDRU ROSETTI"', localitate: "VIDRA" },
  { nume: "LICEUL TEHNOLOGIC VINGA", localitate: "VINGA" },
  { nume: 'LICEUL TEORETIC "DR.VICTOR GOMOIU"', localitate: "VÎNJU MARE" },
  {
    nume: 'LICEUL TEORETIC "BOGDAN VODA" VISEU DE SUS',
    localitate: "VIŞEU DE SUS",
  },
  { nume: "LICEUL TEHNOLOGIC VISEU DE SUS", localitate: "VIŞEU DE SUS" },
  {
    nume: 'LICEUL TEORETIC "GEORGE POP DE BASESTI" VISEU DE SUS',
    localitate: "VIŞEU DE SUS",
  },
  { nume: 'LICEUL TEORETIC "MIHAI VITEAZUL" VIȘINA', localitate: "VIŞINA" },
  { nume: "LICEUL TEHNOLOGIC VITOMIRESTI", localitate: "VITOMIREŞTI" },
  { nume: "LICEUL TEHNOLOGIC VLĂDENI", localitate: "VLADENI" },
  { nume: "LICEUL TEHNOLOGIC SAT VLADIA", localitate: "VLADIA" },
  { nume: 'LICEUL TEHNOLOGIC "GÁBOR ÁRON" VLĂHIȚA', localitate: "VLĂHIŢA" },
  { nume: "LICEUL TEHNOLOGIC VOINEȘTI", localitate: "VOINEŞTI" },
  { nume: 'LICEUL TEHNOLOGIC "NICOLAE BĂLCESCU"', localitate: "VOLUNTARI" },
  {
    nume: 'LICEUL TEORETIC "MARK TWAIN INTERNATIONAL"',
    localitate: "VOLUNTARI",
  },
  {
    nume: "AMERICAN INTERNATIONAL SCHOOL OF BUCHAREST",
    localitate: "VOLUNTARI",
  },
  {
    nume: 'LICEUL TEHNOLOGIC "STEFAN CEL MARE SI SFANT" VORONA',
    localitate: "VORONA",
  },
  { nume: 'COLEGIUL TEHNIC "MIHAI VITEAZU" VULCAN', localitate: "VULCAN" },
  {
    nume: 'COLEGIUL TEHNIC "ALESANDRU PAPIU ILARIAN" ZALĂU',
    localitate: "ZALĂU",
  },
  { nume: 'LICEUL REFORMAT "WESSELENYI" ZALĂU', localitate: "ZALĂU" },
  { nume: 'COLEGIUL NATIONAL "SILVANIA" ZALĂU', localitate: "ZALĂU" },
  { nume: 'LICEUL DE ARTĂ "IOAN SIMA" ZALĂU', localitate: "ZALĂU" },
  { nume: 'LICEUL ORTODOX "SF.NICOLAE" ZALĂU', localitate: "ZALĂU" },
  {
    nume: 'LICEUL CU PROGRAM SPORTIV "AVRAM IANCU" ZALĂU',
    localitate: "ZALĂU",
  },
  { nume: 'LICEUL TEHNOLOGIC "MIHAI VITEAZUL" ZALĂU', localitate: "ZALĂU" },
  { nume: 'LICEUL TEHNOLOGIC "VOIEVODUL GELU" ZALĂU', localitate: "ZALĂU" },
  { nume: 'LICEUL PEDAGOGIC "GHEORGHE ȘINCAI" ZALĂU', localitate: "ZALĂU" },
  {
    nume: 'LICEUL TEORETIC "MITROPOLIT IOAN MEȚIANU" ZĂRNEȘTI',
    localitate: "ZĂRNEŞTI",
  },
  { nume: 'LICEUL TEHNOLOGIC "MALAXA" ZĂRNEȘTI', localitate: "ZĂRNEŞTI" },
  { nume: 'LICEUL TEORETIC "DR.P. BOROS FORTUNAT" ZETEA', localitate: "ZETEA" },
  { nume: "LICEUL TEORETIC ZIMNICEA", localitate: "ZIMNICEA" },
  { nume: 'LICEUL "CORNELIU MEDREA" ZLATNA', localitate: "ZLATNA" },
  {
    nume: 'COLEGIUL TEHNIC "MARCEL GUGUIANU" SAT ZORLENI',
    localitate: "ZORLENI",
  },
];
